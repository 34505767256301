/*

Hook komponenta pomocou ktorej sa data stanu persistentnymi ako cookies
 

pouzitie:

import UseLocalStorage from '@Core/components/UseLocalStorage';

pouzit cez useState takto:

const [pictureList, setPictureList] = UseLocalStorage('obrazky',tempPics);

 

prvy parameter je Key, druhy je lubovolna hodnota v state ktora sa tak stava permanentnou

 

Vznik:

 

  17.5.2021, prevzate z https://usehooks.com/useLocalStorage/

 

*/

import React, { useState } from "react";

 

// Hook

function UseLocalStorage(key, initialValue) {

  // State to store our value

  // Pass initial state function to useState so logic is only executed once

  const [storedValue, setStoredValue] = useState(() => {

    try {

      // Get from local storage by key

      const item = window.localStorage.getItem(key);

      // Parse stored json or if none return initialValue

      return item ? JSON.parse(item) : initialValue;

    } catch (error) {

      // If error also return initialValue

      console.log(error);

      return initialValue;

    }

  });

 

  // Return a wrapped version of useState's setter function that ...

  // ... persists the new value to localStorage.

  const setValue = (value) => {

    try {

      // Allow value to be a function so we have same API as useState

      const valueToStore =

        value instanceof Function ? value(storedValue) : value;

      // Save state

      setStoredValue(valueToStore);

      // Save to local storage

      window.localStorage.setItem(key, JSON.stringify(valueToStore));

    } catch (error) {

      // A more advanced implementation would handle the error case

      console.log(error);

    }

  };

 

  return [storedValue, setValue];

}

 

export default UseLocalStorage;