import React, {useState, useRef, useEffect} from 'react';
import InstallPWA from "./components/InstallPWA";
import './App.css';
import home from './asets/home1.png';
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import CallFetch from "./components/CallFetch";
import UseLocalStorage from './components/UseLocalStorage';
import datax from "./data.js";

let aktualizacia='14.9.2024';
let transProgress=0;
let _xDown, _yDown;
let localCurrentQuestion=-1;
//let stav=0; //0 intro, 1 otazkovanie, 2 end

function App() {
const [stav, setStav] = useState(0);
const [loaded, setLoaded]=useState(0);
const [mainData, setMainData] = useState([]);
const [progresss, setProgresss] = useState(0); //stale sa zvacsujuca hodnota pre prekreslovanie
const [currentQuestion, setCurrentQuestion] = UseLocalStorage('currentq',-1); 
const [answers, setAnswers] = UseLocalStorage('answersq',[]); 

useEffect(() => {
    //nastavim lokalnu premennu z localstorage
    localCurrentQuestion=currentQuestion;

    setMainData(datax);
    setLoaded(0);


    //nacitam data zo servera, ak sa load nepodari, vezmem offline data a nastavim priznak loaded na 0
    // CallFetch.post('./getq/', { hash: 'xxx' })
    // .then(data => {
    //     if (Array.isArray(data.response)) {
    //         if (data.response.length==2) {
    //             setMainData(data.response);
    //             setLoaded(1);
    //         } else {
    //             setMainData(datax);
    //             setLoaded(0);
    //         }
    //     } else {
    //         setMainData(datax);
    //         setLoaded(0);
    //     }

    //  })
    // .catch(error => {console.error('There was an error!', error); setMainData(datax); setLoaded(0)});
  }, []);

useEffect(() => {
    if (stav==1) {
    }
}, [stav]);

useEffect(() => {
    //alert(5);
    //console.log(stav);
    if (stav==1) {
        //localCurrentQuestion=currentQuestion;
        displayQuestion(currentQuestion);
    }    
}, [progresss]);
  
/* SWIPE */
document.addEventListener(
    'touchstart',
    handleTouchStart,
    false
);
document.addEventListener(
    'touchmove',
    handleTouchMove,
    false
);
function ignoreSwipe(event) {
  // if some touches come from elements with ignoreswipe class > ignore
  return Array.from(event.touches).some((t) =>
      t.target.classList.contains('noswipe')
  );
}  
function handleTouchStart(event) {
if (ignoreSwipe(event)) {
    _xDown = undefined;
    _yDown = undefined;
    return;
}

const firstTouch = event.touches[0];
_xDown = firstTouch.clientX;
_yDown = firstTouch.clientY;
}
function handleTouchMove(event) {

if (!_xDown || !_yDown) {
    return;
}

const xUp = event.touches[0].clientX;
const yUp = event.touches[0].clientY;

const xDiff = _xDown - xUp;
const yDiff = _yDown - yUp;

if (Math.abs(xDiff) > Math.abs(yDiff)) {
    /*most significant*/
    if (xDiff > 0) {
        /* left swipe */
        if (Math.abs(xDiff)>5) {
            $('#nextBtn').click();
        }
    } else {
        /* right swipe */

        if (Math.abs(xDiff)>5) {
            $('#prevBtn').click();
        }
    }
} else {
    if (yDiff > 0) {
        /* up swipe */
//        console.log('app: up swipe ', true);
//        alert('up');
    } else {
        /* down swipe */
//        console.log('app: down swipe ', true);
//        alert('down');
    }
}

/* reset values */
_xDown = null;
_yDown = null;
}
/* SWIPE END*/
function startNew() {
    if (currentQuestion>1) {
        if (!window.confirm("Si si istý že chceš íst odznova?")) return;
    }
    setAnswers([]);
    setCurrentQuestion(0);
    localCurrentQuestion=0;
    setStav(1);
    setProgresss(progresss+1);
    //displayQuestion(currentQuestion);
}

function startContinue() {
    setStav(1);
    localCurrentQuestion=currentQuestion;
    setProgresss(progresss+1);
}

function vyfarbi(spravna,odpoved) {
    if ((odpoved<1) || (odpoved>3)) {
        $('#lmo1').css('background','silver') ;
        $('#lmo2').css('background','silver') ;
        $('#lmo3').css('background','silver') ;
    } else {
        $('#lmo1').css('background','silver') ;
        $('#lmo2').css('background','silver') ;
        $('#lmo3').css('background','silver') ;
        $('#lmo'+spravna).css('background','#afffaf') ;
        if (spravna!=odpoved) {
            $('#lmo'+odpoved).css('background','#ffafaf') ;
        } 
    }
}

function displayBack(co) {
    $('#col2').show();
    $('#'+co).hide();
    transProgress=0;
}

function generujReklamu() {
    let retazce=[];
    retazce[0]='<img src="./images/dama.png"><br><br>Hraj Dámu proti iným kvízerom';
    retazce[1]='<img src="./images/duel.png"><br><br>Poraz súpera vo vedomostnom dueli';
    retazce[2]='<img src="./images/galeria.png"><br><br>Vytvor si profil a nájdi si priateľov';
    retazce[3]='<img src="./images/hadanka1.png"><br><br>Hádanky na každý deň';
    retazce[4]='<img src="./images/chat1.png"><br><br>Stovky priateľov na Chatoch';
    retazce[5]='<img src="./images/chest3p.png"><br><br>Dokážeš otvoriť truhlicu a získať odmenu?';
    retazce[6]='<img src="./images/kakuro.png"><br><br>Logicka hra Kakuro';
    retazce[7]='<img src="./images/kartovymahjong.png"><br><br>Kartový mahjong';
    retazce[8]='<img src="./images/krizovky.png"><br><br>Švédske krížovky';
    retazce[9]='<img src="./images/kvizwar.png"><br><br>Strategická hra KvízWar. Poraz človeka alebo počítač!!';
    retazce[10]='<img src="./images/magicka_gula.png"><br><br>Magická guľa. Najlepší Chat na KvízMajstrovi';
    retazce[11]='<img src="./images/mahjong.png"><br><br>Najhranejší Mahjong na slovensku';
    retazce[12]='<img src="./images/miny.png"><br><br>Minesweeper, nájdeš všetky míny?';
    retazce[13]='<img src="./images/pexeso.png"><br><br>Simultánne pexeso';
    retazce[14]='<img src="./images/piskvorky.png"><br><br>Poraz súpera v piškvorkách';
    retazce[15]='<img src="./images/sachy.png"><br><br>Šachová liga na KvízMajstrovi';
    retazce[16]='<img src="./images/sedma.png"><br><br>Hra Sedma Online, proti človeku alebo počítaču';
    retazce[17]='<img src="./images/slovnahra.png"><br><br>Idú ti písmenká? Dokáž to v Slovnej hre.';
    retazce[18]='<img src="./images/soliter.png"><br><br>Solitér, relax s kartami.';
    retazce[19]='<img src="./images/sudoku.png"><br><br>Sudoku liga, online na KvízMajstrovi';
    retazce[20]='<img src="./images/testpamat.png"><br><br>Trénuj si pamäť s Kvízmajstrom';
    retazce[21]='<img src="./images/tetris.png"><br><br>Logická hra Tetris. Si lepší ako ostatní?';
    retazce[22]='<img src="./images/tipovanie.png"><br><br>Tipovanie zápasov s KvízMajstrom';
    retazce[23]='<img src="./images/traffic1.png"><br><br>Vždy aktuálne Testy na vodičák len pre Vás';
    retazce[24]='<img src="./images/vlastne.png"><br><br>Vytvor si vlastný kvíz pre priateľov';
    retazce[25]='<img src="./images/vodicak.png"><br><br>Učetnie s KvízMajstrom je vždy zábava';
    retazce[26]='<img src="./images/znacky.png"><br><br>Aktuálne značky, križovatky, testy. Len na KvízMajstrovi.';
    retazce[27]='<img src="./logo.jpg" width=50><br><br>Online diskusie na KvízMajstrovi sú vždy plné skvelých ľudí.';
    retazce[28]='<img src="./logo.jpg" width=50><br><br>Zaregistrujte a zabávajte sa ešte dnes.';
    retazce[29]='<img src="./logo.jpg" width=50><br><br>KvízMajster bude vždy zadarmo pre všetkých.';
    let rand=Math.floor(Math.random()*retazce.length);
    return '<center><h4>www.kvizmajster.sk<br><br>'+retazce[rand]+'</h4></center>';
}

function showInfo() {
    setStav(2);
}

//presun na dalsiu utazku
function moveNext() {
    if (transProgress>0) return; //ak sa pohybuje reklama, nedovol posuvat
    $('#nextBtn').css('background','white');
    $('#swiper').hide();
    if (mainData[0].length>(localCurrentQuestion+1)) {        
        //alert('next: '+localCurrentQuestion);
        localCurrentQuestion=localCurrentQuestion+1;
        setCurrentQuestion(currentQuestion+1);
        setProgresss(progresss+1);
        // $('#col2').hide();
        // $('#kmReklama').html(generujReklamu());
        // $('#kmReklama').show();
        // $('#kmReklama').css('left','500px');
        // transProgress=1;
        // $("#kmReklama").animate({ "left": "4" }, {
        //     duration: 500,
        //     easing: "swing",
        //     complete: function() {
        //         setTimeout(function() {
        //             displayBack('kmReklama');
        //         }, 2500); // 1000 milliseconds delay                
        //     }
        // });
        
    } else {
        alert('Si na poslednej otazke \n Viac na www.kvizmajster.sk');
    }
}


//presun na dalsiu utazku
function moveBack() {
    if (transProgress>0) return; //ak sa pohybuje reklama, nedovol posuvat
    $('#nextBtn').css('background','white');
    $('#swiper').hide();
    if (currentQuestion>0) {
        //alert(localCurrentQuestion);        
        localCurrentQuestion=localCurrentQuestion-1;        
        setCurrentQuestion(currentQuestion-1);
        setProgresss(progresss+1);
        $('#col2').hide();
        $('#kmReklama').html(generujReklamu());
        $('#kmReklama').show();
        $('#kmReklama').css('left','-500px');
        transProgress=1;
        $("#kmReklama").animate({ "left": "4" }, {
            duration: 500,
            easing: "swing",
            complete: function() {
                setTimeout(function() {
                    displayBack('kmReklama');
                }, 2500); // 1000 milliseconds delay 
            }
        });

    }
}


function sendAnswer(Nr) { //v Nr je 1/2/3
    if (answers[currentQuestion]>0) {
        return;//
    }
    answers[currentQuestion]=Nr;
    setAnswers(answers);
    let spravna=mainData[0][currentQuestion].nl_correct;
    vyfarbi(spravna,Nr);
    //na prvej odpovedi zobrazim info ze swipe
    if (currentQuestion==0) {
        $('#swiper').show();
    }
    $('#nextBtn').css('background','#afffaf');
}

function displayQuestion(qNr) {
    //console.log(qNr);
    let obrazok=mainData[0][qNr].uv_obrazok;
    if (obrazok>'') {
        if (mainData[0][qNr].nl_okruh_id==4) {
            //krizovatka
            $("#otobr").html('<img src="https://www.kvizmajster.sk/vodicak/'+obrazok+'" width="300px !important" max-height=200>');
        } else {
            $("#otobr").html('<img src="https://www.kvizmajster.sk/vodicak/'+obrazok+'" max-width="200px" max-height="200px">');
        }
        
    } else {
        $("#otobr").html('');
    }
    $("#ottxt").html(mainData[0][qNr].uv_otazka);
    $("#od1").html('<span style="background:silver;padding:5px;border-radius:5px">A</span> '+mainData[0][qNr].uv_odpoveda);
    $("#od2").html('<span style="background:silver;padding:5px;border-radius:5px">B</span> '+mainData[0][qNr].uv_odpovedb);
    $("#od3").html('<span style="background:silver;padding:5px;border-radius:5px">C</span> '+mainData[0][qNr].uv_odpovedc);
    //console.log(answers.length);
    //console.log(qNr);
    // if ()
    if (answers.length<(localCurrentQuestion+1)) {
        answers.push(0);
        //odlozim si do localstorage
        setAnswers(answers);
    }
    let spravna=mainData[0][qNr].nl_correct;
     console.log(spravna);
    let odpoved=answers[qNr];
     console.log(odpoved);
    vyfarbi(spravna,odpoved);
    console.log(answers);
    //if (answers[currentQuestion])

    //zistenie uspesnosti
    let uspesnost=0;let i=0;
    for (i=0;i<answers.length;i++) {
        if (answers[i]==mainData[0][i].nl_correct) uspesnost++;
    }    

    //zobrazenie paticky
    let retazec='Otázka '+(qNr+1)+' / '+mainData[0].length;
    retazec+='&nbsp;&nbsp;&nbsp;&nbsp; Správne odpovede: '+uspesnost;
    $("#paticka").html(retazec);
}

function main() {
    if (stav==0) {
        const datum=new Date();
        return(
            <>
            {/* horna lista */}
            <div style={{"width":"100%","height":"35px","padding":"5px","position":"relative","marginBottom":"10px"}}>
                <a href="/"><img style={{position:"absolute","left":"2px","cursor":"pointer"}} width="40" title='Štart' src={home} /></a>
                <InstallPWA />
                <a href="https://www.kvizmajster.sk/?akcia=registracia#mainshow" target="_blank" style={{position:"absolute","right":"2px","cursor":"pointer"}}>www.kvizmajster.sk</a>
            </div>

            <h1 style={{color:'#309010'}}><b>Otázky na vodičák {datum.getFullYear()}</b></h1><br/>
            <button className="okbtn" onClick={()=>{startNew()}}>Spustiť odznova</button><br/><br/>
            <button className="okbtn" onClick={()=>{startContinue()}}>Pokračovať</button><br/><br/>
            <button className="okbtn" onClick={()=>{showInfo()}}>Informácie</button><br/><br/>
            <br />
            <div style={{fontSize:"16px",position:"absolute","left":"6px","cursor":"pointer"}}>verzia: 1.1.14.9.2024</div>
            </>        
        );
    } else if (stav==1) {
        return(
            <>
            {/* horna lista */}
            <div style={{"width":"100%","height":"35px","padding":"5px","position":"relative","marginBottom":"10px"}}>
                <a href="/"><img style={{position:"absolute","left":"2px","cursor":"pointer"}} width="40" title='Štart' src={home} /></a>
                
                <div onClick={()=>{moveBack()}} className="okbtn" id="prevBtn" style={{fontSize:'18px',position:"absolute","left":"80px","cursor":"pointer"}}>Späť</div>
                <div onClick={()=>{moveNext()}} className="okbtn" id="nextBtn" style={{fontSize:'18px',position:"absolute","left":"150px","cursor":"pointer"}}>Ďalej</div>
            </div>
            
            <div className='paticka' id="paticka"></div>
            <div className="swipe" id="swiper">
                <div className="path"></div>
                <div className="hand-icon"></div>
                <div id="infodiv" style={{color:"white"}}> * swipe na ďalšiu otázku</div>
            </div>
            

            <div id="kmReklama" style={{marginTop:'10px',color:'black',position:'absolute', width:'350px', display:'none', overflow:'hidden', background:'white'}}>
                Portál www.kvizmajster.sk<br/>
                Najzábavnejšia Slovenská webka :) <br/> Príď si pokecať a nájsť nových priateľov. 
            </div>
            {/* <div id="rekVpravo" style={{color:'black',position:'absolute', width:'400px', display:'none', overflow:'hidden', background:'white'}}>
                <h1>Na www.kvizmajster.sk nájdeš skvelých ľudí ochotných pomocť a poradiť.</h1>
            </div> */}

            {/* <div id="frame" style={{position:'absolute',width:'98%'}}> */}
                <div id="col2" style={{position:'relative', overflow:'hidden', width:'98%'}}>
                    <div className="container-fluid">
                        <div className="answercontainer container" style={{background:'white',minHeight:'150px !important'}}><div id="ottxt" className="">...</div><div id="otobr" className=""></div></div>
                    </div>
                    <div className="container-fluid" >
                        <div style={{borderRadius:'5px'}} className="answercontainer container" id="lmo1" onClick={()=>{sendAnswer(1);}}><div style={{borderRadius:'5px',width:'100%',textAlign:'left'}} id="od1" className="answer">...</div></div>
                        <div style={{borderRadius:'5px'}} className="answercontainer container" id="lmo2" onClick={()=>{sendAnswer(2);}}><div style={{borderRadius:'5px',width:'100%',textAlign:'left'}} id="od2" className="answer">...</div></div>
                        <div style={{borderRadius:'5px'}} className="answercontainer container" id="lmo3" onClick={()=>{sendAnswer(3);}}><div style={{borderRadius:'5px',width:'100%',textAlign:'left'}} id="od3" className="answer">...</div></div>
                    </div>
                    {/* <br/><br/><br/><br/>zzz */}
                    {/* <div className="container-fluid">
                        <div className="ways">
                            
                        </div>
                    </div> */}

                </div>
                
            {/* </div> */}
            {/* <div style={{clear:'both',position:'relative','height':'40px',width:'300px'}}><center> www.kvizmajster.sk</center></div> */}
            <br/><br/>
            </>        
        );
    } else if (stav==2) {
        return(
            <>
            {/* horna lista */}
            <div style={{"width":"100%","height":"35px","padding":"5px","position":"relative","marginBottom":"10px"}}>
                <a href="/"><img style={{position:"absolute","left":"0px","cursor":"pointer"}} width="40" title='Štart' src={home} /></a>                
            </div>
            <h3>Otázky na vodičák pre skupinu B, aktualizované {aktualizacia}</h3>
            <br/><br/>
            <div style={{textAlign:'left',padding:'10px',fontSize:'20px'}}>
                <li><b>Je táto aplikácia zadarmo?</b> Áno, kvízmajster je proste zadara. Celá naša mini-sociálna sieť je vytvorená ľuďmi pre ľudí s účelom pomáhať.<br />
                Navyše, ak sa regneš a prídeš si anonymne pokecať na vedomák (čosi ako discord na webe), možno vyhráš nejaké sponzorské ceny.</li>
                <li><b>Táto apka je určená pre mobilné telefóny.</b> Na PC odporúčame použiť testy na našej stránke <a href="https://www.kvizmajster.sk/">www.kvizmajster.sk</a></li>
                <li><b>Ako to bude prebiehať na polícii?</b> Ak si sa učil s KvízMajstrom, žiadny strach. Sadneš za komp, naťukáš rodné čislo a za 30 minút vyplníš presne <a href="https://www.kvizmajster.sk/vodicak/" target="_blank">takýto test</a>.</li>
                <li><b>Môžem používať pomôcky?</b> Otázky su vždy náhodne vygenerované. Poradie odpovedí tiež, takže ťaháky nehrozia.<br />
                 Navyše celý čas ťa v náhodných intervaloch môže snímať kamera, takže sa to radšej poriadne nauč ^^</li>
                <li><b>Sú tieto otázky platné?</b> Ku dňu vydania tejto aplikácie sú otázky na 99% zhodné so sadou otázok v testoch ktoré dostaneš na polícii.</li>
                <li><b>Čo ak sa učím na inú skupinu ako B?</b> Odporúčame zopakovať si špecifické otázky pre danú skupinu. Nájdeš ich tiež na kvízmajstrovi.</li>
            </div>
            <br/><br/>Tally Ho!!!
            </>        
        );
    }
}

  return (
    <div className="App">
      {main()}
    </div>
  );
}

export default App;
