let datax=[
    [
        {
            "nl_otazka_id": "1515",
            "uv_otazka": "Ak svieti tento signál samostatne pri riadení cestnej premávky v križovatke, znamená pre vodiča:",
            "uv_odpoveda": "“Čakaj“; vodič musí zastaviť vozidlo pred križovatkou, pričom ak je pri rozsvietení tohto signálu už tak blízko, že nemôže zastaviť vozidlo bezpečne, smie pokračovať v jazde.",
            "uv_odpovedb": "“Stoj“; vodič musí zastaviť vozidlo pred križovatkou.",
            "uv_odpovedc": "“Voľno“; vodič smie pokračovať v jazde.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "obr3/pcp/15_2b.png",
            "body": "3",
            "poznamka": "",
            "hash": "6154e220668b70b7e5b1"
        },
        {
            "nl_otazka_id": "1016",
            "uv_otazka": "Neohrozením sa rozumie povinnosť účastníka cestnej premávky",
            "uv_odpoveda": "počínať si tak, aby inému účastníkovi cestnej premávky nevzniklo nijaké nebezpečenstvo.",
            "uv_odpovedb": "neobchádzať ani nepredchádzať vozidlo prepravujúce nebezpečné veci a jazdiť za takýmto vozidlom vo vzdialenosti najmenej 50 m.",
            "uv_odpovedc": "počínať si tak, aby inému účastníkovi cestnej premávky nijako neprekážal.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "7f062893c35bbf49a64f"
        },
        {
            "nl_otazka_id": "1300",
            "uv_otazka": "Vodič smie dávať zvukové výstražné znamenie",
            "uv_odpoveda": "len vtedy, ak je to potrebné na odvrátenie hroziaceho nebezpečenstva, a mimo obce aj na upozornenie vodiča predchádzaného vozidla.",
            "uv_odpovedb": "vždy vtedy, ak je to potrebné na zabezpečenie plynulej jazdy jeho vozidla, najmä na upozornenie vodiča predchádzaného vozidla v obci.",
            "uv_odpovedc": "len v obci na upozornenie vodiča predchádzaného vozidla.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "bfc705b2066f50946fbe"
        },
        {
            "nl_otazka_id": "1198",
            "uv_otazka": "Smie sa vodič otáčať na ceste označenou touto dopravnou značkou?",
            "uv_odpoveda": "Nie, nesmie sa otáčať.",
            "uv_odpovedb": "Áno, smie sa otáčať, ak tým neohrozí ostatných účastníkov cestnej premávky.",
            "uv_odpovedc": "Áno, smie sa otáčať.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/321.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "97368e6985c3a5583c03"
        },
        {
            "nl_otazka_id": "1386",
            "uv_otazka": "Toto osobitné označenie sa môže používať na vozidle vedenom",
            "uv_odpoveda": "osobou so zrakovým postihnutím.",
            "uv_odpovedb": "vodičom začiatočníkom.",
            "uv_odpovedc": "osobou so sluchovým postihnutím.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "obr3/pcp/25_2_4.png",
            "body": "3",
            "poznamka": "",
            "hash": "3c1898499a1cc35b7a61"
        },
        {
            "nl_otazka_id": "1523",
            "uv_otazka": "Ak svieti tento doplnkový signál pri riadení cestnej premávky v križovatke a je umiestnený v protiľahlom rohu križovatky, pre vodiča znamená, že",
            "uv_odpoveda": "musí zastaviť vozidlo.",
            "uv_odpovedb": "môže odbočiť vľavo, ak dodrží pravidlo o odbočovaní vľavo.",
            "uv_odpovedc": "premávka protiidúcich vozidiel je zastavená signálom červenej farby v tvare plného kruhu a vodič môže odbočiť vľavo.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "obr3/pcp/15_6.png",
            "body": "3",
            "poznamka": "",
            "hash": "ebbda11f2f2ab1915885"
        },
        {
            "nl_otazka_id": "1339",
            "uv_otazka": "Pred vjazdom do priebežných jazdných pruhov na diaľnici vodič",
            "uv_odpoveda": "je povinný použiť pripájací jazdný pruh; tam, kde pripájací jazdný pruh nie je, vodič má prednosť v jazde pred vozidlami idúcimi v priebežných jazdných pruhoch.",
            "uv_odpovedb": "osobného motorového vozidla nie je povinný použiť existujúci pripájací jazdný pruh.",
            "uv_odpovedc": "je povinný použiť pripájací jazdný pruh; tam, kde pripájací jazdný pruh nie je, vodič musí dať prednosť v jazde vozidlám idúcim v priebežných jazdných pruhoch.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "4a991c2f8cf5f0250fdf"
        },
        {
            "nl_otazka_id": "1489",
            "uv_otazka": "Smie vodič osobného automobilu stáť v obytnej zóne?",
            "uv_odpoveda": "Áno.",
            "uv_odpovedb": "Nie; ani vtedy ak to umožňuje dopravná značka.",
            "uv_odpovedc": "Nie, ak dopravnou značkou nie je určené inak.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "024913eb63ab97dc9660"
        },
        {
            "nl_otazka_id": "1565",
            "uv_otazka": "Vodič motorového vozidla s najväčšou prípustnou celkovou hmotnosťou prevyšujúcou 3 500 kg, okrem vodiča autobusu, smie na diaľnici jazdiť rýchlosťou najviac",
            "uv_odpoveda": "90 km.h-1.",
            "uv_odpovedb": "130 km.h-1.",
            "uv_odpovedc": "110 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3750e760aa472bc3ca2b"
        },
        {
            "nl_otazka_id": "1573",
            "uv_otazka": "Môže vodič motorového vozidla jazdiť vyššou rýchlosťou na úseku cesty označenom touto dopravnou značkou?",
            "uv_odpoveda": "Áno, smie jazdiť vyššou rýchlosťou; bez ohľadu na konštrukčnú rýchlosť ťažného vozidla, berie ohľad iba na konštrukčnú rýchlosť prípojného vozidla.",
            "uv_odpovedb": "Nesmie jazdiť vyššou rýchlosťou.",
            "uv_odpovedc": "Áno, smie jazdiť vyššou rýchlosťou; ak pritom neprekročí najväčšiu konštrukčnú rýchlosť vozidla, a ak ide o jazdnú súpravu, ani najväčšiu konštrukčnú rýchlosť nijakého z vozidiel súpravy.",
            "nl_okruh_id": "2",
            "nl_correct": "3",
            "uv_obrazok": "obr3/upp_or/c_15.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "6c91612a9ddca15843a9"
        },
        {
            "nl_otazka_id": "1688",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Označuje priechod pre chodcov; značka upozorňuje na nebezpečenstvo pohybu chodcov na vozovke.",
            "uv_odpovedb": "Označuje komunikáciu určenú pre chodcov a prikazuje chodcovi použiť takto označenú cestičku.",
            "uv_odpovedc": "Zakazuje vstup chodcom na chodník.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/220.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "8d8a05f9b05639178e96"
        },
        {
            "nl_otazka_id": "1806",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Kaplnka",
            "uv_odpovedb": "Budova Červeného kríža",
            "uv_odpovedc": "Nemocnica",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/2-38.png",
            "body": "2",
            "poznamka": "",
            "hash": "e5618a5d6116ceffe19e"
        },
        {
            "nl_otazka_id": "1748",
            "uv_otazka": "Táto dodatková tabuľka",
            "uv_odpoveda": "Vyznačuje parkovisko pre nákladné automobily.",
            "uv_odpovedb": "Obmedzuje platnosť značky, pod ktorou je umiestnená, len na vyznačených účastníkov cestnej premávky.",
            "uv_odpovedc": "Vyznačuje druh vozidla, na ktoré sa nevzťahuje značka, pod ktorou je tabuľka umiestnená.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/506.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "e5a924906957296478f2"
        },
        {
            "nl_otazka_id": "1640",
            "uv_otazka": "Táto dopravná značka upozorňuje na",
            "uv_odpoveda": "Miesto alebo úsek cesty, na ktorom môže dochádzať k častému výskytu domácich zvierat.",
            "uv_odpovedb": "Miesto kde je zákaz hnania zvierat cez cestu.",
            "uv_odpovedc": "Zvýšené nebezpečenstvo neočakávaného pohybu voľne žijúcej zveri cez cestu.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/145.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "fe660d8d9c25238113a2"
        },
        {
            "nl_otazka_id": "1635",
            "uv_otazka": "Táto dopravná značka upozorňuje na",
            "uv_odpoveda": "Blízkosť školy.",
            "uv_odpovedb": "Priechod pre chodcov.",
            "uv_odpovedc": "Podchod alebo nadchod.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/142.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "a2aa17ea1928d3fb55d3"
        },
        {
            "nl_otazka_id": "1641",
            "uv_otazka": "Táto dopravná značka upozorňuje na",
            "uv_odpoveda": "Nerovnosť vozovky.",
            "uv_odpovedb": "Prácu na ceste alebo v jej blízkosti, ktorá môže ohroziť bezpečnosť cestnej premávky alebo pri ktorej môžu byť ohrození pracovníci, ktorí túto činnosť vykonávajú.",
            "uv_odpovedc": "Výkopové práce.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/131.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "11cf629d0044ccce79a8"
        },
        {
            "nl_otazka_id": "1628",
            "uv_otazka": "Táto dopravná značka upozorňuje na",
            "uv_odpoveda": "Zúženú vozovku z vyznačenej strany alebo strán.",
            "uv_odpovedb": "miesto, kde sa znižuje počet jazdných pruhov z jednej strany.",
            "uv_odpovedc": "pripájací jazdný pruh.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/114.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "a298d2db37dffe55b8d0"
        },
        {
            "nl_otazka_id": "1758",
            "uv_otazka": "Táto vodorovná dopravná značka",
            "uv_odpoveda": "Vyznačuje priestor, do ktorého je zakázané vchádzať alebo zasahovať.",
            "uv_odpovedb": "Vyznačuje miesto, kde je zakázané zastavenie a státie.",
            "uv_odpovedc": "Vhodným spôsobom vyznačuje miesto, kde je dovolené zastavenie a státie a určený spôsob státia.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/622.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "ac202bd67da1e21934c1"
        },
        {
            "nl_otazka_id": "1894",
            "uv_otazka": "Vodič modrého vozidla",
            "uv_odpoveda": "je povinný umožniť vodičovi červeného a vodičovi žltého vozidla obídenie prekážky.",
            "uv_odpovedb": "má pred vodičom červeného a vodičom žltého vozidla prednosť, pretože v ich jazdnom pruhu sa nachádza prekážka.",
            "uv_odpovedc": "je povinný umožniť vodičovi červeného vozidla obídenie prekážky.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/36.png",
            "body": "4",
            "poznamka": "",
            "hash": "5ae36fb81ade7671548f"
        },
        {
            "nl_otazka_id": "1826",
            "uv_otazka": "Ako prvé prejdú cez križovatku",
            "uv_odpoveda": "vaše vozidlo súčasne s električkou prichádzajúcou sprava.",
            "uv_odpovedb": "obidve električky súčasne.",
            "uv_odpovedc": "vaše vozidlo súčasne s modrým vozidlom.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/12.png",
            "body": "4",
            "poznamka": "",
            "hash": "1f70e06c701a13585cb9"
        },
        {
            "nl_otazka_id": "1854",
            "uv_otazka": "Červené vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "prvé súčasne s modrým vozidlom.",
            "uv_odpovedb": "posledné.",
            "uv_odpovedc": "prvé súčasne so zeleným vozidlom.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/26.png",
            "body": "4",
            "poznamka": "",
            "hash": "fb71833c81a643e5948a"
        },
        {
            "nl_otazka_id": "1908",
            "uv_otazka": "Vodič, ktorý sa zúčastnil na dopravnej nehode, je povinný zdržať sa požitia alkoholu alebo inej návykovej látky",
            "uv_odpoveda": "do príchodu odťahovej služby.",
            "uv_odpovedb": "do príchodu odbornej zdravotníckej pomoci.",
            "uv_odpovedc": "po nehode v čase, keď by to bolo na ujmu zistenia, či pred jazdou alebo počas jazdy požil alkohol alebo inú návykovú látku.",
            "nl_okruh_id": "5",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "94cc4560454b76e44c91"
        },
        {
            "nl_otazka_id": "1949",
            "uv_otazka": "Táto kontrolka signalizuje zapnutie",
            "uv_odpoveda": "obrysových svietidiel.",
            "uv_odpovedb": "stretávacích svetlometov.",
            "uv_odpovedc": "diaľkových svetlometov.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "obr3/tvv/32.png",
            "body": "2",
            "poznamka": "",
            "hash": "5a3fb00cccb69d3f5512"
        },
        {
            "nl_otazka_id": "1930",
            "uv_otazka": "Ak sa na prístrojovom paneli rozsvieti oranžová kontrolka ABS",
            "uv_odpoveda": "vozidlo môže spôsobiť zablokovanie volantu.",
            "uv_odpovedb": "vozidlo sa bude správať pri brzdení ako vozidlo bez ABS.",
            "uv_odpovedc": "vozidlo môže spôsobiť vypnutie svetiel.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "obr3/tvv/01.png",
            "body": "2",
            "poznamka": "",
            "hash": "fa8fb3cbea921a3489f9"
        },
        {
            "nl_otazka_id": "1978",
            "uv_otazka": "Pedál akcelerátora ovládame",
            "uv_odpoveda": "ľavou nohou.",
            "uv_odpovedb": "pravou nohou.",
            "uv_odpovedc": "striedavo pravou alebo ľavou nohou.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "d320775aab4ad11eae9b"
        },
        {
            "nl_otazka_id": "2067",
            "uv_otazka": "Vodič vozidla, ktoré podlieha prihláseniu do evidencie vozidiel, je povinný pri vedení vozidla mať pri sebe",
            "uv_odpoveda": "osvedčenie o evidencii časť I.",
            "uv_odpovedb": "technický preukaz.",
            "uv_odpovedc": "technické osvedčenie.",
            "nl_okruh_id": "7",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "99d908c221b69bde3f6b"
        },
        {
            "nl_otazka_id": "2024",
            "uv_otazka": "Držiteľ vodičského oprávnenia skupiny AM smie viesť motorové vozidlá skupiny AM, kde patria",
            "uv_odpoveda": "ľahké dvojkolesové motorové vozidlá kategórie L1e s najväčšou konštrukčnou rýchlosťou vyššou ako 25 km · h-1.",
            "uv_odpovedb": "motorové vozidlá s výkonom motora väčším ako 4 kW v prípade elektrického motora s najväčšou konštrukčnou rýchlosťou vyššou ako 25 km · h-1.",
            "uv_odpovedc": "ľahké dvojkolesové motorové vozidlá so zdvihovým objemom valcov motora nepresahujúcim 125 cm3, s najväčšou konštrukčnou rýchlosťou vyššou ako 25 km · h-1.",
            "nl_okruh_id": "7",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "2dc3d194a3794df267d6"
        },
        {
            "nl_otazka_id": "2118",
            "uv_otazka": "Technickou nespôsobilosťou vozidla na prevádzku v cestnej premávke je každý stav na vozidle, ktorý môže",
            "uv_odpoveda": "byť zdrojom ohrozenia bezpečnosti a plynulosti cestnej premávky a životného prostredia.",
            "uv_odpovedb": "byť zdrojom ohrozenia bezpečnosti, životného prostredia, verejného zdravia alebo zdrojom znečistenia alebo poškodenia cesty.",
            "uv_odpovedc": "ohroziť životné prostredie a verejné zdravie.",
            "nl_okruh_id": "8",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "857dc902e82716c8aa0e"
        },
        {
            "nl_otazka_id": "2149",
            "uv_otazka": "Vozidlo nemôže počas prevádzky v cestnej premávke",
            "uv_odpoveda": "byť vybavené vonkajšími spätnými zrkadlami alebo iným zariadením, ktoré zabezpečí nepriamy výhľad z vozidla na sledovanie cesty za vozidlom a vedľa seba na každej strane vozidla.",
            "uv_odpovedb": "znečisťovať okolie únikom oleja, iného maziva alebo obdobných látok.",
            "uv_odpovedc": "byť vybavené homologizovaným prenosným výstražným trojuholníkom.",
            "nl_okruh_id": "8",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "83b661b132c9b2c7c484"
        },
        {
            "nl_otazka_id": "2249",
            "uv_otazka": "Do základných pravidiel bezpečnej jazdy patrí",
            "uv_odpoveda": "Vodič má neustále pozerať do zrkadiel, aby vedel kto ide za ním.",
            "uv_odpovedb": "V prípade zvýšeného napätia, strachu alebo nervozity, vodič má odstaviť vozidlo na bezpečnom mieste a upokojiť sa.",
            "uv_odpovedc": "V prípade zvýšeného napätia, strachu alebo nervozity, vodič má pokračovať v jazde ďalej, aby čo najskôr dorazili do cieľa.",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "b29e9037c68ce05abb3c"
        },
        {
            "nl_otazka_id": "2239",
            "uv_otazka": "Do základných pravidiel bezpečnej jazdy patrí",
            "uv_odpoveda": "Nejazdite, pokiaľ ste unavený.",
            "uv_odpovedb": "Jazdite vždy pomaly",
            "uv_odpovedc": "Nejazdite, pokiaľ máte nutkavú potrebu jesť",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "feb86bf1f7d5fa9eb033"
        },
        {
            "nl_otazka_id": "2204",
            "uv_otazka": "Pri nebezpečnom oslnení protiidúcim vozidlom je potrebné",
            "uv_odpoveda": "znížiť rýchlosť jazdy, sledovať pravý okraj vozovky a periférnym videním sledovať protiidúce vozidlo",
            "uv_odpovedb": "znížiť rýchlosť jazdy a sledovať protiidúce vozidlo",
            "uv_odpovedc": "znížiť rýchlosť jazdy, sledovať predovšetkým protiidúce vozidlo a periférnym videním sledovať pravý okraj vozovky",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "84fada523748a2586549"
        },
        {
            "nl_otazka_id": "2240",
            "uv_otazka": "Do základných pravidiel bezpečnej jazdy patrí",
            "uv_odpoveda": "Neprekročte najvyššiu dovolenú rýchlosť, s výnimkou obchádzania.",
            "uv_odpovedb": "Buďte pripravení na možnosť výskytu neočakávanej prekážky.",
            "uv_odpovedc": "Neprekročte najvyššiu dovolenú rýchlosť, s výnimkou predchádzania.",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e1bd9dcac76a80cc2637"
        },
        {
            "nl_otazka_id": "2189",
            "uv_otazka": "Úroveň bdelosti vodiča je",
            "uv_odpoveda": "v noci vyššia ako poobede",
            "uv_odpovedb": "zvyčajne poobede nižšia ako doobeda",
            "uv_odpovedc": "poobede vyššia ako doobeda",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "2e096727af86c171f0ce"
        },
        {
            "nl_otazka_id": "2304",
            "uv_otazka": "Ak vodič vozidla jazdí predpísanou rýchlosťou a vozidlo idúce za ním sa snaží ho predísť, mal by mu v tom vodič vozidla zabrániť?",
            "uv_odpoveda": "Áno, ale vodič iného vozidla môže ohroziť vodiča vozidla.",
            "uv_odpovedb": "Nie.",
            "uv_odpovedc": "Nie, pokiaľ vodič vozidla nie je v takej pozícii, aby mohol bezpečne zabrániť vodičovi iného vozidla predchádzať ho.",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "22542ab4d1d0d6626f62"
        },
        {
            "nl_otazka_id": "2210",
            "uv_otazka": "Pri jazde na snehu alebo ľade vodič nesmie",
            "uv_odpoveda": "prudko brzdiť, prudko akcelerovať, volantom má však točiť čo najrýchlejšie",
            "uv_odpovedb": "prudko brzdiť, prudko akcelerovať a prudko točiť volantom",
            "uv_odpovedc": "prudko točiť volantom, ale musí brzdiť a akcelerovať intenzívne",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "6a94276519f94213f52a"
        },
        {
            "nl_otazka_id": "2167",
            "uv_otazka": "Voľbou nesprávnej pneumatiky (letná/zimná)",
            "uv_odpoveda": "druh dezénu neovplyvňuje adhéziu (trenie)",
            "uv_odpovedb": "súčiniteľ adhézie (trenia) sa značne zmenšuje",
            "uv_odpovedc": "súčiniteľ adhézie (trenia) sa značne zväčšuje",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1ec499fb9f1ae1f9b596"
        },
        {
            "nl_otazka_id": "2311",
            "uv_otazka": "Spojka umožňuje",
            "uv_odpoveda": "radenie neutrálu.",
            "uv_odpovedb": "plynulý rozjazd, radenie prevodových stupňov a zastavenie vozidla so zaradeným prevodovým stupňom.",
            "uv_odpovedc": "len plynulý rozjazd.",
            "nl_okruh_id": "10",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "2cf1d8de3c493336ca46"
        },
        {
            "nl_otazka_id": "2335",
            "uv_otazka": "Pri rozjazde, zrýchľovaní a jazde do kopca je nutné priviesť na kolesá",
            "uv_odpoveda": "krútiaci moment. Nezáleží na veľkosti  krútiaceho momentu, rozhodujúci je výkon motora.",
            "uv_odpovedb": "krútiaci moment na kolesá hnanej nápravy vozidla.",
            "uv_odpovedc": "väčší krútiaci moment, ako pri rovnakých podmienkach pri jazde po rovine.",
            "nl_okruh_id": "10",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "432c60c0c6d2df82742a"
        },
        {
            "nl_otazka_id": "1262",
            "uv_otazka": "Pred železničným priecestím vodič je povinný",
            "uv_odpoveda": "počínať si mimoriadne opatrne, najmä sa presvedčiť, či môže bezpečne prejsť cez železničné priecestie.",
            "uv_odpovedb": "dať zvukové výstražné znamenie na upozornenie osoby pribratej na zaistenie bezpečnej prevádzky železničného priecestia.",
            "uv_odpovedc": "vždy zastaviť vozidlo.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e775fb80a4fda0a9c7a8"
        },
        {
            "nl_otazka_id": "1172",
            "uv_otazka": "Pred odbočovaním vľavo je vodič povinný zaradiť sa",
            "uv_odpoveda": "čo najďalej vľavo v časti vozovky určenej pre jeho smer jazdy s ohľadom na rozmery vozidla alebo nákladu a šírku vozovky; to neplatí pre cyklistu.",
            "uv_odpovedb": "čo najďalej vpravo v časti vozovky určenej pre jeho smer jazdy.",
            "uv_odpovedc": "na pravú krajnicu.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3c427c9ff37c2c3c8870"
        },
        {
            "nl_otazka_id": "1516",
            "uv_otazka": "Ak svieti tento signál pri riadení cestnej premávky v križovatke, znamená pre vodiča:",
            "uv_odpoveda": "“Voľno“; vodič smie pokračovať v jazde pri dodržaní pravidiel cestnej premávky.",
            "uv_odpovedb": "“Stoj“; vodič musí zastaviť vozidlo pred križovatkou.",
            "uv_odpovedc": "“Čakaj“; vodič musí zastaviť vozidlo pred križovatkou a čakať.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "obr3/pcp/15_2c.png",
            "body": "3",
            "poznamka": "",
            "hash": "d3d12394595537146f89"
        },
        {
            "nl_otazka_id": "1235",
            "uv_otazka": "Vodič nesmie zastaviť a stáť",
            "uv_odpoveda": "vľavo na jednosmernej ceste.",
            "uv_odpovedb": "v pravom jazdnom pruhu.",
            "uv_odpovedc": "na vyhradenom jazdnom pruhu okrem vozidla, pre ktoré je vyhradený jazdný pruh určený.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "653444d3d539bc82bf5a"
        },
        {
            "nl_otazka_id": "1507",
            "uv_otazka": "Z idúceho vozidla označeného príslušnosťou k Policajnému zboru a vozidla neoznačeného príslušnosťou k Policajnému zboru dáva policajt pokyn na zastavenie vozidla",
            "uv_odpoveda": "zapnutím výstražnej funkcie smerových svietidiel.",
            "uv_odpovedb": "zapnutím zvukového znamenia.",
            "uv_odpovedc": "rozsvietením nápisu “STOP“.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "7231cf74ef75c177bb4c"
        },
        {
            "nl_otazka_id": "1346",
            "uv_otazka": "Vodič motorového vozidla kategórie M1 a N1 je na diaľnici povinný za vozidlom idúcim pred ním dodržiavať minimálne takú vzdialenosť, ktorú vozidlo prejde",
            "uv_odpoveda": "rýchlosťou 130 km/h za dve sekundy.",
            "uv_odpovedb": "za tri sekundy.",
            "uv_odpovedc": "za dve sekundy.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "4972d2e3aebc536b90f6"
        },
        {
            "nl_otazka_id": "1588",
            "uv_otazka": "Vodič pri vchádzaní na cestu z pozemku ležiaceho vedľa cesty, areálu s obmedzeným prístupom, oploteného objektu, garáže, parkoviska, obratiska električiek a podobných miest, z poľnej cesty, z lesnej cesty, z cestičky pre cyklistov, z obytnej zóny alebo z pešej zóny",
            "uv_odpoveda": "je povinný dať prednosť v jazde vozidlu idúcemu po ceste.",
            "uv_odpovedb": "je vždy povinný zastaviť vozidlo pred cestou.",
            "uv_odpovedc": "nie je povinný dať prednosť v jazde vozidlu idúcemu po ceste",
            "nl_okruh_id": "2",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "8380ac110324e5fa33d0"
        },
        {
            "nl_otazka_id": "1597",
            "uv_otazka": "Pri vlečení motorového vozidla sa smie jazdiť rýchlosťou najviac",
            "uv_odpoveda": "60 km.h-1.",
            "uv_odpovedb": "80 km.h-1.",
            "uv_odpovedc": "70 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "b05b66717d2ea71c9e32"
        },
        {
            "nl_otazka_id": "1689",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Označuje oddelenú komunikáciu určenú pre chodcov a cyklistov a vyznačuje, ktorý pruh je určený pre chodcov a ktorý pruh je určený pre cyklistov; cyklistom a chodcom prikazuje použiť časť pre nich určenú.",
            "uv_odpovedb": "Označuje spoločnú komunikáciu určenú pre chodcov a cyklistov a prikazuje im ju použiť.",
            "uv_odpovedc": "Zakazuje vstup chodcom a cyklistom do pešej zóny.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/222.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "0b2e16be446560708ca6"
        },
        {
            "nl_otazka_id": "1723",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Koniec obce.",
            "uv_odpovedb": "Koniec obytnej zóny.",
            "uv_odpovedc": "Koniec zákazu zastavenia.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/316.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "97d7a25ea189b1ebe095"
        },
        {
            "nl_otazka_id": "1716",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Označuje miesto, od ktorého platia osobitné ustanovenia o cestnej premávke v pešej zóne.",
            "uv_odpovedb": "Upozorňuje na miesto so zvýšeným výskytom detí.",
            "uv_odpovedc": "Označuje zónu pre matky s deťmi.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/317.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "6b9c8cadf552a5f34703"
        },
        {
            "nl_otazka_id": "1712",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Miesto, od ktorého platia pravidlá cestnej premávky o správaní sa na diaľnici a užívanie podlieha úhrade diaľničnou známkou.",
            "uv_odpovedb": "Začiatok cesty pre motorové vozidlá.",
            "uv_odpovedc": "Podjazd alebo nadjazd.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/309.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "be9298e10148086c4286"
        },
        {
            "nl_otazka_id": "1646",
            "uv_otazka": "Táto dopravná značka informuje",
            "uv_odpoveda": "O vzdialenosti 80 m k priecestiu.",
            "uv_odpovedb": "O vzdialenosti 140 m k priecestiu.",
            "uv_odpovedc": "O sklone vozovky vpravo.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/152-1.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "084a77d5f8b714f695f2"
        },
        {
            "nl_otazka_id": "1659",
            "uv_otazka": "Táto dopravná značka znamená:",
            "uv_odpoveda": "Zákaz vjazdu všetkými vozidlami v oboch smeroch jazdy.",
            "uv_odpovedb": "Slepá cesta.",
            "uv_odpovedc": "Zákaz vjazdu motorových vozidiel.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/231.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "677c15e0d1201d5c4134"
        },
        {
            "nl_otazka_id": "1706",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Označuje zvýšenú krajnicu.",
            "uv_odpovedb": "Umožňuje státie vozidiel na chodníku a vyznačeným symbolom označuje spôsob ich státia.",
            "uv_odpovedc": "Umožňuje státie vozidiel s najväčšou prípustnou celkovou hmotnosťou neprevyšujúcou 3,5 t na chodníku a vyznačeným symbolom označuje spôsob ich státia.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/273.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "892c35226bfc0de4c6af"
        },
        {
            "nl_otazka_id": "1687",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Zakazuje vjazd bicyklov.",
            "uv_odpovedb": "Označuje cestičku pre cyklistov alebo cyklistický pruh a prikazuje cyklistovi použiť takto označenú cestičku alebo pruh.",
            "uv_odpovedc": "Označuje priechod pre cyklistov; značka upozorňuje na nebezpečenstvo pohybu cyklistov na vozovke.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/221.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "10a4a55053ed420e6da2"
        },
        {
            "nl_otazka_id": "1850",
            "uv_otazka": "Vozidlá prejdú cez križovatku v tomto poradí:",
            "uv_odpoveda": "1. zelené, 2. modré, 3. žlté.",
            "uv_odpovedb": "1. modré, 2. zelené, 3. žlté.",
            "uv_odpovedc": "1. žlté, 2. zelené, 3. modré.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/24.png",
            "body": "4",
            "poznamka": "",
            "hash": "6549aec20a2a50346e74"
        },
        {
            "nl_otazka_id": "1872",
            "uv_otazka": "Ako prvé prejde cez križovatku",
            "uv_odpoveda": "červené vozidlo.",
            "uv_odpovedb": "zelené vozidlo.",
            "uv_odpovedc": "modré vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/25.png",
            "body": "4",
            "poznamka": "",
            "hash": "e69123e6e68d98d58488"
        },
        {
            "nl_otazka_id": "1841",
            "uv_otazka": "V poradí druhé prejde cez križovatku",
            "uv_odpoveda": "zelené vozidlo.",
            "uv_odpovedb": "červené vozidlo.",
            "uv_odpovedc": "vaše vozidlo súčasne so žltým vozidlom.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/20.png",
            "body": "4",
            "poznamka": "",
            "hash": "1d62d35ea65ef7ccb38e"
        },
        {
            "nl_otazka_id": "1912",
            "uv_otazka": "Ak sa účastníci škodovej udalosti nedohodli na jej zavinení sú povinní",
            "uv_odpoveda": "ohlásiť dopravnú nehodu policajtovi.",
            "uv_odpovedb": "ohlásiť škodovú udalosť obvodnému úradu dopravy.",
            "uv_odpovedc": "privolať odťahovú službu.",
            "nl_okruh_id": "5",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "feca957257083086035c"
        },
        {
            "nl_otazka_id": "1967",
            "uv_otazka": "Ak svieti táto kontrolka signalizuje nedostatočné množstvo",
            "uv_odpoveda": "motorového oleja.",
            "uv_odpovedb": "brzdovej kvapaliny.",
            "uv_odpovedc": "chladiacej kvapaliny.",
            "nl_okruh_id": "6",
            "nl_correct": "1",
            "uv_obrazok": "obr3/tvv/17.png",
            "body": "2",
            "poznamka": "",
            "hash": "d893d4bb8cdfb7c10d6d"
        },
        {
            "nl_otazka_id": "2003",
            "uv_otazka": "Ak dôjde počas jazdy alebo pri zastavení k povoleniu lana, vodič vlečného vozidla",
            "uv_odpoveda": "zaradí 2. prevodový stupeň.",
            "uv_odpovedb": "znižuje rýchlosť jazdy.",
            "uv_odpovedc": "pri rozjazde najskôr lano napne a až potom vykoná vlastné pohnutie vlečeného vozidla.",
            "nl_okruh_id": "6",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "dc2f05c3af9e25b14138"
        },
        {
            "nl_otazka_id": "2087",
            "uv_otazka": "Držiteľ vodičského preukazu",
            "uv_odpoveda": "je povinný oznámiť orgánu Policajného zboru do 15 dní zničenie, stratu alebo odcudzenie vodičského preukazu.",
            "uv_odpovedb": "je povinný oznámiť orgánu Policajného zboru do 7 dní zničenie, stratu alebo odcudzenie vodičského preukazu.",
            "uv_odpovedc": "nie je povinný oznámiť orgánu Policajného zboru zničenie, stratu alebo odcudzenie vodičského preukazu.",
            "nl_okruh_id": "7",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "c71438c8821f5242af49"
        },
        {
            "nl_otazka_id": "2026",
            "uv_otazka": "Držiteľ vodičského oprávnenia skupiny AM smie viesť motorové vozidlá skupiny AM, kde patria",
            "uv_odpoveda": "ľahké štvorkolky kategórie L6e.",
            "uv_odpovedb": "motorové vozidlá s výkonom motora väčším ako 4 kW v prípade elektrického motora s najväčšou konštrukčnou rýchlosťou vyššou ako 25 km · h-1.",
            "uv_odpovedc": "ľahké dvojkolesové motorové vozidlá so zdvihovým objemom valcov motora nepresahujúcim 125 cm3, s najväčšou konštrukčnou rýchlosťou vyššou ako 25 km · h-1.",
            "nl_okruh_id": "7",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "25d658361e462318aa5c"
        },
        {
            "nl_otazka_id": "2137",
            "uv_otazka": "Osvetlenie vozidla musí byť počas prevádzky v cestnej premávke",
            "uv_odpoveda": "za všetkých prevádzkových podmienok a poveternostných podmienok dobre viditeľné a nemôže byť znečistené s výnimkou osobitosti premávky v zimnom období.",
            "uv_odpovedb": "za všetkých prevádzkových podmienok a poveternostných podmienok dobre viditeľné a nemôže byť znečistené.",
            "uv_odpovedc": "za všetkých prevádzkových podmienok dobre viditeľné a nemôže byť znečistené.",
            "nl_okruh_id": "8",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "c5e762b6332ba72df8b1"
        },
        {
            "nl_otazka_id": "2138",
            "uv_otazka": "Na vozidlách sa môžu počas prevádzky v cestnej premávke používať",
            "uv_odpoveda": "len svetelné zdroje a zariadenia v predpísanom počte a s predpísanou farbou vyžarovaného alebo odrážaného svetla, ktoré sú pre daný druh a kategóriu vozidla predpísané s výnimkou zvláštnych motorových vozidiel.",
            "uv_odpovedb": "len svetelné zdroje a zariadenia v predpísanom počte, ktoré sú pre daný druh a kategóriu vozidla predpísané.",
            "uv_odpovedc": "len svetelné zdroje a zariadenia v predpísanom počte a s predpísanou farbou vyžarovaného alebo odrážaného svetla, ktoré sú pre daný druh a kategóriu vozidla predpísané.",
            "nl_okruh_id": "8",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "8e50e32184c273c8e3df"
        },
        {
            "nl_otazka_id": "2162",
            "uv_otazka": "Technické oneskorenie bŕzd je čas, ktorý",
            "uv_odpoveda": "plynie od začiatku brzdenia až do zastavenia vozidla",
            "uv_odpovedb": "je potrebný na úplné zastavenie vozidla",
            "uv_odpovedc": "plynie od okamihu stlačenia pedálu brzdy do začiatku samotného brzdenia",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "bf411ae52517c09ff84d"
        },
        {
            "nl_otazka_id": "2246",
            "uv_otazka": "Ktorú zásadu by mal dodržiavať začínajúci vodič? Mal by",
            "uv_odpoveda": "jazdiť primeranou rýchlosťou, radšej v pravom pruhu.",
            "uv_odpovedb": "jazdiť čo najbližšie pri stredovej čiare.",
            "uv_odpovedc": "jazdiť čo najpomalšie, radšej v ľavom pruhu.",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ddc26548ff14f0df2ce2"
        },
        {
            "nl_otazka_id": "2196",
            "uv_otazka": "Nárazový vietor najčastejšie ohrozuje cestnú premávku",
            "uv_odpoveda": "len na diaľnici mimo obce",
            "uv_odpovedb": "na mostoch a nadjazdoch",
            "uv_odpovedc": "v obci",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "b57a6086c32f39be4139"
        },
        {
            "nl_otazka_id": "2306",
            "uv_otazka": "Prečo je predchádzanie nákladného vozidla nebezpečnejšie ako predchádzanie osobného automobilu?",
            "uv_odpoveda": "Nákladné vozidlo je dlhšie ako osobný automobil.",
            "uv_odpovedb": "Nákladné vozidlo je pomalšie ako osobný automobil.",
            "uv_odpovedc": "Nákladné vozidlo je ťažšie ako osobný automobil.",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e9827909d35508d24c81"
        },
        {
            "nl_otazka_id": "2254",
            "uv_otazka": "Vodič pri prechode na inú továrenskú značku vozidla musí",
            "uv_odpoveda": "vyskúšať maximálnu rýchlosť vozidla.",
            "uv_odpovedb": "sa sústrediť výlučne na ekonomické zásady jazdy.",
            "uv_odpovedc": "si zvyknúť na odlišné jazdné vlastnosti vozidla a na odlišné ovládanie vozidla.",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "d35fed99fb4fc822f6d1"
        },
        {
            "nl_otazka_id": "2226",
            "uv_otazka": "Pre bezpečné predchádzanie platí zásada",
            "uv_odpoveda": "Pohľadom do spätných zrkadiel skontrolujte situáciu za sebou.",
            "uv_odpovedb": "Po zaujatí miesta v druhom jazdnom pruhu, zapnite zvukovú výstrahu.",
            "uv_odpovedc": "Po zaujatí miesta v druhom jazdnom pruhu zapnite pravý ukazovateľ smeru jazdy.",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "0e6bf814f35f3cc10abc"
        },
        {
            "nl_otazka_id": "2218",
            "uv_otazka": "Pri nedotáčavom šmyku",
            "uv_odpoveda": "predné kolesá nepriliehajú k povrchu a zadné tlačia auto dopredu",
            "uv_odpovedb": "zadná časť auta sa snaží predbehnúť prednú",
            "uv_odpovedc": "dostane šmyk zadná náprava",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "969c9ff4907bff788069"
        },
        {
            "nl_otazka_id": "2217",
            "uv_otazka": "Pri pretáčavom šmyku",
            "uv_odpoveda": "zadná časť auta sa snaží predbehnúť prednú",
            "uv_odpovedb": "dostane šmyk predná náprava",
            "uv_odpovedc": "predné kolesá nepriliehajú k povrchu a zadné tlačia auto dopredu",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "c1c46c96fa717368ab28"
        },
        {
            "nl_otazka_id": "2328",
            "uv_otazka": "Chladiacu kvapalinu tvorí",
            "uv_odpoveda": "brzdová kvapalina.",
            "uv_odpovedb": "zmes destilovanej vody a nemrznúcej kvapaliny.",
            "uv_odpovedc": "destilovaná voda.",
            "nl_okruh_id": "10",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "2eb4da1c8122e2c4b453"
        },
        {
            "nl_otazka_id": "2347",
            "uv_otazka": "Ak na ukazovateli so symbolom olejničky na prístrojovom paneli zasvieti červené svetlo, signalizuje poruchu:",
            "uv_odpoveda": "nedostatok oleja v prevodovke.",
            "uv_odpovedb": "mazania motora.",
            "uv_odpovedc": "bŕzd.",
            "nl_okruh_id": "10",
            "nl_correct": "2",
            "uv_obrazok": "obr3/tvv/35.png",
            "body": "1",
            "poznamka": "",
            "hash": "78f9ff688b1c5cc2aa74"
        },
        {
            "nl_otazka_id": "1438",
            "uv_otazka": "Chodci smú isť po krajnici alebo po okraji vozovky, ak tým, najmä za zníženej viditeľnosti alebo za zvýšenej premávky, neohrozia alebo neobmedzia cestnú premávku, najviac",
            "uv_odpoveda": "traja vedľa seba.",
            "uv_odpovedb": "dvaja vedľa seba.",
            "uv_odpovedc": "piati vedľa seba.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f3135259c5869863ea9f"
        },
        {
            "nl_otazka_id": "1375",
            "uv_otazka": "Zvláštne výstražné svetlo oranžovej farby sa môže používať",
            "uv_odpoveda": "na označenie vozidla s právom prednostnej jazdy.",
            "uv_odpovedb": "len na označenie vozidla, ktoré svojou jazdou môže ohroziť plynulosť cestnej premávky.",
            "uv_odpovedc": "aj na označenie prekážky cestnej premávky.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "75e307e4367452a0cd6b"
        },
        {
            "nl_otazka_id": "1108",
            "uv_otazka": "Ak by vozidlá idúce v obci súčasne vo všetkých jazdných pruhoch bránili v jazde rýchlejšie idúcemu vozidlu, vodič ktorý jazdí v",
            "uv_odpoveda": "ľavom jazdnom pruhu, sú povinní okamžite zastaviť vozidlo.",
            "uv_odpovedb": "pravom jazdnom pruhu, je povinný tento čo najskôr uvoľniť.",
            "uv_odpovedc": "ľavom jazdnom pruhu, je povinný ho čo najskôr uvoľniť; to neplatí, ak vodič používa ľavý jazdný pruh na obchádzanie, odbočovanie, otáčanie alebo predchádzanie.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ab4211a05c76543e21f5"
        },
        {
            "nl_otazka_id": "1003",
            "uv_otazka": "Chodcom sa rozumie účastník cestnej premávky pohybujúci sa pešo; chodcom je aj",
            "uv_odpoveda": "osoba, ktorá tlačí alebo ťahá ručný vozík s celkovou šírkou nepresahujúcou 600 mm.",
            "uv_odpovedb": "vodič každého nemotorového vozidla, ktoré sa pohybuje rýchlosťou nižšou ako 30 km.h-1.",
            "uv_odpovedc": "pohonič záprahového vozidla a osoba, ktorá tlačí alebo ťahá ručný vozík s celkovou dĺžkou nepresahujúcou 150 cm.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "7d7b31453e16365d7c05"
        },
        {
            "nl_otazka_id": "1510",
            "uv_otazka": "Ak svieti tento pruhový signál umiestnený nad jazdným pruhom, pre vodiča znamená:",
            "uv_odpoveda": "Jazdný pruh sa môže používať.",
            "uv_odpovedb": "Jazdný pruh sa nesmie používať.",
            "uv_odpovedc": "Jazdný pruh sa smie používať, ak sa protiidúci vodiči dohodnú.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "obr3/pcp/17_1a.png",
            "body": "3",
            "poznamka": "",
            "hash": "fc82f946724e36ada9f2"
        },
        {
            "nl_otazka_id": "1040",
            "uv_otazka": "Vodič nesmie viesť motorové vozidlo bez príslušného vodičského oprávnenia; to neplatí, ak",
            "uv_odpoveda": "má povolenú jazdu odborom dopravy mestského alebo obecného úradu.",
            "uv_odpovedb": "má povolenú jazdu okresným úradom.",
            "uv_odpovedc": "sa podrobuje skúške z vedenia motorového vozidla.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e3c846a98dbea9aeddb5"
        },
        {
            "nl_otazka_id": "1325",
            "uv_otazka": "Pri poruche osvetlenia alebo inej poruche nedovoľujúcej použitie osvetlenia, musia byť na vlečenom vozidle za zníženej viditeľnosti rozsvietené",
            "uv_odpoveda": "dve červené svetlá vpredu aj vzadu.",
            "uv_odpovedb": "svietidlá na strane k stredu vozovky vpredu bielym neoslňujúcim svetlom a vzadu aspoň jedným červeným svetlom.",
            "uv_odpovedc": "svetlá vo vnútri vozidla.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "996bc2ba08de5a27b6bf"
        },
        {
            "nl_otazka_id": "1391",
            "uv_otazka": "Vodič vozidla s parkovacím preukazom",
            "uv_odpoveda": "nemusí po nevyhnutne potrebný čas dodržiavať najvyššie dovolené rýchlosti jazdy.",
            "uv_odpovedb": "môže vojsť aj do pešej zóny; pritom nesmie ohroziť bezpečnosť cestnej premávky.",
            "uv_odpovedc": "nesmie vchádzať do pešej zóny.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "a992c90e908ef9852161"
        },
        {
            "nl_otazka_id": "1549",
            "uv_otazka": "Dať prednosť v jazde sa rozumie povinnosť",
            "uv_odpoveda": "účastníka cestnej premávky počínať si tak, aby inému účastníkovi cestnej premávky nevzniklo nijaké nebezpečenstvo.",
            "uv_odpovedb": "chodca prechádzajúceho na priechode pre chodcov neobmedziť motorové vozidlá prichádzajúce sprava.",
            "uv_odpovedc": "účastníka cestnej premávky počínať si tak, aby ten, kto má prednosť v jazde, nemusel náhle zmeniť smer alebo rýchlosť jazdy.",
            "nl_okruh_id": "2",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "857420e2aae36b8c8b01"
        },
        {
            "nl_otazka_id": "1557",
            "uv_otazka": "Vodič motorového vozidla s najväčšou prípustnou celkovou hmotnosťou neprevyšujúcou 3 500 kg  smie jazdiť rýchlosťou najviac",
            "uv_odpoveda": "90 km.h-1, na diaľnici smie jazdiť rýchlosťou najviac 130 km.h-1.",
            "uv_odpovedb": "110 km.h-1, na diaľnici a ceste pre motorové vozidlá nemá rýchlosť jazdy obmedzenú.",
            "uv_odpovedc": "90 km.h-1, na diaľnici a na ceste pre motorové vozidlá smie jazdiť rýchlosťou najviac 100 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "eb1fdd732869b4386a16"
        },
        {
            "nl_otazka_id": "1708",
            "uv_otazka": "Táto dopravná značka znamená:",
            "uv_odpoveda": "Parkovisko - parkovacie miesta s vyhradeným státím pre TAXI.",
            "uv_odpovedb": "Zastávka TAXI.",
            "uv_odpovedc": "Stanovište vozidiel taxislužby.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/332.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "7f2ea5f8e6861dd697b5"
        },
        {
            "nl_otazka_id": "1754",
            "uv_otazka": "Táto vodorovná dopravná značka",
            "uv_odpoveda": "Určuje smer jazdy motorových vozidiel.",
            "uv_odpovedb": "Vyznačuje parkovisko s pozdĺžnym státím.",
            "uv_odpovedc": "Vyznačuje smer jazdy cyklistov. Vodičov motorových vozidiel upozorňuje, že sa nachádzajú na vozovke so zvýšeným pohybom cyklistov.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/635.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "af5578a808e2918894dc"
        },
        {
            "nl_otazka_id": "1651",
            "uv_otazka": "Ak je táto dopravná značka umiestnená pred železničným priecestím alebo pred križovatkou",
            "uv_odpoveda": "Označuje železničné priecestie strážené osobou pribratou na zaistenie bezpečnej prevádzky železničného priecestia, alebo križovatku riadenú policajtom.",
            "uv_odpovedb": "Označuje zastávku železničného vozidla, alebo stanovisko policajnej kontroly nachádzajúce sa za križovatkou.",
            "uv_odpovedc": "Vodič je povinný zastaviť vozidlo na značke STOP čiara alebo na takom mieste, odkiaľ má náležitý rozhľad na križovatku alebo na železničnú trať.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/202.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "5c7553252d73c57747ce"
        },
        {
            "nl_otazka_id": "1681",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Prikazuje vodičovi podrobiť sa colnej kontrole.",
            "uv_odpovedb": "Zakazuje pokračovať v jazde bez zastavenia vozidla. Na značke sa stručne uvádza dôvod zastavenia.",
            "uv_odpovedc": "Zakazuje vojsť do hraničného pásma.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/245.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "b43cb8b2e9b39c584a07"
        },
        {
            "nl_otazka_id": "1626",
            "uv_otazka": "Táto dopravná značka upozorňuje na",
            "uv_odpoveda": "Nebezpečné klesanie.",
            "uv_odpovedb": "miesto, kde priečny sklon vozovky presahuje 10 %.",
            "uv_odpovedc": "priklonenú strmú stráň zľava.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/112.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "e9d147bc986f9b5ad932"
        },
        {
            "nl_otazka_id": "1660",
            "uv_otazka": "Táto dopravná značka znamená:",
            "uv_odpoveda": "Zúžená vozovka.",
            "uv_odpovedb": "Zákaz vjazdu všetkými vozidlami.",
            "uv_odpovedc": "Zákaz vjazdu nemotorových vozidiel.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/230.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "4a99a0f389e5ee741664"
        },
        {
            "nl_otazka_id": "1786",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Bicykle s elektrickým pomocným pohonom, ktorého výkon sa preruší, keď bicykel dosiahne rýchlosť 25 km/h",
            "uv_odpovedb": "Kolobežky s pomocným motorčekom, samovyvažovacie vozidlá a iné dopravné prostriedky s elektrickým pohonom na individuálne presuny",
            "uv_odpovedc": "Elektromobily",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/1-15.png",
            "body": "2",
            "poznamka": "",
            "hash": "40e1c8fa1273dc73fc04"
        },
        {
            "nl_otazka_id": "1731",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Je smerová tabuľa na vyznačenie obchádzky.",
            "uv_odpovedb": "Informuje o odbočení k diaľnici alebo k diaľniciam a podľa potreby aj o diaľkových cieľoch.",
            "uv_odpovedc": "Označuje diaľnicu.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/365.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "3d597acc53a542392ebd"
        },
        {
            "nl_otazka_id": "1856",
            "uv_otazka": "Červené vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "prvé.",
            "uv_odpovedb": "druhé.",
            "uv_odpovedc": "posledné.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/27.png",
            "body": "4",
            "poznamka": "",
            "hash": "11b5a929dcb26b49c7fd"
        },
        {
            "nl_otazka_id": "1875",
            "uv_otazka": "Ako posledné prejde cez križovatku",
            "uv_odpoveda": "modré vozidlo",
            "uv_odpovedb": "zelené vozidlo.",
            "uv_odpovedc": "červené vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/26.png",
            "body": "4",
            "poznamka": "",
            "hash": "54f59f7f9aa47a969880"
        },
        {
            "nl_otazka_id": "1846",
            "uv_otazka": "Vozidlá prejdú cez križovatku v tomto poradí:",
            "uv_odpoveda": "1. červené, 2. vaše vozidlo, 3. žlté, 4. zelené.",
            "uv_odpovedb": "1. žlté, 2. zelené, 3. červené, 4. vaše vozidlo.",
            "uv_odpovedc": "1. vaše vozidlo, 2. červené, 3. žlté, 4. zelené.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/22.png",
            "body": "4",
            "poznamka": "",
            "hash": "54b7b32f731a40843e15"
        },
        {
            "nl_otazka_id": "1929",
            "uv_otazka": "Účastník škodovej udalosti je povinný urobiť vhodné opatrenia, aby nebola ohrozená",
            "uv_odpoveda": "bezpečnosť alebo plynulosť cestnej premávky.",
            "uv_odpovedb": "bezpečnosť cestnej premávky len za zníženej viditeľnosti.",
            "uv_odpovedc": "plynulosť cestnej premávky len za hustého sneženia.",
            "nl_okruh_id": "5",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "10ab81cb324e2be1f108"
        },
        {
            "nl_otazka_id": "1966",
            "uv_otazka": "Ak svieti táto kontrolka upozorňuje na",
            "uv_odpoveda": "poruchu alternátora alebo systému dobíjania.",
            "uv_odpovedb": "poruchu brzdového systému.",
            "uv_odpovedc": "nefunkčné stretávacie svetlomety.",
            "nl_okruh_id": "6",
            "nl_correct": "1",
            "uv_obrazok": "obr3/tvv/08.png",
            "body": "2",
            "poznamka": "",
            "hash": "fb67e08917cad7719e15"
        },
        {
            "nl_otazka_id": "1931",
            "uv_otazka": "Ak na ukazovateli so symbolom olejničky na prístrojovom paneli zasvieti červené svetlo, signalizuje poruchu",
            "uv_odpoveda": "mazania motora.",
            "uv_odpovedb": "nedostatku oleja v prevodovke.",
            "uv_odpovedc": "bŕzd.",
            "nl_okruh_id": "6",
            "nl_correct": "1",
            "uv_obrazok": "obr3/tvv/35.png",
            "body": "2",
            "poznamka": "",
            "hash": "40e44eedf3c7b5f70e0e"
        },
        {
            "nl_otazka_id": "1972",
            "uv_otazka": "Najekologickejší druh paliva je",
            "uv_odpoveda": "LPG/CNG.",
            "uv_odpovedb": "motorová nafta.",
            "uv_odpovedc": "benzín.",
            "nl_okruh_id": "6",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "5e6a55edaea9a1d07dd6"
        },
        {
            "nl_otazka_id": "2081",
            "uv_otazka": "Držiteľ vodičského preukazu je povinný",
            "uv_odpoveda": "oznámiť orgánu Policajného zboru do 14 dní podstatnú zmenu svojej podoby.",
            "uv_odpovedb": "oznámiť orgánu Policajného zboru do 30 dní podstatnú zmenu svojej podoby.",
            "uv_odpovedc": "oznámiť orgánu Policajného zboru do 7 dní podstatnú zmenu svojej podoby.",
            "nl_okruh_id": "7",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "d6b7c41dbbf67d63dfa3"
        },
        {
            "nl_otazka_id": "2030",
            "uv_otazka": "Vodičské oprávnenie skupiny B oprávňuje viesť aj",
            "uv_odpoveda": "dvojkolesové motorové vozidlá bez postranného vozíka alebo s postranným vozíkom skupiny A1 s automatickou prevodovkou po dvoch rokoch od jeho udelenia, a to len na území Slovenskej republiky.",
            "uv_odpovedb": "motorové vozidlá skupiny A1 po dvoch rokoch od jeho udelenia.",
            "uv_odpovedc": "motorové vozidlá skupiny A2.",
            "nl_okruh_id": "7",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "dda857b3212eaf080bbc"
        },
        {
            "nl_otazka_id": "2114",
            "uv_otazka": "Vozidlo, ktoré nemá platnú technickú kontrolu pravidelnú, ak takej kontrole podlieha",
            "uv_odpoveda": "môže jeho prevádzkovateľ v cestnej premávke prevádzkovať za dodržania podmienky absolvovania technickej kontroly pravidelnej do 30 dní odo dňa začatia prevádzkovania.",
            "uv_odpovedb": "nesmie jeho prevádzkovateľ v cestnej premávke prevádzkovať.",
            "uv_odpovedc": "nesmie jeho prevádzkovateľ v cestnej premávke prevádzkovať, jeho prevádzka je však dovolená na miestnych komunikáciách.",
            "nl_okruh_id": "8",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "f043e391e6c8879df8f9"
        },
        {
            "nl_otazka_id": "2134",
            "uv_otazka": "Vozidlo sa považuje za technicky nespôsobilé na prevádzku v cestnej premávke, najmä ak",
            "uv_odpoveda": "diaľkové svetlomety nie je možné prepnúť na obrysové svetlá.",
            "uv_odpovedb": "je nedostatočná nosnosť pneumatiky alebo jej rýchlostná kategória pri aktuálnom používaní, pneumatika sa dotýka iných pevných častí vozidla, čo narúša bezpečnosť vedenia vozidla.",
            "uv_odpovedc": "vozidlo nie je vybavené aspoň jedným hľadacím svetlometom bielej farby.",
            "nl_okruh_id": "8",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "193c4fef179181ef8916"
        },
        {
            "nl_otazka_id": "2193",
            "uv_otazka": "Alkohol u vodiča  spôsobuje",
            "uv_odpoveda": "zhoršenie periférneho videnia",
            "uv_odpovedb": "zväčšovanie zorného poľa",
            "uv_odpovedc": "kvalitnejšie vnímanie prostredia",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "7eb9990fa3ced1063219"
        },
        {
            "nl_otazka_id": "2202",
            "uv_otazka": "Pri jazde v noci vodič neskôr zbadá chodca",
            "uv_odpoveda": "v odeve s reflexnými prvkami",
            "uv_odpovedb": "v tmavom oblečení",
            "uv_odpovedc": "v svetlom oblečení",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1e6b49035b689810202b"
        },
        {
            "nl_otazka_id": "2186",
            "uv_otazka": "Únavu vodiča spôsobuje najčastejšie",
            "uv_odpoveda": "sneženie, alebo hustý dážď",
            "uv_odpovedb": "defenzívna jazda",
            "uv_odpovedc": "časté vetranie interiéru vozidla",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "948259fcc4d3a804b9e2"
        },
        {
            "nl_otazka_id": "2164",
            "uv_otazka": "Správny postup pri krízovom brzdení s vozidlom vybaveným s ABS je",
            "uv_odpoveda": "uvoľniť plynový pedál, stlačiť brzdový pedál a súčasne zaradiť neutrál",
            "uv_odpovedb": "uvoľniť plynový pedál a prerušovane stláčať brzdový pedál",
            "uv_odpovedc": "uvoľniť plynový pedál, prudko stlačiť súčasne brzdový a spojkový pedál",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1ddfbeb386e0e4b75aed"
        },
        {
            "nl_otazka_id": "2157",
            "uv_otazka": "Vzhľadom k fyzikálnym zákonom, ktoré pôsobia na vozidlo v zákrute je správne",
            "uv_odpoveda": "počas prejazdu zákrutou intenzívne zrýchľovať",
            "uv_odpovedb": "pred zákrutou spomaliť a na výjazde zo zákruty zrýchľovať",
            "uv_odpovedc": "brzdiť v zákrute",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3dc00779e3a3a1428f16"
        },
        {
            "nl_otazka_id": "2338",
            "uv_otazka": "Množstvo paliva v nádrži kontrolujeme pomocou",
            "uv_odpoveda": "mierkou.",
            "uv_odpovedb": "ukazovateľa stavu paliva.",
            "uv_odpovedc": "tyčkou.",
            "nl_okruh_id": "10",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "a268d0aa0d2b56c6fab1"
        },
        {
            "nl_otazka_id": "2319",
            "uv_otazka": "Palivová (zápalná) zmes pre dvojdobé zážihové motory je zložená z",
            "uv_odpoveda": "nafty a vzduchu.",
            "uv_odpovedb": "benzínu, oleja a vzduchu.",
            "uv_odpovedc": "nafty, oleja a vzduchu.",
            "nl_okruh_id": "10",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "1413070f094d1114fa0c"
        },
        {
            "nl_otazka_id": "1483",
            "uv_otazka": "Do pešej zóny je povolený vjazd len vozidlám",
            "uv_odpoveda": "ktorým to umožňuje dopravná značka.",
            "uv_odpovedb": "vedených vodičom začiatočníkom.",
            "uv_odpovedc": "ktorých najväčšia prípustná celková hmotnosť nepresahuje 3 500 kg.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "aa6692ed6ba1002d0a14"
        },
        {
            "nl_otazka_id": "1383",
            "uv_otazka": "Toto osobitné označenie sa môže používať na vozidle vedenom",
            "uv_odpoveda": "vodičom začiatočníkom.",
            "uv_odpovedb": "osobou so sluchovým postihnutím.",
            "uv_odpovedc": "osobou so zrakovým postihnutím.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "obr3/pcp/25_2_1.png",
            "body": "3",
            "poznamka": "",
            "hash": "6c8ef1b6afaf6c67854d"
        },
        {
            "nl_otazka_id": "1285",
            "uv_otazka": "Ak to vyžaduje bezpečnosť alebo plynulosť cestnej premávky, vodič je povinný",
            "uv_odpoveda": "dávať znamenie o zmene smeru jazdy.",
            "uv_odpovedb": "dať zvukové výstražné znamenie.",
            "uv_odpovedc": "zapnúť húkačku.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "787a1a8296fc9475bc3c"
        },
        {
            "nl_otazka_id": "1239",
            "uv_otazka": "Vodič nesmie zastaviť a stáť vo vzdialenosti kratšej ako 5 m od začiatku a 5 m od konca pozdĺžnej súvislej čiary alebo nástupného ostrovčeka tam, kde by medzi touto čiarou alebo nástupným ostrovčekom a vozidlom nezostal voľný aspoň jeden jazdný pruh široký najmenej",
            "uv_odpoveda": "2,5 m.",
            "uv_odpovedb": "3 m.",
            "uv_odpovedc": "2 m.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "73a7cc9b39f5781d3c62"
        },
        {
            "nl_otazka_id": "1514",
            "uv_otazka": "Ak svieti tento signál  počas rozsvieteného signálu červenej farby pri riadení cestnej premávky v križovatke, znamená pre vodiča:",
            "uv_odpoveda": "“Voľno“; vodič smie pokračovať v jazde pri dodržaní pravidiel cestnej premávky.",
            "uv_odpovedb": "“Stoj“; vodič musí zastaviť vozidlo pred križovatkou.",
            "uv_odpovedc": "“Čakaj“; vodič sa musí pripraviť na jazdu.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "obr3/pcp/15_2b.png",
            "body": "3",
            "poznamka": "",
            "hash": "98a06371b25edb6fddb7"
        },
        {
            "nl_otazka_id": "1031",
            "uv_otazka": "Účastníkovi cestnej premávky je zakázané používať",
            "uv_odpoveda": "zvukové výstražné znamenia.",
            "uv_odpovedb": "zimné pneumatiky v období od 15. novembra do 31. marca.",
            "uv_odpovedc": "technické prostriedky a zariadenia, ktorých činnosť umožňuje odhalenie alebo ovplyvňovanie funkcií technických prostriedkov používaných pri plnení úloh na úseku výkonu dohľadu nad bezpečnosťou a plynulosťou cestnej premávky alebo ich umiestnenie vo vozidle spôsobom, ktorý umožňuje ich použitie.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ac2bfbb52848af028141"
        },
        {
            "nl_otazka_id": "1322",
            "uv_otazka": "Motorové vozidlo sa smie vliecť len vtedy, ak má",
            "uv_odpoveda": "účinné riadenie a brzdy.",
            "uv_odpovedb": "účinné riadenie; brzdy musia byť účinné len, ak je motorové vozidlo vlečené pomocou spojnice.",
            "uv_odpovedc": "funkčné servoriadenie.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ba05f97a5316ff63d71d"
        },
        {
            "nl_otazka_id": "1194",
            "uv_otazka": "Na jednosmernej ceste, ak je to nevyhnutne potrebné najmä na zaradenie do radu stojacich vozidiel alebo na vyjdenie z neho, vodič",
            "uv_odpoveda": "smie cúvať.",
            "uv_odpovedb": "nesmie cúvať.",
            "uv_odpovedc": "smie sa otáčať a cúvať.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f3f5d35480298a0088c8"
        },
        {
            "nl_otazka_id": "1585",
            "uv_otazka": "Vodič, ktorý prichádza na križovatku po ceste neoznačenej dopravnou značkou ako vedľajšia cesta alebo ako hlavná cesta, je povinný dať prednosť v jazde vozidlu prichádzajúcemu",
            "uv_odpoveda": "zľava.",
            "uv_odpovedb": "po jednosmernej ceste.",
            "uv_odpovedc": "sprava.",
            "nl_okruh_id": "2",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5b4e21b58e2d7c5cf795"
        },
        {
            "nl_otazka_id": "1600",
            "uv_otazka": "Najmenšia rýchlosť jazdy stanovená na diaľnici sa nevzťahuje na vodiča",
            "uv_odpoveda": "zvláštneho motorového vozidla vchádzajúceho na diaľnicu alebo vychádzajúceho z diaľnice v čase od 22.00 do 04.00 hodín.",
            "uv_odpovedb": "pri predchádzaní.",
            "uv_odpovedc": "motorového vozidla vchádzajúceho na diaľnicu alebo vychádzajúceho z diaľnice.",
            "nl_okruh_id": "2",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "7feb72cc940fbfbfafb0"
        },
        {
            "nl_otazka_id": "1702",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Cestičku pre cyklistov.",
            "uv_odpovedb": "Priechod pre cyklistov.",
            "uv_odpovedc": "Obytnú zónu.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/326.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "71d613a8fe642e73c9cb"
        },
        {
            "nl_otazka_id": "1733",
            "uv_otazka": "Táto dopravná značka informuje",
            "uv_odpoveda": "O názve miestnej časti obce, prípadne o vzdialenosti k nej.",
            "uv_odpovedb": "O smere, prípadne aj o vzdialenosti v kilometroch k vyznačeným cieľom a o čísle cyklistickej trasy.",
            "uv_odpovedc": "O odbočení k turistickým cieľom.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/367.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "c1e8d1c059746abbf82c"
        },
        {
            "nl_otazka_id": "1717",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Školskú zónu.",
            "uv_odpovedb": "Koniec pešej zóny a začiatok školskej zóny.",
            "uv_odpovedc": "Koniec pešej zóny.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/318.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "ccb20f5f41773024be9e"
        },
        {
            "nl_otazka_id": "1801",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Parkovacia garáž, parkovací dom",
            "uv_odpovedb": "Parkovisko",
            "uv_odpovedc": "Parkovisko P+R (parkovisko, z ktorého je možné pokračovať v ceste pravidelnou verejnou hromadnou dopravou)",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/2-29.png",
            "body": "2",
            "poznamka": "",
            "hash": "73b41a50a01b4e5ad5c7"
        },
        {
            "nl_otazka_id": "1809",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Parkovacie miesta s pozdĺžnym státím na chodníku",
            "uv_odpovedb": "Parkovacie miesta s priečnym státím čiastočne na chodníku (státie povolené kolmo alebo šikmo na os vozovky, jednou nápravou na chodníku a prednou alebo zadnou časťou vozidla smerom k vozovke)",
            "uv_odpovedc": "Parkovacie miesta s pozdĺžnym státím čiastočne na chodníku",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/4-5.png",
            "body": "2",
            "poznamka": "",
            "hash": "2f559818f387adf2cab7"
        },
        {
            "nl_otazka_id": "1729",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Vopred informuje o čiastočnej alebo úplnej uzávierke cesty a podľa potreby o obchádzkovej trase.",
            "uv_odpovedb": "Označuje zastávkový jazdný pruh.",
            "uv_odpovedc": "Vyznačuje obmedzenie v jazdnom pruhu.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/392.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "6d675dd4eef8023cc360"
        },
        {
            "nl_otazka_id": "1762",
            "uv_otazka": "Zábrana na označenie uzávierky vyznačuje",
            "uv_odpoveda": "Miesto zastavenia vozidla k podrobeniu sa kontroly v cestnej premávke.",
            "uv_odpovedb": "Uzávierku celej šírky cesty alebo jej časti.",
            "uv_odpovedc": "Trvalú prekážku na ceste.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/701.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "fc87e525232aa8b0db1d"
        },
        {
            "nl_otazka_id": "1838",
            "uv_otazka": "Vozidlá prejdú cez križovatku v tomto poradí:",
            "uv_odpoveda": "1. zelené, 2. električka, 3. modré, 4. žlté, 5. vaše vozidlo.",
            "uv_odpovedb": "1. modré, 2. zelené, 3. žlté, 4. vaše vozidlo, 5. električka.",
            "uv_odpovedc": "1. električka, 2. zelené, 3. modré, 4. žlté, 5. vaše vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/18.png",
            "body": "4",
            "poznamka": "",
            "hash": "38362313af8f42b8606b"
        },
        {
            "nl_otazka_id": "1831",
            "uv_otazka": "Vaše vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "druhé.",
            "uv_odpovedb": "prvé.",
            "uv_odpovedc": "posledné.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/15.png",
            "body": "4",
            "poznamka": "",
            "hash": "f2295a08e226a352deb7"
        },
        {
            "nl_otazka_id": "1840",
            "uv_otazka": "Vaše vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "prvé súčasne so zeleným vozidlom.",
            "uv_odpovedb": "tretie súčasne so zeleným vozidlom.",
            "uv_odpovedc": "posledné.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/19.png",
            "body": "4",
            "poznamka": "",
            "hash": "ec69c7fcb858311f7d97"
        },
        {
            "nl_otazka_id": "1920",
            "uv_otazka": "Účastník dopravnej nehody je povinný bezodkladne upovedomiť osobu, ktorá nie je účastníkom dopravnej nehody, o hmotnej škode, ktorá jej bola spôsobená dopravnou nehodou, a oznámiť jej svoje osobné údaje; ak to nie je možné",
            "uv_odpoveda": "upovedomenie a oznámenie zabezpečí prostredníctvom súdu.",
            "uv_odpovedb": "upovedomenie a oznámenie zabezpečí prostredníctvom Policajného zboru.",
            "uv_odpovedc": "nezabezpečí upovedomenie a oznámenie.",
            "nl_okruh_id": "5",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "2193dd0dc54569ce50fc"
        },
        {
            "nl_otazka_id": "1993",
            "uv_otazka": "Pred odbočovaním vpravo je vodič povinný",
            "uv_odpoveda": "zaradiť sa čo najbližšie k pravému okraju vozovky; ak pritom musí s ohľadom na rozmery vozidla alebo nákladu vybočiť zo smeru svojej jazdy vľavo, vždy dáva len znamenie o zmene smeru jazdy vpravo.",
            "uv_odpovedb": "zaradiť sa ku ľavému okraju vozovky; ak pritom musí s ohľadom na rozmery vozidla alebo nákladu vybočiť zo smeru svojej jazdy vpravo, dáva znamenie o zmene smeru jazdy vpravo.",
            "uv_odpovedc": "zaradiť sa vždy do stredu vozovky.",
            "nl_okruh_id": "6",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "5b4253163e59dd2a44a8"
        },
        {
            "nl_otazka_id": "2139",
            "uv_otazka": "Vozidlo sa považuje za technicky nespôsobilé na prevádzku v cestnej premávke, najmä ak",
            "uv_odpoveda": "najvyšší bod činnej svietiacej plochy brzdových svetiel je nižšie ako 1500 mm nad rovinou vozovky.",
            "uv_odpovedb": "nie je vybavené zariadením na spájanie vozidiel.",
            "uv_odpovedc": "mechanizmus riadenia má narušenú funkčnosť.",
            "nl_okruh_id": "8",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "8b0b2a5e8169dfc61c7f"
        },
        {
            "nl_otazka_id": "2146",
            "uv_otazka": "Použitie pneumatík s protisklzovými hrotmi je zakázané",
            "uv_odpoveda": "pre vozidlá kategórie M a N.",
            "uv_odpovedb": "pre všetky vozidlá, okrem vozidiel záchrannej služby.",
            "uv_odpovedc": "pre všetky vozidlá, okrem zvláštnych motorových vozidiel.",
            "nl_okruh_id": "8",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "e5ad353942844c71913c"
        },
        {
            "nl_otazka_id": "2203",
            "uv_otazka": "Ako sa mení priľnavosť pneumatiky k povrchu vozovky pri prvých kvapkách dažďa?",
            "uv_odpoveda": "Priľnavosť sa výrazne nemení.",
            "uv_odpovedb": "Priľnavosť sa zvýši.",
            "uv_odpovedc": "Priľnavosť sa výrazne zníži.",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "739e16cb064c93456f49"
        },
        {
            "nl_otazka_id": "2175",
            "uv_otazka": "Pri zvyšovaní rýchlosti sa zorné pole vodiča",
            "uv_odpoveda": "zmenšuje",
            "uv_odpovedb": "zväčšuje, ale iba cez deň",
            "uv_odpovedc": "nemení",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "836608df1374dae85cca"
        },
        {
            "nl_otazka_id": "2198",
            "uv_otazka": "Silná hmla okrem zníženej viditeľnosti spôsobuje",
            "uv_odpoveda": "vlhkosť na povrchu vozovky a v zimných obdobiach aj poľadovicu",
            "uv_odpovedb": "koróziu a poruchy zapaľovania na vozidle",
            "uv_odpovedc": "zníženie počtu pomaly idúcich vozidiel v cestnej premávke",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f5f290bd9db08ccac387"
        },
        {
            "nl_otazka_id": "2177",
            "uv_otazka": "Vodičskou predvídavosťou nazývame",
            "uv_odpoveda": "činnosť obrátenú inam, než na dopravnú situáciu",
            "uv_odpovedb": "sústredenie vedomia na niektorý predmet",
            "uv_odpovedc": "schopnosť vodiča správne odhadnúť vývoj dopravnej situácie",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1b3ea40bf7b60f0075b8"
        },
        {
            "nl_otazka_id": "2155",
            "uv_otazka": "Úsek potrebný na zastavenie Vášho vozidla sa skladá z",
            "uv_odpoveda": "úseku reakcie vodiča, úseku oneskorenia bŕzd a úseku brzdnej dráhy",
            "uv_odpovedb": "úsekov reakcie a akcie ostatných vodičov",
            "uv_odpovedc": "úseku brzdnej dráhy",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "bfe6e2f81ba6ccf8657f"
        },
        {
            "nl_otazka_id": "2153",
            "uv_otazka": "Pri preprave nákladu na strešnom nosiči treba počítať s tým, že",
            "uv_odpoveda": "jazdné vlastnosti vozidla sa nemenia",
            "uv_odpovedb": "náklad nepriaznivo ovplyvňuje stabilitu vozidla",
            "uv_odpovedc": "náklad nepriaznivo ovplyvňuje stabilitu vozidla, ale zníži sa spotreba paliva",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "743720fe2b67af722f44"
        },
        {
            "nl_otazka_id": "2310",
            "uv_otazka": "Funkciou spojky je",
            "uv_odpoveda": "prenášať krútiaci moment motora do prevodovky.",
            "uv_odpovedb": "výlučne spojiť motor a rozvodovku.",
            "uv_odpovedc": "prenášať krútiaci moment z prevodovky do rozvodovky.",
            "nl_okruh_id": "10",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "11e76f255b84cbf361a1"
        },
        {
            "nl_otazka_id": "2334",
            "uv_otazka": "Medzi časté príčiny nadmerného opotrebovania spojky, patrí",
            "uv_odpoveda": "zlozvyk vodičov, keď počas jazdy majú úplne uvoľnenú spojku.",
            "uv_odpovedb": "zlozvyk vodičov, keď počas jazdy opierajú nohu o pedál spojky.",
            "uv_odpovedc": "plynulý rozjazd.",
            "nl_okruh_id": "10",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "eeff02b936107d44d26c"
        },
        {
            "nl_otazka_id": "1473",
            "uv_otazka": "Do pešej zóny je povolený vjazd",
            "uv_odpoveda": "všetkým vozidlám, ak nejazdia rýchlosťou vyššou ako 20 km/h.",
            "uv_odpovedb": "len bicyklom a motocyklom.",
            "uv_odpovedc": "len vozidlám, ktorým to umožňuje dopravná značka.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "9dda5ff711838d55cfb7"
        },
        {
            "nl_otazka_id": "1508",
            "uv_otazka": "Tento zastavovací terč používa pri zastavení vozidla",
            "uv_odpoveda": "akákoľvek iná osoba.",
            "uv_odpovedb": "len poverená osoba.",
            "uv_odpovedc": "len policajt.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "obr3/pcp/23_7_1.png",
            "body": "3",
            "poznamka": "",
            "hash": "56347334534b9a75401f"
        },
        {
            "nl_otazka_id": "1058",
            "uv_otazka": "Vodič motorového vozidla nesmie",
            "uv_odpoveda": "zastaviť vozidlo na priechode pre chodcov, mieste na prechádzanie alebo na priechode pre cyklistov,  ak to nevyžaduje bezpečnosť cestnej premávky.",
            "uv_odpovedb": "stáť na priechode pre cyklistov, smie však  zastaviť na priechode pre chodcov.",
            "uv_odpovedc": "stáť na priechode pre chodcov, smie však  zastaviť na priechode pre cyklistov.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3c32806e78e48a2cef1b"
        },
        {
            "nl_otazka_id": "1029",
            "uv_otazka": "Účastník cestnej premávky",
            "uv_odpoveda": "je povinný dbať na informačné dopravné značky a svetelné signály; na iné dopravné značky a dopravné zariadenia je povinný dbať len vodič električky.",
            "uv_odpovedb": "nie je povinný dbať na svetelné signály.",
            "uv_odpovedc": "je povinný správať sa disciplinovane a ohľaduplne tak, aby neohrozil bezpečnosť alebo plynulosť cestnej premávky, pritom je povinný prispôsobiť svoje správanie najmä stavebnému, dopravno-technickému stavu cesty, situácii v cestnej premávke, poveternostným podmienkam a svojim schopnostiam.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "fdf5ded60a06be9e8575"
        },
        {
            "nl_otazka_id": "1399",
            "uv_otazka": "V motorovom vozidle smú sa na miestach na to vyhradených prepravovať osoby",
            "uv_odpoveda": "len do prípustnej užitočnej hmotnosti vozidla, pritom počet prepravovaných osôb starších ako 12 rokov nesmie byť vyšší, ako je počet miest uvedených v osvedčení o evidencii.",
            "uv_odpovedb": "len do prípustnej užitočnej hmotnosti vozidla, pritom počet prepravovaných osôb nesmie byť vyšší, ako je počet miest uvedených v osvedčení o evidencii.",
            "uv_odpovedc": "staršie ako 15 rokov.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "b546953616a4a5277ffa"
        },
        {
            "nl_otazka_id": "1084",
            "uv_otazka": "Detské zadržiavacie zariadenie nesmie byť obrátené dozadu (obrátenie dozadu je otočenie v opačnom smere, ako je smer jazdy) na sedadle, ktoré je",
            "uv_odpoveda": "chránené čelným airbagom, ak nebol airbag vyradený z činnosti alebo nebol automaticky deaktivovaný.",
            "uv_odpovedb": "chránené čelným airbagom a airbag bol deaktivovaný.",
            "uv_odpovedc": "vybavené trojbodovým bezpečnostným pásom.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "25011fc3b58d4020f119"
        },
        {
            "nl_otazka_id": "1392",
            "uv_otazka": "Vodič vozidla označeného ako vozidlo lekára pri poskytovaní zdravotnej starostlivosti nemusí po nevyhnutne potrebný čas dodržiavať",
            "uv_odpoveda": "najvyššie dovolené rýchlosti jazdy.",
            "uv_odpovedb": "zákaz státia a najvyššie dovolené rýchlosti.",
            "uv_odpovedc": "zákaz státia; pritom nesmie ohroziť bezpečnosť cestnej premávky.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f3dac5f32fbe26fd32b9"
        },
        {
            "nl_otazka_id": "1577",
            "uv_otazka": "Ak sa vodiči protiidúcich vozidiel nemôžu bezpečne vyhnúť, prednosť v jazde je povinný dať ten vodič,",
            "uv_odpoveda": "na ktorého strane nie je zúžená vozovka.",
            "uv_odpovedb": "ktorý prichádza k prekážke nižšou rýchlosťou.",
            "uv_odpovedc": "na ktorého strane jazdy je prekážka alebo zúžená vozovka.",
            "nl_okruh_id": "2",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "35a0e2b1c70276b6698e"
        },
        {
            "nl_otazka_id": "1615",
            "uv_otazka": "Akou rýchlosťou môže jazdiť najviac vodič osobného automobilu v školskej zóne?",
            "uv_odpoveda": "Vjazd  vozidiel do  školskej  zóny je zakázaný.",
            "uv_odpovedb": "20 km.h-1.",
            "uv_odpovedc": "30 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "15d57cf8e5213c69cf93"
        },
        {
            "nl_otazka_id": "1625",
            "uv_otazka": "Táto dopravná značka upozorňuje na",
            "uv_odpoveda": "Spôsob obchádzania prekážky.",
            "uv_odpovedb": "Dve alebo viac zákrut nasledujúcich bezprostredne za sebou vo vyznačenom smere, ktorých bezpečný prejazd si vyžaduje výrazné zníženie rýchlosti jazdy.",
            "uv_odpovedc": "Nebezpečenstvo šmyku.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/111.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "e2a5f7d038f610acd640"
        },
        {
            "nl_otazka_id": "1711",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Označuje koniec vyhradeného jazdného pruhu.",
            "uv_odpovedb": "Koniec cesty pre motorové vozidlá.",
            "uv_odpovedc": "Označuje koniec parkoviska.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/312.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "795cb0acd4a7d11ce6d5"
        },
        {
            "nl_otazka_id": "1751",
            "uv_otazka": "Ak je touto značkou vyznačený jazdný pruh",
            "uv_odpoveda": "Vodič je povinný riadiť sa významom bližšej čiary.",
            "uv_odpovedb": "Riadi sa premávka v jazdných pruhoch svetelnými signálmi.",
            "uv_odpovedc": "Vodič sa riadi významom ľavej čiary z tejto dvojice čiar.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/603.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "de4a3511a440d96ca39b"
        },
        {
            "nl_otazka_id": "1693",
            "uv_otazka": "Táto dopravná značka informuje",
            "uv_odpoveda": "O prednosti pred protiidúcimi vozidlami.",
            "uv_odpovedb": "O smere jazdy.",
            "uv_odpovedc": "O počte jazdných pruhov.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/401-409.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "a65a8961718dbeeac568"
        },
        {
            "nl_otazka_id": "1757",
            "uv_otazka": "Značka umiestnená v osi",
            "uv_odpoveda": "Parkovacieho jazdného pruhu vyznačuje povolené zastavenie a státie kolmo alebo šikmo na okraj vozovky.",
            "uv_odpovedb": "Pozdĺžnej prerušovanej čiary na ceste s obojsmernou premávkou vyznačuje blížiaci sa prechod Pozdĺžnej prerušovanej čiary do Pozdĺžnej súvislej čiary.",
            "uv_odpovedc": "Pozdĺžnej prerušovanej čiary na ceste s obojsmernou premávkou vyznačuje miesto vjazdu vozidiel na cestu z miesta ležiaceho mimo cesty.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/631.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "49bc129d7fd43599a235"
        },
        {
            "nl_otazka_id": "1705",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Núdzovú odstavnú plochu.",
            "uv_odpovedb": "Miesto alebo priestor, kde je dovolené zastavenie a státie vozidiel.",
            "uv_odpovedc": "Odstavnú plocha pre vozidlá s nadrozmerným nákladom.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/272.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "b107ddf06ad2a08fd087"
        },
        {
            "nl_otazka_id": "1814",
            "uv_otazka": "Vozidlá prejdú cez križovatku v tomto poradí:",
            "uv_odpoveda": "1. modré, 2. vaše vozidlo, 3. červené.",
            "uv_odpovedb": "1. červené, 2. modré, 3. vaše vozidlo.",
            "uv_odpovedc": "1. vaše vozidlo, 2. červené, 3. modré.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/01.png",
            "body": "4",
            "poznamka": "",
            "hash": "d56fd9551669d7d9c9b2"
        },
        {
            "nl_otazka_id": "1870",
            "uv_otazka": "Vozidlá prejdú cez križovatku v tomto poradí:",
            "uv_odpoveda": "1. zelené, 2. modré, 3. žlté.",
            "uv_odpovedb": "1. modré, 2. zelené, 3. žlté.",
            "uv_odpovedc": "1. žlté, 2. zelené, 3. modré.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/24.png",
            "body": "4",
            "poznamka": "",
            "hash": "58248a8e7af98bbc15a7"
        },
        {
            "nl_otazka_id": "1918",
            "uv_otazka": "Účastník dopravnej nehody",
            "uv_odpoveda": "nie je povinný umožniť obnovenie cestnej premávky.",
            "uv_odpovedb": "je povinný umožniť obnovenie cestnej premávky len za zníženej viditeľnosti.",
            "uv_odpovedc": "je povinný umožniť obnovenie cestnej premávky, najmä premávky vozidiel pravidelnej verejnej dopravy osôb.",
            "nl_okruh_id": "5",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "2434416054e114257efd"
        },
        {
            "nl_otazka_id": "1945",
            "uv_otazka": "Ak sa na prístrojovom paneli rozsvieti kontrolka tlaku v pneumatikách, signalizuje",
            "uv_odpoveda": "výmenu letných pneumatík za zimné pneumatiky.",
            "uv_odpovedb": "nesprávny tlak v pneumatikách.",
            "uv_odpovedc": "nedostatočnú hĺbku dezénových drážok pneumatík.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "obr3/tvv/37.png",
            "body": "2",
            "poznamka": "",
            "hash": "966c5ad53be3ec0982f6"
        },
        {
            "nl_otazka_id": "2090",
            "uv_otazka": "Vodič",
            "uv_odpoveda": "nie je povinný mať pri sebe platné doklady predpísané zákonom.",
            "uv_odpovedb": "je povinný mať pri sebe platné doklady predpísané zákonom.",
            "uv_odpovedc": "je povinný mať len vodičský preukaz.",
            "nl_okruh_id": "7",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "068b492e69d008b01cb9"
        },
        {
            "nl_otazka_id": "2031",
            "uv_otazka": "Vodičské oprávnenie skupiny B oprávňuje viesť motorové vozidlá skupiny B, ako aj motorové vozidlá skupiny",
            "uv_odpoveda": "C1.",
            "uv_odpovedb": "AM, B1.",
            "uv_odpovedc": "B1, A1, A2.",
            "nl_okruh_id": "7",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "4abcec4db32ba48ab16c"
        },
        {
            "nl_otazka_id": "2121",
            "uv_otazka": "Vozidlo sa považuje za technicky nespôsobilé na prevádzku v cestnej premávke, najmä ak",
            "uv_odpoveda": "je prasklina alebo chyba zvarov na diskoch kolies.",
            "uv_odpovedb": "vozidlo nie je vybavené zariadením na spájanie vozidiel.",
            "uv_odpovedc": "sú poškodené lapače nečistôt.",
            "nl_okruh_id": "8",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "ffb39544401293359bc0"
        },
        {
            "nl_otazka_id": "2212",
            "uv_otazka": "Na klzkom povrchu vozovky je potrebné",
            "uv_odpoveda": "brzdiť intenzívne, aby sa minimalizovalo riziko šmyku",
            "uv_odpovedb": "brzdiť a zrýchľovať citlivo, aby sa minimalizovalo riziko šmyku",
            "uv_odpovedc": "meniť smer jazdy rýchlejšie",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f019d5fa8302e28bf1b0"
        },
        {
            "nl_otazka_id": "2208",
            "uv_otazka": "Vznik aquaplaningu najviac ovplyvňujú",
            "uv_odpoveda": "zimné pneumatiky",
            "uv_odpovedb": "druh vozovky",
            "uv_odpovedc": "rýchlosť jazdy, výška vodného stĺpca a stav pneumatík",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "8be94db0625a6c9d4096"
        },
        {
            "nl_otazka_id": "2307",
            "uv_otazka": "Vodič vozidla registruje pred vozidlom nebezpečnú situáciu. Mal by pozrieť do spätných zrkadiel?",
            "uv_odpoveda": "Áno, aby mohol zrýchliť a vyhnúť sa riziku.",
            "uv_odpovedb": "Nie je potrebné, aby sa pozrel do spätných zrkadiel.",
            "uv_odpovedc": "Áno, aby mohol vyhodnotiť, aký vplyv bude mať akékoľvek jeho konanie na iných účastníkov cestnej premávky.",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "dd9f538844a84bc8cec9"
        },
        {
            "nl_otazka_id": "2200",
            "uv_otazka": "Z hľadiska zásad bezpečnej jazdy, ako by mal reagovať vodič pri oslnení?",
            "uv_odpoveda": "Vodič má periférne pozorovať stredovú deliacu čiaru.",
            "uv_odpovedb": "Pohľad smerujeme na čiaru označujúcu pravý okraj vozovky, prípadne na pravý okraj vozovky a periférnym videním sledujeme prichádzajúce vozidlo.",
            "uv_odpovedc": "Vodič má intenzívne začať brzdiť.",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "7943dc6cb5d1c2c4f498"
        },
        {
            "nl_otazka_id": "2171",
            "uv_otazka": "Veľkosť súčiniteľa adhézie (trenia) závisí",
            "uv_odpoveda": "výlučne od povrchu vozovky",
            "uv_odpovedb": "od kategórie motorového vozidla",
            "uv_odpovedc": "na druhu, povrchu, stavu vozovky a pneumatík, a do istej miery aj od rýchlosti jazdy",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "26bcf222882665f43f35"
        },
        {
            "nl_otazka_id": "2156",
            "uv_otazka": "Správny postup pri brzdení v bežnej premávke z vyššej rýchlosti je",
            "uv_odpoveda": "uvoľniť plynový pedál, stlačiť súčasne brzdový a spojkový pedál",
            "uv_odpovedb": "uvoľniť plynový pedál, stlačiť brzdový pedál a súčasne zaradiť neutrál",
            "uv_odpovedc": "uvoľniť plynový pedál, stlačiť brzdový pedál a tesne pred zastavením stlačiť spojkový pedál",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "68cb694a9ac153eeb7cc"
        },
        {
            "nl_otazka_id": "2172",
            "uv_otazka": "Činnosť systému ABS pri brzdení rozpozná vodič",
            "uv_odpoveda": "podľa rozsvietenia kontrolného svetla systému ESP na palubnej doske",
            "uv_odpovedb": "podľa vibrovania pedálu brzdy",
            "uv_odpovedc": "podľa zvukového signálu systému ABS",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "43d6ce4a4f0103a28498"
        },
        {
            "nl_otazka_id": "2373",
            "uv_otazka": "Chladiacu kvapalinu tvorí",
            "uv_odpoveda": "brzdová kvapalina.",
            "uv_odpovedb": "destilovaná voda.",
            "uv_odpovedc": "zmes destilovanej vody a nemrznúcej kvapaliny.",
            "nl_okruh_id": "10",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "13732068cb1e9cd1807d"
        },
        {
            "nl_otazka_id": "2333",
            "uv_otazka": "Vo všeobecnosti sa dá povedať, že životnosť spojky výrazne ovplyvňujú",
            "uv_odpoveda": "technika jazdy nemá vplyv na životnosť spojky.",
            "uv_odpovedb": "vodičské zručnosti, používanie vysokých otáčok pri rozbehu vozidla keď vodič má ešte spojku v zábere a samotná prevádzka (častá jazda mestom alebo naopak diaľnicou).",
            "uv_odpovedc": "jazda s vysokou rýchlosťou.",
            "nl_okruh_id": "10",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "00f43f3acb7bb2563c97"
        },
        {
            "nl_otazka_id": "1161",
            "uv_otazka": "Vodič je povinný za vozidlom idúcim pred ním",
            "uv_odpoveda": "dodržiavať takú vzdialenosť, aby mohol včas znížiť rýchlosť jazdy, prípadne zastaviť vozidlo, ak vodič vozidla jazdiaceho pred ním zníži rýchlosť jazdy alebo zastaví.",
            "uv_odpovedb": "dodržiavať takú vzdialenosť, aby zreteľne videl v ňom sediace osoby.",
            "uv_odpovedc": "jazdiť vo vzdialenosti najmenej 40 m.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "37306fcd57ef3bd233c5"
        },
        {
            "nl_otazka_id": "1208",
            "uv_otazka": "Vodič vozidla, ktoré zastavilo alebo stálo a opäť vychádza od okraja cesty alebo od chodníka",
            "uv_odpoveda": "nie je povinný dávať znamenie o zmene smeru jazdy.",
            "uv_odpovedb": "je povinný dávať znamenie o zmene smeru jazdy.",
            "uv_odpovedc": "je povinný dávať znamenie o zmene smeru jazdy len mimo obce.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "0794f059e612275fe20d"
        },
        {
            "nl_otazka_id": "1467",
            "uv_otazka": "Osoba vykonávajúca práce spojené s jej údržbou, opravou alebo s výstavbou cesty je oprávnená dávať pokyny na zastavenie vozidla,",
            "uv_odpoveda": "ak to vyžaduje bezpečnosť cestnej premávky.",
            "uv_odpovedb": "ak to vyžaduje plynulosť cestnej premávky; pritom musí mať pri sebe vodičský preukaz.",
            "uv_odpovedc": "len ak ju tým poveril orgán Policajného zboru.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "eef9e7439947424eb668"
        },
        {
            "nl_otazka_id": "1472",
            "uv_otazka": "Hry detí na ceste sú dovolené",
            "uv_odpoveda": "len v obytnej zóne.",
            "uv_odpovedb": "len v pešej zóne.",
            "uv_odpovedc": "v obytnej a školskej zóne.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ce8ecba84a91272fedd8"
        },
        {
            "nl_otazka_id": "1185",
            "uv_otazka": "Vodič je povinný zaistiť bezpečné otáčanie alebo cúvanie pomocou spôsobilej a náležite poučenej osoby,",
            "uv_odpoveda": "ak to vyžadujú okolnosti, najmä nedostatočný rozhľad.",
            "uv_odpovedb": "ak sa otáča alebo cúva v tuneli a v jeho tesnej blízkosti.",
            "uv_odpovedc": "ak sa otáča alebo cúva na priechode pre chodcov alebo na priechode pre cyklistov.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "0c9c93b6c8008ad9e9ec"
        },
        {
            "nl_otazka_id": "1623",
            "uv_otazka": "Prednosť v jazde vyplývajúca zo svetelných signálov",
            "uv_odpoveda": "nie je nadradená prednosti v jazde určenej dopravnými značkami.",
            "uv_odpovedb": "je nadradená prednosti v jazde určenej dopravnými značkami, len ak je to dovolené dopravnou značkou.",
            "uv_odpovedc": "je nadradená prednosti v jazde určenej dopravnými značkami.",
            "nl_okruh_id": "2",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "19b22c8761a4cbda5899"
        },
        {
            "nl_otazka_id": "1760",
            "uv_otazka": "Táto dopravná značka vyznačuje",
            "uv_odpoveda": "Priestor, kde je dovolené zastavenie a státie.",
            "uv_odpovedb": "Priestor, do ktorého je zakázané vchádzať alebo doň nákladom presahovať, ak to nie je potrebné na obchádzanie prekážky cestnej premávky.",
            "uv_odpovedc": "Šikmý priechod pre chodcov.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/620.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "99deca4f51c13c8db9bb"
        },
        {
            "nl_otazka_id": "1686",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Označuje kruhový objazd a prikazuje smer jazdy len v smere vyznačenom šípkami na značke.",
            "uv_odpovedb": "Prikazuje jednosmernú premávku v smere vyznačenom šípkami.",
            "uv_odpovedc": "Prikázaný smer otáčania.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/213.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "d5c5ff0167297d3abd16"
        },
        {
            "nl_otazka_id": "1632",
            "uv_otazka": "Táto dopravná značka upozorňuje na",
            "uv_odpoveda": "Úsek cesty, ktorej povrch je znečistený a za mokra klzký.",
            "uv_odpovedb": "Ujazdený alebo zľadovatený sneh alebo na poľadovicu.",
            "uv_odpovedc": "Povinnosť použiť pri jazde snehové reťaze.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/121.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "1096e7dd79b0cc7bde77"
        },
        {
            "nl_otazka_id": "1703",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Vstup do podzemnej dopravnej dráhy.",
            "uv_odpovedb": "Podchod alebo nadchod.",
            "uv_odpovedc": "Priechod pre chodcov.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/327.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "8cb602b5aa9590e48694"
        },
        {
            "nl_otazka_id": "1736",
            "uv_otazka": "Táto dopravná značka informuje",
            "uv_odpoveda": "O najvyššej dovolenej rýchlosti pre elektromobily.",
            "uv_odpovedb": "O čísle cesty zaradenej do medzinárodnej siete ciest kategórie E.",
            "uv_odpovedc": "O čísle cesty I. triedy alebo o čísle cesty II. triedy.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/353.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "6a19b8b5fb9ccbfead3d"
        },
        {
            "nl_otazka_id": "1737",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Označuje kraj, okres, časť obce, most a podobne.",
            "uv_odpovedb": "Informuje o názve najbližšej križovatky.",
            "uv_odpovedc": "Označuje koniec obce.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/340.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "4330c1703287a69f14e8"
        },
        {
            "nl_otazka_id": "1654",
            "uv_otazka": "Akú cestu označuje táto dopravná značka?",
            "uv_odpoveda": "Začiatok alebo priebeh hlavnej cesty.",
            "uv_odpovedb": "Vjazd na cestu pre motorové vozidlá.",
            "uv_odpovedc": "Začiatok alebo priebeh vedľajšej cesty.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/302.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "2923b0101035379108c5"
        },
        {
            "nl_otazka_id": "1633",
            "uv_otazka": "Táto dopravná značka upozorňuje na",
            "uv_odpoveda": "Zvýšené riziko odletujúceho štrku spod kolies vozidla.",
            "uv_odpovedb": "Zákaz státia na krajnici.",
            "uv_odpovedc": "Nebezpečenstvo poškodenia pneumatík.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/122.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "6b66d4f489763f741e89"
        },
        {
            "nl_otazka_id": "1857",
            "uv_otazka": "Ako posledné prejde cez križovatku",
            "uv_odpoveda": "červené vozidlo.",
            "uv_odpovedb": "zelené vozidlo.",
            "uv_odpovedc": "žlté vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/28.png",
            "body": "4",
            "poznamka": "",
            "hash": "4880879280f60820581a"
        },
        {
            "nl_otazka_id": "1865",
            "uv_otazka": "Vozidlá prejdú cez križovatku v tomto poradí:",
            "uv_odpoveda": "1. červené vojde do križovatky a dá prednosť v jazde protiidúcemu modrému, 2. zelené, 3. modré, 4. červené dokončí odbočovanie vľavo.",
            "uv_odpovedb": "1. modré, 2. červené, 3. zelené.",
            "uv_odpovedc": "1. červené, 2. modré, 3. zelené.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/08.png",
            "body": "4",
            "poznamka": "",
            "hash": "7e1c7db31743c2eff82b"
        },
        {
            "nl_otazka_id": "1842",
            "uv_otazka": "Vaše vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "posledné súčasne so žltým vozidlom.",
            "uv_odpovedb": "prvé.",
            "uv_odpovedc": "druhé.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/20.png",
            "body": "4",
            "poznamka": "",
            "hash": "051354a26fd1f4091a74"
        },
        {
            "nl_otazka_id": "1882",
            "uv_otazka": "Ako posledné prejde cez križovatku",
            "uv_odpoveda": "zelené vozidlo.",
            "uv_odpovedb": "červené vozidlo súčasne s modrým.",
            "uv_odpovedc": "zelené vozidlo súčasne s červeným.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/30.png",
            "body": "4",
            "poznamka": "",
            "hash": "2c23b956dafe603dfba0"
        },
        {
            "nl_otazka_id": "1905",
            "uv_otazka": "Dopravná nehoda je udalosť v cestnej premávke, ktorá sa stane v priamej súvislosti s premávkou vozidla a pri ktorej",
            "uv_odpoveda": "dôjde k stretu aspoň troch vozidiel.",
            "uv_odpovedb": "uniknú nebezpečné veci.",
            "uv_odpovedc": "neuniknú nebezpečné veci",
            "nl_okruh_id": "5",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "898eae2650026c593b57"
        },
        {
            "nl_otazka_id": "1948",
            "uv_otazka": "Táto kontrolka signalizuje zapnutie",
            "uv_odpoveda": "obrysových svietidiel.",
            "uv_odpovedb": "diaľkových svetlometov.",
            "uv_odpovedc": "stretávacích svetlometov.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "obr3/tvv/07.png",
            "body": "2",
            "poznamka": "",
            "hash": "dac3493911762202234f"
        },
        {
            "nl_otazka_id": "1939",
            "uv_otazka": "Ak sa na prístrojovom paneli rozsvieti varovná kontrolka teploty alebo nízkej hladiny chladiacej kvapaliny",
            "uv_odpoveda": "signalizuje vysokú hladinu chladiacej kvapaliny.",
            "uv_odpovedb": "signalizuje nízku teplotu chladiacej kvapaliny.",
            "uv_odpovedc": "signalizuje, že sa motor prehrieva, čo značí jeho preťaženie alebo chýbajúcu chladiacu kvapalinu.",
            "nl_okruh_id": "6",
            "nl_correct": "3",
            "uv_obrazok": "obr3/tvv/34.png",
            "body": "2",
            "poznamka": "",
            "hash": "0af31ebb618af34bb253"
        },
        {
            "nl_otazka_id": "1947",
            "uv_otazka": "Ak svieti táto kontrolka znamená to,",
            "uv_odpoveda": "nízku hladinu kvapaliny v nádržke ostrekovača. ",
            "uv_odpovedb": "že teplota chladiacej kvapaliny je príliš nízka.",
            "uv_odpovedc": "že sa motor prehrieva, čo značí jeho preťaženie alebo chýbajúcu chladiacu kvapalinu.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "obr3/tvv/18.png",
            "body": "2",
            "poznamka": "",
            "hash": "70b98188f570c5a7b914"
        },
        {
            "nl_otazka_id": "2072",
            "uv_otazka": "Vodičský preukaz je neplatný ak",
            "uv_odpoveda": "vodič jazdí pod vplyvom alkoholu.",
            "uv_odpovedb": "vodič nemá počas kontroly platnú technickú a emisnú kontrolu.",
            "uv_odpovedc": "vodičské oprávnenie v ňom uvedené sa odobralo alebo obmedzilo.",
            "nl_okruh_id": "7",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "331fa6ac32b6704d15e6"
        },
        {
            "nl_otazka_id": "2122",
            "uv_otazka": "Ak je nefunkčný brzdový posilňovač alebo sú nefunkčné súčasti posilňovača",
            "uv_odpoveda": "považuje sa takéto vozidlo za technicky nespôsobilé na prevádzku v cestnej premávke.",
            "uv_odpovedb": "možno také vozidlo používať len na účelových komunikáciách.",
            "uv_odpovedc": "možno s takýmto vozidlom jazdiť rýchlosťou neprevyšujúcou 50 km/h.",
            "nl_okruh_id": "8",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "9964e54f62d0ef32cbd4"
        },
        {
            "nl_otazka_id": "2112",
            "uv_otazka": "Vozidlo sa musí udržiavať",
            "uv_odpoveda": "v takom technickom stave, aby nedošlo k obmedzeniu bezpečnosti cestnej premávky.",
            "uv_odpovedb": "v dobrom technickom stave podľa pokynov na údržbu ustanovených prevádzkovateľom.",
            "uv_odpovedc": "v riadnom technickom stave podľa pokynov na obsluhu a údržbu ustanovených výrobcom.",
            "nl_okruh_id": "8",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "e0cc9495701d79d509be"
        },
        {
            "nl_otazka_id": "2185",
            "uv_otazka": "Únavu vodiča spôsobuje najčastejšie",
            "uv_odpoveda": "nedostatok alebo kvalita spánku na únavu nemá vplyv",
            "uv_odpovedb": "krátka jazda s častými prestávkami",
            "uv_odpovedc": "nedostatok alebo zlá kvalita spánku",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "c225caa386f03ad4c336"
        },
        {
            "nl_otazka_id": "2174",
            "uv_otazka": "Je spánkové apnoe (porucha spánku) jedným z faktorov únavy vodiča?",
            "uv_odpoveda": "nie",
            "uv_odpovedb": "len za zníženej viditeľnosti",
            "uv_odpovedc": "áno",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ce7a04b8c5a4eb9591d0"
        },
        {
            "nl_otazka_id": "2209",
            "uv_otazka": "Po prejdení kaluže môže dôjsť k navlhčeniu bŕzd, preto",
            "uv_odpoveda": "vodič nemusí reagovať. Vlhkosť nemá vplyv na brzdný účinok.",
            "uv_odpovedb": "vodič nesmie brzdiť.",
            "uv_odpovedc": "je potrebné mať na zreteli nižší účinok bŕzd.",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "b12c0811bb5435bb1632"
        },
        {
            "nl_otazka_id": "2194",
            "uv_otazka": "Vodič musí počítať s tým, že starší človek pri prechádzaní vozovky",
            "uv_odpoveda": "vždy správne reaguje na prichádzajúce vozidlá",
            "uv_odpovedb": "zostane na vozovke stáť, otočí sa a ide naspäť, alebo inak neprimerane reaguje",
            "uv_odpovedc": "úmyselne nedáva pozor",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "54570aa7f8c06386e78a"
        },
        {
            "nl_otazka_id": "2214",
            "uv_otazka": "Pre zvládnutie nedotáčavého šmyku",
            "uv_odpoveda": "neakcelerujeme a nebrzdíme",
            "uv_odpovedb": "akcelerujeme a natočíme volant viac do zákruty",
            "uv_odpovedc": "brzdíme prerušovane",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "b7502341e0712c2e18a1"
        },
        {
            "nl_otazka_id": "2197",
            "uv_otazka": "Pri bočnom vetre má vplyv na smerovú stabilitu vozidla",
            "uv_odpoveda": "sila a rýchlosť vetra a stav krajnice",
            "uv_odpovedb": "veľkosť vozidla a počet prepravovaných osôb vo vozidle",
            "uv_odpovedc": "sila vetra, rýchlosť a bočná plocha vozidla, ako aj zaťaženie vozidla",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "bd792f47cc25b47e52e8"
        },
        {
            "nl_otazka_id": "2323",
            "uv_otazka": "Pneumatika s dušou sa označuje",
            "uv_odpoveda": "TUBE TYPE.",
            "uv_odpovedb": "PLY.",
            "uv_odpovedc": "185/65 R14 86T.",
            "nl_okruh_id": "10",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "41091788f19985b2c65c"
        },
        {
            "nl_otazka_id": "2322",
            "uv_otazka": "Termostat vo vozidle slúži na",
            "uv_odpoveda": "reguláciu teploty paliva.",
            "uv_odpovedb": "samočinnú reguláciu teploty chladiacej kvapaliny.",
            "uv_odpovedc": "reguláciu teploty hydraulického oleja v prevodovke.",
            "nl_okruh_id": "10",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "26df763d917c943ace94"
        },
        {
            "nl_otazka_id": "1180",
            "uv_otazka": "Vodič pri otáčaní nesmie",
            "uv_odpoveda": "dávať znamenie o zmene smeru jazdy.",
            "uv_odpovedb": "dávať znamenie o zmene smeru jazdy, ak odbočuje na miesto mimo cesty.",
            "uv_odpovedc": "ohroziť vodiča idúceho za ním.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "7be217be5fa3a8ddd9ad"
        },
        {
            "nl_otazka_id": "1249",
            "uv_otazka": "Vodič nesmie zastaviť a stáť na mieste, kde by medzi vozidlom a najbližšou električkovou koľajnicou nezostal voľný jazdný pruh široký najmenej",
            "uv_odpoveda": "2,5 m.",
            "uv_odpovedb": "3,5 m.",
            "uv_odpovedc": "3 m.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "6affc4c4549168a6ea2e"
        },
        {
            "nl_otazka_id": "1301",
            "uv_otazka": "Svetelné výstražné znamenie sa dáva prerušovaným zapínaním",
            "uv_odpoveda": "stretávacích svetlometov alebo diaľkových svetlometov.",
            "uv_odpovedb": "smerových svietidiel alebo predných svetlometov do hmly.",
            "uv_odpovedc": "parkovacích svietidiel.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "4116b4c1c2dadbf76837"
        },
        {
            "nl_otazka_id": "1384",
            "uv_otazka": "Toto osobitné označenie sa môže používať na vozidle",
            "uv_odpoveda": "zubára pri poskytovaní akútnej starostlivosti.",
            "uv_odpovedb": "lekára pri poskytovaní zdravotnej starostlivosti.",
            "uv_odpovedc": "vedenom vodičom začiatočníkom.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "obr3/pcp/25_2_2.png",
            "body": "3",
            "poznamka": "",
            "hash": "9f1901adb06c7b5723a1"
        },
        {
            "nl_otazka_id": "1191",
            "uv_otazka": "V tuneli a v jeho tesnej blízkosti vodič",
            "uv_odpoveda": "nesmie cúvať, ale smie sa otáčať.",
            "uv_odpovedb": "smie cúvať.",
            "uv_odpovedc": "sa nesmie otáčať a nesmie cúvať",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "9be30017a42f86276c7e"
        },
        {
            "nl_otazka_id": "1075",
            "uv_otazka": "Prevádzkovateľ vozidla je povinný zabezpečiť, aby sa na jazdu pribral potrebný počet spôsobilých a náležité poučených osôb, ak je mu vopred známe, že",
            "uv_odpoveda": "to bude vyžadovať bezpečnosť cestnej premávky.",
            "uv_odpovedb": "vo vozidle budú prepravované nebezpečné veci alebo živé zvieratá.",
            "uv_odpovedc": "vodič v potrebnom rozsahu neovláda vedenie vozidla.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "56bc2aa0df461481e0c5"
        },
        {
            "nl_otazka_id": "1491",
            "uv_otazka": "Smie vodič osobného automobilu stáť v školskej zóne?",
            "uv_odpoveda": "Nie.",
            "uv_odpovedb": "Áno, ak tým nie je obmedzený pohyb chodcov.",
            "uv_odpovedc": "Len, ak je to dovolené dopravnou značkou.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "8428916d98ed5cbcb474"
        },
        {
            "nl_otazka_id": "1591",
            "uv_otazka": "Akou rýchlosťou je povinný jazdiť vodič pri prechádzaní železničným priecestím ?",
            "uv_odpoveda": "Najmenej 30 km.h-1 a najviac 50 km.h-1.",
            "uv_odpovedb": "Najmenej 30 km.h-1, a ak na priecestnom zabezpečovacom zariadení svieti prerušované biele svetlo najviac 50 km.h-1.",
            "uv_odpovedc": "Najviac 30 km.h-1, a ak na priecestnom zabezpečovacom zariadení svieti prerušované biele svetlo najviac 50 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5d61cafa2e85989a4b0f"
        },
        {
            "nl_otazka_id": "1601",
            "uv_otazka": "Najmenšia rýchlosť jazdy stanovená na diaľnici sa nevzťahuje na vodiča",
            "uv_odpoveda": "motorového vozidla pri súbežnej jazde a v iných prípadoch, ak je to potrebné z hľadiska bezpečnosti a plynulosti cestnej premávky.",
            "uv_odpovedb": "v čase od 22.00 do 04.00 hodín.",
            "uv_odpovedc": "zvláštneho motorového vozidla v čase od 00.00 do 22.00 hodín.",
            "nl_okruh_id": "2",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e334d1dde715c1b703a1"
        },
        {
            "nl_otazka_id": "1724",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Pred križovatkou informuje o ceste, ktorá končí, alebo ktorou nemožno ďalej pokračovať vjazde.",
            "uv_odpovedb": "Vo vyznačenom smere označuje miesto na núdzové brzdenie na prudkom klesaní.",
            "uv_odpovedc": "Informuje o blízkosti odpočívadla so sociálnym zariadením a autoservisom.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/336.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "2399be8bd2a5f5c3dd7e"
        },
        {
            "nl_otazka_id": "1728",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Vyznačuje spôsob radenia sa pred križovatkou.",
            "uv_odpovedb": "Vyznačuje hlavnú cestu.",
            "uv_odpovedc": "Vhodným spôsobom informuje v obci o križovatke, na ktorej je zakázané odbočenie alebo prikázaný smer jazdy a vyznačuje smer obchádzania.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/397.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "10031189b00e23c5c57c"
        },
        {
            "nl_otazka_id": "1685",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Prikazuje otáčanie.",
            "uv_odpovedb": "Upozorňuje na nebezpečné klesanie.",
            "uv_odpovedc": "Prikazuje obchádzanie vo vyznačenom smere.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/212.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "15600821cf1d51275463"
        },
        {
            "nl_otazka_id": "1793",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Osoby so zdravotným postihnutím",
            "uv_odpovedb": "Chodci",
            "uv_odpovedc": "Osoby pohybujúce sa na korčuliach",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/1-27.png",
            "body": "2",
            "poznamka": "",
            "hash": "75b62679376d366d380b"
        },
        {
            "nl_otazka_id": "1674",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Ukončuje zákaz vyplývajúci z predchádzajúcej značky Zákaz obchádzania.",
            "uv_odpovedb": "Znamená zákaz obchádzania vľavo.",
            "uv_odpovedc": "Ukončuje zákaz vyplývajúci z predchádzajúcej značky Zákaz predchádzania.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/264.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "0044b6e4f42c6c905bec"
        },
        {
            "nl_otazka_id": "1753",
            "uv_otazka": "Táto vodorovná dopravná značka vyznačuje",
            "uv_odpoveda": "Priechod pre jazdcov na koni.",
            "uv_odpovedb": "Priechod pre cyklistov.",
            "uv_odpovedc": "Priechod pre chodcov.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/610.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "d2058ed03a0d255dfca3"
        },
        {
            "nl_otazka_id": "1899",
            "uv_otazka": "Vodič modrého vozidla",
            "uv_odpoveda": "je povinný dať prednosť vodičovi červeného vozidla, pretože prichádza sprava.",
            "uv_odpovedb": "nie je povinný dať prednosť vodičovi červeného vozidla, pretože ide po kruhovom objazde.",
            "uv_odpovedc": "má prednosť pred vodičom červeného vozidla, pretože ide po kruhovom objazde.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/07.png",
            "body": "4",
            "poznamka": "",
            "hash": "826a775b4288a2e03cec"
        },
        {
            "nl_otazka_id": "1825",
            "uv_otazka": "Vaše vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "prvé.",
            "uv_odpovedb": "tretie.",
            "uv_odpovedc": "druhé.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/11.png",
            "body": "4",
            "poznamka": "",
            "hash": "b3b500fd854addd17b80"
        },
        {
            "nl_otazka_id": "1839",
            "uv_otazka": "Vozidlá prejdú cez križovatku v tomto poradí:",
            "uv_odpoveda": "1. vaše vozidlo súčasne so zeleným, 2. električka č. 1 súčasne s električkou č. 2.",
            "uv_odpovedb": "1. vaše vozidlo, 2 .električka č. 1 súčasne s električkou č. 2, 3. zelené.",
            "uv_odpovedc": "1. zelené, 2. električka č. 1 súčasne s električkou č. 2, 3. vaše vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/19.png",
            "body": "4",
            "poznamka": "",
            "hash": "f53806ff16b8bf70e996"
        },
        {
            "nl_otazka_id": "1927",
            "uv_otazka": "Účastník škodovej udalosti je povinný",
            "uv_odpoveda": "preukázať svoju totožnosť inému účastníkovi škodovej udalosti.",
            "uv_odpovedb": "poskytnúť odbornú zdravotnú pomoc osobám zraneným pri škodovej udalosti.",
            "uv_odpovedc": "oznámiť vznik škodovej udalosti v obci príslušníkovi obecnej polície.",
            "nl_okruh_id": "5",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "31a3c7b441243acc0644"
        },
        {
            "nl_otazka_id": "1973",
            "uv_otazka": "Pri rozjazde je dôležité z hladiska úspornej jazdy",
            "uv_odpoveda": "zapnúť tempomat.",
            "uv_odpovedb": "svižne akcelerovať.",
            "uv_odpovedc": "zapnúť klimatizáciu.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "7342c25125aaf7b38a44"
        },
        {
            "nl_otazka_id": "2079",
            "uv_otazka": "Držiteľ vodičského preukazu",
            "uv_odpoveda": "nie je povinný oznámiť orgánu Policajného zboru zmenu svojich osobných údajov uvedených vo vodičskom preukaze.",
            "uv_odpovedb": "je povinný oznámiť orgánu Policajného zboru do 14 dní zmenu svojich osobných údajov uvedených vo vodičskom preukaze.",
            "uv_odpovedc": "je povinný oznámiť orgánu Policajného zboru do 7 dní zmenu svojich osobných údajov uvedených vo vodičskom preukaze.",
            "nl_okruh_id": "7",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "1075d1f17a60042e6d86"
        },
        {
            "nl_otazka_id": "2023",
            "uv_otazka": "Držiteľ vodičského oprávnenia skupiny B, ktorému bolo vodičské oprávnenie skupiny B udelené pred dovŕšením veku 18 rokov, môže do dovŕšenia veku 18 rokov, najmenej však tri mesiace, viesť motorové vozidlo",
            "uv_odpoveda": "ktorejkoľvek skupiny len v prítomnosti osoby sediacej na mieste spolujazdca vedľa vodiča, ktorá je držiteľom vodičského oprávnenia skupiny B najmenej 10 rokov, a ktorá je na tento účel zapísaná v evidencii vodičov.",
            "uv_odpovedb": "skupiny B len v prítomnosti osoby sediacej na mieste spolujazdca vedľa vodiča, ktorá je držiteľom vodičského oprávnenia skupiny B najmenej 5 rokov, a ktorá je na tento účel zapísaná v evidencii vodičov.",
            "uv_odpovedc": "skupiny B len v prítomnosti osoby sediacej na mieste spolujazdca vedľa vodiča, ktorá je držiteľom vodičského oprávnenia skupiny B najmenej 10 rokov, a ktorá je na tento účel zapísaná v evidencii vodičov.",
            "nl_okruh_id": "7",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "7b8ab2b898bfa9bf8efa"
        },
        {
            "nl_otazka_id": "2115",
            "uv_otazka": "Vozidlo, ktoré nemá platnú emisnú kontrolu pravidelnú, ak takej kontrole podlieha",
            "uv_odpoveda": "môže jeho prevádzkovateľ v cestnej premávke prevádzkovať za dodržania podmienky absolvovania emisnej kontroly pravidelnej do 30 dní odo dňa začatia prevádzkovania.",
            "uv_odpovedb": "nesmie jeho prevádzkovateľ v cestnej premávke prevádzkovať.",
            "uv_odpovedc": "nesmie jeho prevádzkovateľ v cestnej premávke prevádzkovať, jeho prevádzka je však dovolená na miestnych komunikáciách.",
            "nl_okruh_id": "8",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "9be3af310688f5713d35"
        },
        {
            "nl_otazka_id": "2100",
            "uv_otazka": "Povinnou výbavou osobného automobilu, nákladného automobilu a autobusu je",
            "uv_odpoveda": "krieda na vyznačenie stôp a postavenia vozidla v prípade dopravnej nehody.",
            "uv_odpovedb": "protisklzová reťaz.",
            "uv_odpovedc": "v závislosti od kategórie vozidla predpísaná lekárnička.",
            "nl_okruh_id": "8",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "ccbc714aeaff7f2b4071"
        },
        {
            "nl_otazka_id": "2221",
            "uv_otazka": "Ku šmyku najčastejšie dochádza kvôli",
            "uv_odpoveda": "príliš pomalému prejazdu zákrutou.",
            "uv_odpovedb": "dopravnému značeniu",
            "uv_odpovedc": "príliš rýchlemu prejazdu zákrutou, prudkému spomaleniu alebo zrýchleniu",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "16d866d3268df93a838e"
        },
        {
            "nl_otazka_id": "2242",
            "uv_otazka": "Ktorú zásadu by mal dodržiavať začínajúci vodič? Mal by",
            "uv_odpoveda": "jazdiť na hranici maximálneho rýchlostného limitu podľa pravidiel cestnej premávky.",
            "uv_odpovedb": "jazdiť na hranici minimálneho rýchlostného limitu.",
            "uv_odpovedc": "si pred jazdou spraviť plán cesty.",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "95d998fd80b9b0d19036"
        },
        {
            "nl_otazka_id": "2181",
            "uv_otazka": "Vodič sa musí riadiť nasledovnou zásadou",
            "uv_odpoveda": "Nevynucovať si prednosť v jazde na križovatke.",
            "uv_odpovedb": "Odbočovať na druhom prevodovom stupni.",
            "uv_odpovedc": "V noci jazdiť len pod dohľadom skúseného vodiča.",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "79e782b0af0db0e55683"
        },
        {
            "nl_otazka_id": "2180",
            "uv_otazka": "Vodič sa musí riadiť nasledovnou zásadou",
            "uv_odpoveda": "Nejazdiť defenzívne a ustráchane.",
            "uv_odpovedb": "Včas signalizovať svoj zámer odbočiť zapínaním výstražnej funkcie smerových svietidiel.",
            "uv_odpovedc": "Včas signalizovať svoj zámer odbočiť.",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "bf98d24ec98460165d82"
        },
        {
            "nl_otazka_id": "2385",
            "uv_otazka": "Aquaplaning (vodný šmyk) znamená",
            "uv_odpoveda": "blokovanie kolies počas brzdenia.",
            "uv_odpovedb": "vytvorenie vodného klina na mokrej vozovke s väčším množstvom vody medzi pneumatikou a vozovkou, ktorý oddelí pneumatiku od vozovky a vozidlo sa stáva neovládateľným.",
            "uv_odpovedc": "defekt kolesa.",
            "nl_okruh_id": "10",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "c115eddb857a4c70365e"
        },
        {
            "nl_otazka_id": "2340",
            "uv_otazka": "Táto kontrolka na prístrojovom paneli upozorňuje na",
            "uv_odpoveda": "znečistenie paliva (vodou).",
            "uv_odpovedb": "správne fungovanie palivovej sústavy.",
            "uv_odpovedc": "nedostatočné množstvo paliva.",
            "nl_okruh_id": "10",
            "nl_correct": "1",
            "uv_obrazok": "obr3/tvv/40.png",
            "body": "1",
            "poznamka": "",
            "hash": "6773b7435023ef6e561d"
        },
        {
            "nl_otazka_id": "1107",
            "uv_otazka": "Vozidlá môžu ísť súbežne vedľa seba,",
            "uv_odpoveda": "ak je na ceste s dvoma alebo s viacerými jazdnými pruhmi v jednom smere jazdy taká hustá premávka, že sa utvoria súvislé prúdy vozidiel, v ktorých vodič motorového vozidla môže jazdiť len takou rýchlosťou, ktorá závisí od rýchlosti vozidiel idúcich pred ním.",
            "uv_odpovedb": "len ak jazdia po diaľnici a po ceste pre motorové vozidlá.",
            "uv_odpovedc": "len ak jazdia po jednosmernej ceste s dvoma jazdnými pruhmi v obci.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5e75c1cffea2762b1b39"
        },
        {
            "nl_otazka_id": "1217",
            "uv_otazka": "Vodič vozidla, ktoré je povinne vybavené prenosným výstražným trojuholníkom, je povinný tento trojuholník použiť počas",
            "uv_odpoveda": "núdzového státia, najmä pri prerušení jazdy pre chybu na vozidle alebo na náklade alebo v dôsledku dopravnej nehody, ak také vozidlo tvorí prekážku cestnej premávky.",
            "uv_odpovedb": "jazdy na diaľnici rýchlosťou nižšou ako 50 km/h.",
            "uv_odpovedc": "prepravy nebezpečného nákladu.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1526776a193dc18b0392"
        },
        {
            "nl_otazka_id": "1088",
            "uv_otazka": "Vodič je povinný na vozovke alebo v jazdnom pruhu jazdiť",
            "uv_odpoveda": "vpravo pri pravom okraji vozovky alebo jazdného pruhu; to neplatí pri obchádzaní, predchádzaní, otáčaní alebo odbočovaní.",
            "uv_odpovedb": "vždy len pri ľavom okraji vozovky.",
            "uv_odpovedc": "vpravo vo vzdialenosti najmenej 1,2 m od krajnice.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "0deadcec1bc85f0f5683"
        },
        {
            "nl_otazka_id": "1071",
            "uv_otazka": "Prevádzkovateľ vozidla nesmie zveriť vedenie vozidla osobe, ktorá",
            "uv_odpoveda": "nespĺňa podmienky na jeho vedenie ustanovené zákonom o cestnej premávke alebo osobitným predpisom.",
            "uv_odpovedb": "nemá najmenej dvojročnú prax vo vedení motorových vozidiel.",
            "uv_odpovedc": "je mladšia ako 25 rokov, aj keď spĺňa ustanovené podmienky.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ab8df0a72354617fa45d"
        },
        {
            "nl_otazka_id": "1110",
            "uv_otazka": "Tam, kde sa dva jazdné pruhy zbiehajú do jedného jazdného pruhu tak, že nie je zrejmé, ktorý z nich je priebežný, vodič jazdiaci v ľavom jazdnom pruhu je povinný dať prednosť v jazde vodičovi v pravom jazdnom pruhu, to neplatí",
            "uv_odpoveda": "ak vodič jazdiaci v ľavom jazdnom pruhu vedie zvláštne motorové vozidlo.",
            "uv_odpovedb": "pri súbežnej jazde, kedy vodič idúci v pravom jazdnom pruhu je povinný umožniť vodičovi prvého vozidla v ľavom jazdnom pruhu zaradenie sa do kolóny idúcich vozidiel, ak ten dáva znamenie o zmene smeru jazdy vpravo.",
            "uv_odpovedc": "ak vodič jazdiaci v ľavom jazdnom pruhu vedie nákladný automobil s najväčšou prípustnou celkovou hmotnosťou presahujúcou 3 500 kg.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "d73e830238aabc4327cb"
        },
        {
            "nl_otazka_id": "1133",
            "uv_otazka": "Riadne označené vozidlo taxislužby jazdiace vo vyhradenom jazdnom pruhu pre autobusy alebo trolejbusy",
            "uv_odpoveda": "nesmie obmedzovať plynulosť jazdy vozidiel, pre ktoré je tento vyhradený jazdný pruh určený.",
            "uv_odpovedb": "smie obmedzovať plynulosť jazdy vozidiel, pre ktoré je tento vyhradený jazdný pruh určený len počas jazdy za nezníženej viditeľnosti.",
            "uv_odpovedc": "smie obmedzovať plynulosť jazdy vozidiel, pre ktoré je tento vyhradený jazdný pruh určený.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "220d26e953cd845e55ca"
        },
        {
            "nl_otazka_id": "1336",
            "uv_otazka": "Diaľnicu môžu používať len vodiči",
            "uv_odpoveda": "motorových vozidiel; iným účastníkom cestnej premávky je používanie diaľnice zakázané.",
            "uv_odpovedb": "motorových vozidiel, ktorých najvyššia dovolená rýchlosť nie je nižšia ako 50 km/h.",
            "uv_odpovedc": "motorových vozidiel, ktorých najvyššia konštrukčná rýchlosť nie je nižšia ako 65 km/h.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3869e111f2f8a66e106e"
        },
        {
            "nl_otazka_id": "1579",
            "uv_otazka": "Vodič motorového vozidla i nemotorového vozidla odbočujúci vpravo je povinný dať prednosť v jazde",
            "uv_odpoveda": "protiidúcim vozidlám pravidelnej verejnej dopravy osôb, ak tieto odbočujú vľavo.",
            "uv_odpovedb": "električke, ak je povolená jazda pozdĺž električky vľavo a vozidlu idúcemu vo vyhradenom jazdnom pruhu po jeho pravej strane.",
            "uv_odpovedc": "prvému vozidlu idúcemu za ním.",
            "nl_okruh_id": "2",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "cdb8ea42dfd713d0e7ca"
        },
        {
            "nl_otazka_id": "1599",
            "uv_otazka": "Na diaľnici smie vodič motorového vozidla jazdiť rýchlosťou najmenej",
            "uv_odpoveda": "60 km.h-1.",
            "uv_odpovedb": "90 km.h-1.",
            "uv_odpovedc": "80 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "449a0358bef7de27687f"
        },
        {
            "nl_otazka_id": "1747",
            "uv_otazka": "Táto dodatková tabuľka vyznačuje",
            "uv_odpoveda": "Prikázaný smer obchádzania.",
            "uv_odpovedb": "Smer platnosti dopravnej značky, pod ktorou je umiestnená, môže byť doplnená údajom o dĺžke platnosti daným smerom v metroch.",
            "uv_odpovedc": "Jednosmernú premávku.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/504.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "dffa3bf39c102e953a8c"
        },
        {
            "nl_otazka_id": "1653",
            "uv_otazka": "O čom informuje táto dopravná značka?",
            "uv_odpoveda": "O priebehu cesty pre motorové vozidlá.",
            "uv_odpovedb": "O krížení cesty s lesnou alebo poľnou cestou.",
            "uv_odpovedc": "O prednosti v jazde v najbližšej križovatke.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/301.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "bb71b9c9a9d3448ca544"
        },
        {
            "nl_otazka_id": "1630",
            "uv_otazka": "Táto dopravná značka upozorňuje na",
            "uv_odpoveda": "možnosť pozdĺžneho parkovania na chodníku na ľavej strane jednosmernej cesty.",
            "uv_odpovedb": "Zvýšený alebo inak nebezpečný ľavý obrubník.",
            "uv_odpovedc": "Zníženú, nespevnenú alebo inak nebezpečnú krajnicu.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/116.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "db6d5eb01e42da6dcd36"
        },
        {
            "nl_otazka_id": "1811",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Smerová šípka",
            "uv_odpovedb": "Diaľničný uzol",
            "uv_odpovedc": "Výjazd (výjazd z diaľnice na inú cestu)",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/5-1.png",
            "body": "2",
            "poznamka": "",
            "hash": "c8450b8c6d244ebd4393"
        },
        {
            "nl_otazka_id": "1746",
            "uv_otazka": "Táto dodatková tabuľka",
            "uv_odpoveda": "Obmedzuje platnosť značky, pod ktorou je umiestnená, len na vozidlá a jazdné súpravy s celkovou hmotnosťou prevyšujúcou vyznačenú hranicu.",
            "uv_odpovedb": "Vyznačuje najvyššie dovolené zaťaženie vozidla pripadajúce na dvojnápravu.",
            "uv_odpovedc": "Obmedzuje platnosť značky na vozidlo, ktorého najväčšia prípustná celková hmotnosť je menšia ako údaj uvedený na tabuľke.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/508.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "9ecace78f437e36ee288"
        },
        {
            "nl_otazka_id": "1775",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Motorové vozidlá",
            "uv_odpovedb": "Motorové vozidlá s výnimkou motocyklov bez postranného vozíka",
            "uv_odpovedc": "Nákladné vozidlá, ktorých najväčšia prípustná celková hmotnosť nepresahuje 3,5 t",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/1-1.png",
            "body": "2",
            "poznamka": "",
            "hash": "962ae05b6fb288bfaa2e"
        },
        {
            "nl_otazka_id": "1715",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Koniec zóny zákazu zastavenia vozidla.",
            "uv_odpovedb": "Koniec zóny zákazu státia.",
            "uv_odpovedc": "Koniec pešej zóny.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/276.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "a36beb514a66bb29b41e"
        },
        {
            "nl_otazka_id": "1863",
            "uv_otazka": "Prednosť v jazde má vodič",
            "uv_odpoveda": "modrého vozidla, pretože ide po kruhovom objazde.",
            "uv_odpovedb": "červeného vozidla, pretože prichádza sprava.",
            "uv_odpovedc": "modrého vozidla, pretože prichádza zľava.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/07.png",
            "body": "4",
            "poznamka": "",
            "hash": "52fa83747ae81284cc60"
        },
        {
            "nl_otazka_id": "1847",
            "uv_otazka": "Vozidlá prejdú cez križovatku v tomto poradí:",
            "uv_odpoveda": "1. červené, 2. zelené, 3. vaše vozidlo súčasne s modrým.",
            "uv_odpovedb": "1. červené, 2. vaše vozidlo súčasne s modrým, 3. zelené.",
            "uv_odpovedc": "1. modré, 2. červené, 3. zelené, 4. vaše vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/23.png",
            "body": "4",
            "poznamka": "",
            "hash": "985c9cdb9a756d602942"
        },
        {
            "nl_otazka_id": "1833",
            "uv_otazka": "Ako posledné prejde cez križovatku",
            "uv_odpoveda": "modré vozidlo.",
            "uv_odpovedb": "červené vozidlo.",
            "uv_odpovedc": "vaše vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/16.png",
            "body": "4",
            "poznamka": "",
            "hash": "ec017eadb54a9df12866"
        },
        {
            "nl_otazka_id": "1862",
            "uv_otazka": "Ako prvé prejde cez križovatku",
            "uv_odpoveda": "červené vozidlo.",
            "uv_odpovedb": "modré vozidlo.",
            "uv_odpovedc": "zelené vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/30.png",
            "body": "4",
            "poznamka": "",
            "hash": "ada52647501893c14466"
        },
        {
            "nl_otazka_id": "1923",
            "uv_otazka": "Účastník škodovej udalosti je",
            "uv_odpoveda": "osoba, ktorá sa priamo aktívne alebo pasívne zúčastnila na dopravnej nehode.",
            "uv_odpovedb": "prevádzkovateľ vozidla zapísaný v osvedčení o evidencii vozidla.",
            "uv_odpovedc": "osoba, ktorá sa priamo aktívne alebo pasívne zúčastnila na škodovej udalosti.",
            "nl_okruh_id": "5",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "fa654c82d366a19ccc19"
        },
        {
            "nl_otazka_id": "2002",
            "uv_otazka": "Napínanie lana počas vlečenia zabezpečuje najmä",
            "uv_odpoveda": "vodič vo vlečnom vozidle.",
            "uv_odpovedb": "vodič vo vlečenom vozidle.",
            "uv_odpovedc": "iná spôsobilá osoba pribratá na tento úkon.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "c8106ab4c626414a2d7e"
        },
        {
            "nl_otazka_id": "2022",
            "uv_otazka": "Motorové vozidlo určitej skupiny smie viesť len osoba, ktorá je držiteľom",
            "uv_odpoveda": "kvalifikačnej karty vodiča.",
            "uv_odpovedb": "vodičského oprávnenia príslušnej skupiny.",
            "uv_odpovedc": "vodičského oprávnenia  skupiny B.",
            "nl_okruh_id": "7",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "7eef258919dcd702c8fb"
        },
        {
            "nl_otazka_id": "2147",
            "uv_otazka": "Motorové vozidlo musí byť vybavené",
            "uv_odpoveda": "ručným hasiacim prístrojom, ktorého celková hmotnosť náplne je najmenej 3 kg.",
            "uv_odpovedb": "vonkajšími spätnými zrkadlami alebo iným zariadením, ktoré zabezpečí nepriamy výhľad z vozidla na sledovanie cesty za vozidlom a vedľa seba na každej strane vozidla.",
            "uv_odpovedc": "najmenej jedným zakladacím klinom, ktorý účinne zabezpečí vozidlo proti samovoľnému pohybu.",
            "nl_okruh_id": "8",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "352eba92b41e0e9aca26"
        },
        {
            "nl_otazka_id": "2158",
            "uv_otazka": "Brzdná dráha závisí aj od",
            "uv_odpoveda": "povrchu vozovky",
            "uv_odpovedb": "hustoty cestnej premávky",
            "uv_odpovedc": "výrobcu brzdového obloženia",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "82e23900ccb9ae81f93d"
        },
        {
            "nl_otazka_id": "2245",
            "uv_otazka": "Ktorú zásadu by mal dodržiavať začínajúci vodič? Mal by",
            "uv_odpoveda": "situácie riešiť vždy čo najrýchlejšie, aby sme čo najmenej zdržiaval iných vodičov.",
            "uv_odpovedb": "vzniknuté situácie riešiť s rozvahou, aj keď pomalšie.",
            "uv_odpovedc": "Jazdiť podľa pravidiel na hranici maximálneho rýchlostného limitu.",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "299c1a86f5ffa9e46908"
        },
        {
            "nl_otazka_id": "2184",
            "uv_otazka": "Vodič sa musí riadiť nasledovnou zásadou",
            "uv_odpoveda": "ak má niekto iný neprimerané správanie, nesmie sa nechať vyprovokovať k rovnakému správaniu",
            "uv_odpovedb": "ak sa niekto iný správa neprimerane, je potrebné ho zastaviť",
            "uv_odpovedc": "ak sa niekto iný správa neprimerane, je potrebné ho obísť",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "352f7cf73c522dfc7613"
        },
        {
            "nl_otazka_id": "2241",
            "uv_otazka": "Do základných pravidiel bezpečnej jazdy patrí",
            "uv_odpoveda": "Nikdy neakcelerujte prudko.",
            "uv_odpovedb": "Dodržiavanie bezpečnej vzdialenosti medzi vozidlami.",
            "uv_odpovedc": "Dodržiavanie bezpečnej vzdialenosti medzi vozidlami, to neplatí pri obchádzaní, predchádzaní a súbežnej jazde.",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f5851ec0c0a83d055e8d"
        },
        {
            "nl_otazka_id": "2231",
            "uv_otazka": "Pri náhlom spozorovaní prekážky v jazdnej dráhe vodič vozidla vybaveného systémom ABS",
            "uv_odpoveda": "začne intenzívne brzdiť, súčasne stlačí pedál spojky a točením volantu sa prekážke vyhne",
            "uv_odpovedb": "nebrzdí a snaží sa točením volantu prekážku obísť",
            "uv_odpovedc": "začne brzdiť tak, aby primerane znížil rýchlosť a pred prekážkou začne zrýchľovať",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5c7cffe0ccbc74b3ea4c"
        },
        {
            "nl_otazka_id": "2154",
            "uv_otazka": "Vozidlá s pohonom zadnej nápravy obvykle majú charakter",
            "uv_odpoveda": "nedotáčavý",
            "uv_odpovedb": "pretáčavý",
            "uv_odpovedc": "neutrálny",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e21d7f74592890aae713"
        },
        {
            "nl_otazka_id": "2346",
            "uv_otazka": "Súčasťou prevodovky osobného automobilu  je synchronizácia. Synchronizáciou sa dosiahne, že",
            "uv_odpoveda": "sa vyrovnávajú otáčky hnaného a hnacieho ozubeného kolesa v rozvodovke.",
            "uv_odpovedb": "po rozjazde sa samočinne zaradí 2. prevodový stupeň.",
            "uv_odpovedc": "otáčky hnaného a hnacieho hriadeľa v prevodovke sa vyrovnajú, čo spôsobí ľahké a plynulé zaradenie prevodových stupňov.",
            "nl_okruh_id": "10",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "671733ca643874161182"
        },
        {
            "nl_otazka_id": "1009",
            "uv_otazka": "Krajnicou sa rozumie",
            "uv_odpoveda": "časť cesty od kraja vozovky po kraj cesty.",
            "uv_odpovedb": "časť cesty vyhradená na premávku vozidiel s najvyššou celkovou prípustnou hmotnosťou prevyšujúcou 3 500 kg.",
            "uv_odpovedc": "miesto mimo cesty, na ktoré smie vojsť len vodič nemotorového vozidla.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "33923fa03742d24d24ad"
        },
        {
            "nl_otazka_id": "1145",
            "uv_otazka": "Vodič, ktorý pri predchádzaní vybočuje zo smeru svojej jazdy",
            "uv_odpoveda": "nesmie ohroziť vodičov jazdiacich za ním.",
            "uv_odpovedb": "smie ohroziť vodičov jazdiacich za ním, ak jazdí rýchlosťou väčšou ako 60 km/h.",
            "uv_odpovedc": "smie ohroziť vodičov jazdiacich za ním.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "bab9971db5ec5a052131"
        },
        {
            "nl_otazka_id": "1218",
            "uv_otazka": "Ak je vozidlo vybavené osobitným zariadením, ktoré umožňuje výstražnú funkciu smerových svietidiel, vodič je povinný ho použiť",
            "uv_odpoveda": "počas otáčania na priechode pre chodcov.",
            "uv_odpovedb": "počas núdzového státia aspoň v čase, kým umiestni prenosný výstražný trojuholník na vozovke.",
            "uv_odpovedc": "počas cúvania na križovatke s riadenou premávkou.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1653fef9217e9372134c"
        },
        {
            "nl_otazka_id": "1091",
            "uv_otazka": "Vodič motorového vozidla pri vojdení na krajnicu, je povinný",
            "uv_odpoveda": "dávať výstražné znamenie.",
            "uv_odpovedb": "dbať na zvýšenú pozornosť.",
            "uv_odpovedc": "zastaviť vozidlo.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "91817f76869c6b77eecc"
        },
        {
            "nl_otazka_id": "1046",
            "uv_otazka": "Vodič nesmie odovzdať vedenie vozidla osobe, ktorá",
            "uv_odpoveda": "nemá najmenej trojročnú prax vo vedení takéhoto vozidla alebo je pod značným vplyvom alkoholu.",
            "uv_odpovedb": "nespĺňa podmienky na vedenie vozidla ustanovené zákonom o cestnej premávke.",
            "uv_odpovedc": "spĺňa podmienky ustanovené zákonom o cestnej premávke, je však mladšia ako 23 rokov.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "24d626c5e0c2d2a76837"
        },
        {
            "nl_otazka_id": "1089",
            "uv_otazka": "Vodič je povinný na vozovke alebo v jazdnom pruhu jazdiť",
            "uv_odpoveda": "vpravo, pri pravom okraji vozovky alebo jazdného pruhu.",
            "uv_odpovedb": "vľavo, pri ľavom okraji vozovky alebo jazdného pruhu.",
            "uv_odpovedc": "vpravo, po pravej krajnici.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "c687943e27ce35d7186e"
        },
        {
            "nl_otazka_id": "1338",
            "uv_otazka": "Cestu pre motorové vozidlá môžu používať len vodiči motorových vozidiel s najväčšou konštrukčnou rýchlosťou najmenej",
            "uv_odpoveda": "40 km/h.",
            "uv_odpovedb": "60 km/h.",
            "uv_odpovedc": "50 km/h.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "bdb57f76073356d76951"
        },
        {
            "nl_otazka_id": "1617",
            "uv_otazka": "Akou rýchlosťou môže jazdiť vodič osobného automobilu v  školskej zóne?",
            "uv_odpoveda": "30 km.h-1.",
            "uv_odpovedb": "Vjazd  vozidiel do  školskej  zóny je zakázaný.",
            "uv_odpovedc": "Najviac 20 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f3eb28a58508423288f7"
        },
        {
            "nl_otazka_id": "1761",
            "uv_otazka": "Tieto dopravné kužele umiestnené v rade za sebou",
            "uv_odpoveda": "Majú zhodný význam ako značka Dvojitá pozdĺžna prerušovaná čiara.",
            "uv_odpovedb": "Vymedzujú priestor, do ktorého je zakázané vchádzať.",
            "uv_odpovedc": "Vyznačujú blížiaci sa prechod pozdĺžnej prerušovanej čiary v súvislú čiaru.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/703.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "4a21355d0f097d6d5dd3"
        },
        {
            "nl_otazka_id": "1725",
            "uv_otazka": "Táto dopravná značka informuje",
            "uv_odpoveda": "O najvyšších dovolených rýchlostiach na cestách pre motorové vozidlá.",
            "uv_odpovedb": "O odporúčaných rýchlostiach na cestách.",
            "uv_odpovedc": "Vodičov vchádzajúcich na územie Slovenskej republiky o najvyššej dovolenej rýchlosti a o dennom svietení.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/342.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "8ccba38fe674593dd872"
        },
        {
            "nl_otazka_id": "1810",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Parkovacie miesta s pozdĺžnym státím čiastočne na chodníku (státie povolené súbežne s osou vozovky, pravými alebo ľavými kolesami na chodníku)",
            "uv_odpovedb": "Parkovacie miesta s priečnym státím čiastočne na chodníku",
            "uv_odpovedc": "Parkovacie miesta s priečnym státím na chodníku",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/4-6.png",
            "body": "2",
            "poznamka": "",
            "hash": "b2e6b2085c078073f643"
        },
        {
            "nl_otazka_id": "1644",
            "uv_otazka": "Táto dopravná značka upozorňuje na",
            "uv_odpoveda": "Vytváranie kolóny vozidiel.",
            "uv_odpovedb": "Predchádzanie vozidiel idúcich v kolóne.",
            "uv_odpovedc": "Súbežnú jazdu osobných motorových vozidiel.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/130.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "60e943dc4e12c4fb2d4a"
        },
        {
            "nl_otazka_id": "1816",
            "uv_otazka": "Vaše vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "prvé.",
            "uv_odpovedb": "druhé.",
            "uv_odpovedc": "posledné.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/02.png",
            "body": "4",
            "poznamka": "",
            "hash": "020be0732d7b193de992"
        },
        {
            "nl_otazka_id": "1864",
            "uv_otazka": "Ako druhé vojde do križovatky",
            "uv_odpoveda": "červené vozidlo.",
            "uv_odpovedb": "modré vozidlo.",
            "uv_odpovedc": "zelené vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/08.png",
            "body": "4",
            "poznamka": "",
            "hash": "09fcd701edaa7412dbb3"
        },
        {
            "nl_otazka_id": "1823",
            "uv_otazka": "Vozidlá prejdú cez križovatku v tomto poradí:",
            "uv_odpoveda": "1. vaše vozidlo, 2. zelené, 3. modré.",
            "uv_odpovedb": "1. modré, 2. zelené, 3. vaše vozidlo.",
            "uv_odpovedc": "všetky tri súčasne.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/06.png",
            "body": "4",
            "poznamka": "",
            "hash": "4f9e957a782e27fa2b17"
        },
        {
            "nl_otazka_id": "1928",
            "uv_otazka": "Účastník škodovej udalosti je povinný",
            "uv_odpoveda": "zdržať sa požitia alkoholu alebo inej návykovej látky.",
            "uv_odpovedb": "zdržať sa požitia alkoholu; inú návykovej látku môže požiť len so súhlasom lekára.",
            "uv_odpovedc": "zdržať sa požitia inej návykovej látky, pričom požiť alkohol môže v primeranom množstve.",
            "nl_okruh_id": "5",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "af07eec0b3fbbfae58b4"
        },
        {
            "nl_otazka_id": "2001",
            "uv_otazka": "Počas vlečenia vozidla podľa možnosti musí byť vlečné lano stále",
            "uv_odpoveda": "voľné.",
            "uv_odpovedb": "nezáleží či lano je voľné alebo napnuté.",
            "uv_odpovedc": "napnuté.",
            "nl_okruh_id": "6",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "d6d247b902291a07c82c"
        },
        {
            "nl_otazka_id": "2080",
            "uv_otazka": "Držiteľ vodičského preukazu",
            "uv_odpoveda": "je povinný oznámiť orgánu Policajného zboru do 15 dní zmenu zdravotnej spôsobilosti a psychickej spôsobilosti.",
            "uv_odpovedb": "nie je povinný oznámiť orgánu Policajného zboru do 7 dní zmenu zdravotnej spôsobilosti a psychickej spôsobilosti.",
            "uv_odpovedc": "je povinný oznámiť orgánu Policajného zboru do 7 dní zmenu zdravotnej spôsobilosti a psychickej spôsobilosti.",
            "nl_okruh_id": "7",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "edcbfbf30bd26025308a"
        },
        {
            "nl_otazka_id": "2170",
            "uv_otazka": "Ak vozidlo má protiblokovací brzdový systém ABS",
            "uv_odpoveda": "brzdíme prerušovane",
            "uv_odpovedb": "nebrzdíme prerušovane",
            "uv_odpovedc": "musíme brzdiť krízovo",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "53cec8b73f840b04ab06"
        },
        {
            "nl_otazka_id": "2213",
            "uv_otazka": "Po prebrodení hlbokej mláky s vozidlom hrozí",
            "uv_odpoveda": "zníženie účinnosti prevádzkovej brzdy vplyvom vniknutia vody do brzdovej sústavy",
            "uv_odpovedb": "zlyhanie chladenia motora",
            "uv_odpovedc": "zlyhanie prevodového mechanizmu vplyvom vniknutia vody  do prevodovky",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "a4a50649483dc65d4d3e"
        },
        {
            "nl_otazka_id": "2152",
            "uv_otazka": "Na vozidlo v zákrute pôsobí najmä, sila",
            "uv_odpoveda": "odstredivá",
            "uv_odpovedb": "hnacia",
            "uv_odpovedc": "tiažová",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e625e0550368d82b5074"
        },
        {
            "nl_otazka_id": "2255",
            "uv_otazka": "Silná hmla okrem zníženej viditeľnosti",
            "uv_odpoveda": "spôsobuje vlhkosť na povrchu vozovky a v zimných obdobiach aj poľadovicu",
            "uv_odpovedb": "spôsobuje koróziu a poruchy zapaľovania na vozidle",
            "uv_odpovedc": "znižuje hladinu chladiacej kvapaliny",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5a05cba1311cb9f4700d"
        },
        {
            "nl_otazka_id": "2302",
            "uv_otazka": "Agresívne jednanie druhého účastníka cestnej premávky môže viesť k vyprovokovaniu vodiča vozidla, čiže konanie vodiča budú ovplyvňovať emócie, čo môže viesť k dopravnej nehode. Ako by sa mal zachovať dotknutý vodič v tejto situácii?",
            "uv_odpoveda": "Mal by zastaviť vozidlo a mal by si urobiť prestávku na upokojenie.",
            "uv_odpovedb": "Mal by uviesť do činnosti iba zvukové výstražné znamenie.",
            "uv_odpovedc": "Mal by uviesť do činnosti zvukové a svetelné výstražné znamenia a gestikulovať.",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "d8b13a04f6414f18aded"
        },
        {
            "nl_otazka_id": "2151",
            "uv_otazka": "Vozidlá s pohonom prednej nápravy obvykle sú",
            "uv_odpoveda": "pretáčavé",
            "uv_odpovedb": "nedotáčavé",
            "uv_odpovedc": "neutrálne",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5b7a53470d4f4f01b4c9"
        },
        {
            "nl_otazka_id": "2337",
            "uv_otazka": "Podvozok vozidla tvoria",
            "uv_odpoveda": "spojka, prevodovka a rozvodovka s diferenciálom.",
            "uv_odpovedb": "rám, nápravy s kolesami, pruženie, riadenie a brzdy.",
            "uv_odpovedc": "chladiaca, mazacia a palivová sústava.",
            "nl_okruh_id": "10",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "9bea52ff42a61683cf18"
        },
        {
            "nl_otazka_id": "1469",
            "uv_otazka": "Osoba, ktorá vykonáva prácu na ceste, musí byť",
            "uv_odpoveda": "označená len vtedy, ak jej na to dal príkaz jej zamestnávateľ.",
            "uv_odpovedb": "označená viditeľným bezpečnostným odevom len za zníženej viditeľnosti.",
            "uv_odpovedc": "označená viditeľným bezpečnostným odevom.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e7c2fb073dfc97401a87"
        },
        {
            "nl_otazka_id": "1169",
            "uv_otazka": "Vodič vozidla idúceho za vozidlom, ktoré odbočuje, musí",
            "uv_odpoveda": "dbať na zvýšenú opatrnosť a svojou jazdou nesmie obmedziť vodiča idúceho za ním.",
            "uv_odpovedb": "dbať na zvýšenú opatrnosť a svojou jazdou nesmie ohroziť vodiča odbočujúceho vozidla.",
            "uv_odpovedc": "dať prednosť v jazde vozidlám prichádzajúcim sprava.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "9891162aa08d36a1987d"
        },
        {
            "nl_otazka_id": "1189",
            "uv_otazka": "Na mieste na prechádzanie alebo na priechode pre cyklistov vodič",
            "uv_odpoveda": "sa nesmie otáčať a nesmie cúvať.",
            "uv_odpovedb": "smie sa otáčať.",
            "uv_odpovedc": "smie cúvať.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3a836ef48ceaadb1f650"
        },
        {
            "nl_otazka_id": "1465",
            "uv_otazka": "Jazdci na zvieratách smú ísť",
            "uv_odpoveda": "len jednotlivo za sebou.",
            "uv_odpovedb": "najviac dvaja vedľa seba.",
            "uv_odpovedc": "najviac traja vedľa seba.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ee2854031d0b997e9649"
        },
        {
            "nl_otazka_id": "1295",
            "uv_otazka": "Ako dlho ponechá vodič znamenie o zmene smeru jazdy dávané smerovými svietidlami?",
            "uv_odpoveda": "Kým mení smer jazdy, vybočuje z neho alebo kým vozidlo nezaujme miesto v jazdnom pruhu, do ktorého prechádza.",
            "uv_odpovedb": "Kým nezačne vybočovať zo smeru jazdy alebo odbočovať.",
            "uv_odpovedc": "Kým nezačne vybočovať zo smeru jazdy.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "4a221d82f0b53f108f41"
        },
        {
            "nl_otazka_id": "1081",
            "uv_otazka": "Na sedadle vozidla, ktoré nie je vybavené bezpečnostným systémom, sa nesmú prepravovať",
            "uv_odpoveda": "deti do veku troch rokov.",
            "uv_odpovedb": "deti vo veku štyroch rokov a viac s telesnou výškou menšou ako 150 cm.",
            "uv_odpovedc": "žiadne osoby.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "6a3d0bc5032e31b941f6"
        },
        {
            "nl_otazka_id": "1327",
            "uv_otazka": "Vlečenie viacerých motorových vozidiel je",
            "uv_odpoveda": "zakázané.",
            "uv_odpovedb": "dovolené, ak sa vlečú dve osobné motorové vozidlá.",
            "uv_odpovedc": "dovolené, ak sa vlečú motocykle bez postranného vozíka.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f640831fd77a3fb96d35"
        },
        {
            "nl_otazka_id": "1589",
            "uv_otazka": "Vodič je pri otáčaní povinný dať prednosť v jazde",
            "uv_odpoveda": "protiidúcim motorovým vozidlám i nemotorovým vozidlám, vozidlám idúcim vo vyhradenom jazdnom pruhu po jeho ľavej strane, električkám idúcim v oboch smeroch a chodcom prechádzajúcim cez vozovku.",
            "uv_odpovedb": "len protiidúcim motorovým vozidlám.",
            "uv_odpovedc": "len protiidúcim vozidlám a vozidlám idúcim vo vyhradenom jazdnom pruhu po jeho ľavej strane.",
            "nl_okruh_id": "2",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1987e99fb0db389bbd51"
        },
        {
            "nl_otazka_id": "1567",
            "uv_otazka": "Vodič motorového vozidla s najväčšou prípustnou celkovou hmotnosťou neprevyšujúcou 3 500 kg  s prípojným vozidlom, ktorého najväčšia prípustná celková hmotnosť prevyšuje 750 kg, smie jazdiť rýchlosťou najviac",
            "uv_odpoveda": "100 km.h-1.",
            "uv_odpovedb": "90 km.h-1.",
            "uv_odpovedc": "70 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "45f37f81303b31e7c24e"
        },
        {
            "nl_otazka_id": "1721",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Koniec pešej zóny.",
            "uv_odpovedb": "Koniec parkovacej zóny.",
            "uv_odpovedc": "Úsek cesty, kde je zákaz zastavenia a státia.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/278.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "e38b6bec292b78b849a7"
        },
        {
            "nl_otazka_id": "1805",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Koľajové vozidlá",
            "uv_odpovedb": "Električky",
            "uv_odpovedc": "Železničná stanica",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/2-35.png",
            "body": "2",
            "poznamka": "",
            "hash": "d55580d4791aba4291fd"
        },
        {
            "nl_otazka_id": "1784",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Osoby pohybujúce sa na skejtborde",
            "uv_odpovedb": "Chodci",
            "uv_odpovedc": "Deti",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/1-11.png",
            "body": "2",
            "poznamka": "",
            "hash": "af8b4448e203b38884bb"
        },
        {
            "nl_otazka_id": "1647",
            "uv_otazka": "Čo označuje táto dopravná značka?",
            "uv_odpoveda": "Železničné priecestie vo vzdialenosti 80 m.",
            "uv_odpovedb": "Križovatku dvoch ciest rovnakého významu.",
            "uv_odpovedc": "Priecestie.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/153.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "1a77256741ae69181666"
        },
        {
            "nl_otazka_id": "1714",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Územie, v ktorom platia ustanovenia o premávke v pešej zóne.",
            "uv_odpovedb": "Oblasť, v ktorej platia ustanovenia o premávke v obytnej zóne.",
            "uv_odpovedc": "Začiatok zóny, v ktorej je zakázané státie okrem státia na vyznačených parkovacích miestach.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/275.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "1d099780f1d6ef1decad"
        },
        {
            "nl_otazka_id": "1855",
            "uv_otazka": "Zelené vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "posledné.",
            "uv_odpovedb": "druhé.",
            "uv_odpovedc": "prvé.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/27.png",
            "body": "4",
            "poznamka": "",
            "hash": "172aed1fa0c3906573ec"
        },
        {
            "nl_otazka_id": "1901",
            "uv_otazka": "Vodič modrého vozidla",
            "uv_odpoveda": "má pred vodičom červeného vozidla prednosť v jazde, pretože prichádza zľava.",
            "uv_odpovedb": "má pred vodičom červeného vozidla prednosť v jazde, pretože ide po kruhovom objazde, ktorý je označený dopravnou značkou “Kruhový objazd“ spolu s dopravnou značkou “Daj prednosť v jazde!“.",
            "uv_odpovedc": "je povinný dať prednosť v jazde vodičovi červeného vozidla, ktorý prichádza sprava,",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/39.png",
            "body": "4",
            "poznamka": "",
            "hash": "a6b865fc890b780215ac"
        },
        {
            "nl_otazka_id": "1889",
            "uv_otazka": "V poradí druhé prejde cez križovatku",
            "uv_odpoveda": "modré vozidlo.",
            "uv_odpovedb": "zelené vozidlo.",
            "uv_odpovedc": "červené vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/34-1.png",
            "body": "4",
            "poznamka": "",
            "hash": "91fb07a367f591efbd74"
        },
        {
            "nl_otazka_id": "1907",
            "uv_otazka": "Vodič, ktorý sa zúčastnil na dopravnej nehode, je povinný",
            "uv_odpoveda": "ohlásiť dopravnú nehodu príslušnej poisťovni.",
            "uv_odpovedb": "privolať odťahovú službu.",
            "uv_odpovedc": "bezodkladne zastaviť vozidlo.",
            "nl_okruh_id": "5",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "68c1482ef4363ce4ac06"
        },
        {
            "nl_otazka_id": "1933",
            "uv_otazka": "Ak bliká kontrolka stabilizačného systému vozidla (ESP), znamená to",
            "uv_odpoveda": "vypnutie sytému ESP vodičom.",
            "uv_odpovedb": "nefunkčnosť systému, pre ktorú ale možno pokračovať v jazde.",
            "uv_odpovedc": "zásah stabilizačného systému.",
            "nl_okruh_id": "6",
            "nl_correct": "3",
            "uv_obrazok": "obr3/tvv/14.png",
            "body": "2",
            "poznamka": "",
            "hash": "6bfea629748355794aed"
        },
        {
            "nl_otazka_id": "2130",
            "uv_otazka": "Vozidlo sa považuje za technicky nespôsobilé na prevádzku v cestnej premávke, najmä ak",
            "uv_odpoveda": "je na zadnom skle použitá schválená fólia.",
            "uv_odpovedb": "brzdové obloženia alebo platničky narúšajú brzdnú funkciu.",
            "uv_odpovedc": "mazivo používané vo vozidle nie je schválené výrobcom.",
            "nl_okruh_id": "8",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "5a28850cf6b3405aaed0"
        },
        {
            "nl_otazka_id": "2145",
            "uv_otazka": "Vozidlo sa považuje za technicky nespôsobilé na prevádzku v cestnej premávke, najmä ak",
            "uv_odpoveda": "na zvukovom výstražnom zariadení došlo k zmene tónu.",
            "uv_odpovedb": "dodatočne namontované svetelné zariadenia narúšajú výhľad z miesta spolujazdca.",
            "uv_odpovedc": "je nalomenie alebo deformácia rámu alebo nosníka podvozku.",
            "nl_okruh_id": "8",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "33077afa97380e828ce5"
        },
        {
            "nl_otazka_id": "2256",
            "uv_otazka": "Pri jazde po moste alebo nadjazde v zime platí zásada",
            "uv_odpoveda": "jazdiť nezmenenou rýchlosťou - tieto miesta namŕzajú najneskôr  ",
            "uv_odpovedb": "jazdiť nezmenenou rýchlosťou - tieto miesta nenamŕzajú",
            "uv_odpovedc": "spomaliť - tieto miesta namŕzajú najskôr a zostávajú dlhšie namrznuté",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3ec6d91d7f9f534dd3cf"
        },
        {
            "nl_otazka_id": "2191",
            "uv_otazka": "Alkohol u vodiča  spôsobuje",
            "uv_odpoveda": "predĺženie reakčného času",
            "uv_odpovedb": "zvýšenie schopnosti vnímať svetelné podnety",
            "uv_odpovedc": "zväčšovanie zorného poľa",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "502242d6a908838b31b8"
        },
        {
            "nl_otazka_id": "2258",
            "uv_otazka": "Môže byť jazda v mŕtvom uhle iného vozidla nebezpečná?",
            "uv_odpoveda": "Áno, ale len ak ide o nákladné vozidlo.",
            "uv_odpovedb": "Nie, takáto jazda je nebezpečná len pre vodiča iného vozidla.",
            "uv_odpovedc": "Áno.",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f464cc31ce0be9986f06"
        },
        {
            "nl_otazka_id": "2192",
            "uv_otazka": "Alkohol u vodiča  spôsobuje",
            "uv_odpoveda": "chybné rozhodovanie a spomalené reakcie",
            "uv_odpovedb": "dlhú jazdu s častými prestávkami",
            "uv_odpovedc": "urýchlené rozhodovanie a zrýchlené reakcie",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ff4b5d7acb44767f8df0"
        },
        {
            "nl_otazka_id": "2345",
            "uv_otazka": "Zaradením neutrálu v prevodovke docielime",
            "uv_odpoveda": "dlhodobé prerušenie prenosu krútiaceho momentu od motora na kolesá vozidla.",
            "uv_odpovedb": "vypnutie spojky.",
            "uv_odpovedc": "krátkodobé prerušenie sily od motora do prevodovky.",
            "nl_okruh_id": "10",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "8b59c0560bb87dee12e9"
        },
        {
            "nl_otazka_id": "1118",
            "uv_otazka": "Záchranársku uličku môže použiť",
            "uv_odpoveda": "vodič vozidla s právom prednostnej jazdy.",
            "uv_odpovedb": "vodič autobusu pravidelnej hromadnej prepravy osôb.",
            "uv_odpovedc": "vodič akéhokoľvek vozidla.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "58b6b7e5d50b0d7b1e81"
        },
        {
            "nl_otazka_id": "1466",
            "uv_otazka": "Sprievodca vedených alebo hnaných zvierat je oprávnený dávať pokyny na zastavenie vozidla,",
            "uv_odpoveda": "len ak má pri sebe vodičský preukaz.",
            "uv_odpovedb": "len za zníženej viditeľnosti.",
            "uv_odpovedc": "ak to vyžaduje bezpečnosť cestnej premávky.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "969bc9b3a00664842c00"
        },
        {
            "nl_otazka_id": "1073",
            "uv_otazka": "Ak vozidlo vrátane nákladu alebo jeho upevnenia nespĺňa ustanovené podmienky, prevádzkovateľ vozidla",
            "uv_odpoveda": "môže prikázať, aby sa vozidlo použilo na jazdu v obci.",
            "uv_odpovedb": "nesmie prikázať ani dovoliť, aby sa vozidlo použilo na jazdu.",
            "uv_odpovedc": "so súhlasom vodiča môže dovoliť, aby sa vozidlo použilo na jazdu.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f8c86345c095a9d48100"
        },
        {
            "nl_otazka_id": "1125",
            "uv_otazka": "Električkový koľajový pás zvýšený nad úroveň vozovky alebo znížený pod jej úroveň, alebo ktorý je od vozovky inak oddelený, najmä obrubníkom, smie sa prechádzať",
            "uv_odpoveda": "na ktoromkoľvek mieste, a to vždy priečne.",
            "uv_odpovedb": "len priečne, a to na mieste prispôsobenom na to.",
            "uv_odpovedc": "na ktoromkoľvek mieste, ak nie je v blízkosti električka.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3521a68c8983df508b84"
        },
        {
            "nl_otazka_id": "1606",
            "uv_otazka": "V obytnej zóne, pešej zóne a školskej zóne vodič smie jazdiť rýchlosťou najviac",
            "uv_odpoveda": "20 km.h-1.",
            "uv_odpovedb": "30 km.h-1.",
            "uv_odpovedc": "50 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "c825024398b352c5cdd2"
        },
        {
            "nl_otazka_id": "1656",
            "uv_otazka": "Čo znamená táto dopravná značka?",
            "uv_odpoveda": "Značka ukladá vodičovi povinnosť dať prednosť v jazde protiidúcim vozidlám.",
            "uv_odpovedb": "Značka ukladá vodičovi povinnosť nedať prednosť v jazde protiidúcim vozidlám.",
            "uv_odpovedc": "Obojsmerná premávka.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/203.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "1f205418711c3237f861"
        },
        {
            "nl_otazka_id": "1787",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Bicykle s elektrickým pomocným pohonom, ktorého výkon sa preruší, keď bicykel dosiahne rýchlosť 25 km/h",
            "uv_odpovedb": "Elektrobicykle",
            "uv_odpovedc": "Kolobežky s pomocným motorčekom, samovyvažovacie vozidlá a iné dopravné prostriedky s elektrickým pohonom na individuálne presuny",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/1-16.png",
            "body": "2",
            "poznamka": "",
            "hash": "6ca3de128ce64f218401"
        },
        {
            "nl_otazka_id": "1796",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Štadión, aréna",
            "uv_odpovedb": "Turistika",
            "uv_odpovedc": "Táborisko",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/2-23.png",
            "body": "2",
            "poznamka": "",
            "hash": "49bc4af7d3aa651bd252"
        },
        {
            "nl_otazka_id": "1851",
            "uv_otazka": "Ako prvé prejde cez križovatku",
            "uv_odpoveda": "červené vozidlo.",
            "uv_odpovedb": "zelené vozidlo.",
            "uv_odpovedc": "modré vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/25.png",
            "body": "4",
            "poznamka": "",
            "hash": "edc3d9ec69581eb8df9c"
        },
        {
            "nl_otazka_id": "1921",
            "uv_otazka": "Účastník dopravnej nehody môže premiestniť vozidlá, ak je to",
            "uv_odpoveda": "potrebné na vyloženie prepravovaných vecí z vozidla.",
            "uv_odpovedb": "nevyhnutné na obnovenie premávky, pritom nie je povinný vyznačiť postavenie vozidiel po dopravnej nehode, situáciu a stopy.",
            "uv_odpovedc": "nevyhnutné na uvoľnenie alebo ošetrenie zranenej osoby alebo na obnovenie cestnej premávky, pritom je však povinný vyznačiť postavenie vozidiel po dopravnej nehode, situáciu a stopy.",
            "nl_okruh_id": "5",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "5675fb85682567b77ffb"
        },
        {
            "nl_otazka_id": "1956",
            "uv_otazka": "Pedál spojky sa ovláda",
            "uv_odpoveda": "akoukoľvek nohou.",
            "uv_odpovedb": "zásadne ľavou nohou, a to prednou časťou chodidla.",
            "uv_odpovedc": "zásadne pravou nohou, a to prednou časťou chodidla.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "3d80c904a49e6402f0cd"
        },
        {
            "nl_otazka_id": "2085",
            "uv_otazka": "Držiteľ vodičského preukazu",
            "uv_odpoveda": "je povinný odovzdať orgánu Policajného zboru neplatný vodičský preukaz podľa zákona o cestnej premávke, ak dovŕšil vek 65 rokov.",
            "uv_odpovedb": "nie je povinný odovzdať orgánu Policajného zboru neplatný vodičský preukaz podľa zákona o cestnej premávke.",
            "uv_odpovedc": "je povinný odovzdať orgánu Policajného zboru neplatný vodičský preukaz podľa zákona o cestnej premávke.",
            "nl_okruh_id": "7",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "af6fd166a676934f95b1"
        },
        {
            "nl_otazka_id": "2025",
            "uv_otazka": "Držiteľ vodičského oprávnenia skupiny AM smie viesť motorové vozidlá skupiny AM, kde patria",
            "uv_odpoveda": "ľahké dvojkolesové motorové vozidlá so zdvihovým objemom valcov motora nepresahujúcim 125 cm3.",
            "uv_odpovedb": "motorové vozidlá s výkonom motora väčším ako 4 kW v prípade elektrického motora s najväčšou konštrukčnou rýchlosťou vyššou ako 25 km · h-1.",
            "uv_odpovedc": "trojkolesové motorové vozidlá kategórie L2e s najväčšou konštrukčnou rýchlosťou vyššou ako 25 km · h-1.",
            "nl_okruh_id": "7",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "087b0d98f9a4669720c1"
        },
        {
            "nl_otazka_id": "2143",
            "uv_otazka": "Ak sú spínače brzdových svetiel nefunkčné,",
            "uv_odpoveda": "vozidlo sa považuje za technicky nespôsobilé na prevádzku v cestnej premávke.",
            "uv_odpovedb": "smie sa takéto vozidlo, okrem diaľnice a cesty I. triedy, používať v cestnej premávke.",
            "uv_odpovedc": "vodič je povinný to po ukončení jazdy oznámiť držiteľovi vozidla.",
            "nl_okruh_id": "8",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "7aeaa1842ad4cf515eaa"
        },
        {
            "nl_otazka_id": "2179",
            "uv_otazka": "Vodič sa musí riadiť nasledovnou zásadou",
            "uv_odpoveda": "Včas signalizovať svoj zámer pri vchádzaní do kruhového objazdu.",
            "uv_odpovedb": "Odbočovať na druhom prevodovom stupni.",
            "uv_odpovedc": "Dodržiavať bezpečnú vzdialenosť od vozidla idúceho pred ním.",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "0bd70e21c3a2e290d5c7"
        },
        {
            "nl_otazka_id": "2225",
            "uv_otazka": "Pre bezpečné zaradenie sa pred predchádzané vozidlo do pôvodného jazdného pruhu platí zásada",
            "uv_odpoveda": "Zaradiť sa až vtedy, keď vidíme predchádzané vozidlo v ľavom zrkadle.",
            "uv_odpovedb": "Zaradiť sa až vtedy, keď sme použili zvukové výstražné znamenie.",
            "uv_odpovedc": "Zaradiť sa až vtedy, keď sme predchádzané vozidlo dostatočne predišli.",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e41c210adcbba3e6c6ff"
        },
        {
            "nl_otazka_id": "2325",
            "uv_otazka": "Pracovný cyklus štvordobého motora pracuje v tomto poradí",
            "uv_odpoveda": "nasávanie, výfuk, výbuch, stláčanie.",
            "uv_odpovedb": "nasávanie, výbuch, stláčanie, výfuk.",
            "uv_odpovedc": "nasávanie, stláčanie (kompresia), výbuch (expanzia), výfuk.",
            "nl_okruh_id": "10",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "2d8001174b96f9aa92e7"
        },
        {
            "nl_otazka_id": "1153",
            "uv_otazka": "Vodič nesmie predchádzať, ak vodič pred ním idúceho vozidla dáva znamenie o zmene smeru jazdy",
            "uv_odpoveda": "vľavo a ak ho nemožno predísť vpravo.",
            "uv_odpovedb": "vľavo a to za žiadnych okolností.",
            "uv_odpovedc": "vpravo.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ca159b55efcf77f1aa29"
        },
        {
            "nl_otazka_id": "1128",
            "uv_otazka": "Ak ide vozidlo vo vyhradenom jazdnom pruhu inou rýchlosťou ako ostatné vozidlá idúce rovnakým smerom",
            "uv_odpoveda": "ide o vzájomné predchádzanie.",
            "uv_odpovedb": "nejde o vzájomné predchádzanie.",
            "uv_odpovedc": "ide o vzájomné predchádzanie, ak ostatné vozidlá jazdia rýchlejšie.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e84e7104fd6a96757c73"
        },
        {
            "nl_otazka_id": "1002",
            "uv_otazka": "Hranicou križovatky sa rozumie",
            "uv_odpoveda": "kolmica na os vozovky vo vzdialenosti desať metrov od miesta, kde sa pre križovatku začína zakrivenie vozovky.",
            "uv_odpovedb": "miesto, ktoré tvorí kolmica na os vozovky v mieste, kde sa pre križovatku začína zakrivenie vozovky.",
            "uv_odpovedc": "kolmica na os vozovky vedená stredom priechodu pre chodcov.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "8b6bd1b3088556d8b954"
        },
        {
            "nl_otazka_id": "1380",
            "uv_otazka": "Správca cesty môže odstrániť vozidlo stojace na ceste vrátane chodníka na náklady jeho prevádzkovateľa, ak",
            "uv_odpoveda": "je na mieste, kde netvorí prekážku cestnej premávky.",
            "uv_odpovedb": "je na mieste, kde tvorí prekážku cestnej premávky.",
            "uv_odpovedc": "je ponechané na mieste, kde je dopravnou značkou státie povolené.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "402b3a387d9d695c8cab"
        },
        {
            "nl_otazka_id": "1047",
            "uv_otazka": "Vodič nesmie odovzdať vedenie vozidla osobe, ktorá",
            "uv_odpoveda": "nemá najmenej dvojročnú prax vo vedení takéhoto vozidla alebo je pod značným vplyvom alkoholu.",
            "uv_odpovedb": "je pod vplyvom alkoholu alebo inej návykovej látky.",
            "uv_odpovedc": "spĺňa podmienky ustanovené zákonom o cestnej premávke, je však mladšia ako 21 rokov.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "eb98c92192f1b76488e9"
        },
        {
            "nl_otazka_id": "1335",
            "uv_otazka": "Ak sa pri vlečení motorového vozidla použije ako spojnica tyč, musí byť zreteľne označená",
            "uv_odpoveda": "striedavo priečnymi žltými a čiernymi pruhmi so šírkou najmenej 10 cm.",
            "uv_odpovedb": "striedavo priečnymi červenými a bielymi pruhmi so šírkou 7,5 cm.",
            "uv_odpovedc": "šikmými červenými pruhmi.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "cae25777850ec646eae6"
        },
        {
            "nl_otazka_id": "1329",
            "uv_otazka": "Vlečenie motorového vozidla s prívesom je",
            "uv_odpoveda": "dovolené, ak najväčšia prípustná celková hmotnosť vlečeného vozidla a prívesu nepresahuje 3500 kg.",
            "uv_odpovedb": "zakázané; smie sa však vliecť motorové vozidlo s návesom.",
            "uv_odpovedc": "zakázané; vliecť sa nesmie ani motorové vozidlo s návesom.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "c3c67f7802526014dffd"
        },
        {
            "nl_otazka_id": "1749",
            "uv_otazka": "Táto dodatková tabuľka",
            "uv_odpoveda": "Prikazuje cyklistom zosadnúť z bicykla.",
            "uv_odpovedb": "Zakazuje vjazd bicyklov; zákaz sa nevzťahuje na prípady, keď je bicykel tlačený.",
            "uv_odpovedc": "Umiestnená pod značkou Jednosmerná cesta umožňuje cyklistom jazdiť v oboch smeroch; vodič iného vozidla je povinný zvýšiť opatrnosť voči cyklistom v priebehu jednosmernej cesty.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/514.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "d1f91a6b9c77ba4f61f2"
        },
        {
            "nl_otazka_id": "1745",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Informuje o nabíjacej stanici s malou elektrárňou pre nabíjanie elektromobilov.",
            "uv_odpovedb": "Informuje o čerpacej stanici skvapalneného ropného plynu.",
            "uv_odpovedc": "Vhodným symbolom označuje miesto, na ktorom sa poskytuje služba významná pre verejnosť.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/330.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "c7d6f7b0703b9b791c74"
        },
        {
            "nl_otazka_id": "1781",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Dvojkolesové alebo trojkolesové motorové vozidlá s najväčšou konštrukčnou rýchlosťou 45 km/h, ktorých zdvihový objem valcov motora nepresahuje 50 cm3 v prípade spaľovacieho motora a najväčší čistý výkon nie je väčší ako 4 kW alebo ktorých najväčší trvalý menovitý výkon nie je väčší ako 4 kW v prípade elektrického motora",
            "uv_odpovedb": "Dvojkolesové motorové vozidlá s postranným vozíkom alebo bez neho s výnimkou mopedov a trojkolesové motorové vozidlá, ktorých prevádzková hmotnosť nepresahuje 400 kg",
            "uv_odpovedc": "Motocykle",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/1-8.png",
            "body": "2",
            "poznamka": "",
            "hash": "4a38e5fc1148ce77c9c0"
        },
        {
            "nl_otazka_id": "1798",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Voľnočasový športový areál",
            "uv_odpovedb": "Turistika",
            "uv_odpovedc": "Táborisko",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/2-26.png",
            "body": "2",
            "poznamka": "",
            "hash": "7fc65119306a73a60700"
        },
        {
            "nl_otazka_id": "1832",
            "uv_otazka": "Ako posledné prejde cez križovatku",
            "uv_odpoveda": "vaše vozidlo.",
            "uv_odpovedb": "zelené vozidlo.",
            "uv_odpovedc": "žlté vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/15.png",
            "body": "4",
            "poznamka": "",
            "hash": "23ab939c998e93738e25"
        },
        {
            "nl_otazka_id": "1822",
            "uv_otazka": "V poradí posledné prejde cez križovatku",
            "uv_odpoveda": "vaše vozidlo súčasne s modrým.",
            "uv_odpovedb": "modré vozidlo súčasne s červeným.",
            "uv_odpovedc": "zelené vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/05.png",
            "body": "4",
            "poznamka": "",
            "hash": "31d92400764461ec829e"
        },
        {
            "nl_otazka_id": "1922",
            "uv_otazka": "Účastník dopravnej nehody",
            "uv_odpoveda": "nie je povinný poskytnúť policajtovi potrebnú súčinnosť pri objasňovaní dopravnej nehody vrátane predloženia dokladov, ktoré s tým súvisia.",
            "uv_odpovedb": "je povinný poskytnúť policajtovi potrebnú súčinnosť pri objasňovaní dopravnej nehody vrátane predloženia dokladov, ktoré s tým súvisia, ak s tým súhlasí prevádzkovateľ vozidla.",
            "uv_odpovedc": "je povinný poskytnúť policajtovi potrebnú súčinnosť pri objasňovaní dopravnej nehody vrátane predloženia dokladov, ktoré s tým súvisia.",
            "nl_okruh_id": "5",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "b1ae79b84b9490540a41"
        },
        {
            "nl_otazka_id": "1960",
            "uv_otazka": "Počas rozjazdu vozidla je potrebné pedál spojky",
            "uv_odpoveda": "držať stlačený až na podlahu.",
            "uv_odpovedb": "podržať v zábere.",
            "uv_odpovedc": "rýchlo uvoľniť a stlačiť plynový pedál až na podlahu.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "c9706f54f9bbd45c388f"
        },
        {
            "nl_otazka_id": "2089",
            "uv_otazka": "Ak držiteľ vodičského preukazu, ktorý bol obnovený z dôvodu straty alebo odcudzenia, svoj predchádzajúci vodičský preukaz nájde alebo získa späť",
            "uv_odpoveda": "je povinný predchádzajúci vodičský preukaz bezodkladne odovzdať orgánu Policajného zboru, ak ho na to vyzve príslušný okresný úrad.",
            "uv_odpovedb": "je povinný predchádzajúci vodičský preukaz bezodkladne odovzdať orgánu Policajného zboru.",
            "uv_odpovedc": "nie je povinný predchádzajúci vodičský preukaz bezodkladne odovzdať orgánu Policajného zboru.",
            "nl_okruh_id": "7",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "fc7fef0e1db3ee9c82db"
        },
        {
            "nl_otazka_id": "2125",
            "uv_otazka": "Hĺbka hlavných drážok pneumatiky nemôže byť menšia ako",
            "uv_odpoveda": "1 mm na vozidlách kategórie L 1e.",
            "uv_odpovedb": "1,8 mm na vozidlách ostatných kategórií",
            "uv_odpovedc": "1,5 mm na vozidlách kategórie L 1e.",
            "nl_okruh_id": "8",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "356ce22229fc9d71a57d"
        },
        {
            "nl_otazka_id": "2160",
            "uv_otazka": "Odstredivú silu pôsobiacu na vozidlo pri prejazde zákrutou najviac ovplyvníme",
            "uv_odpoveda": "úpravou hmotnosti pri konštrukcii vozidla",
            "uv_odpovedb": "zmenou rýchlosti",
            "uv_odpovedc": "zapnutím ABS",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "2f07af0fc87b57ba4e6b"
        },
        {
            "nl_otazka_id": "2257",
            "uv_otazka": "Za zhoršených poveternostných podmienok (dážď, hmla, sneženie, ľad) platí zásada",
            "uv_odpoveda": "jazdiť vždy za druhým vozidlom",
            "uv_odpovedb": "jazdiť maximálnou povolenou rýchlosťou",
            "uv_odpovedc": "znížiť rýchlosť jazdy tak, aby ste boli schopní bezpečne zastaviť vozidlo",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "d7090087b1d2a2a2b5d9"
        },
        {
            "nl_otazka_id": "2301",
            "uv_otazka": "Vodič vozidla zaznamená, že pri priechode pre chodcov stoja ľudia, nemal by na nich nikdy kývať s cieľom ukázať im, aby prešli cez priechod, pretože",
            "uv_odpoveda": "k priechodu pre chodcov sa môže približovať iný vodič vozidla, napríklad z protismeru a ten nemusí mať rovnaký úmysel ich pustiť, a ak nezastaví ľudia by sa mohli ocitnúť v ohrození.",
            "uv_odpovedb": "bude bezpečnejšie pre každého, ak bude vodič pokračovať v jazde.",
            "uv_odpovedc": "nemusia vidieť vozidlo a vodiča budú považovať za blázna.",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f2ce8e2dbfd76504b877"
        },
        {
            "nl_otazka_id": "2211",
            "uv_otazka": "Pri jazde na ceste s prudkým klesaním je správne",
            "uv_odpoveda": "zaradiť nižší prevodový stupeň na začiatku klesania",
            "uv_odpovedb": "zaradiť neutrál a intenzívne brzdiť",
            "uv_odpovedc": "zaradiť vyšší prevodový stupeň a brzdiť",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "4a0a7813c233af17cb1f"
        },
        {
            "nl_otazka_id": "2344",
            "uv_otazka": "Uzávierka diferenciálu sa nesmie používať",
            "uv_odpoveda": "pri jazde po vozovke s dobrou priľnavosťou pneumatík k vozovke a v zákrutách.",
            "uv_odpovedb": "na vozovke s rozdielnou adhéziou.",
            "uv_odpovedc": "v teréne.",
            "nl_okruh_id": "10",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "9ec998bcf1cdce130f35"
        },
        {
            "nl_otazka_id": "1484",
            "uv_otazka": "V obytnej zóne a pešej zóne je státie motorových vozidiel",
            "uv_odpoveda": "zakázané, ak dopravnou značkou nie je určené inak.",
            "uv_odpovedb": "povolené na akomkoľvek mieste na ceste.",
            "uv_odpovedc": "zakázané len na chodníku.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "4ff14142cb485c4fa4e7"
        },
        {
            "nl_otazka_id": "1353",
            "uv_otazka": "Vodičovi motorového vozidla je na diaľnici a na ceste pre motorové vozidlá otáčanie a cúvanie",
            "uv_odpoveda": "dovolené.",
            "uv_odpovedb": "zakázané.",
            "uv_odpovedc": "dovolené, tam, kde je stredný deliaci pás prerušený.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "de35b105739e0c057f47"
        },
        {
            "nl_otazka_id": "1007",
            "uv_otazka": "Považuje sa za vodiča osoba, ktorá tlačí motocykel?",
            "uv_odpoveda": "Áno.",
            "uv_odpovedb": "Iba vtedy, ak na hlave má riadne upevnenú ochrannú prilbu určenú pre motocyklistov.",
            "uv_odpovedc": "Nie.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "90a9e7d7b8d5be82dcb2"
        },
        {
            "nl_otazka_id": "1370",
            "uv_otazka": "Ak na stojacom vozidle svieti zvláštne výstražné svetlo modrej alebo červenej farby, prípadne ich kombinácia, musí vodič iného vozidla",
            "uv_odpoveda": "zvýšiť rýchlosť jazdy.",
            "uv_odpovedb": "bezodkladne zastaviť vozidlo.",
            "uv_odpovedc": "zvýšiť opatrnosť, znížiť rýchlosť jazdy, prípadne zastaviť vozidlo.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "93ad0390f14807e893c1"
        },
        {
            "nl_otazka_id": "1211",
            "uv_otazka": "Vodičovi vozidla pravidelnej verejnej dopravy osôb sú vodiči ostatných vozidiel povinní umožniť vyjdenie zo zastávky alebo zo zastávkového pruhu, a to znížením rýchlosti jazdy, prípadne i zastavením vozidla",
            "uv_odpoveda": "v obci aj mimo obce.",
            "uv_odpovedb": "len mimo obce.",
            "uv_odpovedc": "v obci.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "750f1302a7bacc441239"
        },
        {
            "nl_otazka_id": "1478",
            "uv_otazka": "Smie vodič vozidla kategórie M1 a N1 vojsť do pešej zóny ?",
            "uv_odpoveda": "Nie, zákaz vjazdu sa vzťahuje iba na vozidlá kategórie N2 a N3.",
            "uv_odpovedb": "Áno, ak to umožňuje dopravná značka.",
            "uv_odpovedc": "Nie, vjazd všetkých vozidiel do pešej zóny je zakázaný.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "0dacb172b27809cd15c6"
        },
        {
            "nl_otazka_id": "1488",
            "uv_otazka": "Smie vodič osobného automobilu zastaviť v obytnej zóne?",
            "uv_odpoveda": "Nie.",
            "uv_odpovedb": "Áno.",
            "uv_odpovedc": "Iba vtedy, ak to umožňuje dopravná značka.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "6b62160478a9bd13f314"
        },
        {
            "nl_otazka_id": "1571",
            "uv_otazka": "V obci smie vodič jazdiť rýchlosťou najviac",
            "uv_odpoveda": "60 km.h-1 a ak ide po diaľnici v obci najviac 110 km.h-1.",
            "uv_odpovedb": "80 km.h-1 a ak ide po diaľnici v obci najviac 100 km.h-1.",
            "uv_odpovedc": "50 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3b41ee11fc115f996081"
        },
        {
            "nl_otazka_id": "1608",
            "uv_otazka": "Akou najväčšou rýchlosťou môže jazdiť vodič osobného automobilu v obytnej zóne?",
            "uv_odpoveda": "20 km.h-1.",
            "uv_odpovedb": "Vjazd  vozidiel do obytnej zóny je zakázaný.",
            "uv_odpovedc": "30 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "b53ab5f0ec586a741a4c"
        },
        {
            "nl_otazka_id": "1730",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Vyznačuje cestu s jednosmernou premávkou.",
            "uv_odpovedb": "Informuje o smere jazdy k vyznačeným cieľom a v prípade sídelných cieľov spravidla aj o vzdialenosti do centra týchto cieľov.",
            "uv_odpovedc": "Prikazuje odbočiť vpravo.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/363.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "a5d065939c7daf14ee7f"
        },
        {
            "nl_otazka_id": "1759",
            "uv_otazka": "Táto dopravná značka vyznačuje",
            "uv_odpoveda": "Vyznačuje priestor, do ktorého je zakázané vchádzať alebo zasahovať.",
            "uv_odpovedb": "Priestor, kde je zakázané zastavenie.",
            "uv_odpovedc": "Priestor, kde je zakázané státie vozidiel.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/621.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "60a97c3f591f2135f5c3"
        },
        {
            "nl_otazka_id": "1704",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Odstavnú plochu pre vozidlá s nadrozmerným nákladom.",
            "uv_odpovedb": "Miesto vyhradené na núdzové státie vozidla.",
            "uv_odpovedc": "Parkovisko na diaľnici.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/335.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "f5c8c8775aef635cadb4"
        },
        {
            "nl_otazka_id": "1739",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Miesto, kde sa končí platnosť pravidiel cestnej premávky o správaní sa v obci a informuje o názve obce alebo miestnej časti obce tvoriacej samostatný sídelný útvar.",
            "uv_odpovedb": "Koniec obce v jazyku národnostnej menšiny na začiatku obce.",
            "uv_odpovedc": "Koniec obytnej zóny.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/306.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "f34d220b50383e97b690"
        },
        {
            "nl_otazka_id": "1797",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Lyžiarske stredisko",
            "uv_odpovedb": "Voľnočasový športový areál",
            "uv_odpovedc": "Turistika",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/2-25.png",
            "body": "2",
            "poznamka": "",
            "hash": "213acef1cc673b13b5bd"
        },
        {
            "nl_otazka_id": "1820",
            "uv_otazka": "Vozidlá prejdú cez križovatku v tomto poradí:",
            "uv_odpoveda": "1. modré. 2. vaše vozidlo, 3. červené.",
            "uv_odpovedb": "1. modré, 2.červené, 3. vaše vozidlo.",
            "uv_odpovedc": "1. vaše vozidlo, 2. modré, 3. červené.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/04.png",
            "body": "4",
            "poznamka": "",
            "hash": "9fde72dbe06684a29c72"
        },
        {
            "nl_otazka_id": "1995",
            "uv_otazka": "Pri jazde dozadu je dôležité si uvedomiť, že vozidlo",
            "uv_odpoveda": "sa otáča na tú stranu, na ktorú točíme volantom.",
            "uv_odpovedb": "nemá aktívny systém ABS.",
            "uv_odpovedc": "sa otáča na opačnú stranu, na ktorú točíme volantom.",
            "nl_okruh_id": "6",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "c8ff0d73db2ba49ec024"
        },
        {
            "nl_otazka_id": "2065",
            "uv_otazka": "Vodič je povinný",
            "uv_odpoveda": "mať platný vodičský preukaz vydaný a uschovaný na bezpečnom mieste.",
            "uv_odpovedb": "mať vodičský preukaz pri sebe a na požiadanie policajta ho priniesť na kontrolu na miesto určené policajtom.",
            "uv_odpovedc": "mať platný vodičský preukaz pri vedení motorového vozidla pri sebe a na výzvu policajta ho predložiť na kontrolu.",
            "nl_okruh_id": "7",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "e47a0936e99820301741"
        },
        {
            "nl_otazka_id": "2148",
            "uv_otazka": "Vozidlo sa považuje za technicky nespôsobilé na prevádzku v cestnej premávke, najmä ak",
            "uv_odpoveda": "je zhodný účinok prevádzkovej brzdy na kolesách tej istej nápravy.",
            "uv_odpovedb": "sa nedá uviesť do činnosti osvetlenie batožinového priestoru.",
            "uv_odpovedc": "sú prekročené emisné limity motora.",
            "nl_okruh_id": "8",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "1bad20e9dc990e7ec9ed"
        },
        {
            "nl_otazka_id": "2251",
            "uv_otazka": "Pokiaľ vodiča ovládajú emócie",
            "uv_odpoveda": "nemusí robiť nič, emócie na vodiča vplývajú pozitívne",
            "uv_odpovedb": "mal by jazdu prerušiť a počkať, až sa upokojí",
            "uv_odpovedc": "musí jazdiť pomalšie",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "d13ccd753a9236381a3e"
        },
        {
            "nl_otazka_id": "2248",
            "uv_otazka": "Ktorú zásadu by mal dodržiavať začínajúci vodič? Mal by",
            "uv_odpoveda": "sledovať cestnú premávku aj za sebou.",
            "uv_odpovedb": "sa pozerať do zrkadiel, aby vedel ako sa vyvíja situácia pred ním.",
            "uv_odpovedc": "vypnúť rádio, aby ho to nerušilo.",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5f53c4ab5b555f04a882"
        },
        {
            "nl_otazka_id": "2298",
            "uv_otazka": "Môže byť jazda v mŕtvom uhle iného vozidla nebezpečná?",
            "uv_odpoveda": "Áno, ale len ak jazdíte v mŕtvom uhle nákladného vozidla.",
            "uv_odpovedb": "Nie, takáto jazda je nebezpečná len pre vodiča autobusu, v ktorého mŕtvom uhle jazdíte.",
            "uv_odpovedc": "Áno.",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "265a1c3263c2009728c5"
        },
        {
            "nl_otazka_id": "2195",
            "uv_otazka": "Alkohol za volantom",
            "uv_odpoveda": "zvyšuje reakčnú dobu a zlepšuje náladu",
            "uv_odpovedb": "neovplyvňuje jednanie vodiča",
            "uv_odpovedc": "spomaľuje reakčnú dobu, zhoršuje rozlišovacie a rozpoznávacie schopnosti",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f25639d1d66a63140288"
        },
        {
            "nl_otazka_id": "1200",
            "uv_otazka": "Na úseku cesty označenou touto dopravnou značkou vodič",
            "uv_odpoveda": "nesmie cúvať za žiadnych okolností.",
            "uv_odpovedb": "smie zastaviť a stáť len na mieste vyznačenom ako parkovisko.",
            "uv_odpovedc": "smie zastaviť a stáť vľavo aj vpravo.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/321-1.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "6f1131fae656d3f688de"
        },
        {
            "nl_otazka_id": "1440",
            "uv_otazka": "Osoba, ktorá sa pohybuje po chodníku na lyžiach, korčuliach, kolobežke, skejtborde alebo na obdobnom športovom vybavení",
            "uv_odpoveda": "smie používať pravú stranu chodníka, pričom nesmie ohroziť ani obmedziť ostatné osoby používajúce chodník.",
            "uv_odpovedb": "smie používať chodník v celej šírke.",
            "uv_odpovedc": "smie používať ľavú stranu chodníka, pričom smie obmedziť ostatné osoby používajúce chodník.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "abeb271d6bb22b367056"
        },
        {
            "nl_otazka_id": "1119",
            "uv_otazka": "Záchranársku uličku môže použiť",
            "uv_odpoveda": "vodič traktora.",
            "uv_odpovedb": "vodič vozidla zabezpečujúci odstránenie následkov dopravnej nehody.",
            "uv_odpovedc": "vodič autobusu pravidelnej hromadnej prepravy osôb.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3e583deff6fc00c78e04"
        },
        {
            "nl_otazka_id": "1492",
            "uv_otazka": "V obytnej, pešej a školskej zóne chodci",
            "uv_odpoveda": "sú povinní umožniť vozidlám jazdu; to platí aj pre deti, ktoré sa hrajú v obytnej zóne.",
            "uv_odpovedb": "sú povinní umožniť jazdu len vozidlám pravidelnej verejnej dopravy osôb; to platí aj pre deti, ktoré sa hrajú na ceste.",
            "uv_odpovedc": "nie sú povinní umožniť vozidlám jazdu.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "872342420048dba54c1c"
        },
        {
            "nl_otazka_id": "1293",
            "uv_otazka": "Znamenie dávané smerovými svietidlami vodič ponechá len v čase, kým",
            "uv_odpoveda": "nezačne meniť smer jazdy.",
            "uv_odpovedb": "nezačne vybočovať zo smeru jazdy.",
            "uv_odpovedc": "mení smer jazdy, vybočuje z neho alebo kým vozidlo nezaujme miesto v jazdnom pruhu, do ktorého prechádza.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3a3612eb1178246c015c"
        },
        {
            "nl_otazka_id": "1182",
            "uv_otazka": "Vodič pri cúvaní smie",
            "uv_odpoveda": "obmedziť ostatných účastníkov cestnej premávky.",
            "uv_odpovedb": "obmedziť len vozidlá s právom prednostnej jazdy.",
            "uv_odpovedc": "ohroziť ostatných účastníkov cestnej premávky.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "c80ace2d0d12c9e7e678"
        },
        {
            "nl_otazka_id": "1323",
            "uv_otazka": "Pri vlečení motorového vozidla sa povinnosti vodiča vzťahujú",
            "uv_odpoveda": "len na vodiča vlečeného vozidla.",
            "uv_odpovedb": "na vodiča vlečného aj vlečeného vozidla.",
            "uv_odpovedc": "len na vodiča vlečného vozidla.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "69812fc378cef832a094"
        },
        {
            "nl_otazka_id": "1604",
            "uv_otazka": "Vodič motorového vozidla musí cestu pre motorové vozidlá opustiť na najbližšom možnom mieste, ak počas jazdy zistí na vozidle alebo na náklade chybu, pre ktorú nemožno dosiahnuť na rovine rýchlosť najmenej",
            "uv_odpoveda": "80 km.h-1.",
            "uv_odpovedb": "60 km.h-1.",
            "uv_odpovedc": "50 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "7d62234b5956c1935b0b"
        },
        {
            "nl_otazka_id": "1763",
            "uv_otazka": "Táto smerovacia tabuľa usmerňuje",
            "uv_odpoveda": "Premávku počas úplnej uzávierky cesty.",
            "uv_odpovedb": "Cestnú premávku v smere šípky alebo šípok.",
            "uv_odpovedc": "Premávku v úseku cesty s nebezpečným stúpaním.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/705.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "a17632fb350d9c0ee83a"
        },
        {
            "nl_otazka_id": "1679",
            "uv_otazka": "Táto dopravná značka znamená:",
            "uv_odpoveda": "Zákaz státia. Zákaz platí aj na priľahlý chodník.",
            "uv_odpovedb": "Koniec zákazu státia.",
            "uv_odpovedc": "Zákaz státia. Zákaz neplatí na priľahlý chodník.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/271.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "719e910a616353ed45b2"
        },
        {
            "nl_otazka_id": "1677",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Zakazuje jazdiť rýchlosťou vyššou ako vyznačenou rýchlosťou v kilometroch za hodinu. Značka zároveň ukončuje platnosť predchádzajúcej značky Najvyššia dovolená rýchlosť.",
            "uv_odpovedb": "Zakazuje jazdiť rýchlosťou vyššou ako vyznačenou rýchlosťou v kilometroch za hodinu, ale neukončuje platnosť predchádzajúcej značky Najvyššia dovolená rýchlosť.",
            "uv_odpovedc": "Informuje o odporúčanej rýchlosti.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/253.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "387c2f1c8e632544e2b2"
        },
        {
            "nl_otazka_id": "1812",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Diaľničný uzol (križovanie diaľnice s inou diaľnicou)",
            "uv_odpovedb": "Výjazd",
            "uv_odpovedc": "Výstražný kríž",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/5-2.png",
            "body": "2",
            "poznamka": "",
            "hash": "b2a1a4d9a3793089195b"
        },
        {
            "nl_otazka_id": "1658",
            "uv_otazka": "Táto dodatková tabuľka vyznačuje",
            "uv_odpoveda": "Tvar kríženia cesty so železnicou. Železnica je vyznačená hrubšou čiarou.",
            "uv_odpovedb": "Tvar križovatky ciest nerozlíšených dopravnými značkami.",
            "uv_odpovedc": "Priebeh hlavnej cesty, ak je umiestnená pod značkou hlavná cesta, vyznačuje priebeh hlavnej cesty, ak hlavná cesta vedie cez križovatku inak ako priamo alebo je jej priebeh ťažko rozoznateľný. Hlavná cesta je vyznačená hrubšou čiarou.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/510.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "fdb2a6d16b5f79959608"
        },
        {
            "nl_otazka_id": "2129",
            "uv_otazka": "Prevádzkovateľ vozidla nesmie v cestnej premávke prevádzkovať vozidlo, ktoré",
            "uv_odpoveda": "má dodatočne namontované schválené svetelné zariadenia narúšajúce výhľad z miesta spolujazdca.",
            "uv_odpovedb": "sa nezhoduje so schváleným typom alebo na ktorom boli vykonané neoprávnené zásahy do vozidla, systému, komponentu alebo samostatnej technickej jednotky, alebo ktoré je dodatočne vybavené neschváleným systémom, komponentom alebo samostatnou technickou jednotkou.",
            "uv_odpovedc": "nemá aspoň kolesá prednej nápravy vybavené účinnými krytmi (blatníkmi, podbehmi).",
            "nl_okruh_id": "8",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "43debf8e9d35eee2f4fc"
        },
        {
            "nl_otazka_id": "2169",
            "uv_otazka": "Pozitívny vplyv na dĺžku brzdnej dráhy (najmä na klzkej vozovke) má vozidlo, ktoré je vybavené systémom",
            "uv_odpoveda": "ASR",
            "uv_odpovedb": "ABS",
            "uv_odpovedc": "ESP",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "eb803c1ebd4d6cdd5913"
        },
        {
            "nl_otazka_id": "2190",
            "uv_otazka": "Alkohol u vodiča  spôsobuje",
            "uv_odpoveda": "kvalitnejšie vnímanie situácie na ceste",
            "uv_odpovedb": "zlepšenie odhadu rýchlosti",
            "uv_odpovedc": "chybný odhad vzdialenosti",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "d99162a357c38df6526a"
        },
        {
            "nl_otazka_id": "2220",
            "uv_otazka": "Najčastejšou príčinou “reťazových havárií“ je",
            "uv_odpoveda": "nedodržanie bezpečnej vzdialenosti",
            "uv_odpovedb": "riskantné predchádzanie v obci a mimo obec.",
            "uv_odpovedc": "riskantná jazda v kolóne po diaľniciach,",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "74f62c54c90ff6637b66"
        },
        {
            "nl_otazka_id": "2163",
            "uv_otazka": "Najväčší vplyv na predĺženie brzdnej dráhy na mokrej vozovke majú pneumatiky, ktoré sú",
            "uv_odpoveda": "prehustené",
            "uv_odpovedb": "nadmerne opotrebované",
            "uv_odpovedc": "podhustené",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "933b1f14c9b07df5ce26"
        },
        {
            "nl_otazka_id": "2253",
            "uv_otazka": "Počas dlhej jazdy",
            "uv_odpoveda": "má vodič urobiť prestávku, ak jazdil nepretržite viac ako päť hodín.",
            "uv_odpovedb": "po diaľnici vodič nemusí čerpať prestávku,  keďže jazdenie po diaľnici nespôsobuje únavu.",
            "uv_odpovedc": "sú potrebné pravidelné prestávky, takéto prestávky má vodič robiť ešte predtým ako pocíti únavu.",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "cea98c75a5cdce11596e"
        },
        {
            "nl_otazka_id": "2329",
            "uv_otazka": "Ak sa na prístrojovom paneli rozsvieti kontrolka teploty alebo nízkej hladiny chladiacej kvapaliny, znamená to",
            "uv_odpoveda": "prehrievanie motora, čo značí jeho preťaženie alebo chýbajúcu chladiacu kvapalinu.",
            "uv_odpovedb": "nízku hladinu vody v nádržke ostrekovača. ",
            "uv_odpovedc": "nízku teplotu chladiacej kvapaliny. ",
            "nl_okruh_id": "10",
            "nl_correct": "1",
            "uv_obrazok": "obr3/tvv/34.png",
            "body": "1",
            "poznamka": "",
            "hash": "b0cb5279136061cc31de"
        },
        {
            "nl_otazka_id": "1214",
            "uv_otazka": "Vodič, ktorý sa chce vzdialiť od vozidla tak, že nebude môcť okamžite zasiahnuť, je povinný urobiť také opatrenia, aby vozidlo nemohlo ohroziť bezpečnosť a plynulosť cestnej premávky a aby ho nemohla neoprávnene použiť iná osoba. Ak je vozidlo povinne vybavené zariadením proti neoprávnenému použitiu, vodič",
            "uv_odpoveda": "je povinný ho použiť.",
            "uv_odpovedb": "je povinný ho použiť, len ak vo vozidle zostali cenné veci.",
            "uv_odpovedc": "nie je povinný ho použiť v obci.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "8993deae440f4c303a0e"
        },
        {
            "nl_otazka_id": "1160",
            "uv_otazka": "Vodič nesmie predchádzať",
            "uv_odpoveda": "vpravo za žiadnych okolností.",
            "uv_odpovedb": "na priechode pre chodcov, priechode pre cyklistov a bezprostredne pred nimi.",
            "uv_odpovedc": "ak by pritom musel podstatne zvýšiť rýchlosť jazdy.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "09554a409c10da8fa789"
        },
        {
            "nl_otazka_id": "1020",
            "uv_otazka": "Účastníkom cestnej premávky sa rozumie osoba, ktorá",
            "uv_odpoveda": "tlačí alebo ťahá ručný vozík s celkovou šírkou nepresahujúcou 600 mm.",
            "uv_odpovedb": "vedie vozidlo.",
            "uv_odpovedc": "sa priamo zúčastňuje cestnej premávky.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "2acfa6c6f86359a63c0a"
        },
        {
            "nl_otazka_id": "1394",
            "uv_otazka": "Vodič",
            "uv_odpoveda": "nesmie dovoliť, aby sa prekročil povolený počet prepravovaných osôb, a podľa svojich možností nesmie pripustiť ani porušenie povinností ustanovených týmto osobám.",
            "uv_odpovedb": "môže pripustiť, aby sa prekročil povolený počet prepravovaných osôb, najviac však o 20 %.",
            "uv_odpovedc": "môže pripustiť, aby sa prekročil povolený počet prepravovaných osôb, ak s tým vopred vyslovili súhlas všetky prepravované osoby.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "31247c4e236366bafb6b"
        },
        {
            "nl_otazka_id": "1083",
            "uv_otazka": "Dieťa vo veku troch rokov a viac s telesnou výškou menšou ako 150 cm",
            "uv_odpoveda": "sa nesmie prepravovať na prednom sedadle vozidla, ktoré nie je vybavené bezpečnostným systémom.",
            "uv_odpovedb": "smie byť prepravované na prednom sedadle vozidla len mimo obce.",
            "uv_odpovedc": "smie byť prepravované na prednom sedadle vozidla len v obci.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1cee508dc06429d04b77"
        },
        {
            "nl_otazka_id": "1590",
            "uv_otazka": "Vodič, ktorý pri otáčaní prichádza do križovatky po vedľajšej ceste označenej touto dopravnou značkou,",
            "uv_odpoveda": "je povinný dať prednosť v jazde vozidlám prichádzajúcim po hlavnej ceste.",
            "uv_odpovedb": "má prednosť v jazde.",
            "uv_odpovedc": "je povinný dať prednosť v jazde vozidlám prichádzajúcim sprava.",
            "nl_okruh_id": "2",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/202.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "9a8fdac9788b4382e262"
        },
        {
            "nl_otazka_id": "1755",
            "uv_otazka": "Smerové šípky vyznačené medzi pozdĺžnymi čiarami",
            "uv_odpoveda": "Vyznačujú koniec jazdných pruhov.",
            "uv_odpovedb": "Vyjadrujú spôsob radenia do jazdných pruhov a prikázaný smer jazdy cez nasledujúcu križovatku alebo odbočenie vo vyznačenom smere.",
            "uv_odpovedc": "Vyjadrujú odporúčaný smer jazdy vozidiel cez nasledujúcu križovatku.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/630.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "bc741ec48b1a17a7e259"
        },
        {
            "nl_otazka_id": "1710",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Označuje jazdný pruh vyhradený pre osobné automobily.",
            "uv_odpovedb": "Miesto, od ktorého platia pravidlá cestnej premávky o správaní sa na ceste pre motorové vozidlá.",
            "uv_odpovedc": "Označuje parkovisko (pozdĺžne státie).",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/311.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "36f6a01fe6204797f6eb"
        },
        {
            "nl_otazka_id": "1779",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Motorové vozidlá, ktorých najväčšia prípustná celková hmotnosť nepresahuje 3,5 t a ktoré sú konštruované a určené na prepravu najviac ôsmich osôb okrem vodiča s výnimkou motocyklov",
            "uv_odpovedb": "Zvláštne motorové vozidlá",
            "uv_odpovedc": "Motorové vozidlá, ktorých najväčšia prípustná celková hmotnosť presahuje 3,5 t",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/1-5.png",
            "body": "2",
            "poznamka": "",
            "hash": "b929bca59b15d1f17028"
        },
        {
            "nl_otazka_id": "1652",
            "uv_otazka": "Na čo upozorňuje táto dopravná značka?",
            "uv_odpoveda": "Na kríženie cesty s električkovým koľajovým pásom.",
            "uv_odpovedb": "Na križovatku, kde nie je prednosť v jazde upravená dopravnými značkami a uplatňujú sa všeobecné pravidlá o prednosti v jazde.",
            "uv_odpovedc": "Na železničné priecestie bez závor.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/136.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "5765a7176b346ca4e400"
        },
        {
            "nl_otazka_id": "1624",
            "uv_otazka": "Táto dopravná značka upozorňuje na",
            "uv_odpoveda": "Zákaz odbočovania vpravo.",
            "uv_odpovedb": "Nebezpečnú zákrutu vo vyznačenom smere, ktorej bezpečný prejazd si vyžaduje výrazné zníženie rýchlosti jazdy.",
            "uv_odpovedc": "Nebezpečné stúpanie.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/110.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "fd8fb67e114916c3213d"
        },
        {
            "nl_otazka_id": "1764",
            "uv_otazka": "Táto dopravná značka označuje miesto, od ktorého platia osobitné ustanovenia o cestnej premávke",
            "uv_odpoveda": "na bicyklovej ceste.",
            "uv_odpovedb": "na diaľnici.",
            "uv_odpovedc": "v obytnej zóne.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/323.png",
            "body": "2",
            "poznamka": "",
            "hash": "c77d25c145c93fe282e3"
        },
        {
            "nl_otazka_id": "1772",
            "uv_otazka": "Informačné dopravné značky",
            "uv_odpoveda": "ukladajú účastníkom cestnej premávky príkazy, zákazy alebo obmedzenia alebo im umožňujú výnimky zo všeobecných pravidiel cestnej premávky.",
            "uv_odpovedb": "upozorňujú účastníkov cestnej premávky na miesto, kde môže hroziť nebezpečenstvo, ktoré je ťažko včas rozoznateľné aj pri dodržaní primeranej opatrnosti.",
            "uv_odpovedc": "vymedzujú miesta, kde platia osobitné pravidlá cestnej premávky, poskytujú účastníkom cestnej premávky informácie dopravného významu alebo slúžia na ich orientáciu.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "6e291530c38a6ee50ba1"
        },
        {
            "nl_otazka_id": "1843",
            "uv_otazka": "Ako prvé prejde cez križovatku",
            "uv_odpoveda": "červené vozidlo.",
            "uv_odpovedb": "žlté vozidlo.",
            "uv_odpovedc": "vaše vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/21.png",
            "body": "4",
            "poznamka": "",
            "hash": "6bc40d3b19960c337a85"
        },
        {
            "nl_otazka_id": "1813",
            "uv_otazka": "V poradí druhé prejde cez križovatku",
            "uv_odpoveda": "vaše vozidlo odbočujúce vľavo.",
            "uv_odpovedb": "červené vozidlo odbočujúce vľavo.",
            "uv_odpovedc": "modré vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/01.png",
            "body": "4",
            "poznamka": "",
            "hash": "e9fa077a415e339ef5b7"
        },
        {
            "nl_otazka_id": "1915",
            "uv_otazka": "Účastník dopravnej nehody je povinný",
            "uv_odpoveda": "zotrvať na mieste dopravnej nehody; to neplatí, ak preukázal svoju totožnosť ostatným účastníkom dopravnej nehody.",
            "uv_odpovedb": "zotrvať na mieste dopravnej nehody až do príchodu policajta alebo sa na toto miesto bezodkladne vrátiť po poskytnutí alebo privolaní pomoci, alebo po ohlásení dopravnej nehody.",
            "uv_odpovedc": "zotrvať na mieste dopravnej nehody až do príchodu odťahovej služby.",
            "nl_okruh_id": "5",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "77e12e43c1b43c1dd4db"
        },
        {
            "nl_otazka_id": "1942",
            "uv_otazka": "Ak sa na prístrojovom paneli rozsvieti kontrolka žeravenia (žhavenia), signalizuje",
            "uv_odpoveda": "problém s filtrom pevných častíc.",
            "uv_odpovedb": "že predhrev dieselového motora, žeraviace sviečky (žhaviče) sú v činnosti.",
            "uv_odpovedc": "nefunkčnú žiarovku.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "obr3/tvv/47.png",
            "body": "2",
            "poznamka": "",
            "hash": "2216078883879c2736e2"
        },
        {
            "nl_otazka_id": "2077",
            "uv_otazka": "Osoba môže byť držiteľom",
            "uv_odpoveda": "viacerých vodičských preukazov vydaných pre každú skupinu zvlášť.",
            "uv_odpovedb": "len vodičského preukazu vydaného v krajine, do ktorej cestuje.",
            "uv_odpovedc": "len jedného vodičského preukazu, okrem medzinárodného vodičského preukazu.",
            "nl_okruh_id": "7",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "bf859947485f8dcaf076"
        },
        {
            "nl_otazka_id": "2123",
            "uv_otazka": "Ak je vozidlom neustále vytváranie kvapiek kvapalín, okrem vody, ktoré predstavuje riziko týkajúce sa životného prostredia",
            "uv_odpoveda": "možno s takýmto vozidlom jazdiť rýchlosťou neprevyšujúcou 50 km/h.",
            "uv_odpovedb": "možno takéto vozidlo používať len na účelových komunikáciách.",
            "uv_odpovedc": "považuje sa takéto vozidlo za technicky nespôsobilé na prevádzku v cestnej premávke.",
            "nl_okruh_id": "8",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "1d964b82604d06f99a1e"
        },
        {
            "nl_otazka_id": "2229",
            "uv_otazka": "Pri náhlom vyjdení vozidla na nespevnenú krajnicu vodič",
            "uv_odpoveda": "začne intenzívne brzdiť a snaží sa čo najskôr zastaviť",
            "uv_odpovedb": "uberie plyn a strhnutím volantu sa snaží vrátiť na vozovku",
            "uv_odpovedc": "nesmie začať prudko brzdiť",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "0ca4013e0a8910700116"
        },
        {
            "nl_otazka_id": "2244",
            "uv_otazka": "Ktorú zásadu by mal dodržiavať začínajúci vodič?",
            "uv_odpoveda": "Ak máte strach z niektorej križovatky, radšej ju vždy obchádzajte, aby ste nezdržiavali premávku.",
            "uv_odpovedb": "V prípade strachu z niektorej križovatky sa na ňu chodte pozrieť peši a v duchu riešte možné situácie.",
            "uv_odpovedc": "V prípade strachu z niektorej križovatky jazdite vždy podľa pravidla pravej ruky.",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ed235a314e44ed1b34a1"
        },
        {
            "nl_otazka_id": "2312",
            "uv_otazka": "Mazacia sústava má za úlohu zabezpečiť",
            "uv_odpoveda": "tvorbu karbónu pre lepšie odvádzanie tepla.",
            "uv_odpovedb": "mazanie, tesnenie, chladenie, ochranu dielov pred koróziou, čistenie a zníženie hluku motora.",
            "uv_odpovedc": "prípravu zápalnej zmesi benzínu a oleja.",
            "nl_okruh_id": "10",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "8bbbd31bd7efe25ae326"
        },
        {
            "nl_otazka_id": "2308",
            "uv_otazka": "Zápalnú zmes pre štvordobé zážihové motory tvorí",
            "uv_odpoveda": "zmes benzínu, vzduchu a oleja.",
            "uv_odpovedb": "zmes benzínu a vzduchu.",
            "uv_odpovedc": "zmes nafty a oleja.",
            "nl_okruh_id": "10",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "e590d634240f32112631"
        },
        {
            "nl_otazka_id": "1114",
            "uv_otazka": "Tam, kde sa jazdné pruhy v jednom smere jazdy zbiehajú, vodič idúci v priebežnom jazdnom pruhu je pri súbežnej jazde povinný",
            "uv_odpoveda": "umožniť vodičovi prvého vozidla nachádzajúceho sa v jazdnom pruhu, ktorý sa zaraďuje do priebežného jazdného pruhu, preradenie do priebežného jazdného pruhu, aj keď nedáva znamenie o zmene smeru jazdy.",
            "uv_odpovedb": "neumožniť vodičovi prvého vozidla nachádzajúceho sa v jazdnom pruhu, ktorý sa zaraďuje do priebežného jazdného pruhu, preradenie do priebežného jazdného pruhu, ak ten dáva znamenie o zmene smeru jazdy.",
            "uv_odpovedc": "umožniť vodičovi prvého vozidla nachádzajúceho sa v jazdnom pruhu, ktorý sa zaraďuje do priebežného jazdného pruhu, preradenie do priebežného jazdného pruhu, ak ten dáva znamenie o zmene smeru jazdy.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "847d3d47d6419daba9b8"
        },
        {
            "nl_otazka_id": "1232",
            "uv_otazka": "Vodič nesmie zastaviť a stáť na mieste, kde by vozidlo zakrývalo",
            "uv_odpoveda": "vodorovnú dopravnú značku vyznačujúcu spôsob radenia do jazdných pruhov a určený smer jazdy cez križovatku alebo vyjadrujúcu doplňujúci údaj nápisom, alebo svetelné signály.",
            "uv_odpovedb": "akúkoľvek vodorovnú dopravnú značku.",
            "uv_odpovedc": "stĺp elektrického osvetlenia.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "775c6eaaa12f399c218d"
        },
        {
            "nl_otazka_id": "1012",
            "uv_otazka": "Motorovým vozidlom sa rozumie",
            "uv_odpoveda": "nekoľajové vozidlo poháňané vlastným motorom a trolejbus.",
            "uv_odpovedb": "koľajové a nekoľajové vozidlo poháňané vlastným motorom.",
            "uv_odpovedc": "vozidlo poháňané vlastným motorom s výnimkou motocykla, traktora a motorového ručného vozíka.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "b51ef666491c0d4aa45b"
        },
        {
            "nl_otazka_id": "1141",
            "uv_otazka": "Vodič je povinný pri obchádzaní stojaceho vozidla pravidelnej verejnej dopravy osôb prihliadnuť na možnosť vbehnutia osôb na vozovku a jazdu prispôsobiť tak, aby neboli ohrozené; to",
            "uv_odpoveda": "neplatí pre vodiča vozidla jazdiaceho v protismere.",
            "uv_odpovedb": "platí aj pre vodiča vozidla jazdiaceho v protismere.",
            "uv_odpovedc": "neplatí pre vodiča motocykla a vodiča nemotorového vozidla.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "bd9c9463e064daa9e9cb"
        },
        {
            "nl_otazka_id": "1271",
            "uv_otazka": "Vodič nesmie vchádzať na železničné priecestie, ak",
            "uv_odpoveda": "sa dáva výstraha prerušovaným zvukom húkačky alebo zvončeka priecestného zabezpečovacieho zariadenia.",
            "uv_odpovedb": "protiidúci vodič dáva zvukové alebo svetelné výstražné znamenie.",
            "uv_odpovedc": "osoba pribratá na zaistenie bezpečnej prevádzky železničného priecestia dáva znamenie vodorovným kývaním ruky s červenou alebo so žltou zástavkou.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "bc0bc967885ccf0ccae3"
        },
        {
            "nl_otazka_id": "1506",
            "uv_otazka": "Z idúceho vozidla označeného príslušnosťou k Policajnému zboru a vozidla neoznačeného príslušnosťou k Policajnému zboru dáva policajt pokyn na zastavenie vozidla",
            "uv_odpoveda": "zdvihnutím ruky.",
            "uv_odpovedb": "svietidlom vyžarujúcim svetlo červenej farby, ktorým pohybuje v hornom polkruhu.",
            "uv_odpovedc": "vysunutým policajným zastavovacím terčom.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e9df5d61aad6ba35b7ac"
        },
        {
            "nl_otazka_id": "1333",
            "uv_otazka": "Ak sa pri vlečení motorového vozidla použije ako spojnica lano",
            "uv_odpoveda": "nesmie byť kratšie ako 2,5 m a dlhšie ako 6 m.",
            "uv_odpovedb": "jeho dĺžka nesmie byť menšia ako 1,5 m.",
            "uv_odpovedc": "nesmie byť vzdialenosť medzi vozidlami menšia ako 2 m.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "25215bf3c410096882db"
        },
        {
            "nl_otazka_id": "1326",
            "uv_otazka": "Pri poruche osvetlenia alebo inej poruche nedovoľujúcej použitie osvetlenia, musia byť na vlečenom vozidle za zníženej viditeľnosti rozsvietené svietidlá",
            "uv_odpoveda": "vo vnútri vozidla.",
            "uv_odpovedb": "na strane k stredu vozovky vpredu bielym neoslňujúcim svetlom a vzadu aspoň jedným červeným svetlom.",
            "uv_odpovedc": "jedným červeným svetlom vpredu aj vzadu.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "c0ec38113976e710763f"
        },
        {
            "nl_otazka_id": "1596",
            "uv_otazka": "Ak na priecestnom zabezpečovacom zariadení nesvieti prerušované biele svetlo, vo vzdialenosti 50 m pred železničným priecestím a pri jeho prechádzaní vodič je povinný jazdiť rýchlosťou najviac",
            "uv_odpoveda": "30 km.h-1.",
            "uv_odpovedb": "40 km.h-1.",
            "uv_odpovedc": "20 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "1",
            "uv_obrazok": "obr3/upp_or/27_30.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "72e0ff1b626624338a31"
        },
        {
            "nl_otazka_id": "1697",
            "uv_otazka": "Táto dopravná značka informuje",
            "uv_odpoveda": "O presmerovaní jazdných pruhov do protismerného jazdného pásu, k okraju vozovky alebo o inej zmene smerovania jazdných pruhov.",
            "uv_odpovedb": "O mieste, kde možno obchádzať vľavo električku, ktorá stojí na zastávke.",
            "uv_odpovedc": "O možnosti jazdy v protismernej časti cesty.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/441-449.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "5c6b8deac76704bff19c"
        },
        {
            "nl_otazka_id": "1720",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Pešiu zónu s možnosťou parkovania vozidiel.",
            "uv_odpovedb": "Začiatok zóny, v ktorej je státie vozidiel dovolené len na vyznačených parkovacích miestach a len za určitých podmienok uvedených na dodatkovej tabuľke.",
            "uv_odpovedc": "Vyhradené parkovisko.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/277.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "3e2b4fd3692d94502f28"
        },
        {
            "nl_otazka_id": "1692",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Odporúča rýchlosť.",
            "uv_odpovedb": "Prikazuje jazdiť rýchlosťou nie nižšou ako vyznačenou rýchlosťou v kilometroch za hodinu; to neplatí, ak je to potrebné z hľadiska bezpečnosti a plynulosti cestnej premávky. Značka zároveň ukončuje platnosť predchádzajúcej značky Najvyššia dovolená rýchlosť",
            "uv_odpovedc": "Prikazuje jazdiť rýchlosťou nie nižšou ako vyznačenou rýchlosťou v kilometroch za hodinu; to neplatí, ak je to potrebné z hľadiska bezpečnosti a plynulosti cestnej premávky. Značka zároveň ukončuje platnosť predchádzajúcej značky Najnižšia dovolená rýchlosť.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/250.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "ad373be071754075343d"
        },
        {
            "nl_otazka_id": "1819",
            "uv_otazka": "Vaše vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "posledné.",
            "uv_odpovedb": "druhé.",
            "uv_odpovedc": "prvé.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/04.png",
            "body": "4",
            "poznamka": "",
            "hash": "3a7c7fd9b763ac6fec18"
        },
        {
            "nl_otazka_id": "1937",
            "uv_otazka": "Čo patrí medzi oznamovače vozidla?",
            "uv_odpoveda": "Volant.",
            "uv_odpovedb": "Otáčkomer.",
            "uv_odpovedc": "Radiaca páka.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "09a5b1df2a2a9aa9bc7d"
        },
        {
            "nl_otazka_id": "2126",
            "uv_otazka": "Hĺbka hlavných drážok pneumatiky nemôže byť menšia ako",
            "uv_odpoveda": "1,6 mm na vozidlách ostatných kategórií okrem kategórie L 1e.",
            "uv_odpovedb": "1,6 mm na vozidlách všetkých kategórií",
            "uv_odpovedc": "1,2 mm na vozidlách kategórie L 1e.",
            "nl_okruh_id": "8",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "0471425ba0b6a64058bb"
        },
        {
            "nl_otazka_id": "2339",
            "uv_otazka": "Nedostatočné množstvo paliva signalizuje kontrolka",
            "uv_odpoveda": "mazania.",
            "uv_odpovedb": "dobíjania.",
            "uv_odpovedc": "rezervného množstva paliva.",
            "nl_okruh_id": "10",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "2e56ff846e5073ea21ed"
        },
        {
            "nl_otazka_id": "1277",
            "uv_otazka": "Ak osoba pribratá na zaistenie bezpečnej prevádzky železničného priecestia dáva znamenie na zastavenie vozidla za zníženej viditeľnosti krúžením červeným svetlom v hornom polkruhu, vodič",
            "uv_odpoveda": "smie vchádzať na železničné priecestie.",
            "uv_odpovedb": "je povinný vchádzať na železničné priecestie zvýšenou rýchlosťou.",
            "uv_odpovedc": "nesmie vchádzať na železničné priecestie.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "099decdaa8e069d206ff"
        },
        {
            "nl_otazka_id": "1497",
            "uv_otazka": "Ak policajt pri riadení cestnej premávky dáva pokyn kývaním ruky hore a dolu, znamená to",
            "uv_odpoveda": "zrýchliť jazdu.",
            "uv_odpovedb": "spomaliť jazdu.",
            "uv_odpovedc": "zastaviť vozidlo.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "obr3/pcp/22_3_2.png",
            "body": "3",
            "poznamka": "",
            "hash": "457b541af92f223bd4bc"
        },
        {
            "nl_otazka_id": "1269",
            "uv_otazka": "Vodič nesmie vchádzať na železničné priecestie, ak",
            "uv_odpoveda": "osoba pribratá na zaistenie bezpečnej prevádzky železničného priecestia dáva pokyn vodorovným kývaním ruky s červenou zástavkou cez stred tela.",
            "uv_odpovedb": "sa dáva výstraha dvoma červenými striedavo prerušovanými svetlami priecestného zabezpečovacieho zariadenia.",
            "uv_odpovedc": "svieti prerušované biele svetlo.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "9140a9e36f49a22583c1"
        },
        {
            "nl_otazka_id": "1011",
            "uv_otazka": "Križovatkou s riadenou premávkou sa rozumie",
            "uv_odpoveda": "križovatka, na ktorej je premávka riadená dopravnými zariadeniami alebo policajtom, prípadne inou oprávnenou osobou.",
            "uv_odpovedb": "každé miesto, v ktorom sa cesty pretínajú alebo spájajú.",
            "uv_odpovedc": "každé miesto, na ktorom je premávka riadená policajtom alebo inou osobou oprávnenou dávať pokyny na zastavenie vozidla.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e9c2936db864c59c2547"
        },
        {
            "nl_otazka_id": "1372",
            "uv_otazka": "Zvláštne výstražné svetlo červenej farby a kombináciu zvláštneho výstražného svetla červenej a modrej farby môže používať",
            "uv_odpoveda": "len vozidlo Slovenskej informačnej služby.",
            "uv_odpovedb": "len vozidlo Policajného zboru.",
            "uv_odpovedc": "vozidlo obecnej polície.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "9640ee02575bb40e280c"
        },
        {
            "nl_otazka_id": "1261",
            "uv_otazka": "Vodič pred železničným priecestím",
            "uv_odpoveda": "musí zaradiť druhý rýchlostný stupeň.",
            "uv_odpovedb": "je povinný počínať si mimoriadne opatrne, najmä sa presvedčiť, či môže bezpečne prejsť cez železničné priecestie.",
            "uv_odpovedc": "je povinný zastaviť vozidlo na takom mieste, z ktorého má náležitý rozhľad na trať.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3144c6382b675bb4be2a"
        },
        {
            "nl_otazka_id": "1193",
            "uv_otazka": "Vodič smie cúvať",
            "uv_odpoveda": "na železničnom priecestí, ak nie je v blízkosti vlak.",
            "uv_odpovedb": "na jednosmernej ceste, ak je to nevyhnutne potrebné najmä na zaradenie do radu stojacich vozidiel alebo na vyjdenie z neho.",
            "uv_odpovedc": "v tuneli.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "06709d5da52c1f4e3fa6"
        },
        {
            "nl_otazka_id": "1587",
            "uv_otazka": "Vodič pri vchádzaní na cestu z pozemku ležiaceho vedľa cesty, areálu s obmedzeným prístupom, oploteného objektu, garáže, parkoviska, obratiska električiek a podobných miest, z poľnej cesty, z lesnej cesty, z cestičky pre cyklistov, z obytnej zóny alebo z pešej zóny",
            "uv_odpoveda": "nie je povinný dať prednosť v jazde vozidlu idúcemu po ceste.",
            "uv_odpovedb": "je povinný zastaviť vozidlo.",
            "uv_odpovedc": "je povinný dať prednosť v jazde vozidlu idúcemu po ceste.",
            "nl_okruh_id": "2",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1d5d91cf019efb6d6ccc"
        },
        {
            "nl_otazka_id": "1672",
            "uv_otazka": "Táto dopravná značka znamená:",
            "uv_odpoveda": "Zákaz otáčania.",
            "uv_odpovedb": "Prikázaný smer jazdy.",
            "uv_odpovedc": "Koniec zákazu otáčania.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/216.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "1578f7466119a291aed2"
        },
        {
            "nl_otazka_id": "1629",
            "uv_otazka": "Táto dopravná značka upozorňuje na",
            "uv_odpoveda": "Nerovnosti povrchu vozovky ako sú výtlky, výmole alebo vyjazdené koľaje.",
            "uv_odpovedb": "Zdvíhací most.",
            "uv_odpovedc": "Tunel.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/115.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "729ee0aa04e07bc30521"
        },
        {
            "nl_otazka_id": "1671",
            "uv_otazka": "Táto dopravná značka znamená:",
            "uv_odpoveda": "Zákaz otáčania.",
            "uv_odpovedb": "Prikázaný smer jazdy vpravo.",
            "uv_odpovedc": "Zákaz odbočenia vo vyznačenom smere.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/215.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "5b0bd73720d2bc4a8275"
        },
        {
            "nl_otazka_id": "1835",
            "uv_otazka": "Vozidlá prejdú cez križovatku v tomto poradí:",
            "uv_odpoveda": "1. červené, 2. zelené, 3. vaše vozidlo.",
            "uv_odpovedb": "1. zelené, 2. vaše vozidlo, 3. červené.",
            "uv_odpovedc": "1. vaše vozidlo, 2. červené, 3. zelené.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/17.png",
            "body": "4",
            "poznamka": "",
            "hash": "d336cbaeaef80fc128d4"
        },
        {
            "nl_otazka_id": "1858",
            "uv_otazka": "Červené vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "prvé.",
            "uv_odpovedb": "druhé.",
            "uv_odpovedc": "posledné.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/28.png",
            "body": "4",
            "poznamka": "",
            "hash": "f23f9a57bcd4b8a0417d"
        },
        {
            "nl_otazka_id": "1917",
            "uv_otazka": "Účastník dopravnej nehody je povinný urobiť vhodné opatrenia, aby",
            "uv_odpoveda": "ostatní účastníci dopravnej nehody zotrvali na mieste dopravnej nehody až do príchodu policajta.",
            "uv_odpovedb": "ostatní účastníci dopravnej nehody nemohli požiť alkohol.",
            "uv_odpovedc": "nebola ohrozená bezpečnosť cestnej premávky na mieste dopravnej nehody.",
            "nl_okruh_id": "5",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "d34cabc3ec10d25abd5c"
        },
        {
            "nl_otazka_id": "1952",
            "uv_otazka": "Nastavenie spätných zrkadiel sa vykonáva",
            "uv_odpoveda": "ihneď po nastúpení do vozidla.",
            "uv_odpovedb": "až po rozjazde vozidla.",
            "uv_odpovedc": "po nastavení správnej polohy sedadla vodiča.",
            "nl_okruh_id": "6",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "8afd5c3059817ff84ae4"
        },
        {
            "nl_otazka_id": "2120",
            "uv_otazka": "Pneumatiky nemôžu mať na svojom vonkajšom obvode, najmä v oblasti behúňa, bočnice a pätky pneumatiky",
            "uv_odpoveda": "trhliny ani poškodenia, ktoré narúšajú kordovú kostru; jej obnaženie nie je závadou.",
            "uv_odpovedb": "trhliny ani poškodenia, ktoré obnažujú kordovú kostru alebo ju narúšajú.",
            "uv_odpovedc": "trhliny, ktoré obnažujú kordovú kostru; jej poškodenie nie je závadou.",
            "nl_okruh_id": "8",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "71da22291095c3dc01a6"
        },
        {
            "nl_otazka_id": "2235",
            "uv_otazka": "Pri preprave materiálu na prípojnom vozidle za osobným vozidlom musí byť náklad",
            "uv_odpoveda": "rovnomerne rozložený",
            "uv_odpovedb": "rozložený tak, aby rovnomerne zaťažoval kolesá t.j. približne 50% prednú a 50% zadnú nápravu",
            "uv_odpovedc": "rozložený tak, aby zaťažoval čo najviac zadnú nápravu vozidla",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "edf8cb5ebae99af5beab"
        },
        {
            "nl_otazka_id": "2228",
            "uv_otazka": "Železničné priecestia okrem dopravného značenia",
            "uv_odpoveda": "sa označujú jedinečným identifikačným číslom (JIČ), umiestneným na dopravnom značení zo strany od železničnej trate",
            "uv_odpovedb": "sú vybavené telefónom núdzového volania",
            "uv_odpovedc": "sú vybavené výstražným svetlom žltej farby",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "0f9ebe3010fb3d242f06"
        },
        {
            "nl_otazka_id": "2216",
            "uv_otazka": "Pre zvládnutie pretáčavého šmyku je potrebné",
            "uv_odpoveda": "ubrať plyn, stlačiť pedál spojky a zatočiť volantom tým smerom, ktorým sa vychýlila zadná časť vozidla (kontra). Ak sa šmyk vyrovná, tak vyrovnáme aj volant, aby sme nevyvolali šmyk na opačnú stranu.",
            "uv_odpovedb": "prudko pridať plyn a prudko stočiť volant proti smeru šmyku.",
            "uv_odpovedc": "stlačiť pedál prevádzkovej brzdy.",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "b21dc71ffaab6934385e"
        },
        {
            "nl_otazka_id": "1142",
            "uv_otazka": "Predchádza sa",
            "uv_odpoveda": "vľavo; vpravo sa predchádza vozidlo, ktoré mení smer jazdy vľavo a ak už nie je pochybnosť o ďalšom smere jeho jazdy.",
            "uv_odpovedb": "vpravo; vľavo sa predchádza len motocykel.",
            "uv_odpovedc": "vľavo za každých okolností.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3842154ed3eb5ed84b42"
        },
        {
            "nl_otazka_id": "1143",
            "uv_otazka": "Pri jazde v pripájacom alebo v odbočovacom jazdnom pruhu sa",
            "uv_odpoveda": "nesmie jazdiť vyššou rýchlosťou, ako jazdia vozidlá v priebežnom jazdnom pruhu.",
            "uv_odpovedb": "smie vpravo predchádzať aj vozidlo idúce v priebežnom jazdnom pruhu.",
            "uv_odpovedc": "nesmie vpravo predchádzať vozidlo idúce v priebežnom jazdnom pruhu.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "a3b40e1609a72ea382c2"
        },
        {
            "nl_otazka_id": "1259",
            "uv_otazka": "Ak pri jazde vozidla v tuneli vznikne porucha vozidla, pre ktorú sa toto vozidlo stane nepojazdným, alebo ak vznikne dopravná nehoda vrátane požiaru, po zastavení vozidla vodič a prepravovaná osoba",
            "uv_odpoveda": "nesmie fajčiť alebo manipulovať s otvoreným ohňom.",
            "uv_odpovedb": "smie fajčiť.",
            "uv_odpovedc": "smie manipulovať s otvoreným ohňom.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "98bbaec4e65815dbedc9"
        },
        {
            "nl_otazka_id": "1439",
            "uv_otazka": "Osoba so zdravotným postihnutím, ktorá sa pohybuje pomocou ručného alebo motorového vozíka určeného pre ňu, smie používať",
            "uv_odpoveda": "ktorúkoľvek krajnicu alebo ktorýkoľvek okraj vozovky.",
            "uv_odpovedb": "len pravú krajnicu.",
            "uv_odpovedc": "len ľavú krajnicu.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1ca814c4c6a5b6e3b031"
        },
        {
            "nl_otazka_id": "1330",
            "uv_otazka": "Za motorovým vozidlom s prívesom sa",
            "uv_odpoveda": "smie vliecť len jedno motorové vozidlo.",
            "uv_odpovedb": "smie vliecť len motocykel.",
            "uv_odpovedc": "nesmie vliecť iné motorové vozidlo.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "8d1814be1092c8ed3bee"
        },
        {
            "nl_otazka_id": "1622",
            "uv_otazka": "Na bicyklovej ceste sa smie jazdiť rýchlosťou najviac",
            "uv_odpoveda": "30 km.h-1.",
            "uv_odpovedb": "50 km.h-1.",
            "uv_odpovedc": "10 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "8edef27838ccd80aca12"
        },
        {
            "nl_otazka_id": "1906",
            "uv_otazka": "Za dopravnú nehodu sa považuje aj škodová udalosť, ak",
            "uv_odpoveda": "sa účastníci škodovej udalosti nedohodli na jej zavinení.",
            "uv_odpovedb": "v niektorom zo zúčastnených vozidiel došlo k poškodeniu prepravovaných vecí.",
            "uv_odpovedc": "ku škodovej udalosti došlo na diaľnici alebo rýchlostnej ceste.",
            "nl_okruh_id": "5",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "d9a6713c4902c5fe94e8"
        },
        {
            "nl_otazka_id": "1979",
            "uv_otazka": "Z hľadiska ekológie je potrebné pri výbere vozidla zohľadniť najmä",
            "uv_odpoveda": "druh karosérie vozidla.",
            "uv_odpovedb": "druh klimatizácie.",
            "uv_odpovedc": "druh paliva.",
            "nl_okruh_id": "6",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "61b043f34557881ac201"
        },
        {
            "nl_otazka_id": "2142",
            "uv_otazka": "Ak je prasklina alebo chyba zvarov na diskoch kolies",
            "uv_odpoveda": "možno s takýmto vozidlom jazdiť rýchlosťou neprevyšujúcou 60 km/h.",
            "uv_odpovedb": "vozidlo sa považuje za technicky nespôsobilé na prevádzku v cestnej premávke.",
            "uv_odpovedc": "možno s takýmto vozidlom jazdiť len na miestnych komunikáciách.",
            "nl_okruh_id": "8",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "536b3d1ddc8405347e1e"
        },
        {
            "nl_otazka_id": "2178",
            "uv_otazka": "Vodič sa musí riadiť nasledovnou zásadou",
            "uv_odpoveda": "Brať ohľad na vodiča predchádzaného vozidla, avšak len mimo obce.",
            "uv_odpovedb": "Brať ohľad najmä na vodiča protiidúceho vozidla; to neplatí pre vodiča električky .",
            "uv_odpovedc": "Správať sa ohľaduplne ku každému účastníkovi cestnej premávky.",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3e55918240ca74e169d6"
        },
        {
            "nl_otazka_id": "2234",
            "uv_otazka": "Charakteristickým chybným jednaním detí v cestnej premávke je, že",
            "uv_odpoveda": "zostanú na vozovke stáť, otočia sa a idú naspäť",
            "uv_odpovedb": "sú nepozorné a vedome porušujú pravidlá cestnej premávky",
            "uv_odpovedc": "náhle vbehnú na vozovku spoza stojaceho autobusu, električky alebo spoza parkujúcich vozidiel",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "b7c9fcf42ae83e28c226"
        },
        {
            "nl_otazka_id": "2227",
            "uv_otazka": "Vodič pri prejazde železničným priecestím",
            "uv_odpoveda": "by mal pred vjazdom na priecestie použiť svetelné výstražne znamenie",
            "uv_odpovedb": "by nemal preraďovať rýchlostné stupne",
            "uv_odpovedc": "by mal zvýšiť rýchlosť jazdy",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "130ab6d7badd844f20d3"
        },
        {
            "nl_otazka_id": "2215",
            "uv_otazka": "Pre zvládnutie nedotáčavého šmyku je potrebné",
            "uv_odpoveda": "stlačiť pedál spojky a následne prevádzkovú brzdu",
            "uv_odpovedb": "stlačiť pedál prevádzkovej brzdy",
            "uv_odpovedc": "stlačiť pedál spojky a korigovať pohyb vozidla otáčaním volantu",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "11da4b5c0da7278113bb"
        },
        {
            "nl_otazka_id": "2327",
            "uv_otazka": "Doba práce motora, ktorá je zdrojom pohybu, sa nazýva",
            "uv_odpoveda": "výbuch (expanzia).",
            "uv_odpovedb": "stláčanie (kompresia).",
            "uv_odpovedc": "nasávanie.",
            "nl_okruh_id": "10",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "7fbed92548f56591dc1d"
        },
        {
            "nl_otazka_id": "1121",
            "uv_otazka": "Ak jazdné pruhy nie sú na vozovke vyznačené, jazdným pruhom sa rozumie časť vozovky dovoľujúca jazdu vozidiel",
            "uv_odpoveda": "najmenej s dvoma kolesami v jazdnom prúde za sebou.",
            "uv_odpovedb": "s troma a viacerými kolesami v jazdnom prúde za sebou.",
            "uv_odpovedc": "len s dvoma kolesami v jazdnom prúde za sebou.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1835652fbf5a10eb70d3"
        },
        {
            "nl_otazka_id": "1436",
            "uv_otazka": "Smie vodič bicykla zastaviť a stáť na chodníku?",
            "uv_odpoveda": "Áno, ak ide o zastavenie alebo státie vozidla, ktoré neprekročí tri minúty.",
            "uv_odpovedb": "Áno, ak ide o zastavenie alebo státie bicykla, pri ktorom ostane voľná šírka chodníka najmenej 1,5 m.",
            "uv_odpovedc": "Nie, to sa vzťahuje aj na núdzové státie.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "721c45bbb4adfc04ea0d"
        },
        {
            "nl_otazka_id": "1443",
            "uv_otazka": "Ak sa chodec nachádza na vozovke a prichádzajú vozidlá s právom prednostnej jazdy",
            "uv_odpoveda": "má prednosť pred týmito vozidlami, ak prechádza cez priechod pre chodcov.",
            "uv_odpovedb": "musí vozidlám bez meškania uvoľniť priestor na prejazd.",
            "uv_odpovedc": "nesmú ho ich vodiči obmedziť, ak prechádza cez priechod pre chodcov.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5021662cb21b671bae11"
        },
        {
            "nl_otazka_id": "1157",
            "uv_otazka": "Vodič nesmie predchádzať",
            "uv_odpoveda": "na moste.",
            "uv_odpovedb": "pri jazde cez križovatku a v takej vzdialenosti pred križovatkou, ktorá nie je dostatočná na bezpečné dokončenie predchádzania pred úrovňou križovatky; to neplatí pri predchádzaní motocykla bez postranného vozíka alebo nemotorového vozidla.",
            "uv_odpovedc": "pri jazde cez križovatku; to neplatí pri predchádzaní zvláštneho motorového vozidla a bicykla.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "36feee4e1a866e7ff054"
        },
        {
            "nl_otazka_id": "1015",
            "uv_otazka": "Neobmedzením sa rozumie povinnosť",
            "uv_odpoveda": "spolujazdca nerozptyľovať pozornosť vodiča hlasitým hovorom, telefonovaním, pitím nápojov alebo inou obdobnou činnosťou.",
            "uv_odpovedb": "účastníka cestnej premávky počínať si tak, aby inému účastníkovi cestnej premávky neprekážal.",
            "uv_odpovedc": "vodiča za žiadnych okolností nevyrušovať vodiča predchádzaného vozidla dávaním zvukového výstražného znamenia alebo svetelného výstražného znamenia.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "56d88ea2b3a9f8c04f86"
        },
        {
            "nl_otazka_id": "1332",
            "uv_otazka": "Ak sa pri vlečení motorového vozidla použije ako spojnica tyč",
            "uv_odpoveda": "nesmie byť kratšia ako 1 m a dlhšia ako 6 m.",
            "uv_odpovedb": "jej dĺžka musí byť väčšia ako 3 m.",
            "uv_odpovedc": "nesmie byť vzdialenosť medzi vozidlami menšia ako 0,5 m.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "6ee6c45ec54185ce91e2"
        },
        {
            "nl_otazka_id": "1610",
            "uv_otazka": "Akou rýchlosťou môže jazdiť vodič osobného automobilu v obytnej zóne?",
            "uv_odpoveda": "Najviac 20 km.h-1.",
            "uv_odpovedb": "Vjazd  vozidiel do obytnej zóny je zakázaný.",
            "uv_odpovedc": "30 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "b3fd00974ecd494536b6"
        },
        {
            "nl_otazka_id": "1766",
            "uv_otazka": "Toto doplnkové zariadenie k svetelným signálom pri riadení cestnej premávky v križovatke umožňuje vodičovi",
            "uv_odpoveda": "zastaviť vozidlo.",
            "uv_odpovedb": "otočiť sa v križovatke s riadenou premávkou, ak svieti signál zelenej farby.",
            "uv_odpovedc": "kedykoľvek sa otočiť sa v križovatke s riadenou premávkou.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/731.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "7993dfb0dfbd278c04c6"
        },
        {
            "nl_otazka_id": "1785",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Malé elektrické vozidlá",
            "uv_odpovedb": "Motorové vozidlá, ktorých jediným zdrojom energie je elektrina alebo hybridné elektrické vozidlá s možnosťou externého nabíjania",
            "uv_odpovedc": "Dobíjacia stanica pre elektromobily",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/1-14.png",
            "body": "2",
            "poznamka": "",
            "hash": "95367a3900c9c681a9f9"
        },
        {
            "nl_otazka_id": "1642",
            "uv_otazka": "Táto dopravná značka upozorňuje na",
            "uv_odpoveda": "Odletujúci štrk.",
            "uv_odpovedb": "Zvýšené riziko výskytu kameňov v dôsledku zosuvu alebo padania kameňov na vozovku.",
            "uv_odpovedc": "Nespevnenú krajnicu.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/125.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "909188ba2563160894f8"
        },
        {
            "nl_otazka_id": "1655",
            "uv_otazka": "Čo znamená táto dopravná značka?",
            "uv_odpoveda": "Značka informuje o výjazde z cesty pre motorové vozidlá.",
            "uv_odpovedb": "Koniec vedľajšej cesty; značka upozorňuje na to, že na najbližšej križovatke má vodič prednosť v jazde",
            "uv_odpovedc": "Značka informuje o ukončení hlavnej cesty",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/303.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "64f2e1e2c417330f688d"
        },
        {
            "nl_otazka_id": "1868",
            "uv_otazka": "Vozidlá prejdú cez križovatku v tomto poradí:",
            "uv_odpoveda": "1. červené, 2. vaše vozidlo, 3. zelené, 4. modré",
            "uv_odpovedb": "1. vaše vozidlo, 2. červené, 3. modré, 4. zelené.",
            "uv_odpovedc": "1. vaše vozidlo, 2. červené, 3. zelené, 4. modré.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/10.png",
            "body": "4",
            "poznamka": "",
            "hash": "b5ee360bd15f0d8cf639"
        },
        {
            "nl_otazka_id": "1895",
            "uv_otazka": "V poradí druhé prejde okolo prekážky",
            "uv_odpoveda": "žlté vozidlo.",
            "uv_odpovedb": "červené vozidlo.",
            "uv_odpovedc": "modré vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/37.png",
            "body": "4",
            "poznamka": "",
            "hash": "0340342968bca681daad"
        },
        {
            "nl_otazka_id": "1959",
            "uv_otazka": "Pri úplnom zošliapnutí pedála spojky dosiahneme",
            "uv_odpoveda": "spojenie motora a prevodovky.",
            "uv_odpovedb": "krátkodobé prerušenie prenosu krútiaceho momentu z motora na prevodovku.",
            "uv_odpovedc": "spojenie prevodovky s hnacou nápravou vozidla.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "21a55046c2751f7f8faa"
        },
        {
            "nl_otazka_id": "2111",
            "uv_otazka": "Prevádzkou vozidla nesmie byť ohrozená",
            "uv_odpoveda": "bezpečnosť a plynulosť cestnej premávky a životné prostredie.",
            "uv_odpovedb": "plynulosť cestnej premávky, životné prostredie alebo verejné zdravie.",
            "uv_odpovedc": "bezpečnosť, životné prostredie alebo verejné zdravie a nesmie dôjsť k poškodzovaniu ciest.",
            "nl_okruh_id": "8",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "9eabb6c1513eae87ba9c"
        },
        {
            "nl_otazka_id": "2205",
            "uv_otazka": "Ak už k aquaplaningu došlo, je potrebné",
            "uv_odpoveda": "brzdiť prerušovane",
            "uv_odpovedb": "uvoľniť plynový pedál, nebrzdiť ani neotáčať volantom a čakať, kým dôjde k obnoveniu kontaktu pneumatík s vozovkou",
            "uv_odpovedc": "stlačiť plynový pedál a zrýchľovať, kým nedôjde k obnoveniu kontaktu pneumatík s vozovkou.",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "27f13deea9e2e56b89c6"
        },
        {
            "nl_otazka_id": "2313",
            "uv_otazka": "Množstvo motorového oleja v motore meriame",
            "uv_odpoveda": "hustomerom.",
            "uv_odpovedb": "mierkou (meracou tyčkou).",
            "uv_odpovedc": "nie je potrebná kontrola motorového oleja.",
            "nl_okruh_id": "10",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "f007178581ed2b74de23"
        },
        {
            "nl_otazka_id": "1263",
            "uv_otazka": "Vozidlá pred železničným priecestím",
            "uv_odpoveda": "musia zastaviť, ak svieti prerušované biele svetlo.",
            "uv_odpovedb": "musia vždy zastaviť.",
            "uv_odpovedc": "sa radia za sebou v poradí, v ktorom prišli.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "068c5bb2edeec2b04860"
        },
        {
            "nl_otazka_id": "1267",
            "uv_otazka": "Na príkaz dopravnej značky je vodič povinný",
            "uv_odpoveda": "zastaviť pri tejto dopravnej značke.",
            "uv_odpovedb": "zastaviť vozidlo na takom mieste, z ktorého má náležitý rozhľad na trať.",
            "uv_odpovedc": "zastaviť vozidlo len vtedy, ak prichádza vlak alebo iné dráhové vozidlo.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "obr3/pcp/27_5.png",
            "body": "3",
            "poznamka": "",
            "hash": "9ba8078439e2620df994"
        },
        {
            "nl_otazka_id": "1004",
            "uv_otazka": "Chodcom sa rozumie účastník cestnej premávky pohybujúci sa pešo; chodcom je aj osoba, ktorá",
            "uv_odpoveda": "jazdí na bicykli alebo tlačí bicykel alebo motocykel.",
            "uv_odpovedb": "jazdí na bicykli, motocykli alebo na kolobežke s pomocným motorčekom.",
            "uv_odpovedc": "tlačí bicykel, motocykel alebo kolobežku s pomocným motorčekom.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f23825d2fb7aef3b858b"
        },
        {
            "nl_otazka_id": "1446",
            "uv_otazka": "Mimo priechodu pre chodcov sa smie cez vozovku prechádzať len",
            "uv_odpoveda": "kolmo na jej os.",
            "uv_odpovedb": "mimo obce.",
            "uv_odpovedc": "v obytnej a školskej zóne a kolmo na os vozovky.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "df17101338d2192f5671"
        },
        {
            "nl_otazka_id": "1286",
            "uv_otazka": "Ste povinný dávať znamenie o zmene smeru jazdy vľavo, ak pokračujete ďalej doľava po hlavnej ceste vyznačenej na dodatkovej tabuľke?",
            "uv_odpoveda": "Áno, vyžaduje to bezpečnosť cestnej premávky a zmena smeru jazdy.",
            "uv_odpovedb": "Nie, nakoľko to nevyžaduje bezpečnosť cestnej premávky.",
            "uv_odpovedc": "Nie, vodič pokračujúci v jazde po hlavnej ceste znamenie o zmene smeru jazdy nedáva.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "obr3/pcp/30_1.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "08efb01b1fcad162b282"
        },
        {
            "nl_otazka_id": "1076",
            "uv_otazka": "Prevádzkovateľ vozidla je povinný zabezpečiť, aby sa farebné vyhotovenie a označenie vozidla nedalo zameniť s farebným vyhotovením a označením vozidiel",
            "uv_odpoveda": "určených pre predsedov vyšších územných celkov.",
            "uv_odpovedb": "Policajného zboru, Vojenskej polície, Zboru väzenskej a justičnej stráže, obecnej polície, Hasičského a záchranného zboru alebo ostatných hasičských jednotiek.",
            "uv_odpovedc": "cudzích zastupiteľských úradov so sídlom na území Slovenskej republiky.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "09e75620aa0bf02ce4d2"
        },
        {
            "nl_otazka_id": "1355",
            "uv_otazka": "Vodič motorového vozidla nesmie na diaľnici predchádzať iné motorové vozidlo",
            "uv_odpoveda": "za hmly, sneženia a hustého dažďa.",
            "uv_odpovedb": "ak by pri predchádzaní svojou výrazne nižšou rýchlosťou obmedzil iné motorové vozidlo.",
            "uv_odpovedc": "ak nemá rozhľad na vzdialenosť najmenej 200 m.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "775ba6908185e44c2507"
        },
        {
            "nl_otazka_id": "1195",
            "uv_otazka": "Na jednosmernej ceste vodič smie",
            "uv_odpoveda": "cúvať, ak je to nevyhnutne potrebné najmä na zaradenie do radu stojacich vozidiel alebo na vyjdenie z neho.",
            "uv_odpovedb": "ohroziť ostatných účastníkov cestnej premávky.",
            "uv_odpovedc": "sa otáčať.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "d4e37b938cf4e15608b8"
        },
        {
            "nl_otazka_id": "1572",
            "uv_otazka": "Na úseku cesty označenom touto dopravnou značkou vodič motorového vozidla",
            "uv_odpoveda": "smie jazdiť rýchlosťou najmenej 50 km.h-1.",
            "uv_odpovedb": "nesmie vyznačenú rýchlosť jazdy prekročiť.",
            "uv_odpovedc": "smie jazdiť rýchlosťou najviac 50 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "1",
            "uv_obrazok": "obr3/upp_or/c_15.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "d74f468b8f290eff88bd"
        },
        {
            "nl_otazka_id": "1778",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Obytné automobily",
            "uv_odpovedb": "Motorové vozidlá, ktoré sú konštruované a určené na prepravu viac ako ôsmich osôb okrem vodiča",
            "uv_odpovedc": "Električky",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/1-4.png",
            "body": "2",
            "poznamka": "",
            "hash": "522b8d32b60ca76509fb"
        },
        {
            "nl_otazka_id": "1639",
            "uv_otazka": "Táto dopravná značka upozorňuje na",
            "uv_odpoveda": "Častý výskyt hnaných hospodárskych zvierat na ceste.",
            "uv_odpovedb": "Miesto kde je zákaz hnania zvierat cez cestu.",
            "uv_odpovedc": "Zver.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/146.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "a65a6612e9c9d5528ea3"
        },
        {
            "nl_otazka_id": "1738",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Obec v jazyku národnostnej menšiny na začiatku obce.",
            "uv_odpovedb": "Miesto, od ktorého platia pravidlá cestnej premávky o správaní sa v obci a informuje o názve obce alebo miestnej časti obce tvoriacej samostatný sídelný útvar.",
            "uv_odpovedc": "Obytnú zónu.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/305.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "9947d2b7654f6bf7015c"
        },
        {
            "nl_otazka_id": "1648",
            "uv_otazka": "Táto dopravná značka upozorňuje na",
            "uv_odpoveda": "Zvýšené riziko silného alebo nárazového vetra.",
            "uv_odpovedb": "Úsek cesty, na ktorom sa vykonávajú meteorologické merania.",
            "uv_odpovedc": "Blízkosť letiska.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/126.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "b87ff340b04b8b402a90"
        },
        {
            "nl_otazka_id": "1732",
            "uv_otazka": "Táto dopravná značka informuje",
            "uv_odpoveda": "O názve najbližšej križovatky.",
            "uv_odpovedb": "O smere a dĺžke obchádzky.",
            "uv_odpovedc": "O odbočení k zvláštnym cieľom alebo v obci informuje o odbočení k miestnym cieľom.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/366.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "e39d1fe3c757bb34f270"
        },
        {
            "nl_otazka_id": "1891",
            "uv_otazka": "Ak svieti signál zelenej farby v tvare šípky doľava umiestnený v protiľahlom rohu križovatky,",
            "uv_odpoveda": "vodič červeného vozidla odbočujúceho vľavo má prednosť vjazde pred protiidúcim zeleným a žltým vozidlom a obidvoma električkami.",
            "uv_odpovedb": "vodiči protiidúceho zeleného a žltého vozidla a protiidúcej električky majú prednosť pred červeným vozidlom.",
            "uv_odpovedc": "vodič červeného vozidla odboču- júceho vľavo je povinný dať pred- nosť v jazde protiidúcemu zelené- mu a žltému vozidlu a električkám idúcim z obidvoch smerov.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "2023/2000001_DS35.jpg",
            "body": "4",
            "poznamka": "",
            "hash": "c1663cb1fc310d1cb70b"
        },
        {
            "nl_otazka_id": "1852",
            "uv_otazka": "Zelené vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "druhé.",
            "uv_odpovedb": "prvé.",
            "uv_odpovedc": "tretie.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/25.png",
            "body": "4",
            "poznamka": "",
            "hash": "7c32a7c7fb00133f7a88"
        },
        {
            "nl_otazka_id": "1853",
            "uv_otazka": "Vozidlá prejdú cez križovatku v tomto poradí:",
            "uv_odpoveda": "1. červené, 2. modré, 3. zelené.",
            "uv_odpovedb": "1. zelené. 2. modré. 3. červené.",
            "uv_odpovedc": "1. modré, 2. zelené, 3. červené.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/26.png",
            "body": "4",
            "poznamka": "",
            "hash": "2744a6149ca2549703cd"
        },
        {
            "nl_otazka_id": "1828",
            "uv_otazka": "Ako posledné prejde cez križovatku",
            "uv_odpoveda": "zelené vozidlo.",
            "uv_odpovedb": "červené vozidlo.",
            "uv_odpovedc": "vaše vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/13.png",
            "body": "4",
            "poznamka": "",
            "hash": "8d1acfce0b1fe3919015"
        },
        {
            "nl_otazka_id": "1909",
            "uv_otazka": "Vodič je povinný zdržať sa požitia alkoholu alebo inej návykovej látky po nehode",
            "uv_odpoveda": "len vtedy, ak dopravnú nehodu zavinil.",
            "uv_odpovedb": "v čase, pokiaľ neumožnil obnovenie premávky, najmä premávky vozidiel pravidelnej verejnej dopravy osôb.",
            "uv_odpovedc": "v čase, keď by to bolo na ujmu zistenia, či pred jazdou alebo počas jazdy požil alkohol alebo inú návykovú látku.",
            "nl_okruh_id": "5",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "7d281d07437cb56a576e"
        },
        {
            "nl_otazka_id": "1977",
            "uv_otazka": "Pri používaní  spotrebičov vo vozidle najviac zvýši spotrebu paliva zapnutie",
            "uv_odpoveda": "klimatizácie.",
            "uv_odpovedb": "rádioprijímača.",
            "uv_odpovedc": "diaľkových svetlometov.",
            "nl_okruh_id": "6",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "5e616c1f2e6b51f9be76"
        },
        {
            "nl_otazka_id": "2088",
            "uv_otazka": "Držiteľ vodičského preukazu je povinný oznámiť orgánu Policajného zboru do 7 dní",
            "uv_odpoveda": "len odcudzenie vodičského preukazu na území iného štátu.",
            "uv_odpovedb": "zničenie, stratu alebo odcudzenie vodičského preukazu.",
            "uv_odpovedc": "poškodenie a zneužitie vodičského preukazu.",
            "nl_okruh_id": "7",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "d28b78784ec982d664e9"
        },
        {
            "nl_otazka_id": "2343",
            "uv_otazka": "Vzduchové chladenie sa",
            "uv_odpoveda": "používa na chladenie motorov osobných vozidiel.",
            "uv_odpovedb": "používa na chladenie u niektorých typov motocyklových motorov.",
            "uv_odpovedc": "v dnešnej dobe už nepoužíva.",
            "nl_okruh_id": "10",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "335086b9e026ba1bc9ef"
        },
        {
            "nl_otazka_id": "1171",
            "uv_otazka": "Pred odbočovaním vpravo je vodič povinný",
            "uv_odpoveda": "pred zaradením sa k pravému okraju vozovky dávať vždy zvukové výstražné znamenie alebo svetelné výstražné znamenie.",
            "uv_odpovedb": "zaradiť sa čo najbližšie k pravému okraju vozovky; ak pritom musí s ohľadom na rozmery vozidla alebo nákladu vybočiť zo smeru svojej jazdy vľavo, vždy dáva len znamenie o zmene smeru jazdy vpravo.",
            "uv_odpovedc": "zaradiť sa vždy do stredu vozovky.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "71e0e555101b5d7f25c2"
        },
        {
            "nl_otazka_id": "1273",
            "uv_otazka": "Ak je už vidieť alebo počuť prichádzajúci vlak alebo iné dráhové vozidlo alebo ak počuť najmä jeho húkanie alebo pískanie, vodič",
            "uv_odpoveda": "smie vchádzať na železničné priecestie, ak nie je dávaná výstraha dvoma červenými striedavo prerušovanými svetlami.",
            "uv_odpovedb": "smie vchádzať na železničné priecestie, ak prichádza nákladný vlak.",
            "uv_odpovedc": "nesmie vchádzať na železničné priecestie.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "b69a50709dfeacae7aa9"
        },
        {
            "nl_otazka_id": "1112",
            "uv_otazka": "Ak je pri súbežnej jazde v niektorom jazdnom pruhu prekážka cestnej premávky, vodič vozidla idúceho vo voľnom jazdnom pruhu je povinný umožniť",
            "uv_odpoveda": "vodičovi prvého vozidla nachádzajúceho sa v jazdnom pruhu, v ktorom je prekážka, jej obídenie, ak ten dáva znamenie o zmene smeru jazdy.",
            "uv_odpovedb": "jej obídenie len vodičom vozidiel pravidelnej verejnej dopravy osôb.",
            "uv_odpovedc": "vodičom všetkých vozidiel nachádzajúcich sa v jazdnom pruhu, v ktorom je prekážka cestnej premávky, jej obídenie.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "a0b956d91059d83b93a4"
        },
        {
            "nl_otazka_id": "1099",
            "uv_otazka": "V obci na ceste s dvoma alebo s viacerými jazdnými pruhmi vyznačenými na vozovke v jednom smere jazdy smie vodič vozidla použiť na jazdu",
            "uv_odpoveda": "len pravý jazdný pruh; ostatné jazdné pruhy sa môžu používať len na predchádzanie alebo na odbočovanie.",
            "uv_odpovedb": "len ľavý jazdný pruh; ostatné jazdné pruhy smie používať len vodič nemotorového vozidla.",
            "uv_odpovedc": "ktorýkoľvek jazdný pruh.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "50ab666407781f682bfe"
        },
        {
            "nl_otazka_id": "1037",
            "uv_otazka": "Vodič motorového vozidla je povinný",
            "uv_odpoveda": "dať prednosť chodcovi, ktorý vstúpil na vozovku a prechádza cez priechod pre chodcov, pritom ho nesmie ohroziť.",
            "uv_odpovedb": "zastaviť vozidlo pred priechodom pre chodcov aj vtedy, ak sa ňom chodci nenachádzajú.",
            "uv_odpovedc": "zastaviť vozidlo pred priechodom pre chodcov len vtedy, ak sa na ňom nachádza najmenej 10 chodcov.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "efc59c918db795d6204b"
        },
        {
            "nl_otazka_id": "1419",
            "uv_otazka": "Pri preprave nákladu sa",
            "uv_odpoveda": "môže prekročiť najväčšia prípustná celková hmotnosť vozidla na účelových komunikáciách.",
            "uv_odpovedb": "nesmie prekročiť najväčšia prípustná celková hmotnosť vozidla.",
            "uv_odpovedc": "môže prekročiť najväčšia prípustná celková hmotnosť vozidla najviac o 10 %.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "6ea29759f29fce21a756"
        },
        {
            "nl_otazka_id": "1404",
            "uv_otazka": "Preprava osôb je zakázaná",
            "uv_odpoveda": "v kabíne vodiča nákladného automobilu.",
            "uv_odpovedb": "v ložnom priestore nákladného prívesu traktora.",
            "uv_odpovedc": "v inom prípojnom vozidle ako vo vozidle, ktoré je určené na prepravu osôb s výnimkou ložného priestoru nákladného prívesu traktora.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "8c5dc4ca089ac3811c7d"
        },
        {
            "nl_otazka_id": "1581",
            "uv_otazka": "Vodič odbočujúci vpravo je povinný dať prednosť v jazde",
            "uv_odpoveda": "protiidúcim vozidlám pravidelnej verejnej dopravy osôb, ak tieto odbočujú vľavo.",
            "uv_odpovedb": "cyklistovi idúcemu rovno vrátane cyklistu idúceho súbežne s cestou; to neplatí pre vodiča električky.",
            "uv_odpovedc": "cyklistovi idúcemu rovno; to platí aj pre vodiča električky.",
            "nl_okruh_id": "2",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "79faa373aeb844f5c0c9"
        },
        {
            "nl_otazka_id": "1634",
            "uv_otazka": "Táto dopravná značka upozorňuje na",
            "uv_odpoveda": "Každú križovatku, kde je premávka riadená svetelnými signálmi.",
            "uv_odpovedb": "Riadenie cestnej premávky svetelnými signálmi.",
            "uv_odpovedc": "Priechod pre chodcov v obci.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/135.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "90e34c78fb0be02bde2c"
        },
        {
            "nl_otazka_id": "1794",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Dobíjacia stanica pre elektromobily",
            "uv_odpovedb": "Čerpacia stanica",
            "uv_odpovedc": "Čerpacia stanica viacerých alternatívnych palív",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/2-8b.png",
            "body": "2",
            "poznamka": "",
            "hash": "0c197b0790578654d6b5"
        },
        {
            "nl_otazka_id": "1744",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Parkovisko vyhradené pre autobusy.",
            "uv_odpovedb": "Jazdný pruh vyhradený pre vozidlá pravidelnej verejnej dopravy osôb.",
            "uv_odpovedc": "Vhodným symbolom zastávku vozidiel pravidelnej verejnej dopravy osôb.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/331.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "c7fb348c40f4fa0ae95c"
        },
        {
            "nl_otazka_id": "1752",
            "uv_otazka": "Táto vodorovná dopravná značka",
            "uv_odpoveda": "Vyznačuje miesto, kde je vodič povinný zastaviť na príkaz značky Stoj, daj prednosť v jazde! alebo pred križovatkou s riadenou premávkou. Značka môže byť doplnená nápisom STOP.",
            "uv_odpovedb": "Prikazuje vodičovi motorového vozidla vypnúť motor.",
            "uv_odpovedc": "Vyznačuje parkovisko s pozdĺžnym státím.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/604.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "83deb0d90f396a3c231d"
        },
        {
            "nl_otazka_id": "1668",
            "uv_otazka": "Táto dopravná značka znamená:",
            "uv_odpoveda": "Zákaz státia vozidla, ktorého okamžitá výška vrátane nákladu presahuje vyznačenú hranicu.",
            "uv_odpovedb": "Zákaz vjazdu vozidiel nižších ako 3,5 m.",
            "uv_odpovedc": "Zákaz vjazdu vozidlom, ktorého okamžitá výška vrátane nákladu presahuje vyznačenú hranicu.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/243.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "ff2b8d174edcfbea15df"
        },
        {
            "nl_otazka_id": "1879",
            "uv_otazka": "V poradí druhé prejde cez križovatku:",
            "uv_odpoveda": "červené vozidlo.",
            "uv_odpovedb": "žlté vozidlo.",
            "uv_odpovedc": "zelené vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/28.png",
            "body": "4",
            "poznamka": "",
            "hash": "8324deeafe89bc7dcedb"
        },
        {
            "nl_otazka_id": "1849",
            "uv_otazka": "Ako posledné prejde cez križovatku",
            "uv_odpoveda": "žlté vozidlo.",
            "uv_odpovedb": "modré vozidlo.",
            "uv_odpovedc": "zelené vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/24.png",
            "body": "4",
            "poznamka": "",
            "hash": "97b4cc358fe13a093e30"
        },
        {
            "nl_otazka_id": "1821",
            "uv_otazka": "Vozidlá prejdú cez križovatku v tomto poradí:",
            "uv_odpoveda": "1. vaše vozidlo, 2. zelené, 3. modré súčasne s červeným.",
            "uv_odpovedb": "1. zelené, 2. červené, 3. modré, 4. vaše vozidlo.",
            "uv_odpovedc": "1. modré súčasne s vaším vozidlom, 2. zelené, 3. červené.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/05.png",
            "body": "4",
            "poznamka": "",
            "hash": "97b4b4e2afaadbe417a6"
        },
        {
            "nl_otazka_id": "1998",
            "uv_otazka": "Pri cúvaní s jednonápravovým prívesom",
            "uv_odpoveda": "sa príves otáča na tú istú stranu ako ťažné vozidlo.",
            "uv_odpovedb": "sa príves otáča na opačnú stranu ako ťažné vozidlo.",
            "uv_odpovedc": "sa musia zapnúť výstražné svetlá.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "fa8ae509793b8ab6efe4"
        },
        {
            "nl_otazka_id": "2176",
            "uv_otazka": "Bezpečná vzdialenosť vozidiel idúcich za sebou závisí od",
            "uv_odpoveda": "ich rýchlosti",
            "uv_odpovedb": "periférneho videnia",
            "uv_odpovedc": "skúseností vodiča",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "8591043bbcc5aaa661aa"
        },
        {
            "nl_otazka_id": "2315",
            "uv_otazka": "Mazanie motora má za úlohu",
            "uv_odpoveda": "znižovať trenie, tesniť, chladiť, splavovať nečistoty, odplavovať nečistoty, tlmiť hluk a chrániť pred koróziou.",
            "uv_odpovedb": "zvyšovať trenie, tesniť, chladiť a chrániť pred koróziou.",
            "uv_odpovedc": "zabezpečiť tvorbu karbónu pre lepšie odvádzanie tepla.",
            "nl_okruh_id": "10",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "271a868a6d1590119618"
        },
        {
            "nl_otazka_id": "1041",
            "uv_otazka": "Vodič nesmie viesť motorové vozidlo počas trvania trestu zákazu činnosti spočívajúceho v zákaze vedenia motorových vozidiel alebo počas trvania sankcie zákazu činnosti spočívajúcej v zákaze vedenia motorových vozidiel.",
            "uv_odpoveda": "len skupiny B.",
            "uv_odpovedb": "akejkoľvek skupiny.",
            "uv_odpovedc": "skupiny podľa uváženia vodiča motorového vozidla.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5b4fdb8dab3bbd8d0080"
        },
        {
            "nl_otazka_id": "1237",
            "uv_otazka": "Vodič nesmie zastaviť a stáť",
            "uv_odpoveda": "vo vzdialenosti kratšej ako 5 m pred a za priechodom pre cyklistov.",
            "uv_odpovedb": "v pravom jazdnom pruhu.",
            "uv_odpovedc": "na cestičke pre cyklistov, cyklistickom pruhu a na ochrannom pruhu pre cyklistov.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "30c5d00fdabcc8166227"
        },
        {
            "nl_otazka_id": "1038",
            "uv_otazka": "Vodič je povinný pribrať potrebný počet spôsobilých osôb, ak",
            "uv_odpoveda": "to vyžaduje bezpečnosť cestnej premávky, a na tento účel ich náležité poučiť.",
            "uv_odpovedb": "sa pri preprave nákladu prekročila najväčšia celková prípustná hmotnosť vozidla.",
            "uv_odpovedc": "v potrebnom rozsahu neovláda predpisy o cestnej premávke.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5ba50908d93f8d10f6c7"
        },
        {
            "nl_otazka_id": "1116",
            "uv_otazka": "Pri hustote cestnej premávky, ktorá vyvolá vznik kolóny stojacich vozidiel na diaľnici alebo na smerovo rozdelenej ceste mimo obce s najmenej dvomi jazdnými pruhmi v jednom smere jazdy, je vodič vozidla povinný",
            "uv_odpoveda": "vykonať taký jazdný úkon, ktorý umožní vytvorenie záchranárskej uličky v strede medzi dvoma jazdnými pruhmi, ak to vyžaduje plynulosť cestnej premávky.",
            "uv_odpovedb": "okamžite zastaviť vozidlo.",
            "uv_odpovedc": "vykonať taký jazdný úkon, ktorý umožní vytvorenie záchranárskej uličky v strede medzi dvoma jazdnými pruhmi pri súbežnej jazde.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3d6e6984e2aaf97bb73e"
        },
        {
            "nl_otazka_id": "1219",
            "uv_otazka": "Vodič vozidla, ktoré je povinne vybavené prenosným výstražným trojuholníkom, je povinný tento trojuholník umiestniť na krajnici alebo na vozovke počas núdzového státia, ak takéto vozidlo tvorí prekážku cestnej premávky na diaľnici",
            "uv_odpoveda": "vo vzdialenosti najmenej 50 m za vozidlom.",
            "uv_odpovedb": "len za zníženej viditeľnosti.",
            "uv_odpovedc": "vo vzdialenosti najmenej 100 m za vozidlom.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "7318ff93fd1a2309b22f"
        },
        {
            "nl_otazka_id": "1390",
            "uv_otazka": "Vodič vozidla s parkovacím preukazom nemusí po nevyhnutne potrebný čas dodržiavať",
            "uv_odpoveda": "najvyššie dovolené rýchlosti jazdy.",
            "uv_odpovedb": "zákaz státia a najvyššie dovolené rýchlosti.",
            "uv_odpovedc": "zákaz státia; pritom nesmie ohroziť bezpečnosť cestnej premávky.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "bb3d2cb520ed90e695ad"
        },
        {
            "nl_otazka_id": "1555",
            "uv_otazka": "Vodič je povinný rýchlosť jazdy prispôsobiť",
            "uv_odpoveda": "len poveternostným podmienkam.",
            "uv_odpovedb": "najmä svojim schopnostiam, vlastnostiam vozidla a nákladu, poveternostným podmienkam, stavu a povahe vozovky a iným okolnostiam, ktoré možno predvídať.",
            "uv_odpovedc": "svojim schopnostiam; vlastnostiam nákladu je vodič povinný rýchlosť jazdy prispôsobiť len vtedy, ak sú vo vozidle prepravované živé zvieratá alebo nebezpečný náklad.",
            "nl_okruh_id": "2",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1fa26f36d1319b003ddf"
        },
        {
            "nl_otazka_id": "1598",
            "uv_otazka": "Na diaľnici smie vodič motorového vozidla jazdiť rýchlosťou najmenej",
            "uv_odpoveda": "80 km.h-1.",
            "uv_odpovedb": "120 km.h-1.",
            "uv_odpovedc": "100 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "6f2cb59d0ad06f5045fb"
        },
        {
            "nl_otazka_id": "1709",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Upozorňuje na povinnosť pred vjazdom do tunela zapnúť diaľkové svetlomety.",
            "uv_odpovedb": "Označuje tunel, v ktorom platia pravidlá cestnej premávky pre správanie sa v tuneli.",
            "uv_odpovedc": "Označuje blízkosť vjazdu do tunela pre koľajové vozidlá.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/322.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "6e97a9374cfac10e4c4f"
        },
        {
            "nl_otazka_id": "1830",
            "uv_otazka": "Vaše vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "tretie.",
            "uv_odpovedb": "prvé súčasne s modrým vozidlom.",
            "uv_odpovedc": "štvrté súčasne so zeleným vozidlom.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/14.png",
            "body": "4",
            "poznamka": "",
            "hash": "ab4a5aa88073a76db997"
        },
        {
            "nl_otazka_id": "2166",
            "uv_otazka": "Aquaplaning znamená",
            "uv_odpoveda": "šmyk vozidla v dôsledku zablokovania kolies pri intenzívnom brzdení",
            "uv_odpovedb": "stratu kontaktu pneumatík s vozovkou na ceste s vrstvou vody",
            "uv_odpovedc": "šmyk vozidla pri rýchlom prejazde zákruty",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "07a72ff491dcee17bab7"
        },
        {
            "nl_otazka_id": "2159",
            "uv_otazka": "Súčiniteľ odporu valenia so zvyšujúcou rýchlosťou jazdy",
            "uv_odpoveda": "nemení sa",
            "uv_odpovedb": "stúpa",
            "uv_odpovedc": "klesá",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "77d7b6cde7b3938f4b2e"
        },
        {
            "nl_otazka_id": "2341",
            "uv_otazka": "Rozsvietenie kontrolky mazania na prístrojovom paneli znamená, že",
            "uv_odpoveda": "mazacia sústava pracuje dobre.",
            "uv_odpovedb": "je nedostatočné množstvo paliva.",
            "uv_odpovedc": "tlak oleja v motore nedosahuje požadovanú úroveň.",
            "nl_okruh_id": "10",
            "nl_correct": "3",
            "uv_obrazok": "obr3/tvv/35.png",
            "body": "1",
            "poznamka": "",
            "hash": "5dddf131834634da51b0"
        },
        {
            "nl_otazka_id": "1078",
            "uv_otazka": "Osoba sediaca na sedadle vozidla povinne vybavenom bezpečnostným pásom alebo iným zadržiavacím zariadením je povinná toto zariadenie použiť; to neplatí pre",
            "uv_odpoveda": "osobu s telesnou výškou menšou ako 130 cm.",
            "uv_odpovedb": "osobu s telesnou výškou menšou ako 150 cm s výnimkou osoby, ktorá je povinná používať zadržiavacie zariadenie podľa podmienok ustanovených v osobitnom predpise.",
            "uv_odpovedc": "osobu s telesnou výškou menšou ako 150 cm, ktorá nie je povinná používať zadržiavacie zariadenie podľa podmienok ustanovených v osobitnom predpise.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "68d322210a856a1bef8b"
        },
        {
            "nl_otazka_id": "1035",
            "uv_otazka": "Vodič je povinný",
            "uv_odpoveda": "zastaviť vozidlo pred priechodom pre chodcov len vtedy, ak sa na ňom nachádza skupina chodcov.",
            "uv_odpovedb": "dbať na zvýšenú opatrnosť voči cyklistom a chodcom, najmä deťom, osobám so zdravotným postihnutím, osobitne voči osobám, ktoré používajú bielu palicu, a starým osobám.",
            "uv_odpovedc": "venovať sa plne vedeniu vozidla; to neplatí počas zníženej viditeľnosti.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "488e0fb37508ecefbd73"
        },
        {
            "nl_otazka_id": "1522",
            "uv_otazka": "Pri tejto zelenej šípke vodič",
            "uv_odpoveda": "môže odbočiť doprava z ktoréhokoľvek jazdného pruhu.",
            "uv_odpovedb": "nesmie odbočiť doprava.",
            "uv_odpovedc": "smie odbočiť doprava len z pravého pruhu a nesmie pri prejazde križovatkou obmedziť ani ohroziť iných účastníkov cestnej premávky, najmä chodcov, cyklistov a vozidlá jazdiace vo voľnom smere.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/730.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "ba8a0b9161812e268986"
        },
        {
            "nl_otazka_id": "1149",
            "uv_otazka": "Vodič predchádzaného vozidla nesmie",
            "uv_odpoveda": "by bol pritom nútený prejsť do protismeru.",
            "uv_odpovedb": "zvyšovať rýchlosť jazdy ani inak brániť predchádzaniu.",
            "uv_odpovedc": "znižovať rýchlosť jazdy.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5e46d0601ae569ac418f"
        },
        {
            "nl_otazka_id": "1162",
            "uv_otazka": "Vodič motorového vozidla je povinný dodržiavať za vozidlom idúcim pred ním",
            "uv_odpoveda": "takú vzdialenosť, aby pri použití diaľkových svetlometov nebol oslnený vodič pred ním idúceho vozidla.",
            "uv_odpovedb": "takú vzdialenosť, aby sa predchádzajúce vozidlo mohlo pred neho bezpečne zaradiť.",
            "uv_odpovedc": "vzdialenosť najmenej 10 m.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "0daabd6580c1670daa93"
        },
        {
            "nl_otazka_id": "1106",
            "uv_otazka": "V obci na ceste s dvoma alebo s viacerými jazdnými pruhmi vyznačenými na vozovke v jednom smere jazdy smie vodič osobného automobilu použiť na jazdu",
            "uv_odpoveda": "ktorýkoľvek jazdný pruh.",
            "uv_odpovedb": "len ľavý jazdný pruh; ostatné jazdné pruhy smie používať len vodič nemotorového vozidla.",
            "uv_odpovedc": "len pravý jazdný pruh; ostatné jazdné pruhy sa môžu používať len na predchádzanie alebo na odbočovanie.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "9016331d492413fdbb68"
        },
        {
            "nl_otazka_id": "1337",
            "uv_otazka": "Cestu pre motorové vozidlá môžu používať len vodiči",
            "uv_odpoveda": "motorových vozidiel, ktorých najvyššia dovolená rýchlosť nie je nižšia ako 50 km/h.",
            "uv_odpovedb": "motorových vozidiel, ktorých najvyššia konštrukčná rýchlosť nie je nižšia ako 65 km/h.",
            "uv_odpovedc": "motorových vozidiel s najväčšou konštrukčnou rýchlosťou najmenej 60 km/h.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ba619c9a8515af5fd329"
        },
        {
            "nl_otazka_id": "1554",
            "uv_otazka": "Ak vodič autobusu alebo trolejbusu vychádza z vyhradeného jazdného pruhu do priľahlého jazdného pruhu, vodič jazdiaci v priľahlom jazdnom pruhu",
            "uv_odpoveda": "nie je povinný mu dať prednosť v jazde.",
            "uv_odpovedb": "je povinný mu dať prednosť v jazde.",
            "uv_odpovedc": "je povinný vždy zastaviť vozidlo.",
            "nl_okruh_id": "2",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e1c9c117337d73895f6b"
        },
        {
            "nl_otazka_id": "1769",
            "uv_otazka": "Platnosť zvislej dopravnej značky umiestnenej na vozidle",
            "uv_odpoveda": "sa končí 100 m v smere jazdy za vozidlom, na ktorom je umiestnená, alebo 100 m v smere jazdy za posledným vozidlom skupiny, ak ide o skupinu vozidiel.",
            "uv_odpovedb": "sa končí 500 m v smere jazdy za vozidlom, na ktorom je umiestnená, alebo 500 m v smere jazdy za posledným vozidlom skupiny, ak ide o skupinu vozidiel.",
            "uv_odpovedc": "sa končí 1000 m v smere jazdy za vozidlom, na ktorom je umiestnená, alebo 1000 m v smere jazdy za posledným vozidlom skupiny, ak ide o skupinu vozidiel.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "58207e25ed116b50036a"
        },
        {
            "nl_otazka_id": "1650",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Označuje zastávku vozidiel pravidelnej verejnej dopravy osôb.",
            "uv_odpovedb": "Označuje slepú ulicu.",
            "uv_odpovedc": "Ukladá vodičovi povinnosť zastaviť vozidlo a dať prednosť v jazde.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/202.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "1bb9f8cb49eadc23e58d"
        },
        {
            "nl_otazka_id": "1883",
            "uv_otazka": "Zelené vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "tretie.",
            "uv_odpovedb": "prvé.",
            "uv_odpovedc": "druhé.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/30.png",
            "body": "4",
            "poznamka": "",
            "hash": "5b387e6ab841c5b5aab3"
        },
        {
            "nl_otazka_id": "1877",
            "uv_otazka": "Červené vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "prvé.",
            "uv_odpovedb": "posledné.",
            "uv_odpovedc": "druhé.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/27.png",
            "body": "4",
            "poznamka": "",
            "hash": "d2cab47b23629b2eda1c"
        },
        {
            "nl_otazka_id": "1982",
            "uv_otazka": "Kedy dosiahneme pri brzdení motorom väčší brzdiaci účinok?",
            "uv_odpoveda": "Pri zaradení nižšieho prevodového stupňa.",
            "uv_odpovedb": "Len pri zaradení 3. alebo 4. prevodového stupňa.",
            "uv_odpovedc": "Pri zaradení vyššieho prevodového stupňa.",
            "nl_okruh_id": "6",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "a11db9c22b9a9df9c460"
        },
        {
            "nl_otazka_id": "2201",
            "uv_otazka": "Základnou zásadou pri jazde v nepriaznivých podmienkach (v noci, počas hmly, dažďa a pri snežení) je",
            "uv_odpoveda": "zapnúť hmlové svetlá",
            "uv_odpovedb": "vidieť a byť videný",
            "uv_odpovedc": "vidieť",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "6d21928b4af84c2887b6"
        },
        {
            "nl_otazka_id": "2320",
            "uv_otazka": "V štvordobom motore je v poradí druhá doba",
            "uv_odpoveda": "stláčanie (kompresia).",
            "uv_odpovedb": "depresia.",
            "uv_odpovedc": "výbuch (expanzia).",
            "nl_okruh_id": "10",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "e9209f88f922061fc240"
        },
        {
            "nl_otazka_id": "1173",
            "uv_otazka": "Ak vodiči protiidúcich vozidiel odbočujú vľavo, vyhýbajú sa",
            "uv_odpoveda": "vľavo s odstupom najmenej 3 m.",
            "uv_odpovedb": "vľavo.",
            "uv_odpovedc": "vpravo.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "18212aef7a017a7ad02a"
        },
        {
            "nl_otazka_id": "1199",
            "uv_otazka": "Vodič smie zastaviť a stáť len",
            "uv_odpoveda": "na jednosmernej ceste v jednom rade a rovnobežne s okrajom cesty.",
            "uv_odpovedb": "na krajnici.",
            "uv_odpovedc": "vpravo v smere jazdy v jednom rade a rovnobežne s okrajom cesty, čo najbližšie k okraju cesty a na jednosmernej ceste vpravo i vľavo.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "c81e54e46c24c1943c9b"
        },
        {
            "nl_otazka_id": "1481",
            "uv_otazka": "Smie vodič  vozidla kategórie M1 a N1 vojsť do školskej zóny?",
            "uv_odpoveda": "Áno, ak to umožňuje dopravná značka.",
            "uv_odpovedb": "Áno.",
            "uv_odpovedc": "Nie.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "23bc388e41f32e6b27e2"
        },
        {
            "nl_otazka_id": "1602",
            "uv_otazka": "Ak vodič motorového vozidla počas jazdy zistí na vozidle alebo na náklade chybu, pre ktorú nemožno dosiahnuť na rovine najmenšiu ustanovenú rýchlosť, musí",
            "uv_odpoveda": "zastaviť vozidlo v pravom jazdnom pruhu.",
            "uv_odpovedb": "diaľnicu opustiť a pokračovať v jazde do najbližšej stanice technickej kontroly.",
            "uv_odpovedc": "diaľnicu opustiť na najbližšom výjazde; to neplatí, ak sa pred výjazdom z diaľnice nachádza parkovisko, kde musí vozidlo odstaviť.",
            "nl_okruh_id": "2",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "97dedd410e51ed3a3176"
        },
        {
            "nl_otazka_id": "1690",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Spoločnú komunikáciu určenú pre chodcov a cyklistov a prikazuje im ju použiť.",
            "uv_odpovedb": "Priechod pre chodcov a cyklistov; značka informuje o spoločnom priechode pre chodcov a cyklistov.",
            "uv_odpovedc": "Oddelenú komunikáciu určenú pre chodcov a cyklistov a vyznačuje, ktorý pruh je určený pre chodcov a ktorý pruh je určený pre cyklistov; cyklistom a chodcom prikazuje použiť časť pre nich určenú.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/223.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "62d84e240308d9e8490c"
        },
        {
            "nl_otazka_id": "1695",
            "uv_otazka": "Táto dopravná značka informuje",
            "uv_odpoveda": "O spôsobe zaraďovania sa vozidiel pred križovatkou.",
            "uv_odpovedb": "O nasledujúcom prikázanom smere jazdy.",
            "uv_odpovedc": "O znížení počtu jazdných pruhov a o spôsobe jeho zníženia.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/411-419.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "7f16efa91406832a4282"
        },
        {
            "nl_otazka_id": "1627",
            "uv_otazka": "Táto dopravná značka upozorňuje na",
            "uv_odpoveda": "miesto, kde priečny sklon vozovky presahuje 10 %.",
            "uv_odpovedb": "Nebezpečné stúpanie.",
            "uv_odpovedc": "priklonenú strmú stráň sprava.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/113.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "1ea93664aab7f050e716"
        },
        {
            "nl_otazka_id": "1834",
            "uv_otazka": "Vaše vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "druhé.",
            "uv_odpovedb": "prvé.",
            "uv_odpovedc": "posledné.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/16.png",
            "body": "4",
            "poznamka": "",
            "hash": "34cc94cb69ec1f137434"
        },
        {
            "nl_otazka_id": "1954",
            "uv_otazka": "Pedál spojky v automobile používame",
            "uv_odpoveda": "vždy ak potrebujeme spomaliť alebo zaparkovať vozidlo.",
            "uv_odpovedb": "pri štartovaní, radení prevodových stupňov a zastavení so zaradeným rýchlostným stupňom.",
            "uv_odpovedc": "len pri brzdení.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "722d920ed850d38edaa6"
        },
        {
            "nl_otazka_id": "2070",
            "uv_otazka": "Vodičský preukaz je neplatný, ak",
            "uv_odpoveda": "bol vydaný v súlade so zákonom.",
            "uv_odpovedb": "bol vydaný v rozpore so zákonom.",
            "uv_odpovedc": "bol vydaný viac ako jeden rok po vykonaní skúšky.",
            "nl_okruh_id": "7",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "bccd2b278b5de91be51d"
        },
        {
            "nl_otazka_id": "2140",
            "uv_otazka": "Vozidlo sa považuje za technicky nespôsobilé na prevádzku v cestnej premávke, najmä ak",
            "uv_odpoveda": "nie je na čelnom skle zatemňovací pás, ktorý umožňuje 50 % priepustnosti svetla.",
            "uv_odpovedb": "výfukové potrubie ohrozuje zdravie osôb vo vozidle.",
            "uv_odpovedc": "je katalyzátor výfukových plynov po lehote kalibrácie.",
            "nl_okruh_id": "8",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "f1af69b7d13eb193bedb"
        },
        {
            "nl_otazka_id": "2165",
            "uv_otazka": "Dĺžku brzdnej dráhy vozidla značne ovplyvňuje",
            "uv_odpoveda": "ASR systém",
            "uv_odpovedb": "rýchlosť jazdy, stav vozovky (adhézia), hmotnosť vozidla a stav pneumatík",
            "uv_odpovedc": "ESP systém",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "020653d9382db301c7d6"
        },
        {
            "nl_otazka_id": "2206",
            "uv_otazka": "Nebezpečenstvo vzniku aquaplaningu sa zvyšuje",
            "uv_odpoveda": "opotrebením dezénu pneumatiky",
            "uv_odpovedb": "nesúmernosťou bŕzd",
            "uv_odpovedc": "nadmernou vôľou riadenia",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "25a1715c01f483365ef0"
        },
        {
            "nl_otazka_id": "2236",
            "uv_otazka": "Sily pôsobiace počas jazdy na náklad ovplyvňujú",
            "uv_odpoveda": "náklad",
            "uv_odpovedb": "jazdné vlastnosti vozidla (jazdnej súpravy)",
            "uv_odpovedc": "pozornosť iných účastníkov cestnej premávky",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "d75f173f3421b8d05bf4"
        },
        {
            "nl_otazka_id": "1519",
            "uv_otazka": "Ak svieti tento signál so symbolom šípok samostatne pri riadení cestnej premávky, znamená pre vodiča",
            "uv_odpoveda": "“Čakaj“; vodič musí zastaviť vozidlo pred križovatkou, pričom ak je pri rozsvietení tohto signálu už tak blízko, že nemôže zastaviť vozidlo bezpečne, smie pokračovať v jazde ktorýmkoľvek smerom.",
            "uv_odpovedb": "“Voľno“; vodič smie pokračovať v jazde pri dodržaní pravidiel cestnej premávky.",
            "uv_odpovedc": "“Čakaj“; vodič musí zastaviť vozidlo pred križovatkou, pričom ak je pri rozsvietení tohto signálu už tak blízko, že nemôže zastaviť vozidlo bezpečne, smie pokračovať v jazde v smere vyznačenom šípkami.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "obr3/pcp/15_3_2.png",
            "body": "3",
            "poznamka": "",
            "hash": "30c2536e9975f6daa442"
        },
        {
            "nl_otazka_id": "1150",
            "uv_otazka": "Vodič nesmie predchádzať, ak",
            "uv_odpoveda": "pred ním jazdí vozidlo pravidelnej verejnej dopravy osôb.",
            "uv_odpovedb": "by bol pritom nútený vojsť na električkový koľajový pás v úrovni vozovky.",
            "uv_odpovedc": "nemá pred sebou rozhľad na takú vzdialenosť, ktorá je potrebná na bezpečné predchádzanie.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "d197038165701ae3c75b"
        },
        {
            "nl_otazka_id": "1513",
            "uv_otazka": "Ak svieti tento signál pri riadení cestnej premávky v križovatke, znamená pre vodiča:",
            "uv_odpoveda": "“Voľno“; vodič smie pokračovať v jazde pri dodržaní pravidiel cestnej premávky.",
            "uv_odpovedb": "“Stoj“; vodič musí zastaviť vozidlo pred križovatkou, pričom ak je pri rozsvietení tohto signálu už tak blízko, že nemôže zastaviť bezpečne, smie pokračovať v jazde.",
            "uv_odpovedc": "“Stoj“; vodič musí zastaviť vozidlo pred križovatkou.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "obr3/pcp/15_2a.png",
            "body": "3",
            "poznamka": "",
            "hash": "359c22bdef2fc9f895f9"
        },
        {
            "nl_otazka_id": "1223",
            "uv_otazka": "Vodič nesmie zastaviť a stáť",
            "uv_odpoveda": "na jednosmernej ceste vľavo.",
            "uv_odpovedb": "na priechode pre chodcov a priechode pre cyklistov a vo vzdialenosti kratšej ako 25 m pred ním.",
            "uv_odpovedc": "na priechode pre chodcov, na mieste na prechádzanie alebo na priechode pre cyklistov a vo vzdialenosti kratšej ako 5 m pred nimi.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "16692be923c32e846466"
        },
        {
            "nl_otazka_id": "1282",
            "uv_otazka": "Čo znamená, ak osoba pribratá na zaistenie bezpečnej prevádzky železničného priecestia vodorovne kýva rukou s červenou alebo so žltou zástavkou a za zníženej viditeľnosti bielym svetlom cez stred tela?",
            "uv_odpoveda": "Je to pokyn na zastavenie vozidla.",
            "uv_odpovedb": "Je to pokyn na jazdu.",
            "uv_odpovedc": "Je to pokyn na pomalšiu jazdu.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "obr3/pcp/29_1.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "0dd5d6b7f56f6d5cb5f3"
        },
        {
            "nl_otazka_id": "1740",
            "uv_otazka": "Táto dopravná značka informuje",
            "uv_odpoveda": "O názve obce alebo miestnej časti obce tvoriacej samostatný sídelný útvar v jazyku národnostnej menšiny na začiatku obce alebo miestnej časti obce.",
            "uv_odpovedb": "O hranici kraja v jazyku národnostnej menšiny.",
            "uv_odpovedc": "O prvej obci susedného štátu v cudzom jazyku.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/307.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "5b9718d2aa1b05d95c45"
        },
        {
            "nl_otazka_id": "1890",
            "uv_otazka": "Ako prvé prejde cez križovatku",
            "uv_odpoveda": "červené vozidlo.",
            "uv_odpovedb": "zelené vozidlo.",
            "uv_odpovedc": "modré vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/34-1.png",
            "body": "4",
            "poznamka": "",
            "hash": "2db6b858c654196c8e24"
        },
        {
            "nl_otazka_id": "1876",
            "uv_otazka": "Vozidlá prejdú cez križovatku v tomto poradí:",
            "uv_odpoveda": "1. zelené, 2. červené, 3. žlté.",
            "uv_odpovedb": "1. červené, 2. žlté, 3. zelené.",
            "uv_odpovedc": "1. žlté, 2. červené, 3. zelené.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/27.png",
            "body": "4",
            "poznamka": "",
            "hash": "64f18adcef6a4657e80d"
        },
        {
            "nl_otazka_id": "1897",
            "uv_otazka": "Vodič modrého vozidla",
            "uv_odpoveda": "má pred vodičom červeného vozidla prednosť, pretože ide v jazdnom pruhu určenom na predchádzanie.",
            "uv_odpovedb": "nesmie ohroziť vodiča červeného vozidla jazdiaceho v priebežnom jazdnom pruhu, do ktorého prechádza.",
            "uv_odpovedc": "má pred vodičom červeného vozidla prednosť, pretože ide v jazdnom pruhu pre pomalé vozidlá.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/38.png",
            "body": "4",
            "poznamka": "",
            "hash": "9bf36f33a9535c7c5ec8"
        },
        {
            "nl_otazka_id": "1962",
            "uv_otazka": "Po preradení prevodového stupňa pedál spojky",
            "uv_odpoveda": "postupne uvoľňujeme a v záberovom pásme pohyb spomalíme.",
            "uv_odpovedb": "držíme stlačený ľavou nohou až kým vozidlo nezrýchli.",
            "uv_odpovedc": "prudko uvoľníme a v záberovom pásme prudko zrýchlime.",
            "nl_okruh_id": "6",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "58999c81db5a3a8d3034"
        },
        {
            "nl_otazka_id": "2133",
            "uv_otazka": "Vozidlo sa považuje za technicky nespôsobilé na prevádzku v cestnej premávke, najmä ak",
            "uv_odpoveda": "vozidlo nebolo v lehote jedného mesiaca po jeho prvom prihlásení do evidencie podrobené technickej kontrole.",
            "uv_odpovedb": "brzdový bubon alebo kotúč je nadmerne opotrebovaný, nadmerne poškodený, nedostatočne upevnený alebo poškodený.",
            "uv_odpovedc": "celková prípustná šírka vozidla presahuje 2200 mm.",
            "nl_okruh_id": "8",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "2bff44ad3a59b2f0c357"
        },
        {
            "nl_otazka_id": "2131",
            "uv_otazka": "Vozidlo sa považuje za technicky nespôsobilé na prevádzku v cestnej premávke, najmä ak",
            "uv_odpoveda": "najvyšší bod činnej svietiacej plochy stretávacích svetiel je nižšie ako 1200 mm nad rovinou vozovky.",
            "uv_odpovedb": "brzdové svetlo alebo jeho svetelný zdroj je nefunkčný.",
            "uv_odpovedc": "nie je vybavené klimatizačným systémom.",
            "nl_okruh_id": "8",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "0fe817d6bf4ca035c6e7"
        },
        {
            "nl_otazka_id": "2188",
            "uv_otazka": "Ak  počas jazdy vodič spozoruje, že u neho dochádza k výraznému poklesu bdelosti až k ospalosti, mal by",
            "uv_odpoveda": "čo najskôr zastaviť vozidlo na vhodnom mieste",
            "uv_odpovedb": "pokračovať v jazde po prvú čerpaciu stanicu",
            "uv_odpovedc": "čo najrýchlejšie doraziť do stanoveného cieľa",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "55654ef369f912d2a545"
        },
        {
            "nl_otazka_id": "2150",
            "uv_otazka": "Najhoršie adhézne (priľnavnostné) podmienky sú na:",
            "uv_odpoveda": "vozovke, keď začína pršať.",
            "uv_odpovedb": "mokrej vozovke",
            "uv_odpovedc": "suchej vozovke",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3474636adca5aedf8195"
        },
        {
            "nl_otazka_id": "1010",
            "uv_otazka": "Križovatkou sa rozumie",
            "uv_odpoveda": "miesto, v ktorom sa cesty pretínajú alebo spájajú.",
            "uv_odpovedb": "mimoúrovňové kríženie cesty so železnicou.",
            "uv_odpovedc": "miesto, v ktorom cesta mení smer.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "121b36760332bebaca99"
        },
        {
            "nl_otazka_id": "1270",
            "uv_otazka": "Kedy nesmie vodič vchádzať na železničné priecestie?",
            "uv_odpoveda": "Ak sa dáva výstraha prerušovaným zvukom húkačky alebo zvončeka priecestného zabezpečovacieho zariadenia.",
            "uv_odpovedb": "Ak osoba pribratá na zaistenie bezpečnej prevádzky železničného priecestia dáva znamenie vodorovným kývaním ruky s červenou alebo so žltou zástavkou.",
            "uv_odpovedc": "Ak protiidúci vodič dáva zvukové alebo svetelné výstražné znamenie.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "327d6d430563111589d1"
        },
        {
            "nl_otazka_id": "1148",
            "uv_otazka": "Vodič pri predchádzaní musí dodržať dostatočný bočný odstup od predchádzaného vozidla",
            "uv_odpoveda": "pritom je povinný prihliadať najmä na rýchlosť, druh svojho i predchádzaného vozidla, poveternostné podmienky a stav a povahu vozovky.",
            "uv_odpovedb": "pritom nie je povinný prihliadať na poveternostné podmienky a stav vozovky.",
            "uv_odpovedc": "pritom nie je povinný prihliadať na rýchlosť predchádzaného vozidla.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3f3b0f2beb7f44f90770"
        },
        {
            "nl_otazka_id": "1475",
            "uv_otazka": "Jazda vozidiel v školskej zóne je",
            "uv_odpoveda": "zakázaná.",
            "uv_odpovedb": "povolená len vozidlám pravidelnej verejnej dopravy osôb.",
            "uv_odpovedc": "povolená.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "70c5a5e99edbcd7cd3ea"
        },
        {
            "nl_otazka_id": "1334",
            "uv_otazka": "Ak sa pri vlečení motorového vozidla použije ako spojnica lano, musí byť zreteľne označené",
            "uv_odpoveda": "za zníženej viditeľnosti červeným neoslňujúcim svetlom.",
            "uv_odpovedb": "žltou zástavkou a štítkom s rozmermi najmenej 25 x 25 cm.",
            "uv_odpovedc": "červenou zástavkou alebo štítkom s rozmermi najmenej 20 x 20 cm.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "6b3de4f4a93a97b7de13"
        },
        {
            "nl_otazka_id": "1777",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Zvláštne motorové vozidlá",
            "uv_odpovedb": "Obytné automobily",
            "uv_odpovedc": "Motorové vozidlá alebo jazdné súpravy, ktorých najväčšia prípustná celková hmotnosť presahuje 3,5 t a ktoré sú určené na prepravu nákladu",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/1-3.png",
            "body": "2",
            "poznamka": "",
            "hash": "b8811bb519efb8e91457"
        },
        {
            "nl_otazka_id": "1696",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Vyznačuje zvýšenie počtu jazdných pruhov a ich prikázané smery jázd.",
            "uv_odpovedb": "Vyznačuje jazdné pruhy pre pomalé vozidlá.",
            "uv_odpovedc": "Informuje na viacpruhovej ceste o potrebe včasného zaradenia sa do jazdných pruhov pred križovatkou podľa jednotlivých smerov jazdy tam, kde je usporiadanie radiacich pruhov v križovatke neobvyklé alebo inak neočakávateľné.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/451-459.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "c39658000ab302d7c778"
        },
        {
            "nl_otazka_id": "1887",
            "uv_otazka": "Na tento pokyn policajta",
            "uv_odpoveda": "je vodič čierneho a vodič zeleného vozidla povinný zastaviť vozidlo pred križovatkou.",
            "uv_odpovedb": "je vodič modrého, vodič červeného a vodič žltého vozidla povinný zastaviť vozidlo pred hranicou križovatky.",
            "uv_odpovedc": "smie vodič čierneho a vodič zeleného vozidla pokračovať v jazde; ak títo vodiči dodržia ustanovenia o odbočovaní, môžu odbočiť vpravo alebo vľavo.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/32.png",
            "body": "4",
            "poznamka": "",
            "hash": "ca919ed0d9b047c0b3d8"
        },
        {
            "nl_otazka_id": "2303",
            "uv_otazka": "Vodič ide za dlhým vozidlom a približujú sa ku križovatke. Dlhé vozidlo dáva znamenie o zmene smeru jazdy, ale začne vybočovať v smere opačnom, ako dáva znamenie o zmene smeru. Čo by mal vodič idúci za dlhým vozidlom urobiť?",
            "uv_odpoveda": "Mal by zachovať dostatočný odstup od dlhého vozidla a musí byť pripravený zastaviť vozidlo.",
            "uv_odpovedb": "Uviesť do činnosti svetelné výstražné znamenie, aby si vodič dlhého vozidla uvedomil, že použil nesprávne znamenie o zmene smeru jazdy.",
            "uv_odpovedc": "Predísť dlhé vozidlo, aby sa z tejto situácie dostal čo najskôr.",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "d315cfff20d02e3294c4"
        },
        {
            "nl_otazka_id": "2161",
            "uv_otazka": "Pri aquaplaningu",
            "uv_odpoveda": "je úplne prerušený kontakt pneumatík s vozovkou",
            "uv_odpovedb": "sa v malej miere zvyšuje priľnavosť pneumatík",
            "uv_odpovedc": "sa nemení priľnavosť pneumatík",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "994ee35b17337601be68"
        },
        {
            "nl_otazka_id": "2168",
            "uv_otazka": "Brzdnú dráhu predĺži",
            "uv_odpoveda": "porucha riadenia",
            "uv_odpovedb": "nesprávna geometria",
            "uv_odpovedc": "chybné pruženie vozidla",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "943fd7272d845fa29e85"
        },
        {
            "nl_otazka_id": "2318",
            "uv_otazka": "Chladiaca sústava slúži najmä na",
            "uv_odpoveda": "chladenie prevodovky a rozvodovky.",
            "uv_odpovedb": "chladenie najviac tepelne namáhaných častí motora, predovšetkým valce a hlavu motora.",
            "uv_odpovedc": "odvod tepla z kabíny vodiča.",
            "nl_okruh_id": "10",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "6bed1e92538ead3f1a13"
        },
        {
            "nl_otazka_id": "1014",
            "uv_otazka": "Nemotorovým vozidlom sa rozumie",
            "uv_odpoveda": "aj motorové vozidlo, ktorého pohotovostná hmotnosť nepresahuje 400 kg.",
            "uv_odpovedb": "samovyvažovacie vozidlo, kolobežka s pomocným motorčekom a bicykel vybavený pomocným elektrickým motorčekom s trvalým menovitým výkonom do 0,25 kW, ktorého výkon sa progresívne znižuje a nakoniec sa preruší, keď vozidlo dosiahne rýchlosť 25 km ? h-1 alebo keď vodič prestane šliapať do pedálov.",
            "uv_odpovedc": "vozidlo pohybujúce sa pomocou ľudskej sily alebo zvieracej sily a bicykel vybavený pomocným elektrickým motorčekom s trvalým menovitým výkonom nad 0,25 kW, ktorého výkon sa progresívne znižuje a nakoniec sa preruší, keď vozidlo dosiahne rýchlosť 35 km ? h-1 alebo keď vodič prestane šliapať do pedálov.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "07d1e5d08065d5d15894"
        },
        {
            "nl_otazka_id": "1122",
            "uv_otazka": "Pozdĺž nástupného ostrovčeka alebo ochranného ostrovčeka sa jazdí",
            "uv_odpoveda": "za každých okolností vľavo.",
            "uv_odpovedb": "vľavo; vpravo sa smie jazdiť len vtedy, ak jazde vľavo bráni prekážka.",
            "uv_odpovedc": "vpravo; vľavo sa smie jazdiť len vtedy, ak jazde vpravo bráni prekážka alebo ak je to bezpečnejšie s ohľadom na rozmery vozidla alebo nákladu.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "b637dc28d58ce3ad92d3"
        },
        {
            "nl_otazka_id": "1177",
            "uv_otazka": "Vodič je povinný zaistiť bezpečný vjazd na cestu pomocou spôsobilej a náležite poučenej osoby,",
            "uv_odpoveda": "pri každom vychádzaní na cestu.",
            "uv_odpovedb": "ak ide o vodiča nákladného vozidla.",
            "uv_odpovedc": "ak to vyžadujú okolnosti, najmä nedostatočný rozhľad.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "b410bea1c6cb0e7dbc08"
        },
        {
            "nl_otazka_id": "1280",
            "uv_otazka": "Môže vodič vchádzať na železničné priecestie ak sa spúšťajú, ak sú spustené alebo ak sa zdvíhajú závory?",
            "uv_odpoveda": "Áno, smie vchádzať na železničné priecestie, ak to rozmery jeho vozidla dovoľujú.",
            "uv_odpovedb": "Áno, smie vchádzať na železničné priecestie, ak nie je vidieť vlak.",
            "uv_odpovedc": "Áno, smie vchádzať na železničné priecestie, ak osoba pribratá na zaistenie bezpečnej prevádzky železničného priecestia dáva pokyn na jazdu.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "bc2fec3c63fcabc30a0d"
        },
        {
            "nl_otazka_id": "1196",
            "uv_otazka": "Vodič sa nesmie otáčať",
            "uv_odpoveda": "na jednosmernej ceste.",
            "uv_odpovedb": "na ceste 1. triedy.",
            "uv_odpovedc": "na žiadnej križovatke.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "c20f7f6778222e67f8b9"
        },
        {
            "nl_otazka_id": "1082",
            "uv_otazka": "Na prednom sedadle vozidla, ktoré nie je vybavené bezpečnostným systémom, sa nesmú prepravovať deti",
            "uv_odpoveda": "s telesnou výškou menšou ako 120 cm.",
            "uv_odpovedb": "do veku troch rokov.",
            "uv_odpovedc": "vo veku troch rokov a viac s telesnou výškou menšou ako 150 cm.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "cb7f8a5366a30b22187e"
        },
        {
            "nl_otazka_id": "1637",
            "uv_otazka": "Táto dopravná značka upozorňuje na",
            "uv_odpoveda": "Zvýšený pohyb detí a zvýšené riziko ich neočakávaného vstupu do vozovky.",
            "uv_odpovedb": "Miesto, kde sú dovolené hry detí na ceste.",
            "uv_odpovedc": "Zakazuje vstup detí.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/141.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "25f7af2615b1cb30532c"
        },
        {
            "nl_otazka_id": "1776",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Električky",
            "uv_odpovedb": "Trolejbusy",
            "uv_odpovedc": "Autobusy",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/1-2.png",
            "body": "2",
            "poznamka": "",
            "hash": "1beabdace92240d58909"
        },
        {
            "nl_otazka_id": "1667",
            "uv_otazka": "Táto dopravná značka znamená:",
            "uv_odpoveda": "Zákaz vjazdu vozidlom, ktorého okamžitá šírka vrátane nákladu presahuje vyznačenú hranicu.",
            "uv_odpovedb": "Zákaz státia vozidla, ktorého okamžitá šírka vrátane nákladu presahuje vyznačenú hranicu.",
            "uv_odpovedc": "Zákaz vjazdu vozidiel užších ako 2 m.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/242.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "005a064d0f1eea55cfc8"
        },
        {
            "nl_otazka_id": "1845",
            "uv_otazka": "V poradí druhé prejde cez križovatku",
            "uv_odpoveda": "žlté vozidlo.",
            "uv_odpovedb": "červené vozidlo.",
            "uv_odpovedc": "vaše vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/22.png",
            "body": "4",
            "poznamka": "",
            "hash": "351794f8730f8f1d3bcb"
        },
        {
            "nl_otazka_id": "1896",
            "uv_otazka": "V poradí tretie prejde okolo prekážky",
            "uv_odpoveda": "žlté vozidlo.",
            "uv_odpovedb": "modré vozidlo.",
            "uv_odpovedc": "zelené vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/37.png",
            "body": "4",
            "poznamka": "",
            "hash": "d49b0e15db45dec32afb"
        },
        {
            "nl_otazka_id": "1815",
            "uv_otazka": "Ako posledné prejde cez križovatku",
            "uv_odpoveda": "modré vozidlo.",
            "uv_odpovedb": "zelené vozidlo.",
            "uv_odpovedc": "vaše vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/02.png",
            "body": "4",
            "poznamka": "",
            "hash": "843e7ce69d259187504d"
        },
        {
            "nl_otazka_id": "1916",
            "uv_otazka": "Účastník dopravnej nehody je povinný",
            "uv_odpoveda": "zdržať sa jedenia, pitia a fajčenia.",
            "uv_odpovedb": "zdržať sa konania, ktoré by bolo na ujmu vyšetrenia dopravnej nehody, najmä premiestnenia vozidiel.",
            "uv_odpovedc": "zabezpečiť, aby ostatní účastníci dopravnej nehody nepožili alkohol.",
            "nl_okruh_id": "5",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "911b026905aae4e8d128"
        },
        {
            "nl_otazka_id": "1989",
            "uv_otazka": "Pri podhustenej pneumatike dochádza k",
            "uv_odpoveda": "zvýšeniu spotreby paliva a menšiemu opotrebovaniu pneumatiky.",
            "uv_odpovedb": "zvýšeniu spotreby paliva a väčšiemu opotrebovaniu pneumatiky.",
            "uv_odpovedc": "zníženiu spotreby paliva a menšiemu valivému odporu.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "7fb3c6ebfd959dcf5d38"
        },
        {
            "nl_otazka_id": "1174",
            "uv_otazka": "Vodič nesmie vojsť na križovatku, ak mu situácia",
            "uv_odpoveda": "nedovoľuje pokračovať v jazde za križovatkou rýchlosťou vyššou ako 60 km/h.",
            "uv_odpovedb": "dovoľuje pokračovať v jazde za križovatkou len zníženou rýchlosťou.",
            "uv_odpovedc": "nedovoľuje pokračovať za križovatkou v jazde, takže by bol nútený zastaviť vozidlo na križovatke, to neplatí, ak vodič zastavuje vozidlo v križovatke z dôvodu dávania prednosti chodcom prechádzajúcim cez vozovku alebo pri odbočovaní doľava.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "8854f90f560c1a97ae18"
        },
        {
            "nl_otazka_id": "1395",
            "uv_otazka": "Vodič podľa svojich možností nesmie pripustiť",
            "uv_odpoveda": "porušenie povinností ustanovených prepravovaným osobám.",
            "uv_odpovedb": "používanie telefónneho prístroja prepravovanými osobami.",
            "uv_odpovedc": "fajčenie prepravovaných osôb.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "d1aff2a6861a6a8777d4"
        },
        {
            "nl_otazka_id": "1594",
            "uv_otazka": "Ak svieti prerušované biele svetlo na priecestnom zabezpečovacom zariadení, vodič je povinný",
            "uv_odpoveda": "zastaviť vozidlo pred železničným priecestím.",
            "uv_odpovedb": "50 m pred železničným priecestím a pri jeho prechádzaní jazdiť rýchlosťou najviac 50 km.h-1.",
            "uv_odpovedc": "80 m pred železničným priecestím jazdiť rýchlosťou najviac 60 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "d0b7409f0567a1dff160"
        },
        {
            "nl_otazka_id": "1638",
            "uv_otazka": "Táto dopravná značka upozorňuje na",
            "uv_odpoveda": "Priechod pre cyklistov primknutý k priechodu pre chodcov.",
            "uv_odpovedb": "Zvýšený pohyb cyklistov alebo na miesto, kde cyklisti prechádzajú cez cestu alebo na ňu vchádzajú.",
            "uv_odpovedc": "Cestičku pre cyklistov alebo na koridor pre cyklistov.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/143.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "5af3685a3f60621d710a"
        },
        {
            "nl_otazka_id": "1869",
            "uv_otazka": "Vaše vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "prvé.",
            "uv_odpovedb": "tretie.",
            "uv_odpovedc": "druhé.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/10.png",
            "body": "4",
            "poznamka": "",
            "hash": "15e2727a38dbcfb8f4be"
        },
        {
            "nl_otazka_id": "1968",
            "uv_otazka": "Pri používaní  spotrebičov vo vozidle najviac zvýši spotrebu paliva zapnutie",
            "uv_odpoveda": "diaľkových svetiel.",
            "uv_odpovedb": "rádioprijímača.",
            "uv_odpovedc": "vyhrievania sedadiel.",
            "nl_okruh_id": "6",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "893ec2f4a9426674e625"
        },
        {
            "nl_otazka_id": "2182",
            "uv_otazka": "Vodič sa musí riadiť nasledovnou zásadou",
            "uv_odpoveda": "V noci jazdiť len pod dohľadom skúseného vodiča.",
            "uv_odpovedb": "Správať sa ohľaduplne ku každému účastníkovi cestnej premávky; to neplatí pre vodiča nemotorového vozidla.",
            "uv_odpovedc": "V noci znížiť rýchlosť.",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f7c27a643470379681a8"
        },
        {
            "nl_otazka_id": "1017",
            "uv_otazka": "Prekážkou cestnej premávky sa rozumie",
            "uv_odpoveda": "každý účastník cestnej premávky, ktorý porušil pravidlá cestnej premávky.",
            "uv_odpovedb": "každé vozidlo, ktoré prepravuje náklad, ktorý presahuje celkovú dĺžku alebo šírku takéhoto vozidla.",
            "uv_odpovedc": "všetko, čo môže ohroziť alebo obmedziť jazdu vozidiel a pohyb chodcov.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5e176d1f32f19840d325"
        },
        {
            "nl_otazka_id": "1144",
            "uv_otazka": "Vodič, ktorý pri predchádzaní vybočuje zo smeru svojej jazdy",
            "uv_odpoveda": "môže ohroziť vodičov jazdiacich za ním len v malom rozsahu.",
            "uv_odpovedb": "je povinný dávať znamenie o zmene smeru jazdy.",
            "uv_odpovedc": "je povinný v obci dávať svetelné výstražné znamenie.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "0a206ad9aa8f17a4b1fb"
        },
        {
            "nl_otazka_id": "1201",
            "uv_otazka": "Vodič smie zastaviť a stáť na",
            "uv_odpoveda": "moste.",
            "uv_odpovedb": "jednosmernej ceste vpravo i vľavo.",
            "uv_odpovedc": "električkovom koľajovom páse.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "6efcffec86f247e1cbe6"
        },
        {
            "nl_otazka_id": "1181",
            "uv_otazka": "Vodič nesmie pri cúvaní",
            "uv_odpoveda": "za zníženej viditeľnosti rozsvietiť stretávacie svetlomety.",
            "uv_odpovedb": "ohroziť ostatných účastníkov cestnej premávky.",
            "uv_odpovedc": "obmedziť ani ohroziť ostatných účastníkov cestnej premávky.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ac4ec2e93df7ef118a4b"
        },
        {
            "nl_otazka_id": "1320",
            "uv_otazka": "Vozidlo stojace za zníženej viditeľnosti v obci na mieste, kde tvorí prekážku cestnej premávky, alebo na ceste mimo obce",
            "uv_odpoveda": "musí byť osvetlené.",
            "uv_odpovedb": "nemusí byť osvetlené.",
            "uv_odpovedc": "musí byť osvetlené len na miestnych komunikáciách.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "23d1884200bd2e062365"
        },
        {
            "nl_otazka_id": "1050",
            "uv_otazka": "Vodič motorového vozidla",
            "uv_odpoveda": "nesmie ohroziť cyklistov prechádzajúcich cez priechod pre cyklistov.",
            "uv_odpovedb": "je povinný dať prednosť vjazde cyklistovi jazdiacemu po priechode pre cyklistov, len ak je takýto priechod vybavený svetelným signalizačným zariadením.",
            "uv_odpovedc": "je povinný prekážať v jazde rýchlejšie idúcim vozidlám.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f3baae460712f600d64e"
        },
        {
            "nl_otazka_id": "1066",
            "uv_otazka": "Vodič motorového vozidla, ktoré je povinne vybavené bezpečnostným odevom, je povinný mať bezpečnostný odev oblečený, ak sa zdržiava",
            "uv_odpoveda": "na parkovisku za zníženej viditeľnosti.",
            "uv_odpovedb": "na vozovke mimo vozidla počas núdzového státia, najmä pri prerušení jazdy pre chybu na vozidle alebo v dôsledku dopravnej nehody.",
            "uv_odpovedc": "na čerpacej stanici.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "8fa637b603fa31225a25"
        },
        {
            "nl_otazka_id": "1592",
            "uv_otazka": "Vo vzdialenosti 50 m pred železničným priecestím a pri jeho prechádzaní vodič je povinný jazdiť rýchlosťou",
            "uv_odpoveda": "najmenej 30 km.h-1.",
            "uv_odpovedb": "najviac 30 km.h-1.; ak na priecestnom zabezpečovacom zariadení svieti prerušované biele svetlo, najviac 50 km.h-1.",
            "uv_odpovedc": "20 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "d488def3eed6b5e9d667"
        },
        {
            "nl_otazka_id": "1818",
            "uv_otazka": "Vozidlá prejdú cez križovatku v tomto poradí:",
            "uv_odpoveda": "1. červené, 2. zelené, 3. vaše vozidlo.",
            "uv_odpovedb": "1. vaše vozidlo. 2. červené. 3. zelené.",
            "uv_odpovedc": "1. zelené, 2. vaše vozidlo, 3. červené.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/03.png",
            "body": "4",
            "poznamka": "",
            "hash": "1059126d73984812077f"
        },
        {
            "nl_otazka_id": "2069",
            "uv_otazka": "Vodičský preukaz je neplatný, ak",
            "uv_odpoveda": "sa jeho držiteľ vzdal vodičského preukazu.",
            "uv_odpovedb": "sa jeho držiteľ vzdal vodičského oprávnenia.",
            "uv_odpovedc": "je v ňom nesprávne uvedený trvalý pobyt.",
            "nl_okruh_id": "7",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "6b53a81b27c0efca7227"
        },
        {
            "nl_otazka_id": "2113",
            "uv_otazka": "Ak vozidlo nie je schválené na prevádzku v cestnej premávke",
            "uv_odpoveda": "môže takéto vozidlo jeho prevádzkovateľ prevádzkovať v cestnej premávke za splnenia podmienky jeho dodatočného schválenia do 30 dní odo dňa začatia prevádzkovania.",
            "uv_odpovedb": "nesmie takéto vozidlo jeho prevádzkovateľ v cestnej premávke prevádzkovať.",
            "uv_odpovedc": "nesmie takéto vozidlo jeho prevádzkovateľ v cestnej premávke prevádzkovať, jeho prevádzka je však dovolená na miestnych komunikáciách.",
            "nl_okruh_id": "8",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "678828b4684442ed37c8"
        },
        {
            "nl_otazka_id": "2233",
            "uv_otazka": "Keď vodič vidí deti pri okraji cesty",
            "uv_odpoveda": "môže pokračovať v jazde nezníženou rýchlosťou len v prípade, že ho deti vidia",
            "uv_odpovedb": "musí dať zvukové výstražné znamenie",
            "uv_odpovedc": "musí predvídať, že niektoré dieťa nečakane vbehne do jazdnej dráhy vozidla",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "7934c7b1c69c8a61f10a"
        },
        {
            "nl_otazka_id": "2238",
            "uv_otazka": "Vzniku rizikových situácií zabránime najmä",
            "uv_odpoveda": "pomalou jazdou vždy pod hranicou najvyššej dovolenej rýchlosti",
            "uv_odpovedb": "rýchlou jazdou s maximálnym využitím jazdných vlastností vozidla",
            "uv_odpovedc": "defenzívnou (predvídavou) jazdou",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "2e5e12708837a011b798"
        },
        {
            "nl_otazka_id": "2232",
            "uv_otazka": "Ak sa vozidlo nevybavené systémom ABS pri brzdení dostalo do šmyku, vodič musí okamžite",
            "uv_odpoveda": "pridať plyn a zošliapnuť spojku",
            "uv_odpovedb": "pedál brzdy viac zošliapnuť a tým zamedziť šmyku",
            "uv_odpovedc": "uvoľniť pedál brzdy",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "129cf3f37be011ec2661"
        },
        {
            "nl_otazka_id": "1430",
            "uv_otazka": "Za prečnievajúci náklad sa považuje aj",
            "uv_odpoveda": "predmet prepravovaný na strešnom nosiči.",
            "uv_odpovedb": "bicykel alebo predmet prepravovaný na strešnom nosiči.",
            "uv_odpovedc": "bicykel alebo iný predmet prepravovaný na nosiči namontovanom vzadu na vozidle.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "dbcc52e6bfff4fdaf21c"
        },
        {
            "nl_otazka_id": "1292",
            "uv_otazka": "Rukou ohnutou v lakti hore sa dáva znamenie",
            "uv_odpoveda": "na zastavenie vozidla.",
            "uv_odpovedb": "o zmene smeru jazdy na opačnú stranu.",
            "uv_odpovedc": "o zmene smeru jazdy doľava.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "7b53246f516d8f903cec"
        },
        {
            "nl_otazka_id": "1502",
            "uv_otazka": "Policajt dáva pokyn na zastavenie vozidla",
            "uv_odpoveda": "kývaním ruky hore a dolu, ktorá smeruje k zastavovanému vozidlu.",
            "uv_odpovedb": "vodorovným kývaním ruky cez stred tela.",
            "uv_odpovedc": "zdvihnutím ruky s otvorenou dlaňou smerujúcou k zastavovanému vozidlu.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "4b491215c70bc502405a"
        },
        {
            "nl_otazka_id": "1719",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Koniec pešej zóny.",
            "uv_odpovedb": "Koniec obytnej zóny.",
            "uv_odpovedc": "Koniec školskej zóny.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/320.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "0a52e332c1de12672848"
        },
        {
            "nl_otazka_id": "1768",
            "uv_otazka": "Zvislá dopravná značka umiestnená na vozidle",
            "uv_odpoveda": "je rovnocenná zvislej dopravnej značke.",
            "uv_odpovedb": "nie je nadradená zvislej dopravnej značke.",
            "uv_odpovedc": "je nadradená zvislej dopravnej značke.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "c8d720fc5f94c3651c6d"
        },
        {
            "nl_otazka_id": "1893",
            "uv_otazka": "Vozidlá prejdú okolo prekážky v tomto poradí:",
            "uv_odpoveda": "1. modré, 2. červené, 3. žlté, 4. zelené.",
            "uv_odpovedb": "1. červené, 2. modré, 3. žlté, 4. zelené.",
            "uv_odpovedc": "1. modré, 2. zelené, 3. červené, 4. žlté.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/36.png",
            "body": "4",
            "poznamka": "",
            "hash": "159c8d242389355349ec"
        },
        {
            "nl_otazka_id": "1871",
            "uv_otazka": "Modré vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "druhé.",
            "uv_odpovedb": "posledné.",
            "uv_odpovedc": "prvé.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/24.png",
            "body": "4",
            "poznamka": "",
            "hash": "a2372cc6304da1f335f4"
        },
        {
            "nl_otazka_id": "1924",
            "uv_otazka": "Účastník škodovej udalosti je povinný",
            "uv_odpoveda": "bezodkladne zastaviť vozidlo, len ak ku škodovej udalosti došlo počas zníženej viditeľnosti.",
            "uv_odpovedb": "bezodkladne zastaviť vozidlo.",
            "uv_odpovedc": "bezodkladne zastaviť vozidlo, len ak škoda na vozidle presahuje jedenapolnásobok väčšej škody podľa Trestného zákona.",
            "nl_okruh_id": "5",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "984889be3fae0df5e12f"
        },
        {
            "nl_otazka_id": "2222",
            "uv_otazka": "Poľadovicu možno najčastejšie očakávať",
            "uv_odpoveda": "na nespevnených vozovkách",
            "uv_odpovedb": "na moste alebo na cestných komunikáciách v horských oblastiach",
            "uv_odpovedc": "v tuneli",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "917ee9ef14ac5622bf6b"
        },
        {
            "nl_otazka_id": "2330",
            "uv_otazka": "Ak svieti táto kontrolka znamená to",
            "uv_odpoveda": "nízku hladinu kvapaliny v nádržke ostrekovača. ",
            "uv_odpovedb": "nízku teplotu chladiacej kvapaliny.",
            "uv_odpovedc": "motor sa prehrieva, čo značí jeho preťaženie alebo chýbajúcu chladiacu kvapalinu.",
            "nl_okruh_id": "10",
            "nl_correct": "2",
            "uv_obrazok": "obr3/tvv/18.png",
            "body": "1",
            "poznamka": "",
            "hash": "0222122c58aabe6a64f7"
        },
        {
            "nl_otazka_id": "1445",
            "uv_otazka": "Kto smie okrem chodcov používať priechod pre chodcov?",
            "uv_odpoveda": "Cyklista.",
            "uv_odpovedb": "Osoba, ktorá tlačí ručný vozík celkovou šírkou väčšou ako 1 m.",
            "uv_odpovedc": "Vodič samovyvažovacieho vozidla, ak pritom neohrozí a neobmedzí chodcov.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1c7421247b85cd64268f"
        },
        {
            "nl_otazka_id": "1298",
            "uv_otazka": "Pri jazde po kruhovom objazde vodič dáva znamenie o zmene smeru jazdy",
            "uv_odpoveda": "len vtedy, ak predchádza.",
            "uv_odpovedb": "len, ak z takej križovatky vychádza.",
            "uv_odpovedc": "ak z takej križovatky vychádza a pri prechádzaní z jedného jazdného pruhu do druhého jazdného pruhu.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/213.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "7feccc6977c80559d545"
        },
        {
            "nl_otazka_id": "1304",
            "uv_otazka": "Ak je nevyhnutné upozorniť ostatných účastníkov cestnej premávky na hroziace nebezpečenstvo, najmä v prípadoch, keď je nutné náhle znížiť rýchlosť jazdy alebo zastaviť vozidlo, vodič",
            "uv_odpoveda": "dáva svetelné výstražné znamenie zapnutím výstražnej funkcie smerových svietidiel.",
            "uv_odpovedb": "použije vodič zvukové výstražné znamenie.",
            "uv_odpovedc": "dáva vodič svetelné výstražné znamenie prerušovaným zapínaním predných hmlových svietidiel.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "10d1998fa4ba15c90c98"
        },
        {
            "nl_otazka_id": "1315",
            "uv_otazka": "Vodič pri zastavení vozidla pred železničným priecestím",
            "uv_odpoveda": "nesmie použiť predné svetlomety do hmly.",
            "uv_odpovedb": "smie vždy použiť stretávacie svetlomety.",
            "uv_odpovedc": "nesmie použiť ani stretávacie svetlomety, ak by nimi mohol oslniť vodiča vozidla v protismere.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "916176e75c0152c0ce19"
        },
        {
            "nl_otazka_id": "1387",
            "uv_otazka": "Parkovací preukaz sa môže používať na vozidle",
            "uv_odpoveda": "lekára pri poskytovaní zdravotnej starostlivosti.",
            "uv_odpovedb": "vedenom osobou so sluchovým postihnutím.",
            "uv_odpovedc": "prepravujúcom fyzickú osobu s ťažkým zdravotným postihnutím, ktorá spĺňa podmienky podľa osobitného predpisu.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "a31aff0311144bd80206"
        },
        {
            "nl_otazka_id": "1603",
            "uv_otazka": "Vodič motorového vozidla musí diaľnicu opustiť na najbližšom výjazde, ak počas jazdy zistí na vozidle alebo na náklade chybu, pre ktorú nemožno dosiahnuť na rovine rýchlosť najmenej",
            "uv_odpoveda": "100 km.h-1.",
            "uv_odpovedb": "60 km.h-1.",
            "uv_odpovedc": "80 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5001ba03ee9dacdff03e"
        },
        {
            "nl_otazka_id": "1701",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Priechod pre chodcov.",
            "uv_odpovedb": "Zákaz vstupu chodcov.",
            "uv_odpovedc": "Pešiu zónu.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/325.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "d0a43478cd0ab0baf35d"
        },
        {
            "nl_otazka_id": "1782",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Bicykle",
            "uv_odpovedb": "Kolobežky",
            "uv_odpovedc": "Mopedy",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/1-9.png",
            "body": "2",
            "poznamka": "",
            "hash": "a01e35e5c00277d7c370"
        },
        {
            "nl_otazka_id": "1951",
            "uv_otazka": "Ak svieti táto kontrolka, signalizuje že",
            "uv_odpoveda": "došlo k prekročeniu rýchlostného limitu.",
            "uv_odpovedb": "tempomat je neaktívny.",
            "uv_odpovedc": "tempomat je aktívny.",
            "nl_okruh_id": "6",
            "nl_correct": "3",
            "uv_obrazok": "obr3/tvv/33.png",
            "body": "2",
            "poznamka": "",
            "hash": "8a3d1477721e98caeafc"
        },
        {
            "nl_otazka_id": "2068",
            "uv_otazka": "Vodičský preukaz je neplatný, ak",
            "uv_odpoveda": "držiteľ nahlási jeho stratu, alebo odcudzenie.",
            "uv_odpovedb": "držiteľ nahlási jeho stratu, odcudzenie nahlásiť nemusí.",
            "uv_odpovedc": "držiteľ nahlási jeho odcudzenie, stratu nahlásiť nemusí.",
            "nl_okruh_id": "7",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "ab7073f20dbd2dc7ae64"
        },
        {
            "nl_otazka_id": "2141",
            "uv_otazka": "Vozidlo sa považuje za technicky nespôsobilé na prevádzku v cestnej premávke, najmä ak",
            "uv_odpoveda": "nedosahuje rýchlosť najmenej 50 km/h.",
            "uv_odpovedb": "nie je žiadna brzdná sila prevádzkovej brzdy na jednom alebo viacerých kolesách.",
            "uv_odpovedc": "namá funkčné osvetlenie batožinového priestoru.",
            "nl_okruh_id": "8",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "bfcfc0cb45eb676c0ee7"
        },
        {
            "nl_otazka_id": "2299",
            "uv_otazka": "Vodič vozidla jazdí za traktorom a chce ho predísť, ale nie je istý či je to bezpečné. Ako by mal postupovať:",
            "uv_odpoveda": "Predísť traktor čo najrýchlejšie s rozsvietenými diaľkovými svetlami s cieľom upozorniť vodičov v protismernej premávke.",
            "uv_odpovedb": "Nasledovať v tesnej blízkosti iné vozidlo, ktoré traktor predchádza.",
            "uv_odpovedc": "Začať predchádzať, až keď si je istý, že je to bezpečné.",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "c2813086a925b205b81a"
        },
        {
            "nl_otazka_id": "2326",
            "uv_otazka": "Rozdiel v činnosti štvordobého zážihového motora a vznetového motora je",
            "uv_odpoveda": "zážihový motor nasáva vždy len čistý vzduch, pričom palivo je do valcov vstreknuté dýzou a vznetový motor nasáva vždy len zmes.",
            "uv_odpovedb": "zážihový motor nasáva vzduch a palivo (benzín) sa do valcov vstrekuje dýzou alebo nasáva zmes benzínu a vzduchu, kým vznetový motor vždy nasáva čistý vzduch.",
            "uv_odpovedc": "zážihový motor nasáva palivo a vznetový motor nasáva zmes.",
            "nl_okruh_id": "10",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "7bb60dedd5c7563e930a"
        },
        {
            "nl_otazka_id": "1207",
            "uv_otazka": "Aký bočný odstup je povinný zachovať vodič pri zastavení a státí vedľa vozidla s parkovacím preukazom pre fyzickú osobu so zdravotným postihnutím?",
            "uv_odpoveda": "Minimálne 1,5 m.",
            "uv_odpovedb": "Minimálne 2 m.",
            "uv_odpovedc": "Najmenej 1,2 m.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "obr3/pcp/23_3.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "77fe06ad1d2d3d01bbc7"
        },
        {
            "nl_otazka_id": "1204",
            "uv_otazka": "Ak nie je ohrozená bezpečnosť a plynulosť cestnej premávky, vodič smie",
            "uv_odpoveda": "v obci aj mimo obce zastaviť a stáť kolmo na okraj cesty.",
            "uv_odpovedb": "mimo obce zastaviť a stáť šikmo na okraj cesty.",
            "uv_odpovedc": "v obci zastaviť a stáť kolmo, prípadne šikmo na okraj cesty alebo zastaviť v druhom rade.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "9820106315b98e5e29d7"
        },
        {
            "nl_otazka_id": "1006",
            "uv_otazka": "Chodníkom sa rozumie",
            "uv_odpoveda": "pravá krajnica; kde krajnica nie je, rozumie sa chodníkom pravý okraj vozovky široký jeden meter.",
            "uv_odpovedb": "komunikácia alebo časť cesty určená pre chodcov, ktorá je spravidla oddelená od vozovky výškovo alebo iným spôsobom.",
            "uv_odpovedc": "len komunikácia alebo časť cesty označená príslušnou dopravnou značkou.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "27049761787c40de8c19"
        },
        {
            "nl_otazka_id": "1231",
            "uv_otazka": "Vodič nesmie zastaviť a stáť na železničnom priecestí, v podjazde a v tuneli a vo vzdialenosti kratšej ako",
            "uv_odpoveda": "15 m pred nimi a 15 m za nimi.",
            "uv_odpovedb": "80 m pred nimi a 80 m za nimi.",
            "uv_odpovedc": "160 m pred nimi a 160 m za nimi.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "4df4e6eaa5c214206624"
        },
        {
            "nl_otazka_id": "1750",
            "uv_otazka": "Ak dopravná značka vyznačuje jazdný pruh",
            "uv_odpoveda": "Je zakázané ju prechádzať alebo nákladom presahovať, ak to nie je potrebné na obchádzanie prekážky cestnej premávky alebo na predchádzanie vozidla.",
            "uv_odpovedb": "Je zakázané ju prechádzať alebo nákladom presahovať, ak to nie je potrebné na obchádzanie prekážky cestnej premávky.",
            "uv_odpovedc": "Je zakázané ju prechádzať alebo nákladom presahovať, ak to nie je potrebné na odbočenie na poľnú cestu. Platí iba pre traktory.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/601.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "84a49590704031df39ba"
        },
        {
            "nl_otazka_id": "1680",
            "uv_otazka": "Táto dopravná značka znamená:",
            "uv_odpoveda": "Zákaz zastavenia. Zákaz neplatí na priľahlý chodník.",
            "uv_odpovedb": "Zákaz zastavenia a státia. Zákaz platí aj na priľahlý chodník.",
            "uv_odpovedc": "Koniec všetkých zákazov.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/270.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "9d564b7ea6c231a30233"
        },
        {
            "nl_otazka_id": "2004",
            "uv_otazka": "Pred jazdou s prívesom sa vykoná kontrola",
            "uv_odpoveda": "funkčnosti osvetlenia na ťažnom vozidle.",
            "uv_odpovedb": "spojenia, funkčnosti poistného zariadenia a osvetlenia jazdnej súpravy.",
            "uv_odpovedc": "funkčnosti osvetlenia prívesu.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "46259279f3c6ca972cbe"
        },
        {
            "nl_otazka_id": "2135",
            "uv_otazka": "Vozidlo sa považuje za technicky nespôsobilé na prevádzku v cestnej premávke, najmä ak",
            "uv_odpoveda": "je riziko odpadnutia systému obmedzenia zvuku.",
            "uv_odpovedb": "nedosahuje rýchlosť najmenej 80 km/h.",
            "uv_odpovedc": "z miesta vodiča nie je možné nastaviť pravé predné zrkadlo.",
            "nl_okruh_id": "8",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "e3a690d654648f934deb"
        },
        {
            "nl_otazka_id": "1167",
            "uv_otazka": "Vodič pri odbočovaní nesmie",
            "uv_odpoveda": "dávať znamenie o zmene smeru jazdy.",
            "uv_odpovedb": "dávať znamenie o zmene smeru jazdy, ak odbočuje na miesto mimo cesty.",
            "uv_odpovedc": "ohroziť vodiča idúceho za ním.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "a36de652c0df7cb444a6"
        },
        {
            "nl_otazka_id": "1123",
            "uv_otazka": "Pozdĺž električky sa jazdí",
            "uv_odpoveda": "za každých okolností vľavo.",
            "uv_odpovedb": "vpravo, ak nie je dopravnou značkou povolená jazda vľavo.",
            "uv_odpovedc": "vľavo, ak nie je dopravnou značkou povolená jazda vpravo.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "056eb7662fd65c2e89dc"
        },
        {
            "nl_otazka_id": "1354",
            "uv_otazka": "Vlečenie motorového vozidla na diaľnici je",
            "uv_odpoveda": "zakázané za každých okolností.",
            "uv_odpovedb": "dovolené len vtedy, ak je to nevyhnutné na jeho odstránenie z diaľnice.",
            "uv_odpovedc": "dovolené, len ak je vlečeným vozidlom osobné motorové vozidlo.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "99a110e3f454380db4e6"
        },
        {
            "nl_otazka_id": "1340",
            "uv_otazka": "Vodič motorového vozidla smie na diaľnicu vchádzať a z diaľnice vychádzať",
            "uv_odpoveda": "všade tam, kde neohrozí vodičov iných vozidiel.",
            "uv_odpovedb": "len na miestach na to určených.",
            "uv_odpovedc": "tam, kde je to preňho najvýhodnejšie.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "c295fcc96f6dde2a1b69"
        },
        {
            "nl_otazka_id": "1774",
            "uv_otazka": "Zónová dopravná značka je regulačná značka, ktorá platí",
            "uv_odpoveda": "na všetkých cestách zóny v oboch smeroch jazdy po značku, ktorá ju ukončuje.",
            "uv_odpovedb": "len na niektorých cestách zóny.",
            "uv_odpovedc": "len na ceste, kde je umiestnená.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "ea8b28e83df03e65b8cb"
        },
        {
            "nl_otazka_id": "1898",
            "uv_otazka": "Vodič červeného vozidla",
            "uv_odpoveda": "má prednosť pred vodičom modrého vozidla, pretože prichádza sprava.",
            "uv_odpovedb": "musí dať prednosť vodičovi modrého vozidla, pretože prichádza zľava.",
            "uv_odpovedc": "musí dať prednosť vodičovi modrého vozidla, pretože ide po kruhovom objazde.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/07.png",
            "body": "4",
            "poznamka": "",
            "hash": "c5112996386acaa51943"
        },
        {
            "nl_otazka_id": "2071",
            "uv_otazka": "Vodičský preukaz je neplatný, ak",
            "uv_odpoveda": "údaje v ňom uvedené nie je možné identifikovať.",
            "uv_odpovedb": "je v ňom nesprávne uvedený trvalý pobyt.",
            "uv_odpovedc": "ho držiteľ nemá počas jazdy pri sebe.",
            "nl_okruh_id": "7",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "446df7817efb100fd426"
        },
        {
            "nl_otazka_id": "2342",
            "uv_otazka": "Pri dopĺňaní chladiacej kvapaliny by mala platiť zásada, že",
            "uv_odpoveda": "chladiace kvapaliny rôzneho typu sa nesmú miešať.",
            "uv_odpovedb": "chladiacu kvapalinu dopĺňame až vtedy, ak je motor úplne zohriaty.",
            "uv_odpovedc": "dopĺňame len destilovanú vodu.",
            "nl_okruh_id": "10",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "e5f44efe6340638cbd3c"
        },
        {
            "nl_otazka_id": "1241",
            "uv_otazka": "Na moste je zakázané",
            "uv_odpoveda": "predchádzať.",
            "uv_odpovedb": "zastaviť a stáť.",
            "uv_odpovedc": "obchádzať.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ab90da4abc72a37d1a4d"
        },
        {
            "nl_otazka_id": "1511",
            "uv_otazka": "Ak svieti tento pruhový signál umiestnený nad jazdným pruhom, pre vodiča znamená:",
            "uv_odpoveda": "Jazdný pruh sa môže používať.",
            "uv_odpovedb": "Jazdný pruh smie používať len vodič motocykla.",
            "uv_odpovedc": "Jazdný pruh sa nesmie používať.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "obr3/pcp/17_1b.png",
            "body": "3",
            "poznamka": "",
            "hash": "59729cd289b463603d29"
        },
        {
            "nl_otazka_id": "1238",
            "uv_otazka": "Vodič nesmie zastaviť a stáť vo vzdialenosti kratšej ako",
            "uv_odpoveda": "5 m od začiatku a 5 m od konca pozdĺžnej súvislej čiary alebo nástupného ostrovčeka tam, kde by medzi touto čiarou alebo nástupným ostrovčekom a vozidlom nezostal voľný aspoň jeden jazdný pruh široký najmenej 3 m.",
            "uv_odpovedb": "10 m od začiatku a 5 m od konca nástupného ostrovčeka.",
            "uv_odpovedc": "10 m od začiatku a 10 m od konca pozdĺžnej súvislej čiary alebo nástupného ostrovčeka.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "0d116f75b0301a1e5612"
        },
        {
            "nl_otazka_id": "1049",
            "uv_otazka": "Vodič nesmie",
            "uv_odpoveda": "dať prednosť chodcom prechádzajúcim cez priechod pre chodcov, ak by na ten účel musel zastaviť vozidlo.",
            "uv_odpovedb": "zastaviť a stáť na účelovej komunikácii mimo obce.",
            "uv_odpovedc": "ohroziť chodcov na chodníku, cyklistov na cestičke pre cyklistov, chodcov a cyklistov pri odbočovaní, pri vchádzaní na cestu a pri otáčaní alebo pri cúvaní.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5b8b0e95ddec581138ab"
        },
        {
            "nl_otazka_id": "1080",
            "uv_otazka": "Dieťa s telesnou výškou menšou ako 150 cm pri preprave vo vozidle vybavenom bezpečnostným systémom musí byť",
            "uv_odpoveda": "pripútané bezpečnostným pásom len na prednom sedadle.",
            "uv_odpovedb": "upínané detským zadržiavacím zariadením, ktoré je vhodné pre telesnú konštitúciu dieťaťa.",
            "uv_odpovedc": "pripútané bezpečnostným pásom mimo obce.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e93c53dae3bc4fb57d92"
        },
        {
            "nl_otazka_id": "1578",
            "uv_otazka": "Vodič odbočujúci vľavo je povinný dať prednosť v jazde",
            "uv_odpoveda": "len protiidúcim vozidlám a vozidlám idúcim vo vyhradenom jazdnom pruhu po jeho ľavej strane.",
            "uv_odpovedb": "len protiidúcim motorovým vozidlám.",
            "uv_odpovedc": "protiidúcim motorovým vozidlám i nemotorovým vozidlám, električkám idúcim v oboch smeroch, vozidlám idúcim vo vyhradenom jazdnom pruhu po jeho ľavej strane, cyklistom idúcim súbežne s cestou a chodcom prechádzajúcim cez vozovku.",
            "nl_okruh_id": "2",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "a9e98104a07fc61330ed"
        },
        {
            "nl_otazka_id": "1678",
            "uv_otazka": "Táto dopravná značka ukončuje",
            "uv_odpoveda": "Odporúčanú rýchlosť.",
            "uv_odpovedb": "Zákaz vyplývajúci z predchádzajúcej značky Najvyššia dovolená rýchlosť.",
            "uv_odpovedc": "Najnižšiu dovolenú rýchlosť vyplývajúcu zo všeobecných pravidiel cestnej premávky.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/263.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "936fcfe3e4f1fef1b4a8"
        },
        {
            "nl_otazka_id": "2230",
            "uv_otazka": "Ak s vozidlom zájdeme na nespevnenú krajnicu,",
            "uv_odpoveda": "plynule uberieme plyn a po spomalení sa na vhodnom mieste vrátime na vozovku",
            "uv_odpovedb": "musíme intenzívne brzdiť, aby sme čo najskôr zastavili vozidlo",
            "uv_odpovedc": "snažíme sa čo najrýchlejšie vrátiť vozidlo na vozovku a pritom intenzívne brzdíme",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "646aabbbc5d77783b7cb"
        },
        {
            "nl_otazka_id": "2247",
            "uv_otazka": "Ktorú zásadu by mal dodržiavať začínajúci vodič? Mal by",
            "uv_odpoveda": "jazdiť pod dohľadom vodiča, ktorý má 10 ročnú prax vo vedení vozidla.",
            "uv_odpovedb": "dodržiavať bezpečnú vzdialenosť za vozidlom idúcimi pred ním.",
            "uv_odpovedc": "jazdiť na krátke vzdialenosti pod dohľadom vodiča, ktorý má 10 ročnú prax vo vedení vozidla.",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "15ca1be4b4b2715a855b"
        },
        {
            "nl_otazka_id": "1247",
            "uv_otazka": "Na kruhovom objazde je státie",
            "uv_odpoveda": "dovolené.",
            "uv_odpovedb": "zakázané.",
            "uv_odpovedc": "zakázané, ak je to nie je potrebné na urýchlené nastúpenie alebo vystúpenie prepravovaných osôb.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "de17f564e02fd640cdc7"
        },
        {
            "nl_otazka_id": "1220",
            "uv_otazka": "Vodič vozidla, ktoré je povinne vybavené prenosným výstražným trojuholníkom, je povinný tento trojuholník použiť počas núdzového státia, ak také vozidlo tvorí prekážku cestnej premávky. Vodič je povinný trojuholník umiestniť na okraji vozovky tak, aby ho prichádzajúci vodiči včas a zreteľne videli, a to vo vzdialenosti najmenej",
            "uv_odpoveda": "30 m za vozidlom mimo obce a na diaľnici najmenej 60 m za vozidlom.",
            "uv_odpovedb": "50 m za vozidlom v obci aj mimo obce.",
            "uv_odpovedc": "50 m za vozidlom a na diaľnici najmenej 100 m za vozidlom, v obci môže byť táto vzdialenosť, ak to vyžadujú okolnosti, kratšia.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "dda49796535239b3e6a7"
        },
        {
            "nl_otazka_id": "1867",
            "uv_otazka": "Vozidlá prejdú cez križovatku v tomto poradí:",
            "uv_odpoveda": "1. červené, 2.modré, 3. zelené.",
            "uv_odpovedb": "1. zelené, 2. červené, 3. modré.",
            "uv_odpovedc": "1. zelené, 2. modré, 3. červené.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/09.png",
            "body": "4",
            "poznamka": "",
            "hash": "cd6ca5fd0c6db339dd80"
        },
        {
            "nl_otazka_id": "1892",
            "uv_otazka": "Ako prvé prejde cez križovatku",
            "uv_odpoveda": "zelené vozidlo.",
            "uv_odpovedb": "modré vozidlo súčasne s obidvoma električkami.",
            "uv_odpovedc": "červené vozidlo súčasne s modrým vozidlom.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "2023/2000001_DS35.jpg",
            "body": "4",
            "poznamka": "",
            "hash": "fa48ca97359bb842c5c2"
        },
        {
            "nl_otazka_id": "2243",
            "uv_otazka": "Ktorú zásadu by mal dodržiavať začínajúci vodič?",
            "uv_odpoveda": "Zo začiatku by mal zvoliť krátke a jednoduché trasy, ktoré pozná.",
            "uv_odpovedb": "Na krátke vzdialenosti by mal jazdiť vždy s rodičom.",
            "uv_odpovedc": "Mal by jazdiť čo najbližšie pri stredovej čiare.",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "a1aefac236a8fc51a810"
        },
        {
            "nl_otazka_id": "2173",
            "uv_otazka": "Pri krízovom brzdení so systémom ABS dosiahne vodič najkratšiu brzdnú dráhu, ak brzdí:",
            "uv_odpoveda": "maximálnou silou prerušovane",
            "uv_odpovedb": "maximálnou silou neprerušovane",
            "uv_odpovedc": "čiastočne a veľmi citlivo",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "7122c54c4608b3526410"
        },
        {
            "nl_otazka_id": "1213",
            "uv_otazka": "Otvárať dvere alebo bočné steny vozidla, ako aj nastupovať do vozidla alebo vystupovať z neho sa smie len vtedy, ak",
            "uv_odpoveda": "vozidlo stojí na parkovisku.",
            "uv_odpovedb": "na to dá pokyn pribratá náležité poučená osoba.",
            "uv_odpovedc": "tým nie je ohrozená bezpečnosť nastupujúcich osôb alebo vystupujúcich osôb ani iných účastníkov cestnej premávky.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "0f1f8d4dd79e6f311ec3"
        },
        {
            "nl_otazka_id": "1376",
            "uv_otazka": "Kto spôsobil prekážku cestnej premávky",
            "uv_odpoveda": "nesmie ju odstrániť; takúto prekážku cestnej premávky je povinný odstrániť správca cesty.",
            "uv_odpovedb": "je povinný ju bezodkladne odstrániť; ak to neurobí, je povinný ju bezodkladne odstrániť na jeho náklady správca cesty.",
            "uv_odpovedc": "je povinný ju odstrániť, len ak ju spôsobil na diaľnici; na ostatných cestách ju smie odstrániť len správca cesty.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e596d02d898202d96ea9"
        },
        {
            "nl_otazka_id": "1290",
            "uv_otazka": "Ak vozidlo nie je vybavené smerovými svietidlami alebo ak majú poruchu, dáva sa znamenie o zmene smeru jazdy",
            "uv_odpoveda": "upažením; rukou ohnutou v lakti hore sa dáva znamenie o zmene smeru jazdy na opačnú stranu.",
            "uv_odpovedb": "predpažením; zapažením sa dáva znamenie o zmene smeru jazdy na opačnú stranu.",
            "uv_odpovedc": "mimo obce svetelným výstražným znamením a v obci zvukovým výstražným znamením počas celého jazdného úkonu.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5fa1e4d0b60b82a0cb69"
        },
        {
            "nl_otazka_id": "1398",
            "uv_otazka": "Prepravované osoby nesmú",
            "uv_odpoveda": "jesť, piť a fajčiť.",
            "uv_odpovedb": "svojím správaním ohrozovať bezpečnosť cestnej premávky najmä obmedzovaním vodiča v bezpečnom ovládaní vozidla, zotrvaním na miestach, kde by boli ohrozené, a vyhadzovaním predmetov z vozidla.",
            "uv_odpovedc": "obsluhovať telefónny prístroj.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5e5cd00787556ee73420"
        },
        {
            "nl_otazka_id": "1556",
            "uv_otazka": "Vodič smie jazdiť len primeranou rýchlosťou, aby bol schopný zastaviť vozidlo na vzdialenosť",
            "uv_odpoveda": "na ktorú má rozhľad.",
            "uv_odpovedb": "20 m, na diaľnici a ceste pre motorové vozidlá 50 m.",
            "uv_odpovedc": "30 m.",
            "nl_okruh_id": "2",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "657f6f6953aef8aba6bb"
        },
        {
            "nl_otazka_id": "1636",
            "uv_otazka": "Táto dopravná značka upozorňuje na",
            "uv_odpoveda": "Zvýšený pohyb chodcov na vozovke.",
            "uv_odpovedb": "Priechod pre chodcov",
            "uv_odpovedc": "Miesto, kde sú dovolené hry detí na ceste.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/140.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "065a122a66d9464c97ea"
        },
        {
            "nl_otazka_id": "1684",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Znamená jednosmerná premávka.",
            "uv_odpovedb": "Znamená zákruta vpravo.",
            "uv_odpovedc": "Prikazuje odbočenie vo vyznačenom smere.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/211.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "7b95b16f6c21211d538e"
        },
        {
            "nl_otazka_id": "1983",
            "uv_otazka": "Ktoré elektrospotrebiče výrazne ovplyvňujú spotrebu paliva?",
            "uv_odpoveda": "Denné LED svetlá.",
            "uv_odpovedb": "Vyhrievanie sedadiel a zadného skla.",
            "uv_odpovedc": "Osvetlenie interiéru vozidla.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "41b045ab4c15bc473e8e"
        },
        {
            "nl_otazka_id": "2317",
            "uv_otazka": "Prevádzková teplota motora chladeného kvapalinou je",
            "uv_odpoveda": "110 až 130 °C.",
            "uv_odpovedb": "60 až 70 °C.",
            "uv_odpovedc": "80 až 90 °C.",
            "nl_okruh_id": "10",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "59ee3121719b07f2e8ca"
        },
        {
            "nl_otazka_id": "1308",
            "uv_otazka": "Za nezníženej viditeľnosti môžu byť na vozidle namiesto stretávacích svetlometov rozsvietené",
            "uv_odpoveda": "hmlové svetlomety.",
            "uv_odpovedb": "denné prevádzkové svietidlá.",
            "uv_odpovedc": "obrysové svietidlá.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "dee4d5be562da74330d4"
        },
        {
            "nl_otazka_id": "1227",
            "uv_otazka": "Na pripájacom alebo na odbočovacom pruhu vodič",
            "uv_odpoveda": "môže len zastaviť, nesmie však stáť.",
            "uv_odpovedb": "nesmie zastaviť a stáť.",
            "uv_odpovedc": "môže zastaviť a stáť len vpravo.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "8334d50931fb57da4d3f"
        },
        {
            "nl_otazka_id": "1551",
            "uv_otazka": "Tam, kde sa dva jazdné pruhy zbiehajú do jedného jazdného pruhu tak, že nie je zrejmé, ktorý z nich je priebežný",
            "uv_odpoveda": "vodič jazdiaci v ľavom jazdnom pruhu je povinný dať prednosť v jazde vodičovi v pravom jazdnom pruhu; to neplatí pri súbežnej jazde.",
            "uv_odpovedb": "má prednosť v jazde vždy vodič vozidla s vyššou hmotnosťou.",
            "uv_odpovedc": "vodič jazdiaci v ľavom jazdnom pruhu má prednosť v jazde.",
            "nl_okruh_id": "2",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5b5d5443b7793607817c"
        },
        {
            "nl_otazka_id": "1803",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Centrum",
            "uv_odpovedb": "Táborisko",
            "uv_odpovedc": "Helipad",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/2-31.png",
            "body": "2",
            "poznamka": "",
            "hash": "f6cacffca38b9753a446"
        },
        {
            "nl_otazka_id": "1874",
            "uv_otazka": "Vozidlá prejdú cez križovatku v tomto poradí:",
            "uv_odpoveda": "1. červené, 2. modré, 3. zelené.",
            "uv_odpovedb": "1. modré, 2. zelené, 3. červené.",
            "uv_odpovedc": "1. zelené. 2. modré. 3. červené.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/26.png",
            "body": "4",
            "poznamka": "",
            "hash": "fab984c2dc4af2ce04e7"
        },
        {
            "nl_otazka_id": "2224",
            "uv_otazka": "Ak má vodič pochybnosti o úspechu predchádzania, zásadne",
            "uv_odpoveda": "sa vráti čo najskôr do svojho pruhu",
            "uv_odpovedb": "nezačne predchádzať",
            "uv_odpovedc": "musí zvýšiť rýchlosť jazdy",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "d8c838ded24838b3a1d4"
        },
        {
            "nl_otazka_id": "1318",
            "uv_otazka": "Činná plocha svetlometov a svietidiel",
            "uv_odpoveda": "môže byť čiastočne zakrytá prepravovaným nákladom.",
            "uv_odpovedb": "môže byť nadmerne znečistená za hmly, sneženia alebo za dažďa.",
            "uv_odpovedc": "nesmie byť zakrytá alebo nadmerne znečistená s výnimkou svetlometov a svietidiel, ktoré nie sú predpísané.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "82d25e648fb5eb66f4b1"
        },
        {
            "nl_otazka_id": "1013",
            "uv_otazka": "Nemotorovým vozidlom sa rozumie",
            "uv_odpoveda": "aj motorové vozidlo, ktorého pohotovostná hmotnosť nepresahuje 400 kg.",
            "uv_odpovedb": "prípojné vozidlo, vozidlo pohybujúce sa pomocou ľudskej sily alebo zvieracej sily s výnimkou mechanického alebo elektrického vozíka pre osoby so zdravotným postihnutím.",
            "uv_odpovedc": "vozidlo pohybujúce sa pomocou ľudskej sily alebo zvieracej sily, električka a trolejbus.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "a431ac075e83baf44122"
        },
        {
            "nl_otazka_id": "1808",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Parkovacie miesta s priečnym státím na chodníku",
            "uv_odpovedb": "Parkovacie miesta s pozdĺžnym státím na chodníku (státie povolené súbežne na os vozovky, všetkými kolesami na chodníku)",
            "uv_odpovedc": "Parkovacie miesta s priečnym státím čiastočne na chodníku",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/4-4.png",
            "body": "2",
            "poznamka": "",
            "hash": "d1e5d4d38629127bac85"
        },
        {
            "nl_otazka_id": "1735",
            "uv_otazka": "Táto dopravná značka informuje",
            "uv_odpoveda": "O čísle cesty I. triedy alebo o čísle cesty II. triedy.",
            "uv_odpovedb": "O čísle cesty III. triedy.",
            "uv_odpovedc": "O čísle medzinárodnej trasy.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/350.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "09a5236aad292d0ef513"
        },
        {
            "nl_otazka_id": "1963",
            "uv_otazka": "Vozidlá s samočinnou (automatickou) prevodovkou majú len dva pedále, chýba pedál",
            "uv_odpoveda": "plynu.",
            "uv_odpovedb": "prevádzkovej brzdy.",
            "uv_odpovedc": "spojky.",
            "nl_okruh_id": "6",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "7c97c67b1e945a1f05fd"
        },
        {
            "nl_otazka_id": "1126",
            "uv_otazka": "Pri vchádzaní na električkový koľajový pás zvýšený nad úroveň vozovky a pri jeho prechádzaní vodič",
            "uv_odpoveda": "môže prekážať v jazde električke len v rozsahu nevyhnutnom na vykonanie jazdného úkonu.",
            "uv_odpovedb": "nesmie ohroziť ani obmedziť električku vjazde.",
            "uv_odpovedc": "môže podľa okolností obmedziť električku vjazde, nesmie ju však ohroziť.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "2cda56f155698c6cdd6c"
        },
        {
            "nl_otazka_id": "1388",
            "uv_otazka": "Vodič vozidla s parkovacím preukazom",
            "uv_odpoveda": "je povinný parkovací preukaz odstrániť z vozidla, ak sa nepoužíva na účel, na ktorý je určený.",
            "uv_odpovedb": "môže zastaviť a stáť len na vyhradenom parkovisku.",
            "uv_odpovedc": "je povinný zastaviť a stáť len na parkovisku s vyhradeným státím.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "65b9cc0d5a5747e9b372"
        },
        {
            "nl_otazka_id": "1756",
            "uv_otazka": "Značka umiestnená v osi",
            "uv_odpoveda": "Priebežného jazdného pruhu vyznačuje jeho blížiace sa skončenie.",
            "uv_odpovedb": "Priebežného jazdného pruhu prikazuje vodičovi odbočiť v smere šípok.",
            "uv_odpovedc": "Protismerného jazdného pruhu vyznačuje prikázaný smer jazdy za križovatkou.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/631.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "2824343f2534238ea85b"
        },
        {
            "nl_otazka_id": "1824",
            "uv_otazka": "Vozidlá prejdú cez križovatku v tomto poradí:",
            "uv_odpoveda": "1. zelené, 2. modré, 3. vaše vozidlo.",
            "uv_odpovedb": "1. modré, 2. vaše vozidlo, 3. zelené.",
            "uv_odpovedc": "1. modré, 2. zelené, 3. vaše vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/11.png",
            "body": "4",
            "poznamka": "",
            "hash": "b3810eb5aacfe51b3653"
        },
        {
            "nl_otazka_id": "1925",
            "uv_otazka": "Účastník škodovej udalosti je povinný",
            "uv_odpoveda": "ohlásiť dopravnú nehodu policajtovi.",
            "uv_odpovedb": "ohlásiť škodovú udalosť obvodnému úradu dopravy.",
            "uv_odpovedc": "inému účastníkovi škodovej udalosti, poskytnúť údaje o poistení vozidla.",
            "nl_okruh_id": "5",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "5d70b1ed0c17bc84faa6"
        },
        {
            "nl_otazka_id": "2066",
            "uv_otazka": "Ak sa vodič nezdržuje vo vozidle nesmie v ňom uchovávať",
            "uv_odpoveda": "doklad o úhrade mýta.",
            "uv_odpovedb": "osvedčenie o evidencii časť I, osvedčenie o evidencii časť II alebo technické osvedčenie vozidla.",
            "uv_odpovedc": "tlačivo zavedené na zabezpečenie náhrady vzniknutej škody.",
            "nl_okruh_id": "7",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "2a28a378ba41e379630d"
        },
        {
            "nl_otazka_id": "1371",
            "uv_otazka": "Vozidlo s právom prednostnej jazdy a vozidlo, ktoré sprevádza",
            "uv_odpoveda": "majú pri čerpaní pohonných látok prednosť, len ak pritom používajú zvláštne výstražné znamenie.",
            "uv_odpovedb": "nesmú čerpať pohonné látky na čerpacej stanici, ktorá je prístupná aj ostatným účastníkom cestnej premávky.",
            "uv_odpovedc": "majú pri čerpaní pohonných látok prednosť; pritom nemusia používať zvláštne výstražné znamenie.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "209e389267b9d2ab74e1"
        },
        {
            "nl_otazka_id": "1045",
            "uv_otazka": "Vodič nesmie",
            "uv_odpoveda": "viesť vozidlo po požití lieku v čase, keď liek môže znížiť jeho schopnosť viesť vozidlo.",
            "uv_odpovedb": "viesť vozidlo po miestnych komunikáciách v čase od 1.00 hod. do 5.00 hod.",
            "uv_odpovedc": "viesť vozidlo po požití lieku v čase, keď liek môže znížiť jeho schopnosť viesť vozidlo; to neplatí, ak sa vo vozidle nachádza spolujazdec.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "fbda9666fcdd79a1e697"
        },
        {
            "nl_otazka_id": "1367",
            "uv_otazka": "Vodič iného vozidla je povinný vozidlu s právom prednostnej jazdy a vozidlu, ktoré sprevádza, umožniť bezpečný a plynulý prejazd,",
            "uv_odpoveda": "len ak ho na to upozornia zapínaním diaľkových svetlometov.",
            "uv_odpovedb": "len ak tak urobia aj pred ním jazdiaci vodiči.",
            "uv_odpovedc": "a ak je to potrebné, aj zastaviť vozidlo na takom mieste, aby im neprekážalo.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "07f033d24b4b069f5eaf"
        },
        {
            "nl_otazka_id": "1240",
            "uv_otazka": "Vodič nesmie zastaviť a stáť",
            "uv_odpoveda": "na moste.",
            "uv_odpovedb": "vľavo na jednosmernej ceste.",
            "uv_odpovedc": "v obci kolmo, prípadne šikmo na okraj vozovky.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "d27f0294bfa12b4d867d"
        },
        {
            "nl_otazka_id": "1683",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Prikazuje obchádzať prekážku v smere, ktorým šípka ukazuje.",
            "uv_odpovedb": "Prikazuje jazdu len vo vyznačenom smere alebo vyznačených smeroch.",
            "uv_odpovedc": "Označuje jednosmernú premávku.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/210.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "6b8c6f05f5a9477d6d85"
        },
        {
            "nl_otazka_id": "1741",
            "uv_otazka": "Táto dopravná značka informuje",
            "uv_odpoveda": "O konci hranice kraja v jazyku národnostnej menšiny.",
            "uv_odpovedb": "O názve obce alebo miestnej časti obce tvoriacej samostatný sídelný útvar v jazyku národnostnej menšiny na konci obce alebo miestnej časti obce.",
            "uv_odpovedc": "O konci hranice okresu v jazyku národnostnej menšiny.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/308.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "ac79594699f35dba0d76"
        },
        {
            "nl_otazka_id": "1265",
            "uv_otazka": "Ak dôjde k zastaveniu vozidla na železničnom priecestí, vodič je povinný",
            "uv_odpoveda": "použiť zariadenie proti neoprávnenému použitiu vozidla.",
            "uv_odpovedb": "oznámiť zastavenie vozidla príslušnému okresnému úradu.",
            "uv_odpovedc": "odstrániť vozidlo zo železničnej trate; ak tak nemôže urobiť, musí bezodkladne vykonať také opatrenia, aby bol vodič koľajového vozidla pred nebezpečenstvom včas varovaný.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "9a526c936207e3e7ba52"
        },
        {
            "nl_otazka_id": "1283",
            "uv_otazka": "Vodič vždy pri zmene smeru jazdy, pri vybočovaní z neho alebo ak to vyžaduje bezpečnosť alebo plynulosť cestnej premávky je povinný",
            "uv_odpoveda": "dávať znamenie o zmene smeru jazdy.",
            "uv_odpovedb": "zaradiť druhý prevodový stupeň.",
            "uv_odpovedc": "použiť osobitné zariadenie umožňujúce výstražnú funkciu smerových svietidiel.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "74dfc7a3e9e2e0d41a2c"
        },
        {
            "nl_otazka_id": "1457",
            "uv_otazka": "Pravidlá o jazde na samovyvažovacom vozidle platia aj na jazdu na",
            "uv_odpoveda": "bicykli s pomocným motorčekom.",
            "uv_odpovedb": "bicykli.",
            "uv_odpovedc": "kolobežke s pomocným motorčekom.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "376be5d7d42388fbfbd7"
        },
        {
            "nl_otazka_id": "1495",
            "uv_otazka": "Pokyny policajta",
            "uv_odpoveda": "nie sú nadradené pokynom vyplývajúcim z dopravných značiek a dopravných zariadení.",
            "uv_odpovedb": "sú nadradené pokynom vyplývajúcim z dopravných značiek, ale nie sú nadradené pokynom vyplývajúcim z dopravných zariadení.",
            "uv_odpovedc": "sú nadradené pokynom vyplývajúcim z dopravných značiek a dopravných zariadení.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f5f9d8392f609c5cf197"
        },
        {
            "nl_otazka_id": "1713",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Koniec diaľnice.",
            "uv_odpovedb": "Koniec hlavnej cesty.",
            "uv_odpovedc": "Miesto, kde je dovolené otáčať sa na diaľnici.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/310.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "10cf8272cf9752ffffab"
        },
        {
            "nl_otazka_id": "1829",
            "uv_otazka": "Ako posledné prejde cez križovatku",
            "uv_odpoveda": "zelené vozidlo.",
            "uv_odpovedb": "vaše vozidlo súčasne s modrým.",
            "uv_odpovedc": "žlté vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/14.png",
            "body": "4",
            "poznamka": "",
            "hash": "2f0c56616ea089974366"
        },
        {
            "nl_otazka_id": "1303",
            "uv_otazka": "Akým spôsobom dáva vodič svetelné výstražné znamenie?",
            "uv_odpoveda": "Zapnutím predných hmlových svetlometov alebo diaľkových svetlometov.",
            "uv_odpovedb": "Za zníženej viditeľnosti prepnutím obrysových svietidiel na stretávacie a späť.",
            "uv_odpovedc": "Prerušovaným zapínaním stretávacích svetlometov alebo diaľkových svetlometov.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5f529df577856926c597"
        },
        {
            "nl_otazka_id": "1501",
            "uv_otazka": "Ak policajt pri riadení cestnej premávky dáva tento pokyn, znamená to",
            "uv_odpoveda": "“Stoj“ pre vodiča prichádzajúceho smerom k chrbtu alebo k pravému boku policajta, ak má policajt pravú ruku predpaženú a ľavú ruku upaženú a “Voľno“ pre vodiča prichádzajúceho smerom k ľavému boku policajta; vodič vozidla prichádzajúceho smerom k čelu policajta nesmie odbočiť vpravo a chodci nesmú prechádzať cez vozovku za chrbtom policajta.",
            "uv_odpovedb": "“Stoj“ pre vodiča prichádzajúceho smerom k chrbtu alebo k pravému boku policajta, ak má policajt pravú ruku predpaženú a ľavú ruku upaženú a “Voľno“ pre vodiča prichádzajúceho smerom k ľavému boku policajta; vodič vozidla prichádzajúceho smerom k čelu policajta môže odbočiť vpravo a chodci smú prechádzať cez vozovku za chrbtom policajta.",
            "uv_odpovedc": "“Voľno“ pre vodiča prichádzajúceho smerom k chrbtu alebo k pravému boku policajta, ak má policajt pravú ruku predpaženú a ľavú ruku upaženú a “Stoj“ pre vodiča prichádzajúceho smerom k ľavému boku policajta.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "obr3/pcp/22_1d.png",
            "body": "3",
            "poznamka": "",
            "hash": "56762488ba460245ecac"
        },
        {
            "nl_otazka_id": "1186",
            "uv_otazka": "Vodič nesmie cúvať",
            "uv_odpoveda": "v obytnej zóne.",
            "uv_odpovedb": "na križovatke s riadenou premávkou.",
            "uv_odpovedc": "na parkovisku.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "145867ae03f820f71c7c"
        },
        {
            "nl_otazka_id": "1771",
            "uv_otazka": "Regulačné dopravné značky",
            "uv_odpoveda": "upozorňujú účastníkov cestnej premávky na miesto, kde môže hroziť nebezpečenstvo, ktoré je ťažko včas rozoznateľné aj pri dodržaní primeranej opatrnosti.",
            "uv_odpovedb": "vymedzujú miesta, kde platia osobitné pravidlá cestnej premávky, poskytujú účastníkom cestnej premávky informácie dopravného významu alebo slúžia na ich orientáciu.",
            "uv_odpovedc": "ukladajú účastníkom cestnej premávky príkazy, zákazy alebo obmedzenia alebo im umožňujú výnimky zo všeobecných pravidiel cestnej premávky.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "72664e64a2d602eaac09"
        },
        {
            "nl_otazka_id": "1657",
            "uv_otazka": "Čo znamená táto dopravná značka?",
            "uv_odpoveda": "Značka informuje vodiča o prednosti v jazde protiidúcich vozidiel.",
            "uv_odpovedb": "Značka informuje vodiča o prednosti v jazde pred protiidúcimi vozidlami.",
            "uv_odpovedc": "Zákaz predchádzania.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/304.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "04bd97fd0aaa01eefa70"
        },
        {
            "nl_otazka_id": "1631",
            "uv_otazka": "Táto dopravná značka upozorňuje na",
            "uv_odpoveda": "Nerovnosť vozovky.",
            "uv_odpovedb": "Dvojitú zákrutu, prvá vľavo.",
            "uv_odpovedc": "Zvýšené riziko vzniku šmyku vozidla aj na suchej ceste.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/120.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "f2c8f487b702ead0e712"
        },
        {
            "nl_otazka_id": "2187",
            "uv_otazka": "Únavu vodiča spôsobuje najčastejšie",
            "uv_odpoveda": "dlhá monotónna jazda bez prestávky",
            "uv_odpovedb": "dlhá jazda s častými prestávkami",
            "uv_odpovedc": "jazda cez deň",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "c376cf71820d6de096b3"
        },
        {
            "nl_otazka_id": "1351",
            "uv_otazka": "Na diaľnici a na ceste pre motorové vozidlá je vodičovi zastavenie a státie",
            "uv_odpoveda": "dovolené všade tam, kde neohrozí vodičov nemotorových vozidiel.",
            "uv_odpovedb": "dovolené na parkovisku a na pripájacom a na odbočovacom pruhu.",
            "uv_odpovedc": "zakázané na inom mieste než na mieste označenom ako parkovisko s výnimkou núdzového státia.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "41a7876e1170bf3aacad"
        },
        {
            "nl_otazka_id": "1496",
            "uv_otazka": "Ak policajt pri riadení cestnej premávky dáva pokyn vodorovným kývaním ruky cez stred tela, znamená to",
            "uv_odpoveda": "odbočiť.",
            "uv_odpovedb": "spomaliť jazdu.",
            "uv_odpovedc": "zrýchliť jazdu.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "obr3/pcp/22_3_1.png",
            "body": "3",
            "poznamka": "",
            "hash": "ba721f07a95a535b627c"
        },
        {
            "nl_otazka_id": "1433",
            "uv_otazka": "Tam, kde chodník nie je alebo kde je neschodný, chodec chodí",
            "uv_odpoveda": "čo najbližšie pri pravom okraji vozovky.",
            "uv_odpovedb": "po pravej krajnici.",
            "uv_odpovedc": "po ľavej krajnici; tam, kde nie je krajnica alebo kde je krajnica neschodná, chodí sa čo najbližšie pri ľavom okraji vozovky.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "a6d8a2ee87e1d7f4e14c"
        },
        {
            "nl_otazka_id": "1742",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Hranicu štátu a uvádza jeho názov.",
            "uv_odpovedb": "Hranicu kraja.",
            "uv_odpovedc": "Hraničný priechod.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/341.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "8b09cf5b4be19b34226c"
        },
        {
            "nl_otazka_id": "1765",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "koniec bicyklovej cesty.",
            "uv_odpovedb": "koniec školskej zóny.",
            "uv_odpovedc": "koniec cesty pre motorové vozidlá.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/324.png",
            "body": "2",
            "poznamka": "",
            "hash": "b4cbf673d0d613177bc2"
        },
        {
            "nl_otazka_id": "1881",
            "uv_otazka": "Vodič modrého vozidla",
            "uv_odpoveda": "má pred vodičom červeného vozidla prednosť v jazde, pretože prichádza zľava.",
            "uv_odpovedb": "má pred vodičom červeného vozidla prednosť v jazde, pretože ide po kruhovom objazde, ktorý je označený dopravnou značkou “Kruhový objazd“ spolu s dopravnou značkou “Stoj, daj prednosť v jazde!“.",
            "uv_odpovedc": "je povinný dať prednosť v jazde vodičovi červeného vozidla, ktorý prichádza sprava.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/29.png",
            "body": "4",
            "poznamka": "",
            "hash": "06b51b8134e433cd5cef"
        },
        {
            "nl_otazka_id": "1848",
            "uv_otazka": "Ako posledné prejde cez križovatku",
            "uv_odpoveda": "červené vozidlo.",
            "uv_odpovedb": "zelené vozidlo.",
            "uv_odpovedc": "vaše vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/23.png",
            "body": "4",
            "poznamka": "",
            "hash": "84ba200ae9a28b7378bc"
        },
        {
            "nl_otazka_id": "1970",
            "uv_otazka": "Počas vlečenia vozidla treba dodržiavať túto zásadu:",
            "uv_odpoveda": "upevniť lano na najpevnejšiu časť karosérie.",
            "uv_odpovedb": "dodržiavať odstup počas vlečenia 3m.",
            "uv_odpovedc": "udržiavať vlečné lano neustále napnuté.",
            "nl_okruh_id": "6",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "355949ecab103baff666"
        },
        {
            "nl_otazka_id": "1425",
            "uv_otazka": "Náklad musí byť na vozidle",
            "uv_odpoveda": "zabezpečený tak, aby pri zmene rýchlosti jazdy alebo zmene smeru jazdy vozidla sa nezošmykol, neprevrátil, nespadol alebo sa inak voľne nepohyboval.",
            "uv_odpovedb": "vždy zakrytý plachtou.",
            "uv_odpovedc": "označený červeným štítkom s rozmermi najmenej 50 x 50 cm.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "591b654d546cdf9eb2b1"
        },
        {
            "nl_otazka_id": "1427",
            "uv_otazka": "Nakladanie a skladanie nákladu na ceste je dovolené len",
            "uv_odpoveda": "na miestnej komunikácii a na účelovej komunikácii.",
            "uv_odpovedb": "vtedy, ak to nemožno urobiť mimo cesty.",
            "uv_odpovedc": "vtedy, ak na to dal súhlas okresný úrad.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "971e79488c0bd90b6c86"
        },
        {
            "nl_otazka_id": "1718",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Zónu, kde sú povolené hry detí na ceste.",
            "uv_odpovedb": "Koniec obce.",
            "uv_odpovedc": "Miesto, od ktorého platia osobitné ustanovenia o cestnej premávke v školskej zóne.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/319.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "e480f71bb663423e6bea"
        },
        {
            "nl_otazka_id": "1773",
            "uv_otazka": "Dodatkové tabuľky sa používajú",
            "uv_odpoveda": "na upozornenie účastníkov cestnej premávky na miesto, kde môže hroziť nebezpečenstvo, ktoré je ťažko včas rozoznateľné aj pri dodržaní primeranej opatrnosti.",
            "uv_odpovedb": "na spresnenie, doplnenie, obmedzenie alebo rozšírenie významu zvislej značky, pod ktorou sú umiestnené.",
            "uv_odpovedc": "na vymedzenie miesta, kde platia osobitné pravidlá cestnej premávky alebo slúžia na ich orientáciu.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "850c98c970f48dcf40c4"
        },
        {
            "nl_otazka_id": "1878",
            "uv_otazka": "Červené vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "druhé.",
            "uv_odpovedb": "prvé.",
            "uv_odpovedc": "posledné.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/28.png",
            "body": "4",
            "poznamka": "",
            "hash": "cc1fa280416a53bf4d3d"
        },
        {
            "nl_otazka_id": "1902",
            "uv_otazka": "Dopravná nehoda je",
            "uv_odpoveda": "udalosť v cestnej premávke, ktorá sa stane v priamej súvislosti s premávkou vozidla a pri ktorej sa usmrtí alebo zraní osoba, znefunkční cesta alebo všeobecne prospešné zariadenie, uniknú nebezpečné veci.",
            "uv_odpovedb": "udalosť v cestnej premávke, pri ktorej dôjde k stretu aspoň dvoch vozidiel.",
            "uv_odpovedc": "každá udalosť, pri ktorej dôjde k poškodeniu vozidla ľubovoľným spôsobom.",
            "nl_okruh_id": "5",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "68b026bc6d81f954eb51"
        },
        {
            "nl_otazka_id": "1996",
            "uv_otazka": "Príves je pripojený k ťažnému vozidlu pomocou",
            "uv_odpoveda": "reťaze.",
            "uv_odpovedb": "lana.",
            "uv_odpovedc": "schváleného spájacieho zariadenia a poistného zariadenia.",
            "nl_okruh_id": "6",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "005bbcf983549650dc41"
        },
        {
            "nl_otazka_id": "2124",
            "uv_otazka": "Vozidlo sa považuje za technicky nespôsobilé na prevádzku v cestnej premávke, najmä ak",
            "uv_odpoveda": "z miesta vodiča nie je možné nastaviť pravé predné zrkadlo.",
            "uv_odpovedb": "je narušené upevnenie kolesa na náboj kolesa alebo je narušené upevnenie pneumatiky.",
            "uv_odpovedc": "nie sú aspoň kolesá prednej nápravy vybavené účinnými krytmi (blatníkmi, podbehmi).",
            "nl_okruh_id": "8",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "f791b0a14b812ea0f75e"
        },
        {
            "nl_otazka_id": "1434",
            "uv_otazka": "Môže iný účastník cestnej premávky než chodec používať chodník?",
            "uv_odpoveda": "Áno, ak je to povolené dopravnou značkou alebo dopravným zariadením.",
            "uv_odpovedb": "Nie.",
            "uv_odpovedc": "Áno, ak ide o zastavenie alebo státie vozidla s najväčšou prípustnou celkovou hmotnosťou neprevyšujúcou 3 500 kg na okraji chodníka priľahlom k ceste, ktoré neprekročí tri minúty.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "aed2eea3e8740bd9c033"
        },
        {
            "nl_otazka_id": "1393",
            "uv_otazka": "Vodič",
            "uv_odpoveda": "môže dovoliť, aby sa prekročil povolený počet prepravovaných osôb, ak s tým vopred vyslovili súhlas všetky prepravované osoby.",
            "uv_odpovedb": "môže dovoliť, aby sa prekročil povolený počet prepravovaných osôb, najviac však o 20 %.",
            "uv_odpovedc": "nesmie dovoliť, aby sa prekročil povolený počet prepravovaných osôb.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "af0fd3e1549a4dcf7b1c"
        },
        {
            "nl_otazka_id": "1205",
            "uv_otazka": "Ak nie je ohrozená bezpečnosť a plynulosť cestnej premávky, vodič smie v obci",
            "uv_odpoveda": "stáť v druhom rade.",
            "uv_odpovedb": "zastaviť a stáť na priechode pre chodcov.",
            "uv_odpovedc": "zastaviť v druhom rade.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "76a12ff409bdf8c68554"
        },
        {
            "nl_otazka_id": "1053",
            "uv_otazka": "Vodič nesmie",
            "uv_odpoveda": "znížiť rýchlosť jazdy za žiadnych okolností.",
            "uv_odpovedb": "znížiť náhle rýchlosť jazdy alebo náhle zastaviť, ak to nevyžaduje bezpečnosť cestnej premávky.",
            "uv_odpovedc": "zastaviť vpravo v smere jazdy rovnobežne s okrajom cesty, čo najbližšie k okraju cesty.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "a29a871968c29e355b73"
        },
        {
            "nl_otazka_id": "1341",
            "uv_otazka": "Vodič motorového vozidla smie na cestu pre motorové vozidlá vchádzať a z cesty pre motorové vozidlá vychádzať len na miestach",
            "uv_odpoveda": "na to určených.",
            "uv_odpovedb": "na to určených dopravnou značkou alebo dopravným zariadením.",
            "uv_odpovedc": "s riadenou premávkou.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "fec30b17e156c44368ac"
        },
        {
            "nl_otazka_id": "1645",
            "uv_otazka": "Táto dopravná značka informuje",
            "uv_odpoveda": "O sklone vozovky vpravo.",
            "uv_odpovedb": "O vzdialenosti 90 m k priecestiu.",
            "uv_odpovedc": "O vzdialenosti 240 m k priecestiu.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/153-3.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "68f58ee134135b26d5f6"
        },
        {
            "nl_otazka_id": "1734",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Informuje o čísle diaľnice alebo o čísle rýchlostnej cesty.",
            "uv_odpovedb": "Označuje vzdialenosť k miestu odbočenia a jeho názov.",
            "uv_odpovedc": "Označuje najbližší výjazd z diaľnice.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/352.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "43de5f12f594f5be4436"
        },
        {
            "nl_otazka_id": "1946",
            "uv_otazka": "Ak sa na prístrojovom paneli rozsvieti kontrolka hladiny v nádržke ostrekovača, signalizuje",
            "uv_odpoveda": "nízku teplotu chladiacej kvapaliny. ",
            "uv_odpovedb": "nízku hladinu chladiacej kvapaliny. ",
            "uv_odpovedc": "nízku hladinu kvapaliny v nádržke ostrekovača. ",
            "nl_okruh_id": "6",
            "nl_correct": "3",
            "uv_obrazok": "obr3/tvv/21.png",
            "body": "2",
            "poznamka": "",
            "hash": "7a6e3a1fca770050357c"
        },
        {
            "nl_otazka_id": "2314",
            "uv_otazka": "Významom chladiacej sústavy je",
            "uv_odpoveda": "odvádzanie prebytočného tepla z motora a udržiavanie správnej prevádzkovej teploty (80 – 90°C). ",
            "uv_odpovedb": "znížiť teplotu vzduchu v interiéri vozidla.",
            "uv_odpovedc": "odvádzanie prebytočného tepla z motora a udržiavanie čo najnižšej prevádzkovej teploty (30 – 40°C). ",
            "nl_okruh_id": "10",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "ae971b460214132f2243"
        },
        {
            "nl_otazka_id": "1090",
            "uv_otazka": "Na krajnicu smie vodič motorového vozidla vojsť",
            "uv_odpoveda": "len pri zastavení a státí alebo pri obchádzaní a vyhýbaní sa prekážke cestnej premávky.",
            "uv_odpovedb": "vždy, ak je to potrebné na vykonanie akéhokoľvek jazdného úkonu.",
            "uv_odpovedc": "pri otáčaní a cúvaní.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "732ff3a190508fa83275"
        },
        {
            "nl_otazka_id": "1450",
            "uv_otazka": "Osoby mladšie ako 10 rokov smú jazdiť na bicykli",
            "uv_odpoveda": "po pravej strane chodníka, ak tým neohrozujú ani neobmedzujú chodcov.",
            "uv_odpovedb": "traja vedľa seba.",
            "uv_odpovedc": "po ľavej krajnici.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "a12c9de383790315c597"
        },
        {
            "nl_otazka_id": "1164",
            "uv_otazka": "Vodiči protiidúcich vozidiel sa vyhýbajú",
            "uv_odpoveda": "vpravo, včas a v dostatočnej miere.",
            "uv_odpovedb": "vľavo a tak, aby bola medzi vozidlami vzdialenosť najmenej 1 m.",
            "uv_odpovedc": "vpravo a tak, aby bola medzi vozidlami vzdialenosť najmenej 1,2 m.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e8e24f9dd27f999fb941"
        },
        {
            "nl_otazka_id": "1382",
            "uv_otazka": "Ak vozidlo tvoriace prekážku cestnej premávky na diaľnici alebo na ceste pre motorové vozidlá bezodkladne neodstráni ten, kto prekážku spôsobil, o okamžitom odstránení vozidla na jeho náklady rozhodne",
            "uv_odpoveda": "príslušný okresný úrad.",
            "uv_odpovedb": "policajt alebo správca cesty.",
            "uv_odpovedc": "len policajt.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "efe7f4a140368e2444ba"
        },
        {
            "nl_otazka_id": "1356",
            "uv_otazka": "Ak vodič motorového vozidla počas jazdy na ceste pre motorové vozidlá zistí na vozidle alebo na náklade chybu, pre ktorú nemožno dosiahnuť na rovine rýchlosť najmenej 60 km/h",
            "uv_odpoveda": "nemusí opustiť cestu pre motorové vozidlá na najbližšom možnom mieste.",
            "uv_odpovedb": "musí ihneď zastaviť na krajnici.",
            "uv_odpovedc": "musí opustiť cestu pre motorové vozidlá na najbližšom možnom mieste.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "363696a6685018254533"
        },
        {
            "nl_otazka_id": "1873",
            "uv_otazka": "Zelené vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "tretie.",
            "uv_odpovedb": "prvé.",
            "uv_odpovedc": "druhé.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/25.png",
            "body": "4",
            "poznamka": "",
            "hash": "d8143d07465bbbd6c4dd"
        },
        {
            "nl_otazka_id": "1926",
            "uv_otazka": "Účastníci škodovej udalosti sú povinní",
            "uv_odpoveda": "poskytnúť odbornú zdravotnú pomoc osobám zraneným pri škodovej udalosti.",
            "uv_odpovedb": "ohlásiť dopravnú nehodu policajtovi.",
            "uv_odpovedc": "vyplniť a podpísať tlačivo zavedené na zabezpečenie náhrady vzniknutej škody (Správa o nehode).",
            "nl_okruh_id": "5",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "59d9c7a17323e06a3a75"
        },
        {
            "nl_otazka_id": "1950",
            "uv_otazka": "Táto kontrolka signalizuje zapnutie",
            "uv_odpoveda": "predných hmlových svetiel. ",
            "uv_odpovedb": "stretávacích svetlometov.",
            "uv_odpovedc": "zadných hmlových svetiel. ",
            "nl_okruh_id": "6",
            "nl_correct": "1",
            "uv_obrazok": "obr3/tvv/26.png",
            "body": "2",
            "poznamka": "",
            "hash": "b57a07609129c5b484a2"
        },
        {
            "nl_otazka_id": "2305",
            "uv_otazka": "Čo by mal pred jazdou vodič urobiť, ak vie dopredu, že počas jazdy ho bude sprevádzať hustá hmla?",
            "uv_odpoveda": "Zachovávať odstup od vozidla pred ním podľa pravidla 2 sekúnd.",
            "uv_odpovedb": "Zapnúť diaľkové svetlá.",
            "uv_odpovedc": "Mal by si naplánovať časovú rezervu na jazdu.",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "32a97386ab66be701ea8"
        },
        {
            "nl_otazka_id": "2372",
            "uv_otazka": "Termostat slúži na",
            "uv_odpoveda": "reguláciu teploty paliva.",
            "uv_odpovedb": "samočinnú reguláciu teploty chladiacej kvapaliny.",
            "uv_odpovedc": "reguláciu teploty motorového oleja.",
            "nl_okruh_id": "10",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "72d116443b96b482c2b4"
        },
        {
            "nl_otazka_id": "1230",
            "uv_otazka": "Ak je priestor zastávky vozidla pravidelnej verejnej dopravy osôb vyznačený príslušnou vodorovnou dopravnou značkou, platí tento zákaz",
            "uv_odpoveda": "5 m pred vyznačeným priestorom a 5 m za  vyznačeným priestorom.",
            "uv_odpovedb": "len pre vyznačený priestor.",
            "uv_odpovedc": "len pre vyznačený priestor a priľahlé nástupište.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ddebad7d0a89522b0fc1"
        },
        {
            "nl_otazka_id": "1369",
            "uv_otazka": "Ak na stojacom vozidle svieti zvláštne výstražné svetlo modrej alebo červenej farby, prípadne ich kombinácia, vodič iného vozidla",
            "uv_odpoveda": "musí zvýšiť opatrnosť, znížiť rýchlosť jazdy, prípadne zastaviť vozidlo.",
            "uv_odpovedb": "musí znížiť rýchlosť jazdy; nesmie však za žiadnych okolností zastaviť vozidlo.",
            "uv_odpovedc": "nesmie za žiadnych okolností znížiť rýchlosť jazdy ani zastaviť vozidlo.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "2454b57c6acaecf71d9c"
        },
        {
            "nl_otazka_id": "1314",
            "uv_otazka": "Ak je vozovka dostatočne a súvisle osvetlená, vodič motocykla nesmie použiť",
            "uv_odpoveda": "predné hmlové svetlomety.",
            "uv_odpovedb": "stretávacie svetlomety.",
            "uv_odpovedc": "diaľkové svetlomety.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "25d2d25797a20b7af02e"
        },
        {
            "nl_otazka_id": "1576",
            "uv_otazka": "Najväčšiu konštrukčnú rýchlosť vozidla, a ak ide o jazdnú súpravu, ani najväčšiu konštrukčnú rýchlosť nijakého z vozidiel súpravy, vodič",
            "uv_odpoveda": "nesmie prekročiť; to neplatí, ak ide po diaľnici.",
            "uv_odpovedb": "môže prekročiť najviac o 20 %.",
            "uv_odpovedc": "nesmie prekročiť.",
            "nl_okruh_id": "2",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "a7d667085a9cb6c7d32f"
        },
        {
            "nl_otazka_id": "2086",
            "uv_otazka": "Držiteľ vodičského preukazu je povinný",
            "uv_odpoveda": "oznámiť orgánu Policajného zboru do 30 dní zničenie, stratu alebo odcudzenie vodičského preukazu.",
            "uv_odpovedb": "oznámiť orgánu Policajného zboru do 7 dní zničenie, stratu alebo odcudzenie vodičského preukazu.",
            "uv_odpovedc": "oznámiť orgánu Policajného zboru do 14 dní zničenie, stratu alebo odcudzenie vodičského preukazu.",
            "nl_okruh_id": "7",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "c2509daee1b7019333ec"
        },
        {
            "nl_otazka_id": "2117",
            "uv_otazka": "Vozidlo, ktoré prekračuje najväčšiu prípustnú celkovú hmotnosť vozidla, najväčšiu prípustnú hmotnosť jazdnej súpravy, najväčšiu prípustnú celkovú hmotnosť prípojného vozidla alebo najväčšiu prípustnú hmotnosť pripadajúcu na nápravy vozidla",
            "uv_odpoveda": "nesmie jeho prevádzkovateľ v cestnej premávke prevádzkovať.",
            "uv_odpovedb": "môže jeho prevádzkovateľ v cestnej premávke prevádzkovať za dodržania podmienky prekročenia hmotnosti do 10 %.",
            "uv_odpovedc": "nesmie jeho prevádzkovateľ v cestnej premávke prevádzkovať, jeho prevádzka je však dovolená na cestách III. triedy.",
            "nl_okruh_id": "8",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "27c008093e699569e15d"
        },
        {
            "nl_otazka_id": "1428",
            "uv_otazka": "Ak náklad prečnieva vozidlo vpredu alebo vzadu",
            "uv_odpoveda": "o viac ako 1 m, musí byť prečnievajúci koniec nákladu označený žltou zástavkou s rozmermi najmenej 20 x 20 cm; za zníženej viditeľnosti vpredu aj vzadu žltou odrazkou.",
            "uv_odpovedb": "o viac ako 40 cm, musí byť prečnievajúci koniec nákladu označený reflexným označením s červenými a bielymi pruhmi širokými 70 mm až 100 mm a smerujúcimi od pozdĺžnej strednej roviny vozidla pod uhlom 45° nadol.",
            "uv_odpovedc": "o viac ako 0,5 m, musí byť vždy označený červenou odrazkou.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "8c85bf7a67a4699441e1"
        },
        {
            "nl_otazka_id": "1493",
            "uv_otazka": "Motorovým vozidlám je jazda na bicyklovej ceste",
            "uv_odpoveda": "povolená, len za zníženej viditeľnosti.",
            "uv_odpovedb": "zakázaná, ak dopravnou značkou nie je určené inak.",
            "uv_odpovedc": "povolená, len po ľavej strane.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ea92217d13eabcb889e9"
        },
        {
            "nl_otazka_id": "1134",
            "uv_otazka": "Za vozidlom pravidelnej verejnej dopravy osôb, ktoré zastavilo v obci na zastávke bez nástupného ostrovčeka alebo bez nástupišťa na zvýšenom električkovom koľajovom páse, je vodič iného vozidla povinný...",
            "uv_odpoveda": "zastaviť len na pokyn vodiča takého vozidla.",
            "uv_odpovedb": "zastaviť len vtedy, ak autobus, prípadne trolejbus zastaví pri okraji vozovky.",
            "uv_odpovedc": "zastaviť vozidlo; ak je na zastávke viac vozidiel, vodič je povinný zastaviť vozidlo za posledným z nich.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "779a7ddb54bf2cc6f96e"
        },
        {
            "nl_otazka_id": "1595",
            "uv_otazka": "Ak na priecestnom zabezpečovacom zariadení svieti prerušované biele svetlo, vo vzdialenosti 50 m pred železničným priecestím a pri jeho prechádzaní vodič je povinný jazdiť rýchlosťou najviac",
            "uv_odpoveda": "30 km.h-1.",
            "uv_odpovedb": "50 km.h-1.",
            "uv_odpovedc": "40 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "2",
            "uv_obrazok": "obr3/upp_or/27_50.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "a6eb9e9e5106459c1de8"
        },
        {
            "nl_otazka_id": "1783",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Osoby pohybujúce sa na korčuliach",
            "uv_odpovedb": "Chodci",
            "uv_odpovedc": "Deti",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/1-10a.png",
            "body": "2",
            "poznamka": "",
            "hash": "e1da8d0f31939c1eb614"
        },
        {
            "nl_otazka_id": "1799",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Parkovacia garáž, parkovací dom",
            "uv_odpovedb": "Parkovisko",
            "uv_odpovedc": "Parkovisko P+R (parkovisko, z ktorého je možné pokračovať v ceste pravidelnou verejnou hromadnou dopravou)",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/2-27.png",
            "body": "2",
            "poznamka": "",
            "hash": "ae19cb05489169c9940f"
        },
        {
            "nl_otazka_id": "1919",
            "uv_otazka": "Účastník dopravnej nehody je povinný preukázať svoju totožnosť na požiadanie",
            "uv_odpoveda": "iného účastníka dopravnej nehody.",
            "uv_odpovedb": "každej osoby, ktorá sa nachádza na mieste dopravnej nehody.",
            "uv_odpovedc": "len policajta.",
            "nl_okruh_id": "5",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "4f3fb41dd3e908f73e21"
        },
        {
            "nl_otazka_id": "2324",
            "uv_otazka": "Pneumatika bez duše sa označuje",
            "uv_odpoveda": "BIAS - PLY.",
            "uv_odpovedb": "TUBELESS.",
            "uv_odpovedc": "185/65 R14 86T.",
            "nl_okruh_id": "10",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "5c0fa617a3d24d2793c5"
        },
        {
            "nl_otazka_id": "1115",
            "uv_otazka": "Vodič pri zaraďovaní sa do priebežného jazdného pruhu pri súbežnej jazde, ak dáva znamenie o zmene smeru jazdy",
            "uv_odpoveda": "smie ohroziť vodiča jazdiaceho v priebežnom jazdnom pruhu, ak ide o vodiča autobusu.",
            "uv_odpovedb": "nesmie ohroziť vodiča jazdiaceho v priebežnom jazdnom pruhu.",
            "uv_odpovedc": "smie ohroziť vodiča jazdiaceho v priebežnom jazdnom pruhu.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "dffa97a4df342d2ca8a5"
        },
        {
            "nl_otazka_id": "1643",
            "uv_otazka": "Táto dopravná značka upozorňuje",
            "uv_odpoveda": "Že na rozdiel od doterajšieho úseku je cestná premávka vedená v oboch smeroch.",
            "uv_odpovedb": "Na miesto, kde má vodič prednosť pred protiidúcim vodičom.",
            "uv_odpovedc": "Na úsek cesty, kde je premávka riadená striedavo.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/132.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "977a07898369c2ef905c"
        },
        {
            "nl_otazka_id": "1861",
            "uv_otazka": "Zelené vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "druhé.",
            "uv_odpovedb": "tretie.",
            "uv_odpovedc": "prvé.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/30.png",
            "body": "4",
            "poznamka": "",
            "hash": "d627c83352492c51c132"
        },
        {
            "nl_otazka_id": "1981",
            "uv_otazka": "Najväčšia spotreba paliva je pri",
            "uv_odpoveda": "zaradení neutrálu.",
            "uv_odpovedb": "akcelerácii.",
            "uv_odpovedc": "prudkom brzdení.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "7649b044372e188435e3"
        },
        {
            "nl_otazka_id": "2316",
            "uv_otazka": "Množstvo oleja v mazacej sústave motora meriame",
            "uv_odpoveda": "mikrometrom.",
            "uv_odpovedb": "mierkou.",
            "uv_odpovedc": "viskozimetrom.",
            "nl_okruh_id": "10",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "57a0b20423708ae9d198"
        },
        {
            "nl_otazka_id": "1077",
            "uv_otazka": "Osoba sediaca na sedadle povinne vybavenom bezpečnostným pásom alebo iným zadržiavacím zariadením",
            "uv_odpoveda": "je povinná toto zariadenie použiť.",
            "uv_odpovedb": "je povinná toto zariadenie použiť pri jazde mimo obce.",
            "uv_odpovedc": "je povinná toto zariadenie použiť len pri jazde rýchlosťou vyššou ako 40 km/h.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "791922461965aeb1796a"
        },
        {
            "nl_otazka_id": "1054",
            "uv_otazka": "Vodič motorového vozidla",
            "uv_odpoveda": "nesmie počas vedenia vozidla obsluhovať telefónny prístroj, okrem použitia systému voľné ruky.",
            "uv_odpovedb": "môže počas vedenia vozidla obsluhovať telefónny prístroj.",
            "uv_odpovedc": "nesmie počas vedenia vozidla počúvať rádio.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "390e91435a1a3d0c051e"
        },
        {
            "nl_otazka_id": "1215",
            "uv_otazka": "Vodič, ktorý sa chce vzdialiť od vozidla tak, že nebude môcť okamžite zasiahnuť, je povinný",
            "uv_odpoveda": "odstaviť ho vždy len na určenom parkovisku.",
            "uv_odpovedb": "vyložiť z vozidla v ňom prepravované veci.",
            "uv_odpovedc": "urobiť také opatrenia, aby vozidlo nemohlo ohroziť bezpečnosť a plynulosť cestnej premávky a aby ho nemohla neoprávnene použiť iná osoba.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "2488148e00477d6b39e7"
        },
        {
            "nl_otazka_id": "1251",
            "uv_otazka": "Na cestách I. triedy a za zníženej viditeľnosti aj na ostatných cestách s výnimkou účelových komunikácií je",
            "uv_odpoveda": "mimo obce zakázané zastavenie a státie na inom mieste než na mieste označenom ako parkovisko s výnimkou núdzového státia.",
            "uv_odpovedb": "mimo obce aj v obci dovolené státie len na mieste označenom ako parkovisko.",
            "uv_odpovedc": "mimo obce dovolené len zastavenie.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "6ce46a868aae38c51fc7"
        },
        {
            "nl_otazka_id": "1788",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Osobné vozidlá s prívesom",
            "uv_odpovedb": "Nákladné vozidlá s prívesom",
            "uv_odpovedc": "Obytné automobily",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/1-17.png",
            "body": "2",
            "poznamka": "",
            "hash": "5ffd9076044f20a0f775"
        },
        {
            "nl_otazka_id": "1726",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Uvádza všeobecné informácie o obchádzke.",
            "uv_odpovedb": "Vhodným textom alebo symbolom informuje o dopravných obmedzeniach na horskom prechode.",
            "uv_odpovedc": "Oznamuje zákaz použiť snehové reťaze.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/343.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "e4f27a4c6d5dd3a80e5b"
        },
        {
            "nl_otazka_id": "1971",
            "uv_otazka": "Najefektívnejšie brzdenie z hľadiska úspory paliva je",
            "uv_odpoveda": "brzdenie motorom.",
            "uv_odpovedb": "prerušované brzdenie.",
            "uv_odpovedc": "brzdenie ručnou brzdou.",
            "nl_okruh_id": "6",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "a9746b7883bca20dae64"
        },
        {
            "nl_otazka_id": "1113",
            "uv_otazka": "Ak pri súbežnej jazde vodič vozidla idúceho vo voľnom jazdnom pruhu umožňuje vodičovi prvého vozidla nachádzajúceho sa v jazdnom pruhu, v ktorom je prekážka, jej obídenie, vodič obchádzajúci prekážku nesmie",
            "uv_odpoveda": "dávať znamenie o zmene smeru jazdy.",
            "uv_odpovedb": "ohroziť vodiča jazdiaceho vo voľnom jazdnom pruhu.",
            "uv_odpovedc": "jazdiť za žiadnych okolností rýchlosťou vyššou ako 15 km/h.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "7f6e1958b3d5fbdf55fd"
        },
        {
            "nl_otazka_id": "1458",
            "uv_otazka": "Vodič samovyvažovacieho vozidla smie jazdiť",
            "uv_odpoveda": "len po ľavej strane chodníka, cestičky pre chodcov alebo priechodu pre chodcov, len ak neohrozí a neobmedzí chodcov, pričom nesmie prekročiť rýchlosť chôdze.",
            "uv_odpovedb": "po pravej strane chodníka, cestičky pre chodcov alebo priechodu pre chodcov, len ak neohrozí a neobmedzí chodcov, pričom nesmie prekročiť rýchlosť chôdze.",
            "uv_odpovedc": "po pravej strane chodníka, cestičky pre chodcov alebo priechodu pre chodcov, len ak neohrozí a neobmedzí chodcov, pričom môže prekročiť rýchlosť chôdze.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "573d783ab5782fca3497"
        },
        {
            "nl_otazka_id": "1244",
            "uv_otazka": "Vodič môže zastaviť a stáť na",
            "uv_odpoveda": "účelovej komunikácii.",
            "uv_odpovedb": "moste.",
            "uv_odpovedc": "električkovom koľajovom páse.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "771ddde926463ce702a3"
        },
        {
            "nl_otazka_id": "1279",
            "uv_otazka": "Ak osoba pribratá na zaistenie bezpečnej prevádzky železničného priecestia dáva pokyn na jazdu cez železničné priecestie vodorovným kývaním ruky s červenou alebo so žltou zástavkou a za zníženej viditeľnosti bielym svetlom cez stred tela, vodič",
            "uv_odpoveda": "je povinný pred vojdením na železničné priecestie vždy zastaviť pri tejto osobe.",
            "uv_odpovedb": "nie je povinný ju poslúchnuť.",
            "uv_odpovedc": "je povinný ju poslúchnuť.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "obr3/pcp/29_1.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "23c6f6c1082401c3e55c"
        },
        {
            "nl_otazka_id": "1800",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Parkovisko",
            "uv_odpovedb": "Parkovisko P+R (parkovisko, z ktorého je možné pokračovať v ceste pravidelnou verejnou hromadnou dopravou)",
            "uv_odpovedc": "Parkovacia garáž, parkovací dom",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/2-28.png",
            "body": "2",
            "poznamka": "",
            "hash": "c931b5833edf912d4b76"
        },
        {
            "nl_otazka_id": "1866",
            "uv_otazka": "Ako posledné prejde cez križovatku",
            "uv_odpoveda": "modré vozidlo.",
            "uv_odpovedb": "zelené vozidlo.",
            "uv_odpovedc": "červené vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/09.png",
            "body": "4",
            "poznamka": "",
            "hash": "4030b923c72c7315038d"
        },
        {
            "nl_otazka_id": "2083",
            "uv_otazka": "Držiteľ vodičského preukazu je povinný",
            "uv_odpoveda": "odovzdať orgánu Policajného zboru neplatný vodičský preukaz do 15 dní odo dňa nadobudnutia právoplatnosti rozhodnutia, ktorým mu súd uložil ochranné liečenie alebo mu súd alebo iný príslušný orgán uložil zákaz činnosti viesť motorové vozidlo.",
            "uv_odpovedb": "odovzdať orgánu Policajného zboru neplatný vodičský preukaz do 7 dní odo dňa nadobudnutia právoplatnosti rozhodnutia, ktorým mu súd uložil ochranné liečenie alebo mu súd alebo iný príslušný orgán uložil zákaz činnosti viesť motorové vozidlo.",
            "uv_odpovedc": "odovzdať orgánu Policajného zboru neplatný vodičský preukaz do 30 dní odo dňa nadobudnutia právoplatnosti rozhodnutia, ktorým mu súd uložil ochranné liečenie alebo mu súd alebo iný príslušný orgán uložil zákaz činnosti viesť motorové vozidlo.",
            "nl_okruh_id": "7",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "13e7ce0a490810c3e92c"
        },
        {
            "nl_otazka_id": "1124",
            "uv_otazka": "Na električkový koľajový pás v úrovni vozovky sa smie vojsť",
            "uv_odpoveda": "za každých okolností.",
            "uv_odpovedb": "len pri obchádzaní , odbočovaní a predchádzaní.",
            "uv_odpovedc": "len pri obchádzaní, odbočovaní, otáčaní, vchádzaní na cestu, alebo ak to dovoľuje dopravná značka alebo ak to vyžadujú mimoriadne okolnosti.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "455ea5cfd8c069469789"
        },
        {
            "nl_otazka_id": "1087",
            "uv_otazka": "Ak vozidlá kategórie M1 a N1 nie sú vybavené detským zadržiavacím zariadením",
            "uv_odpoveda": "nie je možné v takomto vozidle prepravovať dieťa s telesnou výškou menšou ako 150 cm.",
            "uv_odpovedb": "možno dieťa vo veku tri a viac rokov pri preprave v obci pripútať na zadnom sedadle vozidla bezpečnostným pásom, ktorým je vozidlo vybavené.",
            "uv_odpovedc": "možno dieťa vo veku do troch rokov pri preprave pripútať na zadnom sedadle vozidla bezpečnostným pásom.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "58c83682953441c9b8e9"
        },
        {
            "nl_otazka_id": "1836",
            "uv_otazka": "Vaše vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "druhé.",
            "uv_odpovedb": "prvé.",
            "uv_odpovedc": "posledné.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/17.png",
            "body": "4",
            "poznamka": "",
            "hash": "8ee4b6eb51697727fffc"
        },
        {
            "nl_otazka_id": "1018",
            "uv_otazka": "Samovyvažovacím vozidlom sa rozumie",
            "uv_odpoveda": "kolobežka s pomocným motorčekom.",
            "uv_odpovedb": "vozidlo založené na vnútornej nestabilnej rovnováhe, ktoré na zachovanie svojej rovnováhy potrebuje pomocný riadiaci systém.",
            "uv_odpovedc": "bicykel vybavený pomocným elektrickým motorčekom s trvalým menovitým výkonom do 0,25 kW, ktorého výkon sa progresívne znižuje a nakoniec sa preruší, keď vozidlo dosiahne rýchlosť 25 km ? h-1 alebo keď vodič prestane šliapať do pedálov.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f1dea5cc6c40c8c6c167"
        },
        {
            "nl_otazka_id": "1067",
            "uv_otazka": "Ak sa vodič motorového vozidla, ktoré je povinne vybavené bezpečnostným odevom, zdržiava na vozovke mimo vozidla počas núdzového státia, najmä pri prerušení jazdy pre chybu na vozidle alebo v dôsledku dopravnej nehody, je povinný",
            "uv_odpoveda": "bezodkladne privolať odťahovú službu.",
            "uv_odpovedb": "umiestniť 20 m za vozidlo biele neoslňujúce svetlo bielej farby.",
            "uv_odpovedc": "mať bezpečnostný odev oblečený.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "435e91977232db8d77de"
        },
        {
            "nl_otazka_id": "1707",
            "uv_otazka": "Táto dopravná značka informuje o",
            "uv_odpoveda": "Mieste, kde je umiestený telefón núdzového volania.",
            "uv_odpovedb": "Blízkosti stanovišťa alebo útvaru Policajného zboru s nepretržitou službou.",
            "uv_odpovedc": "Mieste alebo úseku, kde policajti vykonávajú kontrolu najvyššej dovolenej rýchlosti vozidiel s automatickou detekciou.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/333.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "bc0a6599ea6fb2a4a6aa"
        },
        {
            "nl_otazka_id": "1957",
            "uv_otazka": "Spojka sa používa",
            "uv_odpoveda": "pri rozjazde, zastavení a preraďovaní prevodových stupňov.",
            "uv_odpovedb": "iba pri zaraďovaní prevodových stupňov.",
            "uv_odpovedc": "vždy keď je potrebné použiť prevádzkovú brzdu.",
            "nl_okruh_id": "6",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "7b2349719eae66abb84a"
        },
        {
            "nl_otazka_id": "2110",
            "uv_otazka": "V cestnej premávke možno prevádzkovať vozidlo, ktoré je",
            "uv_odpoveda": "spôsobilé a technicky spôsobilé premávky na pozemných komunikáciách; to neplatí na účelovej komunikácii.",
            "uv_odpovedb": "spôsobilé na prevádzku v cestnej premávke a technicky spôsobilé na prevádzku v cestnej premávke.",
            "uv_odpovedc": "spôsobilé na premávku v cestnej doprave a technicky spôsobilé na premávku v cestnej doprave; to neplatí pre zvláštne vozidlá.",
            "nl_okruh_id": "8",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "3d6168d4ca15ebc9ddcf"
        },
        {
            "nl_otazka_id": "1206",
            "uv_otazka": "Pri zastavení a státí vodič je povinný",
            "uv_odpoveda": "čo najlepšie využiť parkovacie miesto a nesmie znemožniť ostatným vodičom vyjdenie z parkovacieho miesta.",
            "uv_odpovedb": "ponechať bočný odstup od vozidla najmenej 1,5 m.",
            "uv_odpovedc": "použiť vždy prenosný výstražný trojuholník.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "0a284389321a362ccadf"
        },
        {
            "nl_otazka_id": "1447",
            "uv_otazka": "Pred vstupom na vozovku chodec",
            "uv_odpoveda": "sa nemusí presvedčiť, či tak môže urobiť bez nebezpečenstva, ak vstupuje na vozovku v obci.",
            "uv_odpovedb": "sa musí presvedčiť, či tak môže urobiť bez nebezpečenstva.",
            "uv_odpovedc": "musí prestať jesť, piť a fajčiť.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1da197142189a794794b"
        },
        {
            "nl_otazka_id": "1321",
            "uv_otazka": "Vozidlo stojace za zníženej viditeľnosti v obci na mieste, kde tvorí prekážku cestnej premávky alebo na ceste mimo obce",
            "uv_odpoveda": "nemusí byť osvetlené.",
            "uv_odpovedb": "musí mať aspoň na strane privrátenej k stredu cesty rozsvietené parkovacie alebo obrysové svietidlá, prípadne musí byť osvetlené na strane privrátenej k stredu cesty aspoň jedným svietidlom viditeľným spredu i zozadu, pričom toto svietidlo nesmie byť umiestnené ďalej ako 400 mm od bočného obrysu vozidla; to neplatí na parkovisku.",
            "uv_odpovedc": "musí mať aspoň na strane privrátenej k stredu cesty rozsvietené parkovacie alebo obrysové svietidlá, prípadne musí byť osvetlené na strane privrátenej k stredu cesty aspoň jedným svietidlom viditeľným spredu i zozadu, pričom toto svietidlo nesmie byť umiestnené ďalej ako 400 mm od bočného obrysu vozidla.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "9ef9a3c31dcd347d8f7f"
        },
        {
            "nl_otazka_id": "1934",
            "uv_otazka": "Ak sa rozsvieti oranžová kontrolka brzdového obloženia",
            "uv_odpoveda": "vozidlo je potrebné okamžite zastaviť, inak hrozí nefunkčnosť brzdového systému.",
            "uv_odpovedb": "s vozidlom je možné najazdiť ešte niekoľko kilometrov a bezpečne dôjsť do servisu.",
            "uv_odpovedc": "znamená prehriatie brzdových kotúčov a je potrebné viac využívať brzdný účinok motora a jazdiť nižšou rýchlosťou.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "obr3/tvv/06.png",
            "body": "2",
            "poznamka": "",
            "hash": "d2857c5732fb28c07b6f"
        },
        {
            "nl_otazka_id": "1454",
            "uv_otazka": "Na priechode pre cyklistov sa jazdí",
            "uv_odpoveda": "vpravo.",
            "uv_odpovedb": "kdekoľvek.",
            "uv_odpovedc": "vľavo.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "8e8c3c50a72b8ed8b684"
        },
        {
            "nl_otazka_id": "1512",
            "uv_otazka": "Ak bliká tento pruhový signál umiestnený nad jazdným pruhom, pre vodiča znamená:",
            "uv_odpoveda": "Zastaviť vozidlo v jazdnom pruhu.",
            "uv_odpovedb": "Opustiť jazdný pruh v smere šípky.",
            "uv_odpovedc": "Pokračovať v jazde v jazdnom pruhu.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "obr3/pcp/17_1c_2.png",
            "body": "3",
            "poznamka": "",
            "hash": "48bcf6dacc3964fc2529"
        },
        {
            "nl_otazka_id": "1275",
            "uv_otazka": "Vodič nesmie vchádzať na železničné priecestie, ak za zníženej viditeľnosti",
            "uv_odpoveda": "osoba pribratá na zaistenie bezpečnej prevádzky železničného priecestia dáva znamenie na zastavenie vozidla krúžením červeným svetlom v hornom polkruhu.",
            "uv_odpovedb": "svieti prerušované biele svetlo.",
            "uv_odpovedc": "osoba pribratá na zaistenie bezpečnej prevádzky železničného priecestia dáva znamenie kývaním ruky s bielym svetlom cez stred tela.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "0eab0bda06dcc618a692"
        },
        {
            "nl_otazka_id": "1137",
            "uv_otazka": "Vodič, ktorý pri obchádzaní vozidla, ktoré zastavilo alebo stojí, prekážky cestnej premávky alebo chodca vybočuje zo smeru svojej jazdy",
            "uv_odpoveda": "nie je povinný dávať znamenie o zmene smeru jazdy.",
            "uv_odpovedb": "nesmie obmedziť vodiča jazdiaceho za ním.",
            "uv_odpovedc": "nesmie ohroziť ani obmedziť vodiča protiidúceho vozidla.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f0fd98b614ea9d9791d8"
        },
        {
            "nl_otazka_id": "1485",
            "uv_otazka": "V školskej zóne",
            "uv_odpoveda": "možno stáť bez obmedzenia.",
            "uv_odpovedb": "možno stáť, ak tým nie je obmedzený pohyb chodcov.",
            "uv_odpovedc": "nemožno stáť.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5da5d8337d048d35b88d"
        },
        {
            "nl_otazka_id": "1827",
            "uv_otazka": "Vozidlá prejdú cez križovatku v tomto poradí:",
            "uv_odpoveda": "1. červené súčasne so zeleným, 2. vaše vozidlo.",
            "uv_odpovedb": "1. vaše vozidlo, 2. červené súčasne so zeleným.",
            "uv_odpovedc": "1. zelené, 2. vaše vozidlo, 3. červené.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/13.png",
            "body": "4",
            "poznamka": "",
            "hash": "c42fa7aa9d193eca053d"
        },
        {
            "nl_otazka_id": "1910",
            "uv_otazka": "Účastník dopravnej nehody je",
            "uv_odpoveda": "len osoba, ktorá dopravnú nehodu zavinila.",
            "uv_odpovedb": "prevádzkovateľ vozidla zapísaný v osvedčení o evidencii vozidla.",
            "uv_odpovedc": "osoba, ktorá sa priamo aktívne alebo pasívne zúčastnila na dopravnej nehode.",
            "nl_okruh_id": "5",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "e1cd91c8584bc479af6c"
        },
        {
            "nl_otazka_id": "1242",
            "uv_otazka": "Pred vjazdom na cestu alebo na vyhradenom parkovisku, ak nejde o vozidlo, pre ktoré je parkovisko vyhradené, vodič nesmie",
            "uv_odpoveda": "zastaviť a stáť.",
            "uv_odpovedb": "zastaviť a stáť; to neplatí, ak ide o zastavenie a státie, ktoré nepresiahne tri minúty.",
            "uv_odpovedc": "stáť, ale môže zastaviť, ak ide o zastavenie, ktoré nepresiahne päť minút.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e829e97936c9723a6876"
        },
        {
            "nl_otazka_id": "1468",
            "uv_otazka": "Osoba, ktorá vykonáva na ceste prácu spojenú s jej údržbou, opravou alebo výstavbou, alebo inú pracovnú činnosť, na ktorú je oprávnená",
            "uv_odpoveda": "nemusí dodržiavať žiadne ustanovenia pravidiel cestnej premávky.",
            "uv_odpovedb": "musí vždy dodržiavať všetky ustanovenia pravidiel cestnej premávky.",
            "uv_odpovedc": "nemusí dodržiavať tie ustanovenia pravidiel cestnej premávky, pri ktorých to povaha vykonávanej práce vylučuje.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "9a2e0211d618a10b209c"
        },
        {
            "nl_otazka_id": "1019",
            "uv_otazka": "Státím sa rozumie",
            "uv_odpoveda": "len také uvedenie vozidla do pokoja, ktoré nepresiahne čas 15 minút.",
            "uv_odpovedb": "prerušenie jazdy na čas nevyhnutne potrebný na urýchlené nastúpenie alebo vystúpenie prepravovaných osôb alebo na urýchlené naloženie alebo zloženie nákladu.",
            "uv_odpovedc": "uvedenie vozidla do pokoja na dlhšie, ako je čas dovolený na zastavenie.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "85b91f9b9a4e4b66a958"
        },
        {
            "nl_otazka_id": "1184",
            "uv_otazka": "Smie vodič pri cúvaní ohroziť ostatných účastníkov cestnej premávky?",
            "uv_odpoveda": "Áno, smie ohroziť.",
            "uv_odpovedb": "Nie, nesmie ohroziť.",
            "uv_odpovedc": "Nie, nesmie ohroziť; to neplatí za zníženej viditeľnosti.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "485074013af5bf06187f"
        },
        {
            "nl_otazka_id": "2237",
            "uv_otazka": "Do základných pravidiel bezpečnej jazdy patrí",
            "uv_odpoveda": "Ak ste unavení, dajte si kávu.",
            "uv_odpovedb": "Nejazdite pod vplyvom alkoholu alebo drog.",
            "uv_odpovedc": "Akýmkoľvek spôsobom upozorňujte iných vodičov na ich chyby.",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e0ae90253f90d9c4196c"
        },
        {
            "nl_otazka_id": "1147",
            "uv_otazka": "Vodič nesmie pri predchádzaní ohroziť ani obmedziť vodičov vozidiel, pred ktoré sa po predídení zaraďuje; pritom je povinný",
            "uv_odpoveda": "dávať znamenie o zmene smeru jazdy.",
            "uv_odpovedb": "nedávať znamenie o zmene smeru jazdy za zníženej viditeľnosti.",
            "uv_odpovedc": "v obci dávať zvukové výstražné znamenie na upozornenie vodiča predchádzaného vozidla.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "59479f604baa9a77f916"
        },
        {
            "nl_otazka_id": "1474",
            "uv_otazka": "Jazda vozidiel v obytnej zóne je",
            "uv_odpoveda": "zakázaná.",
            "uv_odpovedb": "povolená.",
            "uv_odpovedc": "povolená len vozidlám pravidelnej verejnej dopravy osôb.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ebc4b5b71448275f0cc1"
        },
        {
            "nl_otazka_id": "1021",
            "uv_otazka": "Vodičom sa rozumie osoba, ktorá",
            "uv_odpoveda": "vedie vozidlo.",
            "uv_odpovedb": "vedie len motorové vozidlo.",
            "uv_odpovedc": "tlačí motocykel, pohybuje sa na lyžiach alebo kolieskových korčuliach, ťahá alebo tlačí sánky.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "230eb145457c8f0cf8b8"
        },
        {
            "nl_otazka_id": "1974",
            "uv_otazka": "Hlavnou zásadou radenia je",
            "uv_odpoveda": "spomaliť vozidlo pred preradením.",
            "uv_odpovedb": "radiť vždy ľahko a radiacu páku nezvierať silou.",
            "uv_odpovedc": "zrýchliť jazdu minimálne o 30 km/h.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "a3d4473b1ab418979b33"
        },
        {
            "nl_otazka_id": "1317",
            "uv_otazka": "Vodič motorového vozidla",
            "uv_odpoveda": "nesmie jazdiť s obrysovými svietidlami, ak má súčasne rozsvietené stretávacie svetlomety alebo predné svetlomety do hmly.",
            "uv_odpovedb": "smie jazdiť aj s parkovacími alebo obrysovými svietidlami, ak má súčasne rozsvietené stretávacie svetlomety alebo diaľkové svetlomety, alebo predné svetlomety do hmly, ak je nimi vozidlo vybavené.",
            "uv_odpovedc": "nesmie jazdiť s diaľkovými svetlometmi.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "efc6de93bd1293d77a3c"
        },
        {
            "nl_otazka_id": "1795",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Táborisko",
            "uv_odpovedb": "Miesto pre obytné automobily",
            "uv_odpovedc": "Turistika",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/2-18.png",
            "body": "2",
            "poznamka": "",
            "hash": "dddc34ec8d50262bc488"
        },
        {
            "nl_otazka_id": "1770",
            "uv_otazka": "Výstražné dopravné značky",
            "uv_odpoveda": "ukladajú účastníkom cestnej premávky príkazy, zákazy alebo obmedzenia alebo im umožňujú výnimky zo všeobecných pravidiel cestnej premávky.",
            "uv_odpovedb": "upozorňujú účastníkov cestnej premávky na miesto, kde môže hroziť nebezpečenstvo, ktoré je ťažko včas rozoznateľné aj pri dodržaní primeranej opatrnosti.",
            "uv_odpovedc": "vymedzujú miesta, kde platia osobitné pravidlá cestnej premávky, poskytujú účastníkom cestnej premávky informácie dopravného významu alebo slúžia na ich orientáciu.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "a8910c8bbb3e32e5df11"
        },
        {
            "nl_otazka_id": "1844",
            "uv_otazka": "Vaše vozidlo prejde cez križovatku ako",
            "uv_odpoveda": "posledné.",
            "uv_odpovedb": "prvé.",
            "uv_odpovedc": "druhé.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/21.png",
            "body": "4",
            "poznamka": "",
            "hash": "72157c5cb52b3650628f"
        },
        {
            "nl_otazka_id": "1911",
            "uv_otazka": "Účastník dopravnej nehody je povinný ohlásiť",
            "uv_odpoveda": "dopravnú nehodu policajtovi.",
            "uv_odpovedb": "dopravnú nehodu policajtovi len vtedy, ak pri dopravnej nehode došlo k zraneniu osoby.",
            "uv_odpovedc": "dopravnú nehodu správcovi cesty.",
            "nl_okruh_id": "5",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "b35d1d1a2acad21b123a"
        },
        {
            "nl_otazka_id": "1944",
            "uv_otazka": "Ak sa na prístrojovom paneli rozsvieti kontrolka žiarovky, signalizuje",
            "uv_odpoveda": "nefunkčnosť obrysových svietidiel. ",
            "uv_odpovedb": "funkčnosť obrysových svietidiel.  ",
            "uv_odpovedc": "nefunkčnú (vypálenú) žiarovku.",
            "nl_okruh_id": "6",
            "nl_correct": "3",
            "uv_obrazok": "obr3/tvv/43.png",
            "body": "2",
            "poznamka": "",
            "hash": "8d3b940e2c7ac86de6f7"
        },
        {
            "nl_otazka_id": "1278",
            "uv_otazka": "Ak vodičovi situácia na železničnom priecestí alebo za ním nedovoľuje, aby ho bezpečne prešiel a pokračoval v jazde, tak",
            "uv_odpoveda": "smie vojsť na železničné priecestie, len ak nie je vidieť prichádzať dráhové vozidlo.",
            "uv_odpovedb": "nesmie vchádzať na železničné priecestie.",
            "uv_odpovedc": "smie zastaviť na železničnom priecestí na čas, ktorý nepresiahne 3 minúty.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "17c396c004eeb9350134"
        },
        {
            "nl_otazka_id": "1022",
            "uv_otazka": "Vozidlom sa rozumie",
            "uv_odpoveda": "len motorové vozidlo.",
            "uv_odpovedb": "motorové a nemotorové vozidlo okrem zvláštneho motorového vozidla a bicykla.",
            "uv_odpovedc": "motorové vozidlo, nemotorové vozidlo a električka.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "19c4c795f3c7c5729a67"
        },
        {
            "nl_otazka_id": "1583",
            "uv_otazka": "Vodič, ktorý prichádza do križovatky po vedľajšej ceste označenej touto dopravnou značkou,",
            "uv_odpoveda": "má prednosť v jazde.",
            "uv_odpovedb": "je povinný dať prednosť v jazde vozidlám prichádzajúcim sprava.",
            "uv_odpovedc": "je povinný dať prednosť v jazde vozidlám a ostatným účastníkom cestnej premávky prichádzajúcim po hlavnej ceste.",
            "nl_okruh_id": "2",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/201.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "f1641b61b083959947f2"
        },
        {
            "nl_otazka_id": "1151",
            "uv_otazka": "Vodič nesmie predchádzať, ak",
            "uv_odpoveda": "na to v obci neupozornil vodiča predchádzaného vozidla zvukovým výstražným znamením.",
            "uv_odpovedb": "by sa nemohol bezpečne zaradiť pred vozidlo alebo pred vozidlá, ktoré chce predísť.",
            "uv_odpovedc": "pred ním jazdí zvláštne motorové vozidlo.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f826051551b3cecb4fbf"
        },
        {
            "nl_otazka_id": "1460",
            "uv_otazka": "Vodiči samovyvažovacích vozidiel smú jazdiť",
            "uv_odpoveda": "len jednotlivo za sebou.",
            "uv_odpovedb": "dvaja vedľa seba.",
            "uv_odpovedc": "po ľavej krajnici, ak neohrozia a neobmedzia chodcov.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "9265eb8fdfb18ab3f9e1"
        },
        {
            "nl_otazka_id": "1120",
            "uv_otazka": "Použitie záchranárskej uličky vodičom iného vozidla ako je vozidlo s právom prednostnej jazdy alebo vozidlo zabezpečujúce odstránenie následkov dopravnej nehody je",
            "uv_odpoveda": "zakázané.",
            "uv_odpovedb": "dovolené, ak neohrozí ostatných účastníkov cestnej premávky.",
            "uv_odpovedc": "dovolené, ak to vyžaduje plynulosť cestnej premávky.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "7f55d2d7ba621e4f9ade"
        },
        {
            "nl_otazka_id": "1593",
            "uv_otazka": "Ak na priecestnom zabezpečovacom zariadení svieti prerušované biele svetlo, vodič je povinný",
            "uv_odpoveda": "vo vzdialenosti 30 m pred železničným priecestím a pri jeho prechádzaní jazdiť rýchlosťou najviac 30 km.h-1.",
            "uv_odpovedb": "zastaviť vozidlo.",
            "uv_odpovedc": "vo vzdialenosti 50 m pred železničným priecestím a pri jeho prechádzaní jazdiť rýchlosťou najviac 50 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "50114fbf4a1a1598209d"
        },
        {
            "nl_otazka_id": "1837",
            "uv_otazka": "V poradí druhé prejde cez križovatku",
            "uv_odpoveda": "modré vozidlo.",
            "uv_odpovedb": "električka.",
            "uv_odpovedc": "zelené vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/18.png",
            "body": "4",
            "poznamka": "",
            "hash": "60edd1d13ddb11685336"
        },
        {
            "nl_otazka_id": "1127",
            "uv_otazka": "Ak ide električka inou rýchlosťou ako ostatné vozidlá idúce rovnakým smerom",
            "uv_odpoveda": "nejde o vzájomné predchádzanie.",
            "uv_odpovedb": "ide o vzájomné predchádzanie, ak električka ide pomalšie ako ostatné vozidlá.",
            "uv_odpovedc": "ide o vzájomné predchádzanie.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5b253086a5c3dba288dc"
        },
        {
            "nl_otazka_id": "1509",
            "uv_otazka": "Tento zastavovací terč používa pri zastavení vozidla",
            "uv_odpoveda": "akákoľvek iná osoba.",
            "uv_odpovedb": "poverená osoba.",
            "uv_odpovedc": "policajt",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "obr3/pcp/23_7_2.png",
            "body": "3",
            "poznamka": "",
            "hash": "6a830d06c7bbd4216397"
        },
        {
            "nl_otazka_id": "1461",
            "uv_otazka": "Vodič samovyvažovacieho vozidla, ak jazdí po chodníku",
            "uv_odpoveda": "nesmie prekročiť rýchlosť chôdze.",
            "uv_odpovedb": "nesmie jazdiť po chodníku.",
            "uv_odpovedc": "nesmie prekročiť rýchlosť chôdze, ak vedie počas jazdy psa alebo iné zviera.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "c55665a97334de2ca74a"
        },
        {
            "nl_otazka_id": "1958",
            "uv_otazka": "NEUTRÁL - je základnou polohou radiacej páky, v tejto polohe",
            "uv_odpoveda": "sa vykonáva rozjazd vozidla.",
            "uv_odpovedb": "sa nesmie štartovať vozidlo.",
            "uv_odpovedc": "nie je zaradený žiadny prevodový stupeň.",
            "nl_okruh_id": "6",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "98e77e36faa26396a699"
        },
        {
            "nl_otazka_id": "1455",
            "uv_otazka": "Cyklista mladší ako 15 rokov je povinný počas jazdy na bicykli",
            "uv_odpoveda": "mimo obce chrániť si hlavu riadne upevnenou ochrannou prilbou.",
            "uv_odpovedb": "chrániť si hlavu riadne upevnenou ochrannou prilbou.",
            "uv_odpovedc": "mať na sebe oblečený odev červenej farby.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "87e1c249742c7e7a6829"
        },
        {
            "nl_otazka_id": "1807",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Parkovacie miesta s pozdĺžnym státím čiastočne na chodníku",
            "uv_odpovedb": "Parkovacie miesta s pozdĺžnym státím na chodníku",
            "uv_odpovedc": "Parkovacie miesta s priečnym státím na chodníku (státie povolené kolmo alebo šikmo na os vozovky, oboma nápravami na chodníku a prednou alebo zadnou časťou vozidla smerom k vozovke)",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/4-3.png",
            "body": "2",
            "poznamka": "",
            "hash": "1e0418099d47dbfa1ba2"
        },
        {
            "nl_otazka_id": "1900",
            "uv_otazka": "Prednosť v jazde má vodič",
            "uv_odpoveda": "modrého vozidla, pretože  ide po kruhovom objazde, ktorý je označený dopravnou značkou “Kruhový objazd“  spolu s dopravnou značkou “Daj prednosť v jazde!“.",
            "uv_odpovedb": "modrého vozidla, pretože prichádza zľava.",
            "uv_odpovedc": "červeného vozidla, pretože prichádza sprava.",
            "nl_okruh_id": "4",
            "nl_correct": "1",
            "uv_obrazok": "obr3/ds/39.png",
            "body": "4",
            "poznamka": "",
            "hash": "671a0c29d34fa66231c6"
        },
        {
            "nl_otazka_id": "1914",
            "uv_otazka": "Účastník dopravnej nehody",
            "uv_odpoveda": "je povinný urobiť potrebné opatrenia na záchranu osoby alebo majetku ohrozeného dopravnou nehodou.",
            "uv_odpovedb": "nesmie urobiť žiadne opatrenia na záchranu osoby, ak by to bolo na ujmu vyšetrenia dopravnej nehody.",
            "uv_odpovedc": "je povinný umožniť obnovenie cestnej premávky len vtedy, ak dopravnú nehodu zavinil.",
            "nl_okruh_id": "5",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "b7304798f1e86da486ba"
        },
        {
            "nl_otazka_id": "2078",
            "uv_otazka": "Držiteľ vodičského preukazu",
            "uv_odpoveda": "nie je povinný chrániť vodičský preukaz pred zničením, poškodením, stratou, odcudzením alebo zneužitím.",
            "uv_odpovedb": "je povinný chrániť vodičský preukaz len pred stratou a odcudzením.",
            "uv_odpovedc": "je povinný chrániť vodičský preukaz pred zničením, poškodením, stratou, odcudzením alebo zneužitím.",
            "nl_okruh_id": "7",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "617389165f224a4f2e19"
        },
        {
            "nl_otazka_id": "1139",
            "uv_otazka": "Vodič vozidla, ktorý pri obchádzaní vozidla, ktoré zastavilo alebo stojí, vybočuje zo smeru svojej jazdy",
            "uv_odpoveda": "môže obmedziť protiidúcich vodičov.",
            "uv_odpovedb": "má prednosť v jazde.",
            "uv_odpovedc": "je povinný dávať znamenie o zmene smeru jazdy.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "8e459de1af9380977d06"
        },
        {
            "nl_otazka_id": "1168",
            "uv_otazka": "Vodič pri odbočovaní je povinný",
            "uv_odpoveda": "dbať na zvýšenú opatrnosť.",
            "uv_odpovedb": "sa vyhnúť protiidúcemu vozidlu.",
            "uv_odpovedc": "znížiť rýchlosť jazdy, najmä pri jazde cez kruhový objazd.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1c8120372b7d7731c139"
        },
        {
            "nl_otazka_id": "1984",
            "uv_otazka": "Môže sa výrazne ušetriť palivo pri jazde na diaľnici tým, že sa",
            "uv_odpoveda": "vypne navigácia GPS.",
            "uv_odpovedb": "zavrú okná.",
            "uv_odpovedc": "vypne tempomat.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "4fe0bbb8d0eb08ccb474"
        },
        {
            "nl_otazka_id": "2073",
            "uv_otazka": "Vodičský preukaz je neplatný",
            "uv_odpoveda": "v čase, v ktorom nemá žiadateľ o udelenie ďalšieho vodičského oprávnenia platnú lekársku prehliadku.",
            "uv_odpovedb": "v čase, v ktorom má jeho držiteľ uloženú povinnosť podrobiť sa lekárskej prehliadke.",
            "uv_odpovedc": "v čase, v ktorom má jeho držiteľ súdom alebo iným príslušným orgánom uložený zákaz činnosti viesť motorové vozidlo.",
            "nl_okruh_id": "7",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "411be6e3103309de0c85"
        },
        {
            "nl_otazka_id": "1310",
            "uv_otazka": "Vodič nesmie použiť diaľkové svetlomety, ak",
            "uv_odpoveda": "jazdí za zníženej viditeľnosti v obci.",
            "uv_odpovedb": "je vozovka dostatočne a súvisle osvetlená alebo ak by mohol byť oslnený vodič protiidúceho vozidla, vodič vozidla idúceho pred ním alebo iný účastník cestnej premávky, strojvedúci vlaku alebo vodič iného dráhového vozidla, alebo vodič plavidla.",
            "uv_odpovedc": "jazdí za zníženej viditeľnosti.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e4c45d08738f27fcb6d1"
        },
        {
            "nl_otazka_id": "1518",
            "uv_otazka": "Ak svieti tento signál so symbolom šípok počas rozsvieteného signálu červenej farby pri riadení cestnej premávky, znamená pre vodiča",
            "uv_odpoveda": "“Stoj“; vodič musí zastaviť vozidlo pred križovatkou.",
            "uv_odpovedb": "“Čakaj“; vodič sa musí pripraviť na jazdu a platí len pre smer vyznačený šípkami.",
            "uv_odpovedc": "“Čakaj“; vodič sa musí pripraviť na jazdu a platí pre všetky smery jazdy.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "obr3/pcp/15_3_2.png",
            "body": "3",
            "poznamka": "",
            "hash": "46a128ba6f2c4ff6687c"
        },
        {
            "nl_otazka_id": "1252",
            "uv_otazka": "Smie vodič na ceste I. triedy mimo obce zastaviť alebo stáť na inom mieste ako na parkovisku?",
            "uv_odpoveda": "Nesmie zastaviť ani stáť, s výnimkou núdzového státia.",
            "uv_odpovedb": "Smie len zastaviť, nesmie však stáť.",
            "uv_odpovedc": "Smie len zastaviť.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/350.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "799b1c57399dbda855d0"
        },
        {
            "nl_otazka_id": "1368",
            "uv_otazka": "Do skupiny vozidiel tvorenej vozidlami s právom prednostnej jazdy a vozidlami, ktoré sprevádzajú, sa vodič iného vozidla",
            "uv_odpoveda": "smie zaraďovať, ak dokáže prispôsobiť rýchlosť jazdy svojho vozidla rýchlosti jazdy takýchto vozidiel.",
            "uv_odpovedb": "nesmie zaraďovať.",
            "uv_odpovedc": "smie zaraďovať, len ak to dovoľuje situácia v cestnej premávke.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "56cb1fb324e151235a5b"
        },
        {
            "nl_otazka_id": "1552",
            "uv_otazka": "Na ceste s troma jazdnými pruhmi vyznačenými na vozovke v jednom smere jazdy vodič prechádzajúci",
            "uv_odpoveda": "z ľavého jazdného pruhu do stredného jazdného pruhu je povinný dať prednosť v jazde vodičovi prechádzajúcemu do stredného jazdného pruhu z pravého jazdného pruhu.",
            "uv_odpovedb": "z ľavého jazdného pruhu do stredného jazdného pruhu smie ohroziť vodiča prechádzajúceho do stredného jazdného pruhu z pravého jazdného pruhu.",
            "uv_odpovedc": "z pravého jazdného pruhu do stredného jazdného pruhu je povinný dať prednosť v jazde vodičovi prechádzajúcemu do stredného jazdného pruhu z ľavého jazdného pruhu.",
            "nl_okruh_id": "2",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ae985ab0d5680098ebaa"
        },
        {
            "nl_otazka_id": "1976",
            "uv_otazka": "Stláčanie pedálu spojky musí byť",
            "uv_odpoveda": "pomalšie a uvoľňovanie rýchle.",
            "uv_odpovedb": "pomalšie a uvoľňovanie tiež pomalšie.",
            "uv_odpovedc": "rýchle a uvoľňovanie pomalšie.",
            "nl_okruh_id": "6",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "ecd7045220293b08cdc6"
        },
        {
            "nl_otazka_id": "1043",
            "uv_otazka": "Vodič motorového vozidla",
            "uv_odpoveda": "môže viesť motorové vozidlo najviac 30 minút po požití alkoholu.",
            "uv_odpovedb": "nesmie viesť motorové vozidlo v takom čase po požití alkoholu alebo inej návykovej látky, keď sa alkohol alebo iná návyková látka ešte môžu nachádzať v jeho organizme.",
            "uv_odpovedc": "smie viesť motorové vozidlo v takom čase po požití alkoholu alebo návykovej látky, keď sa alkohol alebo iná návyková látka ešte môžu nachádzať v jeho organizme, ak to dovoľuje situácia v cestnej premávke, najmä za zníženej viditeľnosti.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "24c28f6cc85ca8ee2a54"
        },
        {
            "nl_otazka_id": "1420",
            "uv_otazka": "Náklad musí byť na vozidle riadne umiestnený, rozložený a upevnený, aby",
            "uv_odpoveda": "nezakrýval názov a sídlo držiteľa vozidla uvedeného na vozidle.",
            "uv_odpovedb": "nezakrýval svetlomety a svietidlá vozidla, odrazové sklá, tabuľku s evidenčným číslom a vyznačenie najvyššej povolenej rýchlosti.",
            "uv_odpovedc": "nezakrýval svetlomety a svietidlá vozidla a tabuľku s evidenčným číslom; to neplatí pri jazde na diaľnici a ceste pre motorové vozidlá.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "88ad4080edcffe7e387f"
        },
        {
            "nl_otazka_id": "1780",
            "uv_otazka": "Tento symbol má význam",
            "uv_odpoveda": "Mopedy",
            "uv_odpovedb": "Dvojkolesové motorové vozidlá s postranným vozíkom alebo bez neho s výnimkou mopedov a trojkolesové motorové vozidlá, ktorých prevádzková hmotnosť nepresahuje 400 kg",
            "uv_odpovedc": "Dvojkolesové alebo trojkolesové motorové vozidlá s najväčšou konštrukčnou rýchlosťou 45 km/h, ktorých zdvihový objem valcov motora nepresahuje 50 cm3 v prípade spaľovacieho motora a najväčší čistý výkon nie je väčší ako 4 kW alebo ktorých najväčší trvalý menovitý výkon nie je väčší ako 4 kW v prípade elektrického motora",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/1-7.png",
            "body": "2",
            "poznamka": "",
            "hash": "89c77dc4db6646e4d633"
        },
        {
            "nl_otazka_id": "1342",
            "uv_otazka": "Vodič pred vjazdom do priebežných jazdných pruhov na diaľnici je  povinný použiť",
            "uv_odpoveda": "pripájací jazdný pruh.",
            "uv_odpovedb": "odbočovací jazdný pruh.",
            "uv_odpovedc": "stredový deliaci pás na mieste, kde je tento pás prerušený.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "b932541834d4067eaa4c"
        },
        {
            "nl_otazka_id": "1306",
            "uv_otazka": "Výstražné znamenie sa smie použiť",
            "uv_odpoveda": "v obci na upozornenie vodiča predchádzaného vozidla.",
            "uv_odpovedb": "len v nevyhnutne potrebnom čase.",
            "uv_odpovedc": "pred začatím jazdného úkonu.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "630af8b3f01d8b649ff8"
        },
        {
            "nl_otazka_id": "1727",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Všeobecné informácie o dopravných obmedzeniach.",
            "uv_odpovedb": "Vhodným textom alebo symbolom informuje o dopravných obmedzeniach na horskom prechode.",
            "uv_odpovedc": "Diaľková návesť; informuje na ceste o diaľkových cieľoch.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/343.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "eefd47429eb49f6b1e0f"
        },
        {
            "nl_otazka_id": "1449",
            "uv_otazka": "Na bicykli sa jazdí predovšetkým",
            "uv_odpoveda": "po ľavej krajnici.",
            "uv_odpovedb": "po cestičke pre cyklistov.",
            "uv_odpovedc": "po chodníku.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "843e50ab94626e4388b1"
        },
        {
            "nl_otazka_id": "1397",
            "uv_otazka": "Prepravované osoby nesmú",
            "uv_odpoveda": "svojím správaním ohrozovať bezpečnosť cestnej premávky.",
            "uv_odpovedb": "obsluhovať telefónny prístroj.",
            "uv_odpovedc": "jesť, piť a fajčiť.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "19ec3db7ac2f0ff8c8b1"
        },
        {
            "nl_otazka_id": "1550",
            "uv_otazka": "Vodič pri prechádzaní z jedného jazdného pruhu do druhého jazdného pruhu",
            "uv_odpoveda": "môže ohroziť vodiča jazdiaceho v jazdnom pruhu, do ktorého prechádza.",
            "uv_odpovedb": "nedáva znamenie o zmene smeru jazdy.",
            "uv_odpovedc": "je povinný dať prednosť v jazde vodičovi jazdiacemu v jazdnom pruhu, do ktorého prechádza; pritom je povinný dávať znamenie o zmene smeru jazdy.",
            "nl_okruh_id": "2",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "b070d3a95b1ddf2343b5"
        },
        {
            "nl_otazka_id": "1975",
            "uv_otazka": "Počas jazdy na vozovke sa jazdí",
            "uv_odpoveda": "v strede jazdného pruhu.",
            "uv_odpovedb": "vpravo pri pravom okraji vozovky.",
            "uv_odpovedc": "vľavo, pri ľavom okraji vozovky, aby sme neohrozili chodcov.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "3abdb28ccf3330fb603d"
        },
        {
            "nl_otazka_id": "2252",
            "uv_otazka": "Ak vodič počas jazdy začína pociťovať únavu",
            "uv_odpoveda": "zrýchli, aby dorazil do cieľa čo najskôr.",
            "uv_odpovedb": "bezpodmienečne zastaví  a urobí si prestávku.",
            "uv_odpovedc": "komunikuje s inými vodičmi napr. gestami.",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f86ec4bae8660a766c96"
        },
        {
            "nl_otazka_id": "2250",
            "uv_otazka": "Ak užívate nejaký liek",
            "uv_odpoveda": "smiete šoférovať iba osobný automobil",
            "uv_odpovedb": "je potrebné zohľadniť informácie uvedené napr. v príbalovom letáku k tomuto lieku.",
            "uv_odpovedc": "motorové vozidlo smiete viesť iba vtedy, ak počas jazdy si preveríte účinok lieku na váš organizmus; ak zistíte, že máte spomalené reakcie ihneď zastavte.",
            "nl_okruh_id": "9",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "95cdea59ac31baaba32a"
        },
        {
            "nl_otazka_id": "1166",
            "uv_otazka": "Pred odbočovaním a počas odbočovania je vodič povinný",
            "uv_odpoveda": "dávať znamenie o zmene smeru jazdy.",
            "uv_odpovedb": "dať prednosť vjazde protiidúcemu vozidlu.",
            "uv_odpovedc": "preradiť na nižší prevodový stupeň.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "94c8dfb33652adc49e93"
        },
        {
            "nl_otazka_id": "1264",
            "uv_otazka": "Vozidlá smú prechádzať cez železničné priecestie len",
            "uv_odpoveda": "v jednom jazdnom prúde, ak nejde o súbežnú jazdu.",
            "uv_odpovedb": "na pokyn iného účastníka cestnej premávky.",
            "uv_odpovedc": "v dvoch jazdných prúdoch.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "a8edff06bc57582a813c"
        },
        {
            "nl_otazka_id": "1183",
            "uv_otazka": "Smie vodič pri cúvaní obmedziť ostatných účastníkov cestnej premávky?",
            "uv_odpoveda": "Áno, smie obmedziť.",
            "uv_odpovedb": "Nie, nesmie obmedziť.",
            "uv_odpovedc": "Áno, smie obmedziť len za nezníženej viditeľnosti.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "2a78ce26416eaf1b0d10"
        },
        {
            "nl_otazka_id": "1743",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Miesto, kde sa vykonáva kontrola najvyššej dovolenej rýchlosti vozidiel technickým prostriedkom konštrukčne pevne spojeným so zemou.",
            "uv_odpovedb": "Možnosti použitia antiradaru vo vozidle.",
            "uv_odpovedc": "Miesto výkonu geodetických prác za použitia radarových prostriedkov na meranie vzdialenosti.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/334.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "1e7bbc5d151d999eafca"
        },
        {
            "nl_otazka_id": "1649",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Ukladá vodičovi povinnosť dať prednosť v jazde.",
            "uv_odpovedb": "Označuje hlavnú cestu.",
            "uv_odpovedc": "Označuje križovatku dvoch ciest rovnakého významu.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/201.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "ef3916557d6786b2b06d"
        },
        {
            "nl_otazka_id": "1243",
            "uv_otazka": "Pred vjazdom na cestu alebo na vyhradenom parkovisku, ak nejde o vozidlo, pre ktoré je parkovisko vyhradené, vodič",
            "uv_odpoveda": "nesmie zastaviť a stáť.",
            "uv_odpovedb": "môže zastaviť a stáť, ak zastavenie ani státie nepresiahne päť minút a neohrozí pritom vodičov motorových vozidiel.",
            "uv_odpovedc": "nesmie zastaviť a stáť; to neplatí, ak ide o zastavenie a státie, ktoré nepresiahne tri minúty.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "8a198f826a7404aceb33"
        },
        {
            "nl_otazka_id": "1941",
            "uv_otazka": "Ak sa na prístrojovom paneli rozsvieti kontrolka bezpečnostného pásu,",
            "uv_odpoveda": "vodič, alebo pasažieri sú pripútaní.",
            "uv_odpovedb": "systém airbagov vykazuje poruchu.",
            "uv_odpovedc": "vodič alebo pasažieri nie sú pripútaní.",
            "nl_okruh_id": "6",
            "nl_correct": "3",
            "uv_obrazok": "obr3/tvv/05.png",
            "body": "2",
            "poznamka": "",
            "hash": "d4b9963db5c34fb10519"
        },
        {
            "nl_otazka_id": "1287",
            "uv_otazka": "Ste povinný dávať znamenie o zmene smeru jazdy vpravo, ak pokračujete rovno na vedľajšiu cestu vyznačenú na dodatkovej tabuľke?",
            "uv_odpoveda": "Áno, ak na to upozorní vodič protiidúceho vozidla.",
            "uv_odpovedb": "Áno, ak to vyžaduje plynulosť cestnej premávky.",
            "uv_odpovedc": "Nie, vodič idúci rovno znamenie o zmene smeru jazdy nikdy nedáva.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "obr3/pcp/30_1.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "c7e5e2f02546195fa006"
        },
        {
            "nl_otazka_id": "1524",
            "uv_otazka": "Toto doplnkové zariadenie k svetelným signálom pri riadení cestnej premávky v križovatke umožňuje vodičovi otočiť sa v križovatke s riadenou premávkou, ak svieti signál zelenej farby, pričom vodič",
            "uv_odpoveda": "nesmie ohroziť iných účastníkov cestnej premávky, najmä chodcov, cyklistov a vozidlá jazdiace vo voľnom smere.",
            "uv_odpovedb": "nesmie ohroziť ani obmedziť iných účastníkov cestnej premávky, najmä chodcov, cyklistov a vozidlá jazdiace vo voľnom smere.",
            "uv_odpovedc": "nesmie obmedziť iných účastníkov cestnej premávky, najmä chodcov, cyklistov a vozidlá jazdiace vo voľnom smere.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/731.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "40ef846b8f22f41c56ce"
        },
        {
            "nl_otazka_id": "1072",
            "uv_otazka": "Prevádzkovateľ nesmie zveriť vedenie vozidla osobe,",
            "uv_odpoveda": "ktorá nemá najmenej dvojročnú prax vo vedení motorových vozidiel.",
            "uv_odpovedb": "ktorá je mladšia ako 25 rokov, aj keď inak spĺňa ustanovené podmienky.",
            "uv_odpovedc": "ktorej totožnosť nepozná.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ee169f020e8043417c2e"
        },
        {
            "nl_otazka_id": "2101",
            "uv_otazka": "Povinnou výbavou motorového vozidla",
            "uv_odpoveda": "sú matice a skrutky umožňujúce upevnenie výstražného zariadenia na vozidle.",
            "uv_odpovedb": "sú najmenej dvojlitrová zásoba maziva a najmenej dvojlitrová zásoba chladiacej kvapaliny v osobitných nádobách; to neplatí pre jednostopové motorové vozidlá.",
            "uv_odpovedc": "je v závislosti od kategórie vozidla predpísaná lekárnička.",
            "nl_okruh_id": "8",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "34695c71ab597d9e9811"
        },
        {
            "nl_otazka_id": "2223",
            "uv_otazka": "Ak predchádzame na priamom úseku vozovky, hrozí nebezpečenstvo nehody, ak",
            "uv_odpoveda": "odhadneme rýchlosť protiidúceho vozidla ako príliš vysokú",
            "uv_odpovedb": "odhadneme rýchlosť predchádzaného vozidla ako príliš vysokú",
            "uv_odpovedc": "odhadneme rýchlosť protiidúceho vozidla ako príliš nízku",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "cd8c12cbd9f0fd3b6681"
        },
        {
            "nl_otazka_id": "1319",
            "uv_otazka": "Musí byť vozidlo stojace za zníženej viditeľnosti na parkovisku osvetlené?",
            "uv_odpoveda": "Nie, nemusí byť osvetlené.",
            "uv_odpovedb": "Nie, nemusí byť osvetlené; to neplatí, ak tvorí prekážku cestnej premávky.",
            "uv_odpovedc": "Áno, musí byť osvetlené.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "d440a91781de894f11a9"
        },
        {
            "nl_otazka_id": "1316",
            "uv_otazka": "Predné svetlomety do hmly a zadné svietidlá do hmly vodič smie použiť",
            "uv_odpoveda": "len za hmly; za sneženia a dažďa je povinný použiť diaľkové svetlomety.",
            "uv_odpovedb": "vždy za zníženej viditeľnosti.",
            "uv_odpovedc": "len za hmly, sneženia alebo za dažďa.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "22d153290597965dcf67"
        },
        {
            "nl_otazka_id": "1187",
            "uv_otazka": "Vodič sa nesmie otáčať a nesmie cúvať",
            "uv_odpoveda": "na križovatke s riadenou premávkou; otáčať sa smie, ak to dovoľuje dopravná značka alebo dopravné zariadenie.",
            "uv_odpovedb": "na parkovisku.",
            "uv_odpovedc": "v obytnej zóne.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3d39749651a273e8065a"
        },
        {
            "nl_otazka_id": "1817",
            "uv_otazka": "Ako prvé prejde cez križovatku",
            "uv_odpoveda": "vaše vozidlo.",
            "uv_odpovedb": "červené vozidlo.",
            "uv_odpovedc": "zelené vozidlo.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/03.png",
            "body": "4",
            "poznamka": "",
            "hash": "260567ea551452b4b865"
        },
        {
            "nl_otazka_id": "1155",
            "uv_otazka": "Vodič nesmie predchádzať...",
            "uv_odpoveda": "vpravo a to za žiadnych okolností.",
            "uv_odpovedb": "po krajnici; to neplatí pri predchádzaní  vozidla vpravo, ktoré mení smer jazdy vľavo a ak už nie je pochybnosť o ďalšom smere jeho jazdy.",
            "uv_odpovedc": "v úseku 300 m pred križovatkou.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "9475e85e6b7324fbd0c5"
        },
        {
            "nl_otazka_id": "1059",
            "uv_otazka": "Vodič smie motorové vozidlo použiť len na cestách; to neplatí, ak sa motorové vozidlo v súlade s osobitnými predpismi použije",
            "uv_odpoveda": "so súhlasom prevádzkovateľa vozidla.",
            "uv_odpovedb": "so súhlasom spolujazdca.",
            "uv_odpovedc": "na prepravu do obytného alebo rekrečného zariadenia.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "9721296a1da019683bfc"
        },
        {
            "nl_otazka_id": "1034",
            "uv_otazka": "Vodič je povinný",
            "uv_odpoveda": "brať ohľad na vozidlo označené ako výcvikové vozidlo autoškoly.",
            "uv_odpovedb": "nebrať ohľad na vozidlo označené ako výcvikové vozidlo autoškoly.",
            "uv_odpovedc": "brať ohľad na každé vozidlo, ktoré sa pohybuje rýchlosťou vyššou ako 90 km.h-1.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ad500ddb0a56c62064a7"
        },
        {
            "nl_otazka_id": "1079",
            "uv_otazka": "Osoba sediaca na sedadle vozidla povinne vybavenom bezpečnostným pásom alebo iným zadržiavacím zariadením je povinná toto zariadenie použiť; to neplatí pre",
            "uv_odpoveda": "vodiča počas jazdy v obci.",
            "uv_odpovedb": "osobu, ktorá nemôže byť pripútaná zo zdravotných dôvodov; takáto osoba sa musí preukázať osvedčením o oslobodení od použitia bezpečnostných systémov vozidla.",
            "uv_odpovedc": "spolujazdca sediaceho na zadnom sedadle vozidla.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "885838a28f0f8e63d831"
        },
        {
            "nl_otazka_id": "1281",
            "uv_otazka": "Ak osoba pribratá na zaistenie bezpečnej prevádzky železničného priecestia dáva pokyn na jazdu, vodič nesmie vchádzať na železničné priecestie, ak",
            "uv_odpoveda": "situácia na železničnom priecestí alebo za ním nedovoľuje, aby ho bezpečne prešiel a pokračoval v jazde.",
            "uv_odpovedb": "sa dáva výstraha prerušovaným zvukom húkačky alebo zvončeka priecestného zabezpečovacieho zariadenia.",
            "uv_odpovedc": "sa dáva výstraha dvoma červenými striedavo prerušovanými svetlami priecestného zabezpečovacieho zariadenia.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "bebd6074c1dfcd66fc10"
        },
        {
            "nl_otazka_id": "1001",
            "uv_otazka": "Električkovým koľajovým pásom sa rozumie",
            "uv_odpoveda": "časť cesty určená na premávku električky, vlaku a iného dráhového vozidla.",
            "uv_odpovedb": "časť cesty, na ktorej je povolená len premávka električky a nemotorových vozidiel.",
            "uv_odpovedc": "časť cesty určená predovšetkým na premávku električky.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "7251c7d2064531806215"
        },
        {
            "nl_otazka_id": "1991",
            "uv_otazka": "Vodič pri odbočovaní nesmie",
            "uv_odpoveda": "ohroziť vodiča idúceho za ním.",
            "uv_odpovedb": "dávať znamenie o zmene smeru jazdy.",
            "uv_odpovedc": "obmedziť vodiča jazdiaceho za ním.",
            "nl_okruh_id": "6",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "3f401346cc8c491e726c"
        },
        {
            "nl_otazka_id": "2084",
            "uv_otazka": "Držiteľ vodičského preukazu",
            "uv_odpoveda": "nie je povinný odovzdať orgánu Policajného zboru vodičský preukaz do 15 dní odo dňa jeho fiktívneho zadržania orgánom Policajného zboru.",
            "uv_odpovedb": "je povinný odovzdať orgánu Policajného zboru vodičský preukaz do 15 dní odo dňa jeho fiktívneho zadržania orgánom Policajného zboru.",
            "uv_odpovedc": "je povinný odovzdať orgánu Policajného zboru vodičský preukaz do 7 dní odo dňa jeho fiktívneho zadržania orgánom Policajného zboru.",
            "nl_okruh_id": "7",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "092e9666580e4fd17cdd"
        },
        {
            "nl_otazka_id": "1463",
            "uv_otazka": "Ak pohonič ide vedľa záprahového vozidla, je povinný ísť po",
            "uv_odpoveda": "pravej strane.",
            "uv_odpovedb": "ľavej strane a najviac jeden meter od vozidla.",
            "uv_odpovedc": "ľavej strane.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "30e71c380f8d217f9c87"
        },
        {
            "nl_otazka_id": "1209",
            "uv_otazka": "Vodičovi vozidla pravidelnej verejnej dopravy osôb v obci vodiči ostatných vozidiel",
            "uv_odpoveda": "sú povinní umožniť vyjdenie zo zastávky alebo zo zastávkového pruhu, a to znížením rýchlosti jazdy, prípadne i zastavením vozidla.",
            "uv_odpovedb": "sú povinní umožniť vyjdenie zo zastávky, len ak sú v tomto vozidle prepravované deti.",
            "uv_odpovedc": "nie sú povinní umožniť vyjdenie zo zastávky.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1652382a2ec8d55bdb52"
        },
        {
            "nl_otazka_id": "1309",
            "uv_otazka": "Vodič nesmie použiť diaľkové svetlomety",
            "uv_odpoveda": "pri jazde mimo obce.",
            "uv_odpovedb": "za zníženej viditeľnosti.",
            "uv_odpovedc": "ak je vozovka dostatočne a súvislé osvetlená.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1a86793d1dbc0360ca97"
        },
        {
            "nl_otazka_id": "1246",
            "uv_otazka": "Vodič nesmie zastaviť a stáť",
            "uv_odpoveda": "10 m od začiatku a 5 m od konca nástupného ostrovčeka.",
            "uv_odpovedb": "vľavo na jednosmernej ceste.",
            "uv_odpovedc": "na električkovom koľajovom páse",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5bd8b9dc649809edcde6"
        },
        {
            "nl_otazka_id": "1385",
            "uv_otazka": "Toto osobitné označenie sa môže používať na",
            "uv_odpoveda": "autobuse, ktorým sa prepravujú deti.",
            "uv_odpovedb": "osobnom vozidle, ktorým sa  prepravuje dieťa.",
            "uv_odpovedc": "nákladnom vozidle.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "obr3/pcp/25_2_3.png",
            "body": "3",
            "poznamka": "",
            "hash": "2a916edc84faecb11d6b"
        },
        {
            "nl_otazka_id": "1357",
            "uv_otazka": "Ak sa na vozovke nachádza súvislá snehová vrstva, ľad alebo námraza, vodič motorového vozidla kategórie M1 a N1 môže také vozidlo použiť v cestnej premávke, len ak",
            "uv_odpoveda": "má uzatvorené havarijné poistenie.",
            "uv_odpovedb": "je toto vozidlo na všetkých nápravách vybavené pneumatikami na jazdu na snehu označené horským symbolom alebo pneumatikami s označením “M+S“, “M.S“ alebo “M&S“.",
            "uv_odpovedc": "je toto vybavené na hnacej náprave zimnými pneumatikami.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "574b66a6299ed6483d18"
        },
        {
            "nl_otazka_id": "1722",
            "uv_otazka": "Táto dopravná značka označuje",
            "uv_odpoveda": "Začiatok obce.",
            "uv_odpovedb": "Pešiu zónu s možnosťou parkovania vozidiel.",
            "uv_odpovedc": "Miesto, od ktorého platia osobitné ustanovenia o cestnej premávke v obytnej zóne.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/315.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "05da97f57bb0b03102cf"
        },
        {
            "nl_otazka_id": "1985",
            "uv_otazka": "Pri výbere vozidla, z pohľadu ekošoférovania, je z menovaných vlastností najdôležitejšie zohľadniť",
            "uv_odpoveda": "druh paliva.",
            "uv_odpovedb": "výbavu vozidla.",
            "uv_odpovedc": "karosériu vozidla.",
            "nl_okruh_id": "6",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "30fdfbcdcfbc040329e0"
        },
        {
            "nl_otazka_id": "1462",
            "uv_otazka": "Cestičku pre cyklistov môže použiť aj osoba pohybujúca sa",
            "uv_odpoveda": "na motorovom vozidle.",
            "uv_odpovedb": "na samovyvažovacom vozidle.",
            "uv_odpovedc": "na zvierati.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "0323aa61a95e0b0a44cd"
        },
        {
            "nl_otazka_id": "2309",
            "uv_otazka": "Palivom pre štvordobé vznetové (dieselové) motory je",
            "uv_odpoveda": "benzín.",
            "uv_odpovedb": "nafta.",
            "uv_odpovedc": "zmes benzínu a vzduchu.",
            "nl_okruh_id": "10",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "c6cfe24dd5638b1e3b2b"
        },
        {
            "nl_otazka_id": "1032",
            "uv_otazka": "Vodič je povinný",
            "uv_odpoveda": "zabezpečiť vozidlo a prepravované veci proti odcudzeniu prostriedkami montovanými do vozidla jeho výrobcom, ak sa vzdiali od vozidla na dobu, ktorá presiahne 30 minút.",
            "uv_odpovedb": "použiť na jazdu len vozidlo, ktoré možno prevádzkovať v cestnej premávke.",
            "uv_odpovedc": "zastaviť vozidlo pred priechodom pre chodcov aj vtedy, ak sa ňom chodci nenachádzajú.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f8ff63ccaa2f2410f374"
        },
        {
            "nl_otazka_id": "1396",
            "uv_otazka": "Za prepravu detí alebo osôb, ktoré pre poruchu zdravia nezodpovedajú za svoje konanie, zodpovedá",
            "uv_odpoveda": "osoba, ktorá ich sprevádza.",
            "uv_odpovedb": "osoba pribratá na zaistenie bezpečnosti a plynulosti cestnej premávky alebo niektorých jazdných úkonov.",
            "uv_odpovedc": "zákonný zástupca.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "03024e5e7e81a9e226f3"
        },
        {
            "nl_otazka_id": "1389",
            "uv_otazka": "Vodič vozidla s parkovacím preukazom",
            "uv_odpoveda": "môže stáť na mieste vyhradenom pre vozidlá prepravujúce osoby s ťažkým zdravotným postihnutím.",
            "uv_odpovedb": "nemusí po nevyhnutne potrebný čas dodržiavať najvyššie dovolené rýchlosti jazdy.",
            "uv_odpovedc": "nemusí po nevyhnutne potrebný čas dodržiavať zákaz státia a najvyššie dovolené rýchlosti.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "13eebfc84835b433e2ff"
        },
        {
            "nl_otazka_id": "1961",
            "uv_otazka": "Pri zaradení každého prevodového stupňa musíme",
            "uv_odpoveda": "pedál spojky a plynový pedál stlačiť až na podlahu.",
            "uv_odpovedb": "úplne uvoľniť plynový pedál a pedál spojky stlačiť až k podlahe.",
            "uv_odpovedc": "úplne uvoľniť plynový pedál a pedál spojky stlačiť po záber.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "27586723f4bb6cf3ace0"
        },
        {
            "nl_otazka_id": "2119",
            "uv_otazka": "Technickou nespôsobilosťou vozidla na prevádzku v cestnej premávke je každý stav na vozidle, ktorý môže",
            "uv_odpoveda": "ohroziť prevádzkové vlastnosti vozidla.",
            "uv_odpovedb": "bezprostredne ohroziť prevádzkové vlastnosti vozidla, vplyvom ktorých môže dôjsť k ohrozeniu bezpečnosti cestnej premávky.",
            "uv_odpovedc": "ohroziť technicko-prevádzkové vlastnosti vozidla, vplyvom ktorých môže dôjsť k obmedzeniu bezpečnosti cestnej premávky.",
            "nl_okruh_id": "8",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "f443a8055208c4332231"
        },
        {
            "nl_otazka_id": "2128",
            "uv_otazka": "Vozidlo sa považuje za technicky nespôsobilé na prevádzku v cestnej premávke, najmä ak",
            "uv_odpoveda": "sú poškodené alebo nefunkčné ostrekovače zadného skla, dôsledkom čoho došlo k zhoršeniu výhľadu vodiča.",
            "uv_odpovedb": "je viditeľná alebo poškodená kordová vrstva pneumatiky.",
            "uv_odpovedc": "je nefunkčný stierač zadného skla.",
            "nl_okruh_id": "8",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "37fb128308fd650276eb"
        },
        {
            "nl_otazka_id": "1188",
            "uv_otazka": "Na priechode pre chodcov vodič",
            "uv_odpoveda": "smie sa otáčať.",
            "uv_odpovedb": "sa nesmie otáčať a nesmie cúvať.",
            "uv_odpovedc": "smie cúvať.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "548a06efb64631a73f62"
        },
        {
            "nl_otazka_id": "1036",
            "uv_otazka": "Vodič má povinnosť dbať na zvýšenú opatrnosť voči chodcom, osobitne voči osobám, ktoré používajú bielu palicu. Ktorý účastník cestnej premávky používa bielu palicu?",
            "uv_odpoveda": "Osoby so zrakovým postihnutím (nevidiaci, prakticky nevidiaci a slabozraký).",
            "uv_odpovedb": "Osoby so sluchovou poruchou.",
            "uv_odpovedc": "Policajt pri riadení premávky na križovatke, ktorú drží v pravej ruke.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e9bb3861dc1042e9a07a"
        },
        {
            "nl_otazka_id": "1682",
            "uv_otazka": "Táto dopravná značka ukončuje",
            "uv_odpoveda": "Zákazy vyplývajúce zo značiek Zákaz státia a Zákaz zastavenia.",
            "uv_odpovedb": "Zákazy vyplývajúce zo všetkých predchádzajúcich zákazových dopravných značiek.",
            "uv_odpovedc": "Zákazy vyplývajúce zo značiek Najvyššia dovolená rýchlosť , Zákaz predchádzania , Zákaz predchádzania pre nákladné vozidlá , Minimálna vzdialenosť medzi vozidlami a reguláciu vyplývajúcu z pruhových značiek.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/267.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "4bf03429719fdcf2b642"
        },
        {
            "nl_otazka_id": "1969",
            "uv_otazka": "Spúšťanie motora (Motor s elektricky riadeným vstrekovacím zariadením) vykonávame",
            "uv_odpoveda": "bez stlačenia pedála akcelerátora.",
            "uv_odpovedb": "bez stlačenia pedála akcelerátora, ale iba pri teplom štarte.",
            "uv_odpovedc": "bez použitia pedála akcelerátora iba vtedy, ak je studený motor.",
            "nl_okruh_id": "6",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "e7f22d9cab7d83127a21"
        },
        {
            "nl_otazka_id": "2336",
            "uv_otazka": "Prevodové ústrojenstvo vozidla tvoria",
            "uv_odpoveda": "spojka, prevodovka a rozvodovka s diferenciálom.",
            "uv_odpovedb": "volant, hriadeľ volantu a kolesá.",
            "uv_odpovedc": "motor, spojka, prevodovka a rozvodovka s diferenciálom.",
            "nl_okruh_id": "10",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "61cf64fc7cd4b64e7a1a"
        },
        {
            "nl_otazka_id": "2321",
            "uv_otazka": "Rozdiel v činnosti štvordobého zážihového motora a vznetového motora je, že",
            "uv_odpoveda": "zážihový motor nasáva vzduch a palivo (benzín) sa do valcov vstrekuje dýzou alebo nasáva zmes benzínových pár a vzduchu, kým vznetový motor vždy nasáva čistý vzduch, a palivo (nafta) sa do valcov vstrekuje dýzou.",
            "uv_odpovedb": "zážihový motor nasáva zmes olejových pár a vzduchu, kým vznetový motor nasáva čistý vzduch a palivo (nafta) sa do valcov vstrekuje karburátorom.",
            "uv_odpovedc": "zážihový motor nasáva zmes olejových pár a vzduchu, kým vznetový motor nasáva čistý vzduch a palivo (nafta) sa do valcov vstrekuje dýzou.",
            "nl_okruh_id": "10",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "da3583d08e928bf0d776"
        },
        {
            "nl_otazka_id": "1222",
            "uv_otazka": "Vodič nesmie zastaviť a stáť",
            "uv_odpoveda": "na účelovej komunikácii za zníženej viditeľnosti.",
            "uv_odpovedb": "na jednosmernej ceste vpravo.",
            "uv_odpovedc": "pred neprehľadným vrcholom stúpania cesty, na ňom a za ním.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "35fdc8c4daa7e29de010"
        },
        {
            "nl_otazka_id": "1057",
            "uv_otazka": "Vodič nesmie použiť",
            "uv_odpoveda": "zvukové výstražné znamenie na odvrátenie hroziaceho nebezpečenstva v obci.",
            "uv_odpovedb": "zimné pneumatiky v období od 15. marca do 15. októbra.",
            "uv_odpovedc": "na jazdu vozidlo, ktoré svojím farebným vyhotovením a označením sa dá zameniť s vozidlom Policajného zboru, Vojenskej polície, Zboru väzenskej a justičnej stráže, obecnej polície, Hasičského a záchranného zboru alebo ostatných hasičských jednotiek.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1b02a6f9b930efec797f"
        },
        {
            "nl_otazka_id": "1039",
            "uv_otazka": "Vodič je povinný",
            "uv_odpoveda": "zabezpečiť vozidlo a prepravované veci proti odcudzeniu prostriedkami montovanými do vozidla jeho výrobcom, ak sa vzdiali od vozidla mimo jeho dohľadu.",
            "uv_odpovedb": "prekážať v jazde rýchlejšie idúcim vozidlám za zníženej viditeľnosti.",
            "uv_odpovedc": "zabezpečiť vozidlo a prepravované veci proti odcudzeniu prostriedkami montovanými do vozidla jeho výrobcom, ak sa vzdiali od vozidla na dobu, ktorá presiahne 30 minút.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1e14c39bd10b79b927a9"
        },
        {
            "nl_otazka_id": "1274",
            "uv_otazka": "Kedy nesmie vodič vchádzať na železničné priecestie?",
            "uv_odpoveda": "Ak osoba pribratá na zaistenie bezpečnej prevádzky železničného priecestia dáva znamenie kývaním ruky s bielym svetlom cez stred tela.",
            "uv_odpovedb": "Ak osoba pribratá na zaistenie bezpečnej prevádzky železničného priecestia dáva znamenie na zastavenie vozidla za zníženej viditeľnosti krúžením červeným svetlom v hornom polkruhu.",
            "uv_odpovedc": "Ak svieti prerušované biele svetlo.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "d16736135e59f5096e4c"
        },
        {
            "nl_otazka_id": "1044",
            "uv_otazka": "Vodič nesmie viesť vozidlo po požití lieku v čase, keď",
            "uv_odpoveda": "nemá pri sebe lieky, ktoré pravidelne užíva a číslo telefónu na svojho lekára.",
            "uv_odpovedb": "liek môže znížiť jeho schopnosť viesť vozidlo; to neplatí, ak je jeho spolujazdcom lekár.",
            "uv_odpovedc": "liek môže znížiť jeho schopnosť viesť vozidlo.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "20003d87c18f71f9e8d3"
        },
        {
            "nl_otazka_id": "1202",
            "uv_otazka": "Pri státí musí zostať voľný aspoň jeden jazdný pruh široký najmenej",
            "uv_odpoveda": "3,5 m.",
            "uv_odpovedb": "3 m pre oba smery jazdy.",
            "uv_odpovedc": "3 m pre každý smer jazdy.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "4ad2538c8620fa4eea34"
        },
        {
            "nl_otazka_id": "1938",
            "uv_otazka": "Ak sa na prístrojovom paneli rozsvieti kontrolka parkovacej brzdy, znamená to že",
            "uv_odpoveda": "je nefunkčný systém ABS.",
            "uv_odpovedb": "parkovacia brzda je aktivovaná.",
            "uv_odpovedc": "vozidlo nie je vybavené parkovacou brzdou.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "obr3/tvv/24.png",
            "body": "2",
            "poznamka": "",
            "hash": "72f21338d0a18b5ea00f"
        },
        {
            "nl_otazka_id": "1471",
            "uv_otazka": "V obytnej zóne, pešej zóne a školskej zóne chodci smú používať",
            "uv_odpoveda": "len cestičku pre chodcov.",
            "uv_odpovedb": "len chodník.",
            "uv_odpovedc": "cestu v celej jej šírke.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "6c9826f6cd4cab4b7dfd"
        },
        {
            "nl_otazka_id": "1940",
            "uv_otazka": "Ak sa na prístrojovom paneli rozsvieti kontrolka systému bezpečnostných vzduchových vakov (airbagov),",
            "uv_odpoveda": "jeden alebo viacerí pasažieri nie sú pripútaní.",
            "uv_odpovedb": "systém bezpečnostných vzduchových vakov vykazuje poruchu.",
            "uv_odpovedc": "parkovacia brzda je aktivovaná.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "obr3/tvv/03.png",
            "body": "2",
            "poznamka": "",
            "hash": "b36c9f8498b924713512"
        },
        {
            "nl_otazka_id": "1052",
            "uv_otazka": "Vodič nesmie",
            "uv_odpoveda": "bezdôvodne pomalou jazdou prekážať v jazde len rýchlejšie idúcim vozidlám pravidelnej verejnej dopravy osôb.",
            "uv_odpovedb": "prekážať v jazde rýchlejšie idúcim vozidlám len za zníženej viditeľnosti.",
            "uv_odpovedc": "prekážať v jazde rýchlejšie idúcim vozidlám a obmedzovať plynulosť cestnej premávky.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "14118b16e49cf909d4d2"
        },
        {
            "nl_otazka_id": "1276",
            "uv_otazka": "Ak osoba pribratá na zaistenie bezpečnej prevádzky železničného priecestia dáva znamenie na zastavenie vozidla krúžením červenou alebo žltou zástavkou, vodič",
            "uv_odpoveda": "smie vchádzať na železničné priecestie.",
            "uv_odpovedb": "nesmie vchádzať na železničné priecestie.",
            "uv_odpovedc": "je povinný vchádzať na železničné priecestie zvýšenou rýchlosťou.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "546403583ef138a768fc"
        },
        {
            "nl_otazka_id": "1136",
            "uv_otazka": "Vodič v úseku zastávky vozidiel pravidelnej verejnej dopravy osôb je povinný",
            "uv_odpoveda": "dbať na zvýšenú opatrnosť a jazdiť primeranou rýchlosťou tak, aby neohrozil ani neobmedzil osobu vystupujúcu z vozidla pravidelnej verejnej dopravy osôb alebo do neho nastupujúcu.",
            "uv_odpovedb": "dbať na zvýšenú opatrnosť a jazdiť primeranou rýchlosťou tak, aby neohrozil osobu vystupujúcu z vozidla pravidelnej verejnej dopravy osôb alebo do neho nastupujúcu.",
            "uv_odpovedc": "zapnúť zariadenie umožňujúce výstražnú funkciu smerových svietidiel.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "a8e78057831844f2f243"
        },
        {
            "nl_otazka_id": "1562",
            "uv_otazka": "Vodič motorového vozidla s najväčšou prípustnou celkovou hmotnosťou neprevyšujúcou 3 500 kg smie jazdiť rýchlosťou najviac",
            "uv_odpoveda": "100 km.h-1; na diaľnici smie jazdiť rýchlosťou najviac 160 km.h-1.",
            "uv_odpovedb": "90 km.h-1; na diaľnici smie jazdiť rýchlosťou najviac 130 km.h-1.",
            "uv_odpovedc": "80 km.h-1; na diaľnici smie jazdiť rýchlosťou najviac 120 km.h-1.",
            "nl_okruh_id": "2",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1625c87325b2cf5cf395"
        },
        {
            "nl_otazka_id": "1904",
            "uv_otazka": "Dopravná nehoda je udalosť v cestnej premávke, ktorá sa stane v priamej súvislosti s premávkou vozidla a pri ktorej",
            "uv_odpoveda": "sa znefunkční cesta alebo všeobecne prospešné zariadenie.",
            "uv_odpovedb": "dôjde k stretu aspoň dvoch vozidiel.",
            "uv_odpovedc": "sa poškodí len vozidlo.",
            "nl_okruh_id": "5",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "8e9c2e462e614a976af0"
        },
        {
            "nl_otazka_id": "1224",
            "uv_otazka": "Vodič nesmie zastaviť a stáť na priechode pre chodcov a vo vzdialenosti kratšej ako",
            "uv_odpoveda": "5 m pred ním a 5 m za ním.",
            "uv_odpovedb": "5 m za ním.",
            "uv_odpovedc": "5 m pred ním.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "b64e8fb14352a0ca4969"
        },
        {
            "nl_otazka_id": "1935",
            "uv_otazka": "Prístup do motorového priestoru u väčšiny osobných motorových vozidiel je zaistený",
            "uv_odpoveda": "ak zasunieme kľúč do spínacej skrinky, kapota motorového priestoru sa automaticky otvorí.",
            "uv_odpovedb": "pomocou tiahla ovládania kapoty motorového priestoru.",
            "uv_odpovedc": "pomocou tiahla ovládania kapoty batožinového priestoru.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "8c7bf0e6a0df7e4b3df9"
        },
        {
            "nl_otazka_id": "1135",
            "uv_otazka": "Vodič, ktorý zastavil vozidlo za vozidlom pravidelnej verejnej dopravy osôb, ktoré zastavilo v obci na zastávke bez nástupného ostrovčeka alebo bez nástupišťa na zvýšenom električkovom koľajovom páse smie pokračovať v jazde, ak",
            "uv_odpoveda": "neohrozí ani neobmedzí osobu, ktorá nastupuje do vozidla pravidelnej verejnej dopravy osôb alebo z neho vystupuje.",
            "uv_odpovedb": "mu dáva vodič vozidla pravidelnej verejnej dopravy osôb znamenie.",
            "uv_odpovedc": "neohrozí osobu, ktorá nastupuje do vozidla pravidelnej verejnej dopravy osôb alebo z neho vystupuje.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ef087da67d08aeb48267"
        },
        {
            "nl_otazka_id": "1913",
            "uv_otazka": "Účastník dopravnej nehody je povinný poskytnúť podľa svojich schopností a možností zranenej osobe",
            "uv_odpoveda": "potrebnú prvú pomoc a bezodkladne privolať záchrannú zdravotnú službu.",
            "uv_odpovedb": "potrebnú prvú pomoc len vtedy, ak je vážne ohrozený život viacerých osôb.",
            "uv_odpovedc": "prvú pomoc a privolať záchrannú zdravotnú službu len vtedy, ak dopravnú nehodu zavinil.",
            "nl_okruh_id": "5",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "fda79df7b1326155b6ec"
        },
        {
            "nl_otazka_id": "1444",
            "uv_otazka": "Ak prichádza vozidlo s právom prednostnej jazdy, chodec",
            "uv_odpoveda": "nesmie vstupovať na vozovku.",
            "uv_odpovedb": "má prednosť pred týmito vozidlami ak prechádza cez priechod pre chodcov.",
            "uv_odpovedc": "smie vstupovať na vozovku len na priechode pre chodcov.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "992ab7ed2be626a50c13"
        },
        {
            "nl_otazka_id": "1288",
            "uv_otazka": "Je vodič povinný dávať znamenie o zmene smeru jazdy vľavo, ak pokračuje ďalej doľava po hlavnej ceste vyznačenej na dodatkovej tabuľke?",
            "uv_odpoveda": "Nie, nemení smer jazdy.",
            "uv_odpovedb": "Nie, lebo pokračuje ďalej po hlavnej ceste.",
            "uv_odpovedc": "Áno, vyžaduje to bezpečnosť cestnej premávky a zmena smeru jazdy.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "obr3/pcp/30_1.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "8045a8681d422748a3c6"
        },
        {
            "nl_otazka_id": "1156",
            "uv_otazka": "Vodič, ktorý predchádza po krajnici vozidlo vpravo, ktoré mení smer jazdy vľavo, ak už nie je pochybnosť o ďalšom smere jeho jazdy",
            "uv_odpoveda": "nesmie zastaviť.",
            "uv_odpovedb": "nesmie ohroziť  cyklistu alebo vodiča malého motocykla jazdiaceho po krajnici ani obmedziť chodca idúceho po krajnici.",
            "uv_odpovedc": "nesmie ohroziť ani obmedziť chodca idúceho po krajnici a cyklistu alebo vodiča malého motocykla jazdiaceho po krajnici.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f04053a0e8a30c961953"
        },
        {
            "nl_otazka_id": "1117",
            "uv_otazka": "Vodič vozidla pri vytváraní záchranárskej uličky na nevyhnutne potrebný čas",
            "uv_odpoveda": "je oprávnený vojsť aj na miesta, kde je to inak zakázané, ak tým neobmedzí iného účastníka cestnej premávky.",
            "uv_odpovedb": "nie je oprávnený vojsť aj na miesta, kde je to inak zakázané, ak tým neohrozí iného účastníka cestnej premávky.",
            "uv_odpovedc": "je oprávnený vojsť aj na miesta, kde je to inak zakázané, ak tým neohrozí iného účastníka cestnej premávky.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f74406ba16d87a40acbe"
        },
        {
            "nl_otazka_id": "1992",
            "uv_otazka": "Pred odbočovaním vľavo je vodič povinný zaradiť sa",
            "uv_odpoveda": "čo najďalej vpravo v časti vozovky určenej pre jeho smer jazdy s ohľadom na rozmery vozidla alebo nákladu a šírku vozovky.",
            "uv_odpovedb": "do stredu vozovky určenej pre jeho smer jazdy s ohľadom na rozmery vozidla alebo nákladu a šírku vozovky.",
            "uv_odpovedc": "čo najďalej vľavo v časti vozovky určenej pre jeho smer jazdy s ohľadom na rozmery vozidla alebo nákladu a šírku vozovky.",
            "nl_okruh_id": "6",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "7b9847a3752cd2cf6c6d"
        },
        {
            "nl_otazka_id": "1165",
            "uv_otazka": "Ak sa vodič nemôže vyhnúť protiidúcej električke vpravo",
            "uv_odpoveda": "vyhýba sa jej vľavo.",
            "uv_odpovedb": "môže na vyhýbanie použiť aj chodník.",
            "uv_odpovedc": "nesmie sa jej vyhýbať vľavo.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3f962886f61c8cefeba8"
        },
        {
            "nl_otazka_id": "1154",
            "uv_otazka": "Vodič nesmie predchádzať",
            "uv_odpoveda": "nemotorové vozidlo v križovatke.",
            "uv_odpovedb": "ak vodič pred ním idúceho vozidla dáva znamenie o zmene smeru jazdy vpravo za účelom zastavenia.",
            "uv_odpovedc": "cez pripájací alebo odbočovací jazdný pruh; to neplatí pri predchádzaní  vozidla vpravo, ktoré mení smer jazdy vľavo a ak už nie je pochybnosť o ďalšom smere jeho jazdy.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "471fccaecfb717acbc45"
        },
        {
            "nl_otazka_id": "1767",
            "uv_otazka": "Zvislá dopravná značka platí",
            "uv_odpoveda": "proti smeru premávky, oproti ktorému je orientovaná, ak z významu značky nevyplýva inak.",
            "uv_odpovedb": "pre oba smery jazdy.",
            "uv_odpovedc": "v smere premávky, oproti ktorému je orientovaná, ak z významu značky nevyplýva inak.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "5910bed8afcdaae56a22"
        },
        {
            "nl_otazka_id": "1521",
            "uv_otazka": "Táto zelená šípka",
            "uv_odpoveda": "umožňuje po zastavení pokračovať v jazde v smere šípky pri dodržaní pravidiel cestnej premávky aj vtedy, ak svieti signál červenej farby v tvare plného kruhu.",
            "uv_odpovedb": "prikazuje pokračovať v jazde v smere šípky.",
            "uv_odpovedc": "zakazuje pokračovať v jazde v smere šípky, ak svieti signál červenej farby v tvare plného kruhu.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/730.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "951f4216881a2f983be7"
        },
        {
            "nl_otazka_id": "1456",
            "uv_otazka": "Samovyvažovacie vozidlo smie na ceste s výnimkou cestičky pre cyklistov, poľnej cesty, lesnej cesty a obytnej zóny viesť len osoba staršia ako",
            "uv_odpoveda": "15 rokov.",
            "uv_odpovedb": "10 rokov.",
            "uv_odpovedc": "18 rokov.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "dc94fb0ee10fd3df87a6"
        },
        {
            "nl_otazka_id": "1228",
            "uv_otazka": "Vodič nesmie zastaviť a stáť na zastávke vozidla pravidelnej verejnej dopravy osôb a priľahlom nástupišti v úseku, ktorý sa začína dopravnou značkou pre zastávku a končí sa",
            "uv_odpoveda": "5 m za označníkom zastávky, a tam, kde taká dopravná značka nie je, vo vzdialenosti kratšej ako 30 m pred označníkom zastávky a 5 m za ním.",
            "uv_odpovedb": "10 m za označníkom zastávky.",
            "uv_odpovedc": "20 m za označníkom zastávky.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "df4ee176f26285d200a1"
        },
        {
            "nl_otazka_id": "2082",
            "uv_otazka": "Držiteľ vodičského preukazu, ktorému sa rozhodnutím obmedzilo alebo odobralo vodičské oprávnenie",
            "uv_odpoveda": "je povinný odovzdať orgánu Policajného zboru neplatný vodičský preukaz a medzinárodný vodičský preukaz do 15 dní odo dňa oznámenia rozhodnutia.",
            "uv_odpovedb": "nie je povinný odovzdať orgánu Policajného zboru neplatný vodičský preukaz a medzinárodný vodičský preukaz.",
            "uv_odpovedc": "je povinný odovzdať orgánu Policajného zboru neplatný vodičský preukaz a medzinárodný vodičský preukaz do 7 dní odo dňa oznámenia rozhodnutia.",
            "nl_okruh_id": "7",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "7b245c34f60975424732"
        },
        {
            "nl_otazka_id": "1030",
            "uv_otazka": "Účastník cestnej premávky",
            "uv_odpoveda": "je povinný dbať na regulačné dopravné značky a svetelné signály; na iné dopravné značky a dopravné zariadenia je povinný dbať len vodič motorového vozidla.",
            "uv_odpovedb": "je povinný poslúchnuť pokyn vyplývajúci z dopravnej značky alebo dopravného zariadenia.",
            "uv_odpovedc": "nie je povinný dbať na dopravné zariadenia.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "c86d55b3153dd068e9ad"
        },
        {
            "nl_otazka_id": "1111",
            "uv_otazka": "Ak v obci na ceste s dvoma alebo s viacerými jazdnými pruhmi vyznačenými na vozovke v jednom smere jazdy idú vozidlá pri súbežnej jazde v jednom z jazdných pruhov rýchlejšie ako vozidlá v inom jazdnom pruhu",
            "uv_odpoveda": "dopúšťa sa vodič rýchlejšie idúceho vozidla priestupku.",
            "uv_odpovedb": "považuje sa to za predchádzanie.",
            "uv_odpovedc": "nepovažuje sa to za predchádzanie.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "6e6beadff74ef6306a66"
        },
        {
            "nl_otazka_id": "1553",
            "uv_otazka": "Vodič pri zaraďovaní z pripájacieho pruhu do priebežného pruhu je povinný dať prednosť v jazde vodičovi idúcemu v",
            "uv_odpoveda": "priebežnom pruhu.",
            "uv_odpovedb": "pravom pruhu.",
            "uv_odpovedc": "odbočovacom pruhu.",
            "nl_okruh_id": "2",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "09057d87b4245cc63b13"
        },
        {
            "nl_otazka_id": "1987",
            "uv_otazka": "Regeneráciu DPF – filtra pevných častíc u vozidiel s naftovým motorom, zabezpečíme",
            "uv_odpoveda": "zahrievaním motora po naštartovaní.",
            "uv_odpovedb": "jazdou s ustálenou vyššou rýchlosťou pri vyšších otáčkach motora, ktorá bude trvať minimálne 10 min.",
            "uv_odpovedc": "pravidelným umývaním filtra na autoumyvárke.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "ce2e13df679a6bc6b9da"
        },
        {
            "nl_otazka_id": "1381",
            "uv_otazka": "Správca cesty môže odstrániť vozidlo stojace na ceste vrátane chodníka na náklady jeho prevádzkovateľa, ak je",
            "uv_odpoveda": "na vyhradenom parkovisku pre vozidlo s konkrétnym evidenčným číslom vozidla, pre osobu so zdravotným postihnutím alebo pre vozidlo s konkrétnym parkovacím preukazom, ak také parkovisko nie je preň určené.",
            "uv_odpovedb": "na parkovisku a na pokyn policajta.",
            "uv_odpovedc": "na vyhradenom parkovisku.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e0069742f0aa49554b99"
        },
        {
            "nl_otazka_id": "1373",
            "uv_otazka": "Vodič vozidla vybaveného zvláštnym výstražným svetlom oranžovej farby je povinný toto svetlo používať",
            "uv_odpoveda": "za každých okolností.",
            "uv_odpovedb": "ak by mohol svojou jazdou alebo pracovnou činnosťou ohroziť bezpečnosť alebo plynulosť cestnej premávky alebo na označenie prekážky cestnej premávky.",
            "uv_odpovedc": "len vtedy, ak jazdí vyššou rýchlosťou ako 90 km/h.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5dd88097a3cc8e3cc9bc"
        },
        {
            "nl_otazka_id": "1312",
            "uv_otazka": "Vodič nesmie použiť diaľkové svetlomety, ak",
            "uv_odpoveda": "s tým nevyslovili súhlas všetci jeho spolucestujúci.",
            "uv_odpovedb": "by mohol byť oslnený vodič protiidúceho vozidla, vodič vozidla idúceho pred ním alebo iný účastník cestnej premávky.",
            "uv_odpovedc": "by mohol byť oslnený vodič protiidúceho vozidla; na prípadné oslnenie vodiča idúceho pred ním nemusí brať ohľad.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ca05abb2581b53599336"
        },
        {
            "nl_otazka_id": "1302",
            "uv_otazka": "Na upozornenie vodiča predchádzaného vozidla v obci smie vodič dávať",
            "uv_odpoveda": "svetelné výstražné znamenie.",
            "uv_odpovedb": "zvukové výstražné znamenie.",
            "uv_odpovedc": "zvukové výstražné znamenie a svetelné výstražné znamenie.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "98f00a225f0ee368a375"
        },
        {
            "nl_otazka_id": "1297",
            "uv_otazka": "Znamenie o zmene smeru jazdy vodič nedáva",
            "uv_odpoveda": "pri vychádzaní z kruhového objazdu.",
            "uv_odpovedb": "pri prechádzaní z jedného jazdného pruhu do druhého jazdného pruhu.",
            "uv_odpovedc": "pri vjazde do kruhového objazdu.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/213.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "0d15bf3eb9d5385b3a52"
        },
        {
            "nl_otazka_id": "1284",
            "uv_otazka": "Znamenie o zmene smeru jazdy vodič je povinný dávať vždy aj vtedy, ak",
            "uv_odpoveda": "vchádza do križovatky.",
            "uv_odpovedb": "to vyžaduje bezpečnosť alebo plynulosť cestnej premávky.",
            "uv_odpovedc": "musí náhle zastaviť vozidlo.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "7576a2e584a0328adb81"
        },
        {
            "nl_otazka_id": "1159",
            "uv_otazka": "Vodič nesmie predchádzať, ak",
            "uv_odpoveda": "na to v obci neupozornil vodiča predchádzaného vozidla zvukovým výstražným znamením.",
            "uv_odpovedb": "by pri predchádzaní svojou výrazne nižšou rýchlosťou obmedzil za ním idúce vozidlo, ktoré začalo úkon predchádzania skôr.",
            "uv_odpovedc": "pred ním jazdí zvláštne motorové vozidlo.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "331bab9dd3d71ef5cd01"
        },
        {
            "nl_otazka_id": "1260",
            "uv_otazka": "Ak pri jazde vozidla v tuneli vznikne porucha vozidla, pre ktorú sa toto vozidlo stane nepojazdným, alebo ak vznikne dopravná nehoda vrátane požiaru, po zastavení vozidla vodič alebo prepravovaná osoba sú povinní opustiť vozidlo a sústrediť sa do miest na to určených, prípadne tunel opustiť",
            "uv_odpoveda": "na pokyn iného účastníka cestnej premávky.",
            "uv_odpovedb": "na pokyn osoby vykonávajúcej dohľad nad prevádzkou tunela.",
            "uv_odpovedc": "na pokyn spolujazdca.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "dd5e435a8e71eceaf47c"
        },
        {
            "nl_otazka_id": "1379",
            "uv_otazka": "Prekážka cestnej premávky sa označuje",
            "uv_odpoveda": "len vtedy, ak ju tvorí motorové vozidlo.",
            "uv_odpovedb": "najmä zábranou na označenie uzávierky, výstražným svetlom oranžovej farby, za zníženej viditeľnosti aj červeným svetlom alebo reflexnou červenou zastávkou.",
            "uv_odpovedc": "len za zníženej viditeľnosti.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "acc9cb4f30917be48f60"
        },
        {
            "nl_otazka_id": "1163",
            "uv_otazka": "Vodič, ktorý nechce alebo nemôže prejsť pozdĺž električky vpravo, je povinný",
            "uv_odpoveda": "dať o tom znamenie upažením.",
            "uv_odpovedb": "jazdiť za ňou v takej vzdialenosti, aby umožnil prejdenie pozdĺž električky ostatným vodičom.",
            "uv_odpovedc": "zastaviť na najbližšom odbočovacom pruhu.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "501e005d98b3b3ac300a"
        },
        {
            "nl_otazka_id": "1903",
            "uv_otazka": "Dopravná nehoda je udalosť v cestnej premávke, ktorá sa stane v priamej súvislosti s premávkou vozidla a pri ktorej",
            "uv_odpoveda": "dôjde k stretu aspoň troch vozidiel.",
            "uv_odpovedb": "sa len usmrtí osoba.",
            "uv_odpovedc": "sa usmrtí alebo zraní osoba.",
            "nl_okruh_id": "5",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "f37a8d612cb1b1e080b6"
        },
        {
            "nl_otazka_id": "1146",
            "uv_otazka": "Vodič nesmie pri predchádzaní",
            "uv_odpoveda": "zvyšovať rýchlosť jazdy.",
            "uv_odpovedb": "ohroziť ani obmedziť vodiča vozidla, pred ktoré sa po predídení zaraďuje.",
            "uv_odpovedc": "obmedziť vodiča vozidla idúceho za ním.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "c3c4fef40891b2bf9be9"
        },
        {
            "nl_otazka_id": "1936",
            "uv_otazka": "Čo patrí medzi ovládacie prvky vozidla?",
            "uv_odpoveda": "Otáčkomer.",
            "uv_odpovedb": "Rýchlomer.",
            "uv_odpovedc": "Pedále.",
            "nl_okruh_id": "6",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "1a8e088ef919ac5e7032"
        },
        {
            "nl_otazka_id": "1352",
            "uv_otazka": "Vodičovi motorového vozidla je na diaľnici a na ceste pre motorové vozidlá zastavenie a státie",
            "uv_odpoveda": "dovolené tam, kde je stredný deliaci pás prerušený.",
            "uv_odpovedb": "zakázané na inom mieste než na mieste označenom ako parkovisko s výnimkou núdzového státia.",
            "uv_odpovedc": "zakázané; neplatí to, na pripájacom a na odbočovacom jazdnom pruhu.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "818d785b275443d76543"
        },
        {
            "nl_otazka_id": "1964",
            "uv_otazka": "Vozidlo s automatickou prevodovkou ovláda vodič nasledovne",
            "uv_odpoveda": "brzdový pedál a plynový pedál ovláda ľavou nohou, pravá noha neobsluhuje žiadny pedál.",
            "uv_odpovedb": "brzdový pedál ovláda pravou nohou a plynový pedál ovláda ľavou nohou.",
            "uv_odpovedc": "pedál brzdy a plynový pedál ovláda pravou nohou, ľavá noha neobsluhuje žiadny pedál.",
            "nl_okruh_id": "6",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "4418d54b84c79a8ba241"
        },
        {
            "nl_otazka_id": "1025",
            "uv_otazka": "Zastavením sa rozumie",
            "uv_odpoveda": "prerušenie jazdy motorového vozidla z dôvodu nezávislého od vôle vodiča.",
            "uv_odpovedb": "len také uvedenie vozidla do pokoja, ktoré nepresiahne čas tri minúty.",
            "uv_odpovedc": "uvedenie vozidla do pokoja na čas nevyhnutne potrebný na urýchlené nastúpenie alebo na vystúpenie prepravovaných osôb alebo na urýchlené naloženie alebo na zloženie nákladu.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "6552a8ebb69f53816796"
        },
        {
            "nl_otazka_id": "1988",
            "uv_otazka": "Pri jazde so strešným nosičom treba počítať s tým, že",
            "uv_odpoveda": "spotreba paliva sa zvyšuje aj napriek tomu, že sa nemenia jazdné vlastnosti vozidla.",
            "uv_odpovedb": "sa zvyšuje spotreba paliva a náklad nepriaznivo ovplyvňuje stabilitu vozidla.",
            "uv_odpovedc": "náklad nepriaznivo ovplyvňuje stabilitu vozidla, ale znižuje sa spotreba paliva.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "09a5007cc24ebaf236c6"
        },
        {
            "nl_otazka_id": "1505",
            "uv_otazka": "Z idúceho vozidla označeného príslušnosťou k Policajnému zboru a vozidla neoznačeného príslušnosťou k Policajnému zboru dáva policajt pokyn na zastavenie vozidla",
            "uv_odpoveda": "kývaním ruky zvisle hore a dolu.",
            "uv_odpovedb": "zdvihnutím ruky s otvorenou dlaňou smerujúcou k zastavovanému vozidlu.",
            "uv_odpovedc": "krúžením ruky v hornom polkruhu.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ec8d50ffd70c3a2fab19"
        },
        {
            "nl_otazka_id": "1442",
            "uv_otazka": "Pri prechádzaní cez cestu chodec",
            "uv_odpoveda": "je povinný prednostne použiť priechod pre chodcov, miesto na prechádzanie, nadchod alebo podchod.",
            "uv_odpovedb": "je povinný použiť priechod pre chodcov, nadchod alebo podchod len za nezníženej viditeľnosti.",
            "uv_odpovedc": "nie je povinný prednostne použiť priechod pre chodcov, nadchod alebo podchod.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "0c7a944dc1dcd5a69302"
        },
        {
            "nl_otazka_id": "2199",
            "uv_otazka": "Z hľadiska zásad bezpečnej jazdy, ako by mal reagovať vodič pri oslnení?",
            "uv_odpoveda": "Nepozerať sa priamo do svetlometov protiidúceho vozidla.",
            "uv_odpovedb": "Jazdiť viac pri stredovej deliacej čiare.",
            "uv_odpovedc": "Nepozerať sa priamo do svetlometov vozidla idúceho pred vami.",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "7f4f72c73ddbcdc7e818"
        },
        {
            "nl_otazka_id": "1060",
            "uv_otazka": "Ak vodič zníži rýchlosť jazdy alebo ak zastaví vozidlo pred priechodom pre chodcov, aby umožnil prejsť cez cestu chodcom, vodiči ostatných vozidiel idúcich rovnakým smerom sú",
            "uv_odpoveda": "takisto povinní znížiť rýchlosť jazdy alebo zastaviť vozidlo, len ak sú chodci mladší ako 15 rokov.",
            "uv_odpovedb": "takisto povinní znížiť rýchlosť jazdy alebo zastaviť vozidlo; to neplatí pre vodiča električky.",
            "uv_odpovedc": "takisto povinní znížiť rýchlosť jazdy alebo zastaviť vozidlo; to neplatí pre vodiča motocykla.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5cac79f2e14cb4bd2af4"
        },
        {
            "nl_otazka_id": "1061",
            "uv_otazka": "Ak vodič počas jazdy zistí, že vozidlo, náklad alebo upevnenie nákladu nespĺňajú ustanovené podmienky",
            "uv_odpoveda": "je povinný dávať svetelné výstražné znamenie zapnutím výstražnej funkcie smerových svietidiel.",
            "uv_odpovedb": "smie pokračovať v jazde k najbližšej čerpacej stanici.",
            "uv_odpovedc": "je povinný chybu odstrániť na mieste; ak to nemôže urobiť, smie v jazde pokračovať primeranou rýchlosťou len do najbližšieho miesta, kde možno chybu odstrániť.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3b685d70a5cd534d717a"
        },
        {
            "nl_otazka_id": "1027",
            "uv_otazka": "Zníženou viditeľnosťou sa rozumie",
            "uv_odpoveda": "taká viditeľnosť, pri ktorej vodič motorového vozidla z dôvodu znečistenia alebo poškodenia skiel dostatočne zreteľne nevidí ostatných účastníkov cestnej premávky.",
            "uv_odpovedb": "taká viditeľnosť, pri ktorej sa účastníci cestnej premávky dostatočne zreteľne navzájom nevidia, ani keď nevidia predmety na ceste, najmä od súmraku do svitania, za hmly, sneženia, dažďa a v tuneli.",
            "uv_odpovedc": "viditeľnosť v čase od 18.00 do 7.00 hodiny a od 1. júla do 31. augusta len od 20.00 do 6.00 hodiny.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "349c112cf8ecb82bddf9"
        },
        {
            "nl_otazka_id": "1152",
            "uv_otazka": "Vodič nesmie predchádzať, ak by",
            "uv_odpoveda": "ohrozil alebo obmedzil vodiča jazdiaceho v protismere, ohrozil iného účastníka cestnej premávky.",
            "uv_odpovedb": "obmedzil vozidlo idúce za ním alebo iného účastníka cestnej premávky.",
            "uv_odpovedc": "pritom musel zvyšovať rýchlosť jazdy.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3455a4be700cc3517004"
        },
        {
            "nl_otazka_id": "1377",
            "uv_otazka": "Ten, kto odstráni prekážku cestnej premávky,",
            "uv_odpoveda": "je povinný bezodkladne zabezpečiť aj odstránenie predmetov a odpadov prekážajúcich v zjazdnosti na ceste, ktoré s prekážkou cestnej premávky súvisia.",
            "uv_odpovedb": "nie je povinný zabezpečiť odstránenie predmetov a odpadov prekážajúcich v zjazdnosti na ceste.",
            "uv_odpovedc": "musí zabezpečiť odstránenie predmetov a odpadov prekážajúcich v zjazdnosti na ceste, aj keď s prekážkou cestnej premávky nesúvisia.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3017138e3ac5c3bc0d5e"
        },
        {
            "nl_otazka_id": "1980",
            "uv_otazka": "Pred zaradením spätného chodu",
            "uv_odpoveda": "vozidlo nezastavíme, stačí ak znížime rýchlosť jazdy.",
            "uv_odpovedb": "vozidlo najskôr zastavíme.",
            "uv_odpovedc": "zapneme výstražné svetlá.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "e900b3483e52c756b053"
        },
        {
            "nl_otazka_id": "1245",
            "uv_otazka": "Vodič na platenom parkovisku",
            "uv_odpoveda": "nesmie zastaviť a stáť, ak vozidlo nemá zaplatený poplatok za parkovanie.",
            "uv_odpovedb": "smie zastaviť a stáť aj bez zaplatenia poplatku, ak čas zastavenia a státia nepresiahne 5 min.",
            "uv_odpovedc": "smie stáť bez obmedzenia.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ca999650a8b5d4aaf5ee"
        },
        {
            "nl_otazka_id": "1024",
            "uv_otazka": "Zastavením sa rozumie",
            "uv_odpoveda": "uvedenie vozidla do pokoja na čas presahujúci 5 minút.",
            "uv_odpovedb": "uvedenie vozidla do pokoja na čas nevyhnutne potrebný na urýchlené nastúpenie alebo na vystúpenie prepravovaných osôb alebo na urýchlené naloženie alebo na zloženie nákladu.",
            "uv_odpovedc": "prerušenie jazdy pre poruchu na vozidle, pre ktorú sa toto vozidlo stalo nepojazdným.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1b95f648f871a5af5bb9"
        },
        {
            "nl_otazka_id": "1366",
            "uv_otazka": "Vozidlom s právom prednostnej jazdy je vozidlo, ktoré",
            "uv_odpoveda": "je vybavené zvláštnym výstražným svetlom zelenej farby.",
            "uv_odpovedb": "prepravuje nebezpečný náklad.",
            "uv_odpovedc": "používa typické zvukové znamenie doplnené zvláštnym výstražným modrým svetlom alebo červeným svetlom, prípadne ich kombináciou pri plnení špeciálnych úloh.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "6c7e0863e0319fb454cc"
        },
        {
            "nl_otazka_id": "1212",
            "uv_otazka": "Vodič vozidla, ktoré zastavilo alebo stálo a opäť vychádza od okraja cesty alebo od chodníka, nesmie",
            "uv_odpoveda": "obmedziť vodičov motorových vozidiel.",
            "uv_odpovedb": "dávať znamenie o zmene smeru jazdy.",
            "uv_odpovedc": "ohroziť ostatných účastníkov cestnej premávky.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "45ba770602e47e54fa76"
        },
        {
            "nl_otazka_id": "1179",
            "uv_otazka": "Pred otáčaním vodič je povinný zaradiť sa",
            "uv_odpoveda": "čo najďalej vpravo v časti vozovky určenej pre jeho smer jazdy.",
            "uv_odpovedb": "na pravú krajnicu.",
            "uv_odpovedc": "čo najďalej vľavo v časti vozovky určenej pre jeho smer jazdy s ohľadom na rozmery vozidla alebo nákladu a šírku vozovky.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "4ceb64c9a9d749c60d03"
        },
        {
            "nl_otazka_id": "2000",
            "uv_otazka": "Pred  vlečením vozidla sa vodiči vlečného a vlečeného vozidla dohodnú",
            "uv_odpoveda": "či budú používať GPS navigáciu.",
            "uv_odpovedb": "len na trase jazdy.",
            "uv_odpovedc": "na spôsobe dorozumevania sa počas jazdy.",
            "nl_okruh_id": "6",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "9c1171d08d05f73e092b"
        },
        {
            "nl_otazka_id": "1311",
            "uv_otazka": "Ak je vozovka dostatočne a súvislé osvetlená, vodič",
            "uv_odpoveda": "smie použiť diaľkové svetlomety, ak vo vzdialenosti najmenej 50 m pred ním nejde žiadne vozidlo.",
            "uv_odpovedb": "smie použiť diaľkové svetlomety.",
            "uv_odpovedc": "nesmie použiť diaľkové svetlomety.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "7e9db117f6b3d3804859"
        },
        {
            "nl_otazka_id": "1429",
            "uv_otazka": "Ak náklad na vozidle prečnieva do strany, musí byť prečnievajúci koniec nákladu označený",
            "uv_odpoveda": "vždy žltými a čiernymi pruhmi.",
            "uv_odpovedb": "červenou zástavkou s rozmermi najmenej 30 x 30 cm.",
            "uv_odpovedc": "reflexným označením s červenými a bielymi pruhmi širokými 70 mm až 100 mm a smerujúcimi od pozdĺžnej strednej roviny vozidla pod uhlom 45° nadol.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "25d6d602fe9afdea3514"
        },
        {
            "nl_otazka_id": "1289",
            "uv_otazka": "Vodič je povinný dávať znamenie o zmene smeru jazdy",
            "uv_odpoveda": "až po začatí takéhoto jazdného úkonu.",
            "uv_odpovedb": "včas pred začatím takéhoto jazdného úkonu.",
            "uv_odpovedc": "až po ukončení takéhoto jazdného úkonu.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "1a499b7a3be7f1352e61"
        },
        {
            "nl_otazka_id": "1965",
            "uv_otazka": "Základná a správna poloha rúk na volante je",
            "uv_odpoveda": "volant držíme buď ľavou alebo pravou rukou.",
            "uv_odpovedb": "volant držíme oboma rukami v polohe tri štvrte na tri.",
            "uv_odpovedc": "ľubovoľná poloha rúk.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "38df1e1b5729c3533cf2"
        },
        {
            "nl_otazka_id": "1272",
            "uv_otazka": "Ak sa spúšťajú, ak sú spustené alebo ak sa zdvíhajú závory, vodič",
            "uv_odpoveda": "smie vchádzať na železničné priecestie, ak to rozmery jeho vozidla dovoľujú.",
            "uv_odpovedb": "smie vchádzať na železničné priecestie, ak nie je vidieť vlak.",
            "uv_odpovedc": "nesmie vchádzať na železničné priecestie.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "0c32f805e314d5f2b537"
        },
        {
            "nl_otazka_id": "1197",
            "uv_otazka": "Vodič sa smie otáčať na ceste označenou touto dopravnou značkou?",
            "uv_odpoveda": "Nie, nesmie sa otáčať.",
            "uv_odpovedb": "Áno, smie sa otáčať, ak tým neohrozí ostatných účastníkov cestnej premávky.",
            "uv_odpovedc": "Áno, smie sa otáčať.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/321-1.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "aecf877b603de4b3f4c8"
        },
        {
            "nl_otazka_id": "1453",
            "uv_otazka": "Cestičku pre cyklistov môže použiť aj osoba pohybujúca sa na",
            "uv_odpoveda": "lyžiach, korčuliach, kolobežke, skejtborde alebo na obdobnom športovom vybavení, ak tým neobmedzí ani neohrozí cyklistov.",
            "uv_odpovedb": "motocykli.",
            "uv_odpovedc": "zvierati.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "be923d7b73fca59ffd3b"
        },
        {
            "nl_otazka_id": "1426",
            "uv_otazka": "Pri preprave živých zvierat",
            "uv_odpoveda": "nesmie byť ohrozená bezpečnosť vodiča a prepravovaných osôb, bezpečnosť prepravovaných zvierat ani bezpečnosť cestnej premávky.",
            "uv_odpovedb": "musia byť na vozidle vždy rozsvietené predné svetlomety do hmly.",
            "uv_odpovedc": "sa nesmie na jazdu použiť diaľnica a cesta pre motorové vozidlá.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ca608cd3da0698820b52"
        },
        {
            "nl_otazka_id": "1268",
            "uv_otazka": "Kedy nesmie vodič vchádzať na železničné priecestie?",
            "uv_odpoveda": "Ak osoba pribratá na zaistenie bezpečnej prevádzky železničného priecestia dáva pokyn vodorovným kývaním ruky s červenou zástavkou cez stred tela.",
            "uv_odpovedb": "Ak svieti prerušované biele svetlo.",
            "uv_odpovedc": "Ak sa dáva výstraha dvoma červenými striedavo prerušovanými svetlami priecestného zabezpečovacieho zariadenia.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "6b97228dfaeb496c4127"
        },
        {
            "nl_otazka_id": "1470",
            "uv_otazka": "Čo tvorí označenie osoby, ktorá vykonáva prácu na ceste?",
            "uv_odpoveda": "Viditeľný bezpečnostný odev z fluorescenčného materiálu, napríklad bezpečnostná reflexná vesta, overal, nohavice, bunda alebo pláštenka oranžovej farby.",
            "uv_odpovedb": "Bezpečnostná reflexná vesta žltej farby.",
            "uv_odpovedc": "Bezpečnostná reflexná vesta, overal, nohavice, bunda alebo pláštenka červenej farby.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "18e395c68c0884a32de0"
        },
        {
            "nl_otazka_id": "1494",
            "uv_otazka": "Úprava cestnej premávky vykonaná dopravnými značkami a dopravnými zariadeniami",
            "uv_odpoveda": "nie je nadradená všeobecnej úprave cestnej premávky.",
            "uv_odpovedb": "je nadradená všeobecnej úprave cestnej premávky.",
            "uv_odpovedc": "je nadradená všeobecnej úprave cestnej premávky len v obytnej zóne, pešej zóne a školskej zóne.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "efa3f4a3f3333ef9a6c8"
        },
        {
            "nl_otazka_id": "1994",
            "uv_otazka": "Aby sme správne zaparkovali je  ",
            "uv_odpoveda": "dôležitá pozícia vozidla len pri parkovaní jazdou dopredu.",
            "uv_odpovedb": "veľmi dôležitá pozícia vozidla, z ktorej vodič začína úkon parkovania.",
            "uv_odpovedc": "dôležité cúvať rýchlo.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "6e62b722e625e6317b93"
        },
        {
            "nl_otazka_id": "1250",
            "uv_otazka": "Vodič nesmie zastaviť a stáť",
            "uv_odpoveda": "kolmo na okraj cesty v obci.",
            "uv_odpovedb": "v obci na ceste 1. triedy.",
            "uv_odpovedc": "na miestach, kde zastavenie alebo státie môže ohroziť bezpečnosť a plynulosť cestnej premávky alebo obmedziť jazdu vozidiel.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "8fe18b1ce6569b82ba98"
        },
        {
            "nl_otazka_id": "1299",
            "uv_otazka": "Na upozornenie vodiča predchádzaného vozidla mimo obce vodič smie dávať",
            "uv_odpoveda": "svetelné výstražné znamenie zapnutím výstražnej funkcie smerových svietidiel.",
            "uv_odpovedb": "zvukové výstražné znamenie.",
            "uv_odpovedc": "znamenie kývaním ruky.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "2bf107c6fe10316af355"
        },
        {
            "nl_otazka_id": "1421",
            "uv_otazka": "Náklad musí byť na vozidle riadne umiestnený, rozložený a upevnený, aby",
            "uv_odpoveda": "neobmedzoval plynulosť cestnej premávky; ak by pritom poškodzoval cestu alebo spôsoboval nadmerný hluk, môže vozidlo ísť rýchlosťou najviac 40 km/h.",
            "uv_odpovedb": "neohrozoval bezpečnosť a plynulosť cestnej premávky, neznečisťoval ani nepoškodzoval cestu ani jej okolie, nespôsoboval nadmerný hluk a neznečisťoval ovzdušie.",
            "uv_odpovedc": "neohrozoval bezpečnosť cestnej premávky a nepoškodzoval cestu; spôsobovať nadmerný hluk nesmie len v nočnej dobe.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "aa5b5dcb6a59af53ba46"
        },
        {
            "nl_otazka_id": "1580",
            "uv_otazka": "Električka, ktorá križuje smer jazdy vozidla idúceho po jej pravej alebo po jej ľavej strane a dáva znamenie o zmene smeru jazdy,",
            "uv_odpoveda": "má prednosť v jazde.",
            "uv_odpovedb": "dáva prednosť v jazde vozidlu idúcemu po jej ľavej strane.",
            "uv_odpovedc": "nemá prednosť v jazde.",
            "nl_okruh_id": "2",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "fad7182e03432dc4cc60"
        },
        {
            "nl_otazka_id": "1307",
            "uv_otazka": "Vozidlo musí mať počas jazdy rozsvietené",
            "uv_odpoveda": "diaľkové svetlomety.",
            "uv_odpovedb": "stretávacie svetlomety alebo im na roveň postavené osvetlenie, to neplatí pre nemotorové vozidlo, ktoré musí mať rozsvietené svietidlá počas zníženej viditeľnosti.",
            "uv_odpovedc": "svetlá vo vnútri vozidla.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ec9556b42b87431ec8e8"
        },
        {
            "nl_otazka_id": "1158",
            "uv_otazka": "Vodič nesmie predchádzať",
            "uv_odpoveda": "na moste.",
            "uv_odpovedb": "pri jazde cez križovatku motocykel bez postranného vozíka alebo nemotorové vozidlo.",
            "uv_odpovedc": "pri jazde cez železničné priecestie.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "bf57210afab6922e5410"
        },
        {
            "nl_otazka_id": "1055",
            "uv_otazka": "Vodič nesmie",
            "uv_odpoveda": "znížiť náhle rýchlosť jazdy za žiadnych okolností.",
            "uv_odpovedb": "zastaviť vľavo na jednosmernej ceste.",
            "uv_odpovedc": "používať hanlivé gestá voči ostatným účastníkom cestnej premávky.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "604976c8951b3f196150"
        },
        {
            "nl_otazka_id": "1233",
            "uv_otazka": "Vodič nesmie zastaviť a stáť na mieste, kde by vozidlo zakrývalo",
            "uv_odpoveda": "zvislú dopravnú značku.",
            "uv_odpovedb": "reklamné zariadenie.",
            "uv_odpovedc": "zvislú dopravnú značku a akúkoľvek vodorovnú dopravnú značku.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "287d167bb6865647f4c4"
        },
        {
            "nl_otazka_id": "1520",
            "uv_otazka": "Ak svieti tento signál so symbolom šípok pri riadení cestnej premávky, znamená pre vodiča",
            "uv_odpoveda": "“Čakaj“; vodič musí zastaviť vozidlo pred križovatkou, pričom ak je pri rozsvietení tohto signálu už tak blízko, že nemôže zastaviť vozidlo bezpečne, smie pokračovať v jazde v smere vyznačenom šípkami.",
            "uv_odpovedb": "“Voľno“; vodič smie pokračovať v jazde a platí len pre smer vyznačený šípkami.",
            "uv_odpovedc": "“Voľno“; vodič smie pokračovať v jazde a platí pre všetky smery jazdy.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "obr3/pcp/15_3_3.png",
            "body": "3",
            "poznamka": "",
            "hash": "fc3bf8733fb0b6ba5b3a"
        },
        {
            "nl_otazka_id": "1051",
            "uv_otazka": "Vodič nesmie",
            "uv_odpoveda": "prekážať v jazde len rýchlejšie idúcim vozidlám pravidelnej verejnej dopravy osôb bezdôvodne pomalou jazdou.",
            "uv_odpovedb": "zastaviť a stáť na účelovej komunikácii v obci.",
            "uv_odpovedc": "poškodzovať životné prostredie, najmä jazdou po verejnej zeleni alebo cestnej zeleni.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "76a3c4fdc8671a8fe4ff"
        },
        {
            "nl_otazka_id": "1203",
            "uv_otazka": "Pri zastavení musí zostať voľný aspoň jeden jazdný pruh široký najmenej",
            "uv_odpoveda": "3 m pre každý smer jazdy.",
            "uv_odpovedb": "2 m.",
            "uv_odpovedc": "3 m pre oba smery jazdy.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "36edfeec7621925f25db"
        },
        {
            "nl_otazka_id": "1943",
            "uv_otazka": "Ak sa na prístrojovom paneli rozsvieti kontrolka elektrického posilňovača riadenia, signalizuje že",
            "uv_odpoveda": "posilňovač riadenia je aktivovaný.",
            "uv_odpovedb": "riadenie vozdila je nefunkčné a vozidlo nezatáča.",
            "uv_odpovedc": "je problém s posilňovačom riadenia a je potrebné navštíviť servis.",
            "nl_okruh_id": "6",
            "nl_correct": "3",
            "uv_obrazok": "obr3/tvv/42.png",
            "body": "2",
            "poznamka": "",
            "hash": "87213840802a81889248"
        },
        {
            "nl_otazka_id": "1005",
            "uv_otazka": "Chodcom sa rozumie účastník cestnej premávky pohybujúci sa pešo; chodcom je aj osoba, ktorá",
            "uv_odpoveda": "sa pohybuje na bicykli rýchlosťou chôdze.",
            "uv_odpovedb": "sa pohybuje na lyžiach, korčuliach, kolobežke bez pomocného motorčeka, skejtborde alebo obdobnom športovom vybavení.",
            "uv_odpovedc": "jazdí na bicykli, motocykli alebo na kolobežke s pomocným motorčekom.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f655b8a58b56958b8347"
        },
        {
            "nl_otazka_id": "1504",
            "uv_otazka": "Policajt dáva pokyn na zastavenie vozidla",
            "uv_odpoveda": "svietidlom vyžarujúcim svetlo zelenej farby, ktorým pohybuje v hornom polkruhu.",
            "uv_odpovedb": "svietidlom vyžarujúcim svetlo červenej farby, ktorým pohybuje v hornom polkruhu.",
            "uv_odpovedc": "svietidlom vyžarujúcim svetlo modrej farby, ktorým pohybuje v hornom polkruhu.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "131bd40669323b16695a"
        },
        {
            "nl_otazka_id": "1343",
            "uv_otazka": "Vodič pred vjazdom do priebežných jazdných pruhov na ceste pre motorové vozidlá je povinný použiť",
            "uv_odpoveda": "odbočovací jazdný pruh.",
            "uv_odpovedb": "pripájací jazdný pruh.",
            "uv_odpovedc": "stredový deliaci pás na mieste, kde je tento pás prerušený.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "936cf8b52e9a98a753eb"
        },
        {
            "nl_otazka_id": "1990",
            "uv_otazka": "Pri vlečení vozidla je potrebné dodržať túto zásadu.",
            "uv_odpoveda": "Rozsvietiť výstražné svetlá.",
            "uv_odpovedb": "Použiť lano alebo tyč schváleného typu.",
            "uv_odpovedc": "Jazdiť rýchlosťou najviac 50 km/hod.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "f41cd0f0b7a7ab6a2e60"
        },
        {
            "nl_otazka_id": "1374",
            "uv_otazka": "Ak je vozidlo vybavené zvláštnym výstražným svetlom oranžovej farby a používa zvláštne výstražné svetlo oranžovej farby, vodič iného vozidla je povinný",
            "uv_odpoveda": "vždy zastaviť vozidlo.",
            "uv_odpovedb": "vždy znížiť rýchlosť jazdy vzhľadom na plynulosť cestnej premávky.",
            "uv_odpovedc": "podľa okolností znížiť rýchlosť jazdy alebo aj zastaviť vozidlo.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "f9a94374e96cee57356d"
        },
        {
            "nl_otazka_id": "1234",
            "uv_otazka": "Vodič nesmie zastaviť a stáť",
            "uv_odpoveda": "v obci kolmo, prípadne šikmo na okraj vozovky.",
            "uv_odpovedb": "na vnútornom jazdnom pruhu.",
            "uv_odpovedc": "vľavo na jednosmernej ceste.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "7e9c45d38dba538aa37b"
        },
        {
            "nl_otazka_id": "1955",
            "uv_otazka": "Medzi kontrolné prístroje patria napríklad",
            "uv_odpoveda": "otáčkomer, rýchlomer, teplomer chladiacej kvapaliny, ukazovateľ stavu paliva.",
            "uv_odpovedb": "volant, spojka, brzda, plyn, radiaca páka.",
            "uv_odpovedc": "hasiaci prístroj a kľúč na matice.",
            "nl_okruh_id": "6",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "9619114585fccf7fe425"
        },
        {
            "nl_otazka_id": "1026",
            "uv_otazka": "Zastavením vozidla sa rozumie",
            "uv_odpoveda": "prerušenie jazdy z dôvodu nezávislého od vôle vodiča.",
            "uv_odpovedb": "uvedenie motorového vozidla do pokoja na čas nevyhnutne potrebný na urýchlené nastúpenie alebo vystúpenie prepravovaných osôb, najviac však na tri minúty.",
            "uv_odpovedc": "uvedenie vozidla do pokoja na čas nevyhnutne potrebný na urýchlené nastúpenie alebo na vystúpenie prepravovaných osôb alebo na urýchlené naloženie alebo na zloženie nákladu.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "a499386145e72dc88f41"
        },
        {
            "nl_otazka_id": "1033",
            "uv_otazka": "Vodič je povinný",
            "uv_odpoveda": "venovať sa plne vedeniu vozidla; sledovať situáciu v cestnej premávke je povinný len v niektorých osobitných prípadoch, najmä počas jazdy vyššou rýchlosťou alebo prepravy nebezpečných vecí.",
            "uv_odpovedb": "sledovať situáciu v cestnej premávke len počas jazdy na diaľnici, na ceste pre motorové vozidlá a na ceste l. triedy.",
            "uv_odpovedc": "venovať sa plne vedeniu vozidla a sledovať situáciu v cestnej premávke.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "683f376bf1873f6427a3"
        },
        {
            "nl_otazka_id": "1229",
            "uv_otazka": "Vodič nesmie zastaviť a stáť na zastávke vozidla pravidelnej verejnej dopravy osôb a priľahlom nástupišti v úseku, ktorý sa začína",
            "uv_odpoveda": "dopravnou značkou pre zastávku a končí sa 5 m za označníkom zastávky.",
            "uv_odpovedb": "vo vzdialenosti 15 m pred označníkom zastávky a končí sa označníkom zastávky.",
            "uv_odpovedc": "dopravnou značkou pre zastávku a končí sa pri označníku zastávky.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "11ea4fe27abce49e0511"
        },
        {
            "nl_otazka_id": "1178",
            "uv_otazka": "Vozidlo vchádzajúce na cestu, ktoré je znečistené",
            "uv_odpoveda": "nemusí byť vopred očistené, aby neznečisťovalo cestu.",
            "uv_odpovedb": "musí byť očistené až po jazde.",
            "uv_odpovedc": "musí byť vopred očistené, aby neznečisťovalo cestu.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "7be8c08bf568f57fab48"
        },
        {
            "nl_otazka_id": "1048",
            "uv_otazka": "Vodič nesmie odovzdať vedenie vozidla osobe,",
            "uv_odpoveda": "ktorá nemá najmenej dvojročnú prax vo vedení takéhoto vozidla alebo je pod značným vplyvom alkoholu.",
            "uv_odpovedb": "ktorá spĺňa podmienky ustanovené zákonom o cestnej premávke, je však mladšia ako 20 rokov.",
            "uv_odpovedc": "ktorej schopnosť viesť vozidlo je inak znížená, najmä úrazom, chorobou, nevoľnosťou alebo únavou.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "6d5a21f1a3ba22da9f11"
        },
        {
            "nl_otazka_id": "1432",
            "uv_otazka": "Toto reflexné označenie sa používa na označenie",
            "uv_odpoveda": "vlečného lana.",
            "uv_odpovedb": "prečniavajúceho nákladu.",
            "uv_odpovedc": "ťažnej tyče.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "obr3/pcp/9_3_0.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "177d80bf610c2f871018"
        },
        {
            "nl_otazka_id": "1378",
            "uv_otazka": "Ak ten, kto prekážku cestnej premávky spôsobil, ju bezodkladne neodstráni, je povinný ju označiť",
            "uv_odpoveda": "len za zníženej viditeľnosti.",
            "uv_odpovedb": "a oznámiť to policajtovi.",
            "uv_odpovedc": "len vtedy, ak prekážku spôsobil na diaľnici alebo na rýchlostnej ceste.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "12c8c7a48ae7c5f333af"
        },
        {
            "nl_otazka_id": "1459",
            "uv_otazka": "Vodič samovyvažovacieho vozidla smie jazdiť",
            "uv_odpoveda": "po pravej strane vyhradeného jazdného pruhu pre cyklistov, cestičky pre cyklistov alebo priechodu pre cyklistov, len ak neohrozí a neobmedzí cyklistov, pričom nesmie prekročiť rýchlosť chôdze.",
            "uv_odpovedb": "po ľavej strane vyhradeného jazdného pruhu pre cyklistov, cestičky pre cyklistov alebo priechodu pre cyklistov, len ak neohrozí a neobmedzí cyklistov.",
            "uv_odpovedc": "po pravej strane vyhradeného jazdného pruhu pre cyklistov, cestičky pre cyklistov alebo priechodu pre cyklistov, len ak neohrozí a neobmedzí cyklistov.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "71b2ff3536437912c88a"
        },
        {
            "nl_otazka_id": "1138",
            "uv_otazka": "Vodič, ktorý pri obchádzaní vozidla, ktoré zastavilo alebo stojí, prekážky cestnej premávky alebo chodca vybočuje zo smeru svojej jazdy",
            "uv_odpoveda": "nesmie ohroziť ani obmedziť vodiča idúceho za ním, ani iného účastníka cestnej premávky.",
            "uv_odpovedb": "môže ohroziť iného účastníka cestnej premávky.",
            "uv_odpovedc": "nesmie ohroziť vodiča idúceho za ním, ani iného účastníka cestnej premávky.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "dcd9cbbec74b60b4fe9a"
        },
        {
            "nl_otazka_id": "1437",
            "uv_otazka": "Osoby, ktoré sa pohybujú po krajnici alebo po okraji vozovky na lyžiach, korčuliach, kolobežke, skejtborde alebo na obdobnom športovom vybavení,  sa môžu pohybovať len po krajnici alebo po okraji vozovky len",
            "uv_odpoveda": "v rade za sebou.",
            "uv_odpovedb": "dvaja vedľa seba.",
            "uv_odpovedc": "traja vedľa seba.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "eec190a17e36b9e7b006"
        },
        {
            "nl_otazka_id": "1431",
            "uv_otazka": "Reflexné označenie s červenými a bielymi pruhmi širokými 70 mm až 100 mm smerujúcimi od pozdĺžnej strednej roviny vozidla pod uhlom 45° nadol sa používa na označenie",
            "uv_odpoveda": "tažnej tyče.",
            "uv_odpovedb": "nákladu, ktorý prečnieva vozidlo vpredu alebo vzadu o viac ako 40 cm.",
            "uv_odpovedc": "vlečného lana.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "obr3/pcp/9_3_0.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "4607f7bb64ce4760cf27"
        },
        {
            "nl_otazka_id": "1094",
            "uv_otazka": "Mimo obce na ceste s dvoma alebo s viacerými jazdnými pruhmi vyznačenými na vozovke v jednom smere jazdy sa jazdí",
            "uv_odpoveda": "v pravom jazdnom pruhu; v ostatných jazdných pruhoch sa smie jazdiť, ak je to potrebné na obchádzanie, predchádzanie, otáčanie alebo na odbočovanie.",
            "uv_odpovedb": "v ľavom jazdnom pruhu; ostatné jazdné pruhy smie používať len vodič nemotorového vozidla.",
            "uv_odpovedc": "v ktoromkoľvek jazdnom pruhu.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3281497feefc26444e9a"
        },
        {
            "nl_otazka_id": "1291",
            "uv_otazka": "Ak to vyžadujú okolnosti, najmä ak znamenie dávané smerovými svietidlami alebo rukou nie je dostatočne viditeľné najmä pre šírku nákladu alebo za zníženej viditeľnosti",
            "uv_odpoveda": "musí dávať znamenie spôsobilá a náležité poučená osoba alebo sa musí použiť iný zreteľný spôsob.",
            "uv_odpovedb": "nesmie vodič za žiadnych okolností uskutočniť zamýšľaný jazdný úkon.",
            "uv_odpovedc": "smie vodič uskutočniť zamýšľaný jazdný úkon, aj ak pritom ohrozí iných účastníkov cestnej premávky.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "10cb18cdf499d313567f"
        },
        {
            "nl_otazka_id": "1452",
            "uv_otazka": "Cyklisti smú jazdiť po ceste",
            "uv_odpoveda": "len jednotlivo za sebou; to neplatí pri jazde po cestičke pre cyklistov, poľnej ceste, lesnej ceste a v obytnej zóne, kde smú jazdiť dvaja cyklisti vedľa seba, ak tým neobmedzujú a neohrozujú ostatných účastníkov cestnej premávky. V skupine najmenej šiestich cyklistov môžu jazdiť dvaja cyklisti vedľa seba.",
            "uv_odpovedb": "bez držania riadidiel, ak v blízkosti nie sú motorové vozidlá.",
            "uv_odpovedc": "najviac dvaja vedľa seba.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3c97126898f8fe0cca1d"
        },
        {
            "nl_otazka_id": "1999",
            "uv_otazka": "Pri vlečení motorového vozidla sa smie jazdiť rýchlosťou najviac ",
            "uv_odpoveda": "50 km/h.",
            "uv_odpovedb": "60 km/h.",
            "uv_odpovedc": "70 km/h.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "0f2bae875a77c26ac152"
        },
        {
            "nl_otazka_id": "1464",
            "uv_otazka": "Osoba, ktorá tlačí alebo ťahá ručný vozík s celkovou šírkou väčšou ako 600 mm, je povinná ísť",
            "uv_odpoveda": "pri pravom okraji vozovky; ak sa tým neohrozujú ani neobmedzujú chodci, smie ísť po pravej krajnici.",
            "uv_odpovedb": "po chodníku, ak sa tým neohrozujú chodci.",
            "uv_odpovedc": "pri ľavom okraji vozovky alebo po ľavej krajnici.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "d6866097f8367ca2e11a"
        },
        {
            "nl_otazka_id": "1023",
            "uv_otazka": "Vozovkou sa rozumie",
            "uv_odpoveda": "spevnená časť cesty určená predovšetkým na premávku motorových vozidiel.",
            "uv_odpovedb": "cesta vrátane krajnice a chodníka.",
            "uv_odpovedc": "časť cesty určená len na premávku nemotorových vozidiel.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "814a702a59636ac577f1"
        },
        {
            "nl_otazka_id": "1986",
            "uv_otazka": "Medzi tri hlavné zásady (pravidlá) ekošoférovania patrí",
            "uv_odpoveda": "pravidelné stláčanie pedála akcelerátora.",
            "uv_odpovedb": "plynulá jazda “ako by sme nemali brzdy“.",
            "uv_odpovedc": "pripútanie sa bezpečnostnými pásmi.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "abef85a89a668aa455ca"
        },
        {
            "nl_otazka_id": "1305",
            "uv_otazka": "Vodič funkciu výstražných smerových svietidiel smie použiť len",
            "uv_odpoveda": "v obci.",
            "uv_odpovedb": "mimo obce.",
            "uv_odpovedc": "ak je to potrebné na odvrátenie hroziaceho nebezpečenstva.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "34beda47185479f7ca53"
        },
        {
            "nl_otazka_id": "1451",
            "uv_otazka": "Kde cestička pre cyklistov nie je alebo nie je zjazdná, jazdí sa na bicykli",
            "uv_odpoveda": "pri pravom okraji vozovky; ak sa tým neohrozujú ani neobmedzujú chodci, smie sa jazdiť po pravej krajnici.",
            "uv_odpovedb": "po chodníku alebo po pravej krajnici.",
            "uv_odpovedc": "po chodníku, ak sa tým neohrozujú chodci.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "345b4f6f0dd29e734815"
        },
        {
            "nl_otazka_id": "1140",
            "uv_otazka": "Vodič je povinný pri obchádzaní stojaceho vozidla pravidelnej verejnej dopravy osôb prihliadnuť na možnosť vbehnutia osôb na vozovku a",
            "uv_odpoveda": "za tým účelom jazdiť vždy rýchlosťou najviac 15 km/h.",
            "uv_odpovedb": "jazdu prispôsobiť tak, aby neboli ohrozené.",
            "uv_odpovedc": "za tým účelom vždy zastaviť vozidlo.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "c27612876454f1110cd7"
        },
        {
            "nl_otazka_id": "1932",
            "uv_otazka": "Pri jazde s vozidlom so samočinnou (automatickou) prevodovkou sa plynový a brzdový pedál ovláda",
            "uv_odpoveda": "ľavou nohou.",
            "uv_odpovedb": "pravou nohou.",
            "uv_odpovedc": "plynový pedál pravou nohou a brzdový pedál ľavou nohou.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "9494a93fbf29360556c6"
        },
        {
            "nl_otazka_id": "1953",
            "uv_otazka": "Pri správnom nastavení vzdialenosti sedadla vodiča je vodič schopný",
            "uv_odpoveda": "zošliapnúť pedál spojky do polovice chodu pedálu.",
            "uv_odpovedb": "zošliapnuť pedál spojky až na podlahu.",
            "uv_odpovedc": "dočiahnuť na zadné sedadlá vozidla.",
            "nl_okruh_id": "6",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "2",
            "poznamka": "",
            "hash": "bb2b2d646eb0a6abb8a2"
        },
        {
            "nl_otazka_id": "1192",
            "uv_otazka": "Ak je to nevyhnutne potrebné najmä na zaradenie sa do radu stojacich vozidiel alebo na vyjdenie z neho, vodič",
            "uv_odpoveda": "smie cúvať na križovatke s riadenou premávkou.",
            "uv_odpovedb": "nesmie cúvať na jednosmernej ceste.",
            "uv_odpovedc": "smie cúvať na jednosmernej ceste.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e6a6e6d0856853234d22"
        },
        {
            "nl_otazka_id": "1042",
            "uv_otazka": "Vodič nesmie požiť alkohol ani inú návykovú látku",
            "uv_odpoveda": "len počas vedenia motocykla a vozidla, ktorého najväčšia celková hmotnosť presahuje 3500 kg.",
            "uv_odpovedb": "najmenej 12 hodín po skončení vedenia vozidla.",
            "uv_odpovedc": "počas vedenia vozidla.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "2039cdc2f2fc73178cb2"
        },
        {
            "nl_otazka_id": "1584",
            "uv_otazka": "Vodič, ktorý prichádza do križovatky po vedľajšej ceste označenej touto dopravnou značkou,",
            "uv_odpoveda": "je povinný dať prednosť v jazde vozidlám prichádzajúcim sprava.",
            "uv_odpovedb": "má prednosť v jazde.",
            "uv_odpovedc": "je povinný dať prednosť v jazde vozidlám a ostatným účastníkom cestnej premávky prichádzajúcim po hlavnej ceste.",
            "nl_okruh_id": "2",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/202.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "7b9f11c89555ff2c45a1"
        },
        {
            "nl_otazka_id": "1190",
            "uv_otazka": "Vodič sa nesmie otáčať a nesmie cúvať",
            "uv_odpoveda": "na železničnom priecestí a v jeho tesnej blízkosti.",
            "uv_odpovedb": "v obytnej zóne.",
            "uv_odpovedc": "na železničnom priecestí a vo vzdialenosti 500 m pred ním.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "3c66198ef2b36f08ddc9"
        },
        {
            "nl_otazka_id": "1109",
            "uv_otazka": "Vodič pri prechádzaní z jedného jazdného pruhu do druhého jazdného pruhu",
            "uv_odpoveda": "môže ohroziť vodiča jazdiaceho v jazdnom pruhu, do ktorého prechádza.",
            "uv_odpovedb": "je povinný dávať znamenie o zmene smeru jazdy.",
            "uv_odpovedc": "môže dávať znamenie o zmene smeru jazdy, ak to vyžaduje bezpečnoeť alebo plynulosť premávky.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "d14b1eb9ede42e895cfd"
        },
        {
            "nl_otazka_id": "1400",
            "uv_otazka": "V motorovom vozidle smú sa na miestach na to vyhradených prepravovať osoby len do",
            "uv_odpoveda": "80 % prípustnej užitočnej hmotnosti vozidla.",
            "uv_odpovedb": "pohotovostnej hmotnosti vozidla.",
            "uv_odpovedc": "prípustnej užitočnej hmotnosti vozidla, pritom počet prepravovaných osôb nesmie byť vyšší, ako je počet povolených miest uvedených v osvedčení o evidencii.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "62718d34fbd952669ac3"
        },
        {
            "nl_otazka_id": "1294",
            "uv_otazka": "Znamenie o zmene smeru jazdy rukou sa dáva",
            "uv_odpoveda": "počas celého jazdného úkonu.",
            "uv_odpovedb": "len pred začatím jazdného úkonu.",
            "uv_odpovedc": "po začatí jazdného úkonu.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "732e1059fe63a1b9b7e3"
        },
        {
            "nl_otazka_id": "1503",
            "uv_otazka": "Policajt dáva pokyn na zastavenie vozidla",
            "uv_odpoveda": "zastavovacím terčom.",
            "uv_odpovedb": "zdvihnutým policajným zastavovacím terčom.",
            "uv_odpovedc": "policajným zastavovacím terčom, ktorým máva v hornom polkruhu.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e190ab40ae63cdce157a"
        },
        {
            "nl_otazka_id": "1586",
            "uv_otazka": "Vodič v kruhovom objazde, ktorý je označený dopravnou značkou “Kruhový objazd“ spolu s dopravnou značkou “Daj prednosť v jazde!“ alebo “Stoj, daj prednosť v jazde!“,",
            "uv_odpoveda": "má prednosť.",
            "uv_odpovedb": "musí zastaviť vozidlo pred priečnou súvislou čiarou so symbolom “Daj prednosť v jazde!“ (č. V5b).",
            "uv_odpovedc": "nemá prednosť v jazde.",
            "nl_okruh_id": "2",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/202.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "17657a7227839c00aac1"
        },
        {
            "nl_otazka_id": "2183",
            "uv_otazka": "Vodič sa musí riadiť nasledovnou zásadou",
            "uv_odpoveda": "Jazdiť v pravom jazdnom pruhu.",
            "uv_odpovedb": "Jazdiť v vľavom jazdnom pruhu.",
            "uv_odpovedc": "Pri zmene jazdných pruhov brať do úvahy v spätných zrkadlách “mŕtvy uhol“.",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "9c73065e2116282dd1d1"
        },
        {
            "nl_otazka_id": "2116",
            "uv_otazka": "Prevádzkovateľ vozidla nesmie v cestnej premávke prevádzkovať vozidlo, ktoré",
            "uv_odpoveda": "nemá identifikačné číslo vozidla VIN zhodné s identifikačným číslom vozidla VIN uvedeným v dokladoch vozidla, ak bolo také číslo vozidlu pridelené.",
            "uv_odpovedb": "má identifikačné číslo vozidla VIN zhodné s identifikačným číslom vozidla VIN uvedeným v dokladoch vozidla, ak bolo také číslo vozidlu pridelené.",
            "uv_odpovedc": "bolo hodnotené výsledkom “spôsobilé na prevádzku v cestnej premávke“ pri kontrole originality vozidla, ak takej kontrole podlieha.",
            "nl_okruh_id": "8",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "5499710483c2e0867cc8"
        },
        {
            "nl_otazka_id": "2219",
            "uv_otazka": "Aký druh šmyku môže končiť  “hodinami“",
            "uv_odpoveda": "kombinácia pretáčavého a nedotáčavého šmyku",
            "uv_odpovedb": "nedotáčavý šmyk",
            "uv_odpovedc": "pretáčavý šmyk",
            "nl_okruh_id": "9",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "4f2f27cbff7b0a5cb9c9"
        },
        {
            "nl_otazka_id": "1698",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Označuje cestu s jednosmernou premávkou a vyznačuje dovolený smer jazdy.",
            "uv_odpovedb": "Znamená “Smerová tabuľa na označenie obchádzky“.",
            "uv_odpovedc": "Znamená “Okruh“.",
            "nl_okruh_id": "3",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/321.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "27ccbca78b62cc468691"
        },
        {
            "nl_otazka_id": "1056",
            "uv_otazka": "Vodič nesmie",
            "uv_odpoveda": "znížiť náhle rýchlosť jazdy za žiadnych okolností.",
            "uv_odpovedb": "vyhadzovať z vozidla predmety a obťažovať ostatných účastníkov cestnej premávky ani iné osoby rozstrekovaním kaluží a blata.",
            "uv_odpovedc": "vypínať motor stojaceho vozidla pri čakaní na svetelný signál “Voľno“.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "0d96abe8b0c74df4075c"
        },
        {
            "nl_otazka_id": "1694",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Informuje o pruhu pre pomaly jazdiace vozidlá.",
            "uv_odpovedb": "Znamená “vyhradený pruh pre autobusy“.",
            "uv_odpovedc": "Informuje o zvýšení počtu jazdných pruhov a o spôsobe jeho zvýšenia.",
            "nl_okruh_id": "3",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/421-429.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "84b05800ffbf7b465a6b"
        },
        {
            "nl_otazka_id": "2300",
            "uv_otazka": "Vodič vozidla prichádza k svetelnej signalizácii, na ktorej svieti signál zelenej farby “Voľno“. Čo by mal urobiť:",
            "uv_odpoveda": "Mal by byť pripravený na zastavenie vozidla, lebo môže dôjsť k zmene svetelného signálu.",
            "uv_odpovedb": "Zrýchliť vozidlo, aby svetelnú signalizáciu prešiel skôr, ako dôjde k zmene signálu.",
            "uv_odpovedc": "Prudko zabrzdiť vozidlo, aby zastavil vozidlo pred zmenou svetelného signálu na signál červenej farby “Stoj“.",
            "nl_okruh_id": "9",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "ae2dbfcc1e4faa2b841a"
        },
        {
            "nl_otazka_id": "1248",
            "uv_otazka": "Vodič na cestnej zeleni a verejnej zeleni, na ostrovčekoch a deliacich pásoch, ak to nie je dovolené dopravnou značkou",
            "uv_odpoveda": "smie zastaviť, ak je to potrebné na urýchlené nastúpenie alebo vystúpenie prepravovaných osôb.",
            "uv_odpovedb": "nesmie zastaviť a stáť.",
            "uv_odpovedc": "smie stáť, ak čas státia nepresiahne 15 min.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "5f95d16a7b96a02ad3eb"
        },
        {
            "nl_otazka_id": "1221",
            "uv_otazka": "Vodič nesmie zastaviť a stáť",
            "uv_odpoveda": "v obci na križovatke tvaru “T“ na náprotivnej strane vyúsťujúcej cesty.",
            "uv_odpovedb": "v žiadnej zákrute a v jej tesnej blízkosti.",
            "uv_odpovedc": "v neprehľadnej zákrute a v jej tesnej blízkosti.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "bf185987cb4c4f075f07"
        },
        {
            "nl_otazka_id": "1700",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Upozorňuje na zákaz vjazdu.",
            "uv_odpovedb": "Označuje cestu, ktorá sa ďalej končí alebo ktorou nemožno ďalej pokračovať v jazde.",
            "uv_odpovedc": "Označuje križovatka tvaru “T“, kde prednosť v jazde je upravená dopravnými značkami.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/328.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "07198ce59c9ce325b011"
        },
        {
            "nl_otazka_id": "1885",
            "uv_otazka": "Tento pokyn policajta znamená",
            "uv_odpoveda": "“Voľno“ pre vodiča zeleného a vodiča žltého vozidla a “Stoj!“ pre vodiča modrého a vodiča červeného vozidla.",
            "uv_odpovedb": "“Voľno“ pre vodiča červeného a vodiča žltého vozidla a “Stoj!“ pre vodiča modrého a vodiča zeleného vozidla.",
            "uv_odpovedc": "“Voľno“ pre vodiča červeného a vodiča modrého vozidla a “Stoj!“ pre vodiča žltého vozidla; vodič zeleného vozidla smie odbočiť vpravo.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/31.png",
            "body": "4",
            "poznamka": "",
            "hash": "2c5c6a3651e5aabaf85b"
        },
        {
            "nl_otazka_id": "2332",
            "uv_otazka": "Pri “priamom zábere“ (i=1) sú",
            "uv_odpoveda": "otáčky vstupného hriadeľa prevodovky sú väčšie ako otáčky výstupného hriadeľa.",
            "uv_odpovedb": "otáčky vstupného hriadeľa prevodovky sú menšie ako otáčky výstupného hriadeľa.",
            "uv_odpovedc": "otáčky vstupného a výstupného hriadeľa prevodovky sú rovnaké.",
            "nl_okruh_id": "10",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "f7302f5d48ea8e644f2b"
        },
        {
            "nl_otazka_id": "1860",
            "uv_otazka": "Vodič modrého vozidla",
            "uv_odpoveda": "má pred vodičom červeného vozidla prednosť v jazde, pretože prichádza zľava.",
            "uv_odpovedb": "má pred vodičom červeného vozidla prednosť v jazde, pretože ide po kruhovom objazde, ktorý je označený dopravnou značkou “Kruhový objazd“ spolu s dopravnou značkou “Stoj, daj prednosť v jazde!“.",
            "uv_odpovedc": "musí dať prednosť v jazde vodičovi červeného vozidla, ktorý prichádza do križovatky sprava.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/29.png",
            "body": "4",
            "poznamka": "",
            "hash": "5bdd3d9c07fc5ec80537"
        },
        {
            "nl_otazka_id": "1880",
            "uv_otazka": "Prednosť v jazde má vodič",
            "uv_odpoveda": "červeného vozidla, pretože vchádza do kruhového objazdu",
            "uv_odpovedb": "modrého vozidla, pretože ide po kruhovom objazde, ktorý je označený dopravnou značkou “Kruhový objazd“ spolu s dopravnou značkou “Stoj, daj prednosť v jazde!“.",
            "uv_odpovedc": "červeného vozidla.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/29.png",
            "body": "4",
            "poznamka": "",
            "hash": "1966f39e8340dffd4165"
        },
        {
            "nl_otazka_id": "2331",
            "uv_otazka": "Zaradením prevodového stupňa “dopomala“ (prevodový pomer i > 1,0) sa",
            "uv_odpoveda": "znížia otáčky kolies a dochádza k  zvýšeniu sily na kolesách - obvykle 1. až 3. prevodový stupeň.",
            "uv_odpovedb": "zvýšia otáčky kolies a dochádza k  zvýšeniu sily - obvykle 4. až 6. prevodový stupeň.",
            "uv_odpovedc": "otáčky vstupného hriadeľa sú menšie ako sú otáčky výstupného hriadeľa  - obvykle 4. až 6. prevodový stupeň.",
            "nl_okruh_id": "10",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "1",
            "poznamka": "",
            "hash": "f759ae545bb085da1e2a"
        },
        {
            "nl_otazka_id": "1499",
            "uv_otazka": "Ak policajt pri riadení cestnej premávky dáva tento pokyn, znamená to",
            "uv_odpoveda": "“Stoj“.",
            "uv_odpovedb": "“Čakaj“.",
            "uv_odpovedc": "“Voľno“.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "obr3/pcp/22_1b.png",
            "body": "3",
            "poznamka": "",
            "hash": "460333aae6f664f3d007"
        },
        {
            "nl_otazka_id": "1886",
            "uv_otazka": "Tento pokyn policajta znamená “Stoj!“ pre vodiča",
            "uv_odpoveda": "modrého a vodiča červeného vozidla.",
            "uv_odpovedb": "modrého a vodiča žltého vozidla.",
            "uv_odpovedc": "zeleného a vodiča čierneho vozidla.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/32.png",
            "body": "4",
            "poznamka": "",
            "hash": "5e4723e697b66f21217b"
        },
        {
            "nl_otazka_id": "1859",
            "uv_otazka": "Prednosť v jazde má vodič",
            "uv_odpoveda": "červeného vozidla, pretože prichádza sprava.",
            "uv_odpovedb": "modrého vozidla, pretože ide po kruhovom objazde, ktorý je označený dopravnou značkou “Kruhový objazd“ spolu s dopravnou značkou “Stoj, daj prednosť v jazde!“.",
            "uv_odpovedc": "modrého vozidla, pretože prichádza zľava.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/29.png",
            "body": "4",
            "poznamka": "",
            "hash": "f6c72f503a843d786b84"
        },
        {
            "nl_otazka_id": "1500",
            "uv_otazka": "Ak policajt pri riadení cestnej premávky dáva tento pokyn, znamená to",
            "uv_odpoveda": "“Čakaj“ pre smer, ku ktorému stojí policajt bokom s upaženou rukou alebo upaženými rukami.",
            "uv_odpovedb": "“Voľno“ pre smer, ku ktorému stojí policajt bokom s upaženou rukou alebo upaženými rukami.",
            "uv_odpovedc": "“Stoj“ pre smer, ku ktorému stojí policajt bokom s upaženou rukou alebo upaženými rukami.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "obr3/pcp/22_1c.png",
            "body": "3",
            "poznamka": "",
            "hash": "c77162296262dd6b6b37"
        },
        {
            "nl_otazka_id": "1699",
            "uv_otazka": "Táto dopravná značka",
            "uv_odpoveda": "Prikazuje smer jazdy vyznačeným smerom.",
            "uv_odpovedb": "Označuje cestu s jednosmernou premávkou a vyznačuje dovolený smer jazdy.",
            "uv_odpovedc": "Znamená “Návestná smerová tabuľa“.",
            "nl_okruh_id": "3",
            "nl_correct": "2",
            "uv_obrazok": "obr3/dz/321.jpg",
            "body": "2",
            "poznamka": "",
            "hash": "b2307ca0c74a566e9844"
        },
        {
            "nl_otazka_id": "1517",
            "uv_otazka": "Ak svieti tento signál so symbolom šípky pri riadení cestnej premávky, znamená pre vodiča",
            "uv_odpoveda": "“Stoj“; vodič musí zastaviť vozidlo pred križovatkou a platí pre všetky smery jazdy.",
            "uv_odpovedb": "“Voľno“; vodič smie pokračovať v jazde pri dodržaní pravidiel cestnej premávky.",
            "uv_odpovedc": "“Stoj“; vodič musí zastaviť vozidlo pred križovatkou a platí len pre smer vyznačený šípkou.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "obr3/pcp/15_3_1.png",
            "body": "3",
            "poznamka": "",
            "hash": "cb989370187ab47dc6fa"
        },
        {
            "nl_otazka_id": "1884",
            "uv_otazka": "Ak má policajt pravú ruku predpaženú a ľavú upaženú, znamená to “Stoj!“ pre",
            "uv_odpoveda": "vodiča zeleného vozidla.",
            "uv_odpovedb": "chodcov prechádzajúcich za chrbtom policajta.",
            "uv_odpovedc": "vodiča žltého vozidla.",
            "nl_okruh_id": "4",
            "nl_correct": "3",
            "uv_obrazok": "obr3/ds/31.png",
            "body": "4",
            "poznamka": "",
            "hash": "718aa8d6401c2064b34e"
        },
        {
            "nl_otazka_id": "1266",
            "uv_otazka": "Pred železničným priecestím, pri ktorom je umiestnená dopravná značka “Stoj, daj prednosť vjazde!“, vodič je povinný zastaviť vozidlo",
            "uv_odpoveda": "vo vzdialenosti najmenej 80 m pred železničným priecestím.",
            "uv_odpovedb": "na takom mieste, z ktorého má náležitý rozhľad na trať.",
            "uv_odpovedc": "len vtedy, ak prichádzajú vozidlá v protismere.",
            "nl_okruh_id": "1",
            "nl_correct": "2",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "48fc90e2dca10be80805"
        },
        {
            "nl_otazka_id": "1296",
            "uv_otazka": "Pri jazde po kruhovom objazde vodič dáva znamenie o zmene smeru jazdy, ak",
            "uv_odpoveda": "z takej križovatky vychádza.",
            "uv_odpovedb": "dôjde k núdzovému zastaveniu vozidla alebo k dopravnej nehode.",
            "uv_odpovedc": "do takejto križovatky vchádza.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "obr3/dz/213.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "e9b1c077190b7525ddad"
        },
        {
            "nl_otazka_id": "1226",
            "uv_otazka": "Vodič smie zastaviť a stáť",
            "uv_odpoveda": "v obci na križovatke tvaru “T“ na náprotivnej strane vyúsťujúcej cesty.",
            "uv_odpovedb": "na pripájacom alebo na odbočovacom pruhu.",
            "uv_odpovedc": "na vnútornom jazdnom pruhu.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "2746a137a361e9b7376e"
        },
        {
            "nl_otazka_id": "1448",
            "uv_otazka": "Chodec",
            "uv_odpoveda": "nesmie prekonávať zábradlie ani iné zábrany.",
            "uv_odpovedb": "smie prekonávať zábradlie alebo iné zábrany, ak v blízkosti nie je priechod pre chodcov.",
            "uv_odpovedc": "nesmie na priechode pre chodcov fajčiť.",
            "nl_okruh_id": "1",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "e1ecb49232558242e671"
        },
        {
            "nl_otazka_id": "1498",
            "uv_otazka": "Ak policajt pri riadení cestnej premávky dáva tento pokyn, znamená to",
            "uv_odpoveda": "“Voľno“ pre smer, ku ktorému stojí policajt čelom alebo chrbtom s upaženou rukou alebo upaženými rukami.",
            "uv_odpovedb": "“Čakaj“ pre smer, ku ktorému stojí policajt čelom alebo chrbtom s upaženou rukou alebo upaženými rukami.",
            "uv_odpovedc": "“Stoj“ pre smer, ku ktorému stojí policajt čelom alebo chrbtom s upaženou rukou alebo upaženými rukami.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "obr3/pcp/22_1a.png",
            "body": "3",
            "poznamka": "",
            "hash": "9e6a483dae093174004e"
        },
        {
            "nl_otazka_id": "1888",
            "uv_otazka": "Ak má policajt pravú ruku predpaženú a ľavú upaženú, znamená to “Stoj!“ pre",
            "uv_odpoveda": "chodcov prechádzajúcich za chrbtom policajta.",
            "uv_odpovedb": "vodiča červeného a vodiča žltého vozidla.",
            "uv_odpovedc": "vodiča zeleného vozidla.",
            "nl_okruh_id": "4",
            "nl_correct": "2",
            "uv_obrazok": "obr3/ds/33.png",
            "body": "4",
            "poznamka": "",
            "hash": "7d650e12bc7b96ac69ae"
        },
        {
            "nl_otazka_id": "1225",
            "uv_otazka": "Vodič nesmie zastaviť a stáť",
            "uv_odpoveda": "na križovatke a vo vzdialenosti kratšej ako 15m pred hranicou križovatky a 10 m za ňou.",
            "uv_odpovedb": "v obci na križovatke tvaru “T“ na náprotivnej strane vyúsťujúcej cesty.",
            "uv_odpovedc": "na križovatke a vo vzdialenosti kratšej ako 5 m pred hranicou križovatky a 5 m za ňou.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "01285d903e5424bddb8b"
        },
        {
            "nl_otazka_id": "1175",
            "uv_otazka": "Na príkaz dopravnej značky “Stoj, daj prednosť v jazde“ vodič je povinný zastaviť vozidlo",
            "uv_odpoveda": "pred hranicou križovatky.",
            "uv_odpovedb": "pred priečnou súvislou čiarou so symbolom daj prednosť.",
            "uv_odpovedc": "na takom mieste, odkiaľ má na križovatku náležitý rozhľad.",
            "nl_okruh_id": "1",
            "nl_correct": "3",
            "uv_obrazok": "obr3/dz/202.jpg",
            "body": "3",
            "poznamka": "",
            "hash": "5c5024bc5a1bedc201b3"
        },
        {
            "nl_otazka_id": "1582",
            "uv_otazka": "Vodič, ktorý prichádza do križovatky po vedľajšej ceste označenej dopravnou značkou “Daj prednosť v jazde“ alebo “Stoj, daj prednosť v jazde“,",
            "uv_odpoveda": "je povinný dať prednosť v jazde vozidlám a ostatným účastníkom cestnej premávky prichádzajúcim po hlavnej ceste.",
            "uv_odpovedb": "je povinný dať prednosť v jazde vozidlám prichádzajúcim sprava.",
            "uv_odpovedc": "má prednosť v jazde.",
            "nl_okruh_id": "2",
            "nl_correct": "1",
            "uv_obrazok": "",
            "body": "3",
            "poznamka": "",
            "hash": "0a8e3235c710c3957130"
        }
    ],
    [
        {
            "id": "1",
            "text": "Pravidlá cestnej premávky"
        },
        {
            "id": "2",
            "text": "Uplatnovanie pravidiel prednosti v jazde a rýchlostné obmedzenia"
        },
        {
            "id": "3",
            "text": "Dopravné znacky a dopravné zariadenia"
        },
        {
            "id": "4",
            "text": "Dopravné situácie  na križovatkách"
        },
        {
            "id": "5",
            "text": "Všeobecné pravidlá správania sa v prípade dopravnej nehody"
        },
        {
            "id": "6",
            "text": "Teória vedenia vozidla"
        },
        {
            "id": "7",
            "text": "Predpisy týkajúce sa dokladov požadovaných v prípade používania vozidla a organizácia casu v doprave"
        },
        {
            "id": "8",
            "text": "Podmienky prevádzky vozidiel v premávke na pozemných komunikáciách"
        },
        {
            "id": "9",
            "text": "Zásady bezpecnej jazdy"
        },
        {
            "id": "10",
            "text": "Konštrukcia vozidiel a ich údržba"
        },
        {
            "id": "100",
            "text": "Všeobecné otázky"
        },
        {
            "id": "101",
            "text": "Technické otázky"
        },
        {
            "id": "102",
            "text": "Značky"
        },
        {
            "id": "103",
            "text": "Križovatky"
        }
    ],
    ["Verzia 31.5.2024"]
]

export default datax;