import React, { useEffect, useState } from "react";
import app from './../asets/app.png';
import sipka from './../asets/sipka.gif';


const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = evt => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  if (!supportsPWA) {
    return <div style={{left:"50px",position:"absolute"}} onClick={()=>{alert('Aplikácia sa startuje.\nSkúste znova za pár sekúnd.')}}><img src={app} height="38"/></div>;
  }
  return (
    <>
    <div style={{left:"50px",position:"absolute",cursor:"pointer"}}><img src={app} onClick={onClick} height="38" title="Nainštalovať aplikáciu"/></div> <div style={{left:"100px",position:"absolute"}}> <img src={sipka} style={{width:'50px'}}></img> nainštalovať ako apku <img src="./images/16.gif"></img></div>
    </>
  );
};

export default InstallPWA;
